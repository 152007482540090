import axios from "axios";
import { auth } from "./firebase";

export default async function sendPushNotification(
  userIds,
  notification,
  data
) {
  try {
    const accessToken = await auth.currentUser.getIdToken();
    const res = await axios.post(
      "https://stairs-app.herokuapp.com/api/notification",
      { userIds, notification, data },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    console.log("sendPushNotification", res.data);
  } catch (error) {
    console.log("sendPushNotification-error", error.message);
  }
}
