import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "date-fns";
import Axios from "axios";
import { rURL } from "../../utils/Links";
import sendPushNotification from "../../utils/sendPushNotification";
import SearchableDropdown from "./SearchableDropdown";

import {
  selectUser,
  selectUserData,
  selectUserType,
  setUserData,
} from "../../features/userSlice";
import { db } from "../../utils/firebase";
import firebase from "firebase";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
var win = null;
moment.locale("en-in");

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      zIndex: 1,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "& .MuiOutlinedInput-input": {
      color: "black",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "black",
    },
    "& .MuiInputLabel-outlined": {
      color: "black",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "black",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "black",
    },
    width: "100%",
  },
});

export default function CreateEvent(props) {
  const classes = useStyles();
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const defaultValue = {
    year: 2021,
    month: 4,
    day: 5,
  };
  const [selectedDay, setSelectedDay] = useState(defaultValue);
  const [selectedDays, setSelectedDays] = useState([]);
  const history = useHistory();
  const [athletes, setAthletes] = useState([]);
  const [eventName, setEventName] = useState("");
  const [description, setDescription] = useState("");
  const [eventDate, setEventDate] = useState(new Date());
  const [eventTime, setEventTime] = useState("17:00");
  const [showVideoLink, setShowVideoLink] = useState(false);
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const location = useLocation();
  const [meetURL, setmeetURL] = useState("");
  const [t, sett] = useState(true);
  const [MeetType, setMeetType] = useState("");
  // useEffect(async () => {
  //   console.log(location);
  //   const queryURL = new URLSearchParams(location.search);
  //   console.log(queryURL.get("code"));
  //   if (queryURL.get("code")) {
  //     console.log(2);
  //     let axiosConfig = {
  //       headers: {
  //         "Content-Type": "application/json;charset=UTF-8",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     };

  //     let code = queryURL.get("code");
  //     win?.close();
  //     await Axios.post(
  //       "https://stairs-app.herokuapp.com/api/getToken",
  //       {
  //         code: code,
  //       },

  //       axiosConfig
  //     ).then((res) => {
  //       console.log(res);
  //       if (res.data.success) {
  //         db.collection("secrets")
  //           .doc("userData?.id")
  //           .set({ tokens: res.data.tokens });
  //       }
  //     });
  //   }
  // }, []);

  useState(() => {
    if (userData?.id) {
      const data = [];
      var temp = [];
      db.collection("athletes")
        .orderBy("name", "asc")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((athlete) => {
            if (userData?.data.listOfAthletes.includes(athlete.id)) {
              let currentID = athlete.id;
              let appObj = { ...athlete.data(), ["id"]: currentID };
              data.push(appObj);
            }
            if (
              props.athletes &&
              props.athletes.length > 0 &&
              props.athletes.includes(athlete.id)
            ) {
              temp.push({ ...athlete.data(), ["id"]: athlete.id });
            }
          });
          if (temp.length > 0) {
            setSelectedAthletes(temp);
            console.log(temp);
          }
          setAthletes(data);
        });
    }
  }, [userData?.id]);

  useEffect(() => {
    if (props.id && athletes.length > 0) {
      setDescription(props.data.description);
      setEventName(props.data.eventName);
      let now = moment(new Date(props.data.eventDate));
      let today_date = {
        year: now.get("year"),
        month: now.get("month") + 1,
        day: now.get("date"),
      };
      setSelectedDay(today_date);
      setEventTime(moment(new Date(props.data.eventDate)).format("HH:mm"));
      setShowVideoLink(props.data.showVideoLink);
    } else {
      setDescription("");
      setEventName("");
      setEventTime("17:30");
      setShowVideoLink(false);
    }
  }, [props?.id, athletes]);

  const handler = async () => {
    if (eventName && selectedAthletes != []) {
      var local_athletes = [];
      var local_token = [];
      selectedAthletes.forEach((id) => {
        local_athletes.push(id.id);
        if (id?.token && id.token != "") {
          local_token.push(id?.token);
        }
      });

      let gmeetlink = "";

      if (!showVideoLink) {
        selectedDays.map((sd) =>
          local_athletes.map((a) =>
            db
              .collection("events")
              .doc()
              .set({
                name: eventName,
                date: firebase.firestore.Timestamp.fromDate(
                  new Date(
                    sd.year,
                    sd.month - 1,
                    sd.day,
                    eventTime.substring(0, 2),
                    eventTime.substring(3, 5),
                    0,
                    0
                  )
                ),
                description: description,
                athletes: [a],
                coachID: userData.id,
                showVideoLink: showVideoLink,
                videolink: "",
              })
          )
        );

        selectedDays.map((sd) =>
          sendPushNotification(
            selectedAthletes.map((sa) => sa.id),
            {
              title: `Event added`,
              body: `${eventName} for ${new Date(
                sd.year,
                sd.month - 1,
                sd.day,
                eventTime.substring(0, 2),
                eventTime.substring(3, 5),
                0,
                0
              )}`,
            }
          )
        );
        alert("Event Added");
      } else {
        db.collection("secrets")
          .doc(userData?.id)
          .get()
          .then(async (snap) => {
            if (!snap.exists) {
              console.log(11222);
              const data = Axios.get(
                "https://stairs-app.herokuapp.com/api/gmeet"
              ).then((res) => {
                if (res?.data?.url) {
                  let url = res?.data?.url;
                  win = window.open(url, "win1", "width = 500, height = 300");
                  var pollTimer = window.setInterval(async function () {
                    try {
                      console.log("Widows URL", win.document.URL);
                      const queryURL = new URL(win.document.URL);
                      console.log("URLRLRLR", queryURL);
                      var url = queryURL.searchParams.get("code");
                      console.log("HIIIII", url);
                      if (url) {
                        window.clearInterval(pollTimer);
                        let axiosConfig = {
                          headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                          },
                        };

                        let code = url;
                        console.log(code);
                        win?.close();
                        await Axios.post(
                          "https://stairs-app.herokuapp.com/api/getToken",
                          {
                            code: code.toString(),
                            rURL,
                          },

                          axiosConfig
                        ).then(async (res) => {
                          if (res.data.success) {
                            // gToken = res.data.tokens;

                            db.collection("secrets")
                              .doc(userData?.id)
                              .set({ tokens: res.data.tokens })
                              .then(async () => {
                                // setTokens(res.data.tokens);
                                let axiosConfig = {
                                  headers: {
                                    "Content-Type":
                                      "application/json;charset=UTF-8",
                                    "Access-Control-Allow-Origin": "*",
                                  },
                                };
                                await Axios.post(
                                  "https://stairs-app.herokuapp.com/api/gmeet/getLink",
                                  {
                                    tokens: res.data.tokens,
                                    rURL,
                                    eventdata: {
                                      eventname: "Syncing",
                                      description: "Syncing Stairs with Google",
                                      videoConference: true,
                                      date: firebase.firestore.Timestamp.fromDate(
                                        new Date()
                                      ),
                                      start:
                                        firebase.firestore.Timestamp.fromDate(
                                          new Date()
                                        ),
                                    },
                                  },

                                  axiosConfig
                                )
                                  .then((res) => {
                                    if (!res?.data?.success) {
                                      alert("Cant get URL please try again");
                                      return;
                                    }
                                    console.log(res?.data, "gmeet link");
                                    gmeetlink =
                                      res?.data.event.data.hangoutLink;

                                    db.collection("coaches")
                                      .doc(userData.id)
                                      .update({
                                        videoURL: gmeetlink,
                                      });
                                  })
                                  .then(async () => {
                                    selectedDays.map((sd) =>
                                      local_athletes.map((a) =>
                                        db
                                          .collection("events")
                                          .doc()
                                          .set({
                                            name: eventName,
                                            date: firebase.firestore.Timestamp.fromDate(
                                              new Date(
                                                sd.year,
                                                sd.month - 1,
                                                sd.day,
                                                eventTime.substring(0, 2),
                                                eventTime.substring(3, 5),
                                                0,
                                                0
                                              )
                                            ),
                                            description: description,
                                            athletes: [a],
                                            coachID: userData.id,
                                            showVideoLink: showVideoLink,
                                            videolink: gmeetlink,
                                          })
                                      )
                                    );

                                    selectedDays.map((sd) =>
                                      sendPushNotification(
                                        selectedAthletes.map((sa) => sa.id),
                                        {
                                          title: `Event added`,
                                          body: `${eventName} for ${new Date(
                                            sd.year,
                                            sd.month - 1,
                                            sd.day,
                                            eventTime.substring(0, 2),
                                            eventTime.substring(3, 5),
                                            0,
                                            0
                                          )}`,
                                        }
                                      )
                                    );

                                    alert("Event Added");
                                  });

                                console.log("tokens  saved to db");
                              });
                          } else {
                            console.log("dont get token");
                            return null;
                          }
                        });
                      } else {
                        console.log("dont get code");
                        return null;
                      }
                    } catch (error) {
                      console.log(error, "window opening problem");
                      return null;
                    }
                  }, 1000);
                } else {
                  console.log("dont get credentials");
                  return null;
                }
              });
            } else {
              let data = snap.data().tokens;
              console.log(data, "data check");
              if (eventName && selectedAthletes != []) {
                let axiosConfig = {
                  headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": "*",
                  },
                };

                console.log(data);
                await Axios.post(
                  "https://stairs-app.herokuapp.com/api/gmeet/getLink",
                  {
                    tokens: data,
                    rURL,
                    eventdata: {
                      eventname: eventName,
                      description: description,
                      videoConference: MeetType === "gmeet",

                      date: firebase.firestore.Timestamp.fromDate(
                        new Date(
                          selectedDay.year,
                          selectedDay.month - 1,
                          selectedDay.day,
                          eventTime.substring(0, 2),
                          eventTime.substring(3, 5),
                          0,
                          0
                        )
                      ),
                      start: firebase.firestore.Timestamp.fromDate(
                        new Date(
                          selectedDay.year,
                          selectedDay.month - 1,
                          selectedDay.day,
                          eventTime.substring(0, 2) + 1,
                          eventTime.substring(3, 5),
                          0,
                          0
                        )
                      ),
                    },
                  },

                  axiosConfig
                ).then(async (out) => {
                  if (out.data?.success) {
                    console.log(out);
                    setmeetURL(userData?.videoURL);
                    //setShowVideoLink(!showVideoLink);
                    selectedDays.map((sd) =>
                      local_athletes.map((a) =>
                        db
                          .collection("events")
                          .doc()
                          .set({
                            name: eventName,
                            date: firebase.firestore.Timestamp.fromDate(
                              new Date(
                                sd.year,
                                sd.month - 1,
                                sd.day,
                                eventTime.substring(0, 2),
                                eventTime.substring(3, 5),
                                0,
                                0
                              )
                            ),
                            description: description,
                            athletes: [a],
                            coachID: userData.id,
                            showVideoLink: showVideoLink,
                            videolink: userData?.data?.videoURL,
                            // videolink: showVideoLink
                            //   ? MeetType == "gmeet"
                            //     ? out.data?.event?.data?.hangoutLink
                            //     : "https://meet.jit.si/wellzap-" +
                            //     `${userData.data.pin}`
                            //   : "",
                          })
                      )
                    );

                    selectedDays.map((sd) =>
                      sendPushNotification(
                        selectedAthletes.map((sa) => sa.id),
                        {
                          title: `Event added`,
                          body: `${eventName} for ${new Date(
                            sd.year,
                            sd.month - 1,
                            sd.day,
                            eventTime.substring(0, 2),
                            eventTime.substring(3, 5),
                            0,
                            0
                          )}`,
                        }
                      )
                    );
                    props.setAddedEventFunc();
                    alert("Event Added");
                    setEventName("");
                    setEventTime("17:30");
                    setAthletes([]);
                    setDescription("");
                  } else {
                    const data = Axios.post(
                      "https://stairs-app.herokuapp.com/api/gmeet",
                      { rURL }
                    ).then((res) => {
                      console.log(res.data);
                      if (res?.data?.url) {
                        let url = res?.data?.url;
                        win = window.open(
                          url,
                          "win1",
                          "width = 500, height = 300"
                        );
                        var pollTimer = window.setInterval(async function () {
                          try {
                            console.log(win.document.URL);

                            const queryURL = new URL(win.document.URL);

                            var url = queryURL.searchParams.get("code");
                            if (queryURL.searchParams.get("code")) {
                              window.clearInterval(pollTimer);
                              let axiosConfig = {
                                headers: {
                                  "Content-Type":
                                    "application/json;charset=UTF-8",
                                  "Access-Control-Allow-Origin": "*",
                                },
                              };

                              let code = url;
                              console.log(code);
                              win?.close();
                              await Axios.post(
                                "https://stairs-app.herokuapp.com/api/getToken",
                                {
                                  code: code.toString(),
                                  rURL,
                                },

                                axiosConfig
                              ).then((res) => {
                                console.log(res);
                                if (res.data.success) {
                                  db.collection("secrets")
                                    .doc(userData?.id)
                                    .set({ tokens: res.data.tokens })
                                    .then(async () => {
                                      let data = res.data.tokens;

                                      let axiosConfig = {
                                        headers: {
                                          "Content-Type":
                                            "application/json;charset=UTF-8",
                                          "Access-Control-Allow-Origin": "*",
                                        },
                                      };

                                      console.log(data);
                                      await Axios.post(
                                        "https://stairs-app.herokuapp.com/api/gmeet/getLink",
                                        {
                                          tokens: data,
                                          rURL,
                                          eventdata: {
                                            eventname: eventName,
                                            description: description,
                                            videoConference:
                                              MeetType === "gmeet",

                                            date: firebase.firestore.Timestamp.fromDate(
                                              new Date(
                                                selectedDay.year,
                                                selectedDay.month - 1,
                                                selectedDay.day,
                                                eventTime.substring(0, 2),
                                                eventTime.substring(3, 5),
                                                0,
                                                0
                                              )
                                            ),
                                            start:
                                              firebase.firestore.Timestamp.fromDate(
                                                new Date(
                                                  selectedDay.year,
                                                  selectedDay.month - 1,
                                                  selectedDay.day,
                                                  eventTime.substring(0, 2) + 1,
                                                  eventTime.substring(3, 5),
                                                  0,
                                                  0
                                                )
                                              ),
                                          },
                                        },

                                        axiosConfig
                                      ).then(async (out) => {
                                        console.log(out);
                                        if (out.data.success) {
                                          setmeetURL(userData?.videoURL);
                                          //setShowVideoLink(!showVideoLink);
                                          selectedDays.map((sd) =>
                                            local_athletes.map((a) =>
                                              db
                                                .collection("events")
                                                .doc()
                                                .set({
                                                  name: eventName,
                                                  date: firebase.firestore.Timestamp.fromDate(
                                                    new Date(
                                                      sd.year,
                                                      sd.month - 1,
                                                      sd.day,
                                                      eventTime.substring(0, 2),
                                                      eventTime.substring(3, 5),
                                                      0,
                                                      0
                                                    )
                                                  ),
                                                  description: description,
                                                  athletes: [a],
                                                  coachID: userData.id,
                                                  showVideoLink: showVideoLink,

                                                  videolink:
                                                    userData?.data?.videoURL,
                                                })
                                            )
                                          );

                                          selectedDays.map((sd) =>
                                            sendPushNotification(
                                              selectedAthletes.map(
                                                (sa) => sa.id
                                              ),
                                              {
                                                title: `Event added`,
                                                body: `${eventName} for ${new Date(
                                                  sd.year,
                                                  sd.month - 1,
                                                  sd.day,
                                                  eventTime.substring(0, 2),
                                                  eventTime.substring(3, 5),
                                                  0,
                                                  0
                                                )}`,
                                              }
                                            )
                                          );

                                          props.setAddedEventFunc();
                                          alert("Event Added");
                                          setEventName("");
                                          setEventTime("17:30");
                                          setAthletes([]);
                                          setDescription("");
                                        } else {
                                          alert("pleas try again later");
                                        }
                                      });
                                    });
                                }
                              });
                              win.close();
                            }
                          } catch (err) {
                            console.log(err);
                            // alert("please try again later");
                          }
                        }, 1000);
                        //history.push();
                      }
                    });
                  }
                });
              }
            }
          });
      }
    } else {
      alert("Pls enter all fields");
    }
  };

  useEffect(() => {
    let now = moment();
    let today_date = {
      year: now.get("year"),
      month: now.get("month") + 1,
      day: now.get("date"),
    };
    setSelectedDay(today_date);
  }, [props?.id]);

  return (
    <div style={{ flex: 1, width: "90%" }}>
      <p>{props?.id ? "Edit Event" : "Create Event"}</p>
      <TextField
        label="Event Name"
        variant="outlined"
        className={classes.root}
        color="black"
        value={eventName}
        required
        onChange={(event) => {
          const { value } = event.target;
          setEventName(value);
        }}
      />

      <p style={{ fontSize: 18, marginTop: 20 }}>Event Date *</p>
      <Calendar
        value={selectedDays}
        onChange={setSelectedDays}
        colorPrimary="var(--primarycolor)" // added this
        colorPrimaryLight="blue"
        calendarClassName="customcalendarAddGoal" // and this
        calendarTodayClassName="custom-today-day"
        minimumDate={utils().getToday()}
      />
      <TextField
        id="time"
        label="Select Time"
        type="time"
        defaultValue="07:30"
        style={{ width: "100%" }}
        inputProps={{
          step: 300, // 5 min
        }}
        value={eventTime}
        onChange={(event) => {
          const { value } = event.target;
          setEventTime(value);
        }}
      />

      <div
        style={{
          marginTop: 20,
          width: "100%",
          zIndex: "2",
          position: "relative",
        }}
      >
        <SearchableDropdown
          name="Select Athletes"
          list={athletes}
          state={selectedAthletes}
          setState={setSelectedAthletes}
        />
      </div>

      <TextField
        label="Event Description"
        variant="outlined"
        color="black"
        value={description}
        required
        multiline
        rows={4}
        style={{ marginTop: 20 }}
        className={classes.root}
        onChange={(event) => {
          const { value } = event.target;
          setDescription(value);
        }}
      />
      <button
        onClick={() => {}}
        style={{
          borderRadius: 5,
          marginTop: 20,
          alignItems: "center",
          borderWidth: 1,
          borderColor: "black",
          padding: 10,
          width: "100%",
          color: "var(--secondarycolor)",
          backgroundColor: showVideoLink && "#ef654d",

          cursor: "pointer",
        }}
      >
        <p
          style={{
            fontSize: 14,
            color: showVideoLink ? "var(--secondarycolor)" : "black",
            textAlign: "center",
            margin: 0,
            padding: 0,
          }}
          onClick={() => {
            setShowVideoLink(!showVideoLink);
            setMeetType("");
          }}
        >
          {!showVideoLink
            ? "Add Video Conferencing"
            : "Remove Video Conferencing"}
        </p>
      </button>

      <div
        onClick={() => {
          handler();
          // if (showVideoLink) {
          //   handler();
          // } else {
          //   if (eventName && selectedAthletes != []) {
          //     var local_athletes = [];
          //     var local_token = [];
          //     selectedAthletes.forEach((id) => {
          //       local_athletes.push(id.id);
          //       if (id?.token && id.token != "") {
          //         local_token.push(id?.token);
          //       }
          //     });

          //     if (props?.id) {
          //       alert(eventName);

          //       db.collection("events")
          //         .doc(props.id)
          //         .update({
          //           eventName,
          //           date: firebase.firestore.Timestamp.fromDate(
          //             new Date(
          //               selectedDay.year,
          //               selectedDay.month - 1,
          //               selectedDay.day,
          //               eventTime.substring(0, 2),
          //               eventTime.substring(3, 5),
          //               0,
          //               0
          //             )
          //           ),
          //           description,
          //           athletes: local_athletes,
          //           coachID: userData.id,
          //           showVideoLink,
          //           videolink: userData?.data?.videolink,
          //         })
          //         .then(() => {
          //           props.setAddedEventFunc();
          //           alert("Event Updated");
          //         });
          //     } else {
          //       const newCityRef = db.collection("events").doc();
          //       const res = await newCityRef
          //         .set({
          //           name: eventName,
          //           date: firebase.firestore.Timestamp.fromDate(
          //             new Date(
          //               selectedDay.year,
          //               selectedDay.month - 1,
          //               selectedDay.day,
          //               eventTime.substring(0, 2),
          //               eventTime.substring(3, 5),
          //               0,
          //               0
          //             )
          //           ),
          //           description: description,
          //           athletes: local_athletes,
          //           coachID: userData.id,
          //           showVideoLink: showVideoLink,
          //           videolink: userData?.data?.videolink,
          //         })
          //         .then(() => {
          //           props.setAddedEventFunc();
          //           alert("Event Added");
          //           setEventName("");
          //           setEventTime("17:30");
          //           setAthletes([]);
          //           setDescription("");
          //         });
          //     }
          //   } else {
          //     alert("please enter all fields");
          //   }
          // }
        }}
        style={{
          backgroundColor: "var(--primarycolor)",
          borderRadius: 10,
          padding: 10,
          marginTop: 20,
        }}
      >
        <p
          style={{
            fontSize: 16,
            fontWeight: "bold",
            color: "var(--secondarycolor)",
            alignSelf: "center",
            margin: 0,
            padding: 0,
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          {props?.id ? "Edit Event" : "Add Event"}
        </p>
      </div>
    </div>
  );
}
