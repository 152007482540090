import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { customStyles } from './Styles/UIStyles';
import "./components.css"
export const Header = () => {

    const history = useHistory();
    const styles = customStyles();

    return (
        <div>
            <h1 className={styles.section_header} id="header">
                <label htmlFor="icon-button-file">
                    <IconButton className={styles.icon_button} aria-label="back-button" onClick={() => history.push("/select-theme")} >
                        <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                </label>
                Create/Update Website
            </h1>
            <h3 className={styles.section_header}>
                Fill In Details Notifications
            </h3>
        </div>
    )
}