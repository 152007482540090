import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../utils/firebase";
import "./PrevTherapy.css";

import { useHistory } from "react-router-dom";
import {
  selectTemperoryId,
  selectUser,
  selectUserType,
  selectUserData,
} from "../../features/userSlice";
import addButton from "./add.png";
import backButton from "./left-arrow.png";

const PreviousPysiotherapyAss = () => {
  const history = useHistory();
  const user = useSelector(selectUser);
  const userType = useSelector(selectUserType);
  const temperoryId = useSelector(selectTemperoryId);
  const userData = useSelector(selectUserData);

  const [getTherapys, setGetTheraphys] = useState([]);
  const [getUserTherapys, setGetUserTheraphys] = useState([]);

  let userId = userData?.id;
  console.log(userId);
  console.log("Heelo", temperoryId);

  useEffect(() => {
    if (temperoryId) {
      db.collection("athletes")
        .doc(temperoryId)
        .collection("Physiotheraphy")
        // .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) =>
          setGetTheraphys(
            snapshot.docs.map((doc) => ({ id: doc.id, therapys: doc.data() }))
          )
        );
    }
  }, [temperoryId]);

  useEffect(() => {
    if (userId) {
      db.collection("athletes")
        .doc(userId)
        .collection("Physiotheraphy")
        // .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) =>
          setGetUserTheraphys(
            snapshot.docs.map((doc) => ({ id: doc.id, therapys: doc.data() }))
          )
        );
    }
  }, [userId]);

  return (
    <>
      {userType != "athlete" && (
        <div className="main" style={{}}>
          <div className="sameline">
            <img
              style={{
                width: 16,
                height: 16,
                cursor: "pointer",
              }}
              src={backButton}
              onClick={() => {
                // window.open("/Athlete/reports/" + Id, "");
                console.log(1);
                history.push({
                  pathname: "/Athlete",
                  state: {
                    AthleteId: temperoryId,
                  },
                });
              }}
            />
            <h1
              style={{
                marginLeft: 10,
                fontSize: 25,
                fontWeight: 500,
              }}
            >
              Previous Physiotherapy Assessments
            </h1>
            <div
              id="newassessment"
              className="button"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 6,
                marginLeft: 16,
              }}
              onClick={() => {
                // window.open("/Athlete/reports/" + Id, "");
                console.log(1);
                history.push({
                  pathname: "/Physiotherapy_Assessment",
                  state: {
                    AthleteId: temperoryId,
                  },
                });
              }}
            >
              <img
                style={{
                  width: 25,
                  height: 25,
                }}
                src={addButton}
              />

              <h2
                style={{
                  paddingLeft: 20,
                }}
              >
                Add a new assessment
              </h2>
            </div>
          </div>

          {getTherapys.map(({ id, therapys }) => (
            <div
              className="prevtherapy"
              onClick={() => {
                // window.open("/Athlete/reports/" + Id, "");
                console.log(1);
                history.push({
                  pathname: `Particular_Physiotherapy_Assessment/${id}`,
                  state: {
                    AthleteId: temperoryId,
                  },
                });
              }}
            >
              <h1
                style={{
                  color: "white",
                  fontSize: 18,
                  padding: 8,
                }}
                key={id}
              >
                {therapys.Name} ({therapys.Date})
              </h1>
            </div>
          ))}
        </div>
      )}

      {userType == "athlete" && (
        <div
          className="main"
          style={{
            marginLeft: 18,
          }}
        >
          <div className="sameline">
            <img
              style={{
                width: 16,
                height: 16,
                cursor: "pointer",
              }}
              src={backButton}
              onClick={() => {
                // window.open("/Athlete/reports/" + Id, "");
                console.log(1);
                history.push({
                  pathname: "/profile",
                  state: {
                    AthleteId: userId,
                  },
                });
              }}
            />
            <h1
              style={{
                marginLeft: 10,
                fontSize: 25,
                fontWeight: 500,
              }}
            >
              Previous Pysiotherapys
            </h1>
          </div>

          {getUserTherapys.map(({ id, therapys }) => (
            <div
              className="prevtherapy"
              onClick={() => {
                // window.open("/Athlete/reports/" + Id, "");
                console.log(1);
                history.push({
                  pathname: `Particular_Physiotherapy_Assessment/${id}`,
                  state: {
                    AthleteId: userId,
                  },
                });
              }}
            >
              <h1
                style={{
                  color: "white",
                  fontSize: 18,
                  padding: 8,
                }}
                key={id}
              >
                {therapys.Name} ({therapys.Date})
              </h1>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default PreviousPysiotherapyAss;
