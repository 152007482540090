import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { auth, db } from "../../utils/firebase";
import firebase from "firebase";
import { storage } from "../../utils/firebase";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import VideocamIcon from "@material-ui/icons/Videocam";

import {
  setDbID,
  selectDbId,
  selectUser,
  setUserDetails,
  selectShowData,
  logout,
  setUserData,
  selectUserData,
} from "../../features/userSlice";
import formatSpecificDate from "../../functions/formatSpecificDate";

const EditOwnWorkout = () => {
  const history = useHistory();
  const userData = useSelector(selectUserData);
  const location = useLocation();
  const [name, setName] = useState(location.state.workout.name);
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [videoData, setVideoData] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [url, setUrl] = React.useState(location.state.workout.videoUrl);
  let time = formatSpecificDate(new Date());
  let coachEmail = userData?.data?.email;

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          margin: 20,
          alignItems: "center",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              marginTop: 5,
            }}
            onClick={() => history.goBack()}
          >
            <ArrowBackIosRoundedIcon
              style={{ height: 18, width: 18, padding: 5, cursor: "pointer" }}
            />
          </div>
          <h2
            style={{
              marginLeft: 10,
              fontWeight: 200,
            }}
          >
            Edit Workout
          </h2>
        </div>
      </div>
      <div
        style={{
          marginLeft: 20,
          marginTop: 40,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={location.state.workout.thumbnail_url}
            width="100"
            height="100"
          />
          <a
            style={{
              marginLeft: 30,
            }}
            href={location.state.workout.videoUrl}
            target="_blank"
          >
            {location.state.workout.videoUrl}
          </a>
        </div>

        <h4>Name</h4>
        <input
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          style={{
            width: 500,
            marginTop: -10,
          }}
        />
        <h3 style={{ marginTop: 30, fontSize: 15, color: "black" }}>
          Change Video Thumbnail
        </h3>
        <div
          class="videoupload"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {thumbnail ? (
            <img
              src={URL.createObjectURL(thumbnail)}
              width="120"
              height="100"
            />
          ) : (
            <img src="/assets/illustration.png" width="120" height="100" />
          )}
          <label
            for="thumbnail"
            style={{
              marginLeft: 20,
            }}
            id="filelabel"
          >
            Click to upload a thumbnail
          </label>
          <input
            type="file"
            class="file"
            id="thumbnail"
            style={{
              outline: "none",
              border: "none",
            }}
            onChange={(e) => {
              if (e.target.files[0]) {
                setThumbnail(e.target.files[0]);
              }
              console.log(thumbnail);
            }}
            name="video"
          />
        </div>

        <div>
          <label
            style={{
              width: 200,
              heigth: 100,
            }}
          >
            <input
              style={{
                width: 30,
                heigth: 30,
                marginTop: 20,
                fontSize: 15,
                color: "black",
              }}
              type="checkbox"
              checked={checked}
              onChange={handleChange}
            />
            Upload Video
          </label>
        </div>

        {checked == true ? (
          <div>
            {" "}
            <h3 style={{ fontSize: 15, color: "black", marginTop: 20 }}>
              Upload Video
            </h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <VideocamIcon size={50} style={{ width: 40, height: 40 }} />
              <label
                for="file"
                style={{
                  marginLeft: 20,
                }}
                id="filelabel"
              >
                {videoData[0]?.video
                  ? videoData[0]?.video.name +
                    " - " +
                    (videoData[0]?.video.size / 1000000).toFixed(2) +
                    "MB"
                  : "Click to upload a video"}
              </label>
            </div>
          </div>
        ) : (
          <div>
            <h4>Video URL</h4>
            <input
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
              style={{
                width: 500,
                marginTop: -10,
              }}
            />
          </div>
        )}

        <input
          type="file"
          class="file"
          id="file"
          style={{
            outline: "none",
            border: "none",
          }}
          onChange={(event) => {
            let temp = [...videoData];
            if (!temp[0]) {
              temp[0] = {};
            }
            temp[0]["video"] = event.target.files[0];
            console.log(event.target.files[0]);
            setVideoData(temp);
          }}
          name="video"
        />
      </div>
      <div
        style={{
          width: 80,
          height: 25,
          marginLeft: 20,
          marginTop: 30,
          textAlign: "center",
          borderRadius: 10,
          backgroundColor: "#ef654d",
          color: "white",
          cursor: "pointer",
          fontSize: 16,
          marginBottom: 20,
          fontWeight: "bold",
        }}
        onClick={async () => {
          if (thumbnail == null && videoData.length == 0) {
            db.collection("coaches")
              .doc(userData?.id)
              .collection("ownWorkout")
              .doc(location.state.workoutId)
              .update({
                name: name,
                workoutName: name,
                videoUrl: url,
              })
              .then(() => {
                alert("Edited Successfully");
              });
          } else {
            if (videoData.length == 0) {
              storage
                .ref(
                  `/coachWorkoutThumbnails/${coachEmail}/${thumbnail.name}/${time}`
                )
                .put(thumbnail);

              let url;
              const storageRef = storage.ref();
              const fileRef = storageRef.child(
                `coachWorkoutThumbnails/${coachEmail}/${thumbnail.name}/${time}`
              );
              await fileRef.getDownloadURL().then((url1) => {
                db.collection("coaches")
                  .doc(userData?.id)
                  .collection("ownWorkout")
                  .doc(location.state.workoutId)
                  .update({
                    name: name,
                    thumbnail_url: url1,
                    videoUrl: url,
                  })
                  .then(() => {
                    alert("Edited Successfully");
                  });
              });
            } else {
              storage
                .ref(
                  `/coachWorkoutThumbnails/${coachEmail}/${thumbnail.name}/${time}`
                )
                .put(thumbnail);

              let url;
              const storageRef = storage.ref();
              const fileRef = storageRef.child(
                `coachWorkoutThumbnails/${coachEmail}/${thumbnail.name}/${time}`
              );
              await fileRef.getDownloadURL().then((url) => {
                let data = [...videoData];

                console.log(data);
                data?.forEach((vid) => {
                  console.log(vid);
                  var formData = new FormData();
                  formData.append("title", vid.title);
                  formData.append("description", vid.description);

                  formData.append(
                    "video",
                    vid["video"]

                    // vid.video.title
                  );
                  // formData.append("video", {
                  //   uri: vid?.thumbnail?.uri ? vid?.thumbnail?.uri : null,
                  //   type: vid?.thumbnail?.type ? vid?.thumbnail?.type : null,
                  //   name: vid?.thumbnail?.name ? vid?.thumbnail?.name : null,
                  // });
                  let headers = {
                    // this is a imp line
                  };
                  let obj = {
                    method: "POST",
                    headers: headers,
                    body: formData,
                  };

                  let url1 =
                    "https://wellzapp-server.herokuapp.com/api/upload/video";
                  console.log(formData);
                  setTimeout(() => {
                    fetch(url1, obj) // put your API URL here
                      .then((resp) => {
                        console.log(1);
                        let json = null;

                        console.log("r", resp);
                        json = resp.json();
                        console.log(" Response", json);
                        if (resp.ok) {
                          return json;
                        }
                        return json.then((err) => {
                          console.log("error :", err);
                          throw err;
                        });
                      })
                      .then((json) => {
                        if (json.success) {
                          console.log(
                            json,
                            firebase.firestore.FieldValue.serverTimestamp(),
                            vid.title,
                            vid.description,
                            userData?.id
                          );

                          db.collection("coaches")
                            .doc(userData?.id)
                            .collection("ownWorkout")
                            .doc(location.state.workoutId)
                            .update({
                              // videoId: json.uri,
                              // thumbnail: "",
                              // timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                              // title: vid.title ? vid.title : "",
                              // description: vid.description ? vid.description : "",
                              // UploadedById: userData?.id,

                              name: name,
                              videoUrl: "https://vimeo.com/" + json.uri,
                              workoutName: name,
                              timestamp:
                                firebase.firestore.FieldValue.serverTimestamp(),
                              thumbnail_url: url,
                            })
                            .then(() => {
                              alert("Edited Successfully");
                            });
                          console.log("wt", json);
                          // videoStatus && videoStatus(true);
                          // videoLink && videoLink("https://vimeo.com/" + json.uri);
                        } else {
                        }
                      })
                      .catch((err) => {
                        console.log("c", err);
                      });
                  }, 2000);
                });
              });
            }
          }
        }}
      >
        Save
      </div>
    </div>
  );
};

export default EditOwnWorkout;
