import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { customStyles } from './Styles/UIStyles';


export const InitialPage = () => {
    const styles = customStyles();
    const history = useHistory();

    function onClickHandle() {
        alert('This page is under construction, will be launching it soon...')
    }

    return (
        <div id="initialpage">
            <div>
                <h1>
                    <label htmlFor="icon-button-file">
                        <IconButton className={styles.icon_button} aria-label="back-button" onClick={() => history.push("/")} >
                            <ArrowBackIosIcon fontSize="small" />
                        </IconButton>
                    </label>
                    Marketing
                </h1>
            </div>
            <br />
            <br />
            <Grid container spacing={4} style={{ display: 'flex', justifyContent: 'center' }} >
                <Grid item>
                    <Button >
                        <Paper className={styles.paper_button} elevation={3} onClick={() => history.push("/select-theme")} >Create/Update
                            Website</Paper>
                    </Button>
                </Grid>
                <Grid item>
                    <Button>
                        <Paper className={styles.paper_button} elevation={3} onClick={() => history.push("/website-leads")}> Website Leads</Paper>
                    </Button>
                </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={4} style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item>
                    <Button >
                        <Paper className={styles.paper_button} elevation={3} onClick={onClickHandle}>Social Media</Paper>
                    </Button>
                </Grid>
                <Grid item>
                    <Button>
                        <Paper className={styles.paper_button} elevation={3} onClick={onClickHandle}>Social Media Leads</Paper>
                    </Button>
                </Grid>
            </Grid>
        </div >
    );
}
export default InitialPage