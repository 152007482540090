import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const siteLink = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

const ContactValidatorDef = Yup.object().shape({
    user_email: Yup.string()
        .email('Entered email id is invalid, please enter a valid email id.')
        .required('Email ID is mandatory'),
    phone_number: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .test('len', 'Phone number should be of 10 digits.', val => val && val.length === 10),
    whatsapp_number: Yup.string()
        .matches(phoneRegExp, 'WhatsApp number is not valid')
        .test('len', 'Phone number should be of 10 digits.', val => val && val.length === 10),
    fb_username: Yup.string()
        .max(30, 'Should be within 30 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/:;"'()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;"\'()#&+-) are only allowed'),
    insta_username: Yup.string()
        .max(30, 'Should be within 30 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/:;"'()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;"\'()#&+-) are only allowed'),
    twitter_username: Yup.string()
        .max(30, 'Should be within 30 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/:;"'()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;"\'()#&+-) are only allowed'),
    fb_link: Yup.string()
        .matches(siteLink,'URL is not valid'),
    insta_link: Yup.string()
        .matches(siteLink,'URL is not valid'),
    twitter_link: Yup.string()
        .matches(siteLink,'URL is not valid')
});


export default ContactValidatorDef;