import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { db } from "../../utils/firebase";

import {
  selectUser,
  selectUserData,
  selectUserType,
  setUserData,
  selectUserVerified,
} from "../../features/userSlice";

const OnboardingFormCoach = () => {
  const history = useHistory();
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const [certificates, setCertificates] = useState("");
  const [awards, setAwards] = useState("");
  return (
    <div
      style={{
        // justifyContent: "center",
        // textAlign: "center",
        marginLeft: 20,
      }}
    >
      <h1>Coach</h1>
      <div>
        <h3>
          You can choose to fill this information now or at a later stage
          through profile page.
        </h3>
      </div>
      <div style={{ paddingTop: 20 }}>
        <h4>Certificates</h4>
        <textarea
          onChange={(e) => {
            setCertificates(e.target.value);
          }}
          type="text"
          placeholder={"Certificates"}
          value={certificates}
          style={{
            width: 500,
            height: 50,
            border: "none",
            borderRadius: 5,
          }}
        />
        <h4>Awards</h4>
        <textarea
          onChange={(e) => {
            setAwards(e.target.value);
          }}
          type="text"
          placeholder={"Awards"}
          value={awards}
          style={{
            width: 500,
            height: 50,
            border: "none",
            borderRadius: 5,
          }}
        />
      </div>
      <button
        type="submit"
        className="signup__button"
        style={{
          width: 180,
        }}
        onClick={() => {
          db.collection("coaches")
            .doc(userData?.id)
            .update({ onboardCoach: false, certificates, awards });
          dispatch(
            setUserData({
              id: userData?.id,
              data: { ...userData?.data, onboardCoach: false },
            })
          );

          history.push("/");
        }}
      >
        {certificates == "" && awards == "" ? "Skip" : "Save"}
      </button>
    </div>
  );
};

export default OnboardingFormCoach;
