import React, { useState, useEffect, useCallback } from "react";
import VODScreenHeader from "./VODScreenHeader";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../utils/firebase";

import {
  setDbID,
  selectDbId,
  selectUser,
  setUserDetails,
  selectShowData,
  logout,
  setUserData,
  selectUserData,
} from "../../features/userSlice";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";

const AssignVideoDetails = () => {
  const userData = useSelector(selectUserData);
  const [assignedWorkoutVideo, setAssignedWorkoutVideo] = useState([]);
  const [daysList, setDaysList] = useState([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ]);

  let { id } = useParams();
  let iid = id;

  useEffect(() => {
    db.collection("CoachWorkoutVideo").onSnapshot((snapshot) =>
      setAssignedWorkoutVideo(
        snapshot.docs.map((doc) => ({ id: doc.id, assignedVideo: doc.data() }))
      )
    );
  }, []);
  return (
    <div
      style={{
        marginLeft: 10,
      }}
    >
      <VODScreenHeader back="/vod" name="Assigned Videos Details" />
      <img
        style={{ objectFit: "contain" }}
        src="/assets/workout.png"
        width="100%"
        height="150px"
      />
      <div className="assignWorkout__banner">
        <img
          src={
            userData?.data?.imageUrl
              ? userData?.data?.imageUrl
              : "https://firebasestorage.googleapis.com/v0/b/stairs-web-app.appspot.com/o/userImage.jpeg?alt=media&token=b50bf709-1e26-4d1a-8fd6-3689f66322c3"
          }
          width="40px"
          height="40px"
          style={{ borderRadius: "40px", objectFit: "cover" }}
        />
        <div>
          <h5 style={{ color: "var(--secondarycolor)" }}>
            {userData?.data?.name}
          </h5>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <h4>Assigned to:</h4>
        {assignedWorkoutVideo.map(
          ({ id, assignedVideo }) =>
            id == iid && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 10,
                }}
              >
                <img
                  style={{ borderRadius: 18 }}
                  src={assignedVideo.imageUrl}
                  alt=""
                  width="36"
                  height="36"
                />
                <h4
                  style={{
                    paddingLeft: 10,
                  }}
                >
                  {assignedVideo.Name}
                </h4>
              </div>
            )
        )}
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <h4>Selected Days:</h4>
        {assignedWorkoutVideo.map(
          ({ id, assignedVideo }) =>
            id == iid && (
              <div
                style={{
                  display: "flex",
                }}
              >
                {assignedVideo.selectedDays.map((day) => (
                  <h4
                    style={{
                      paddingLeft: 14,
                    }}
                  >
                    {day}
                  </h4>
                ))}
              </div>
            )
        )}
      </div>
      <h4 id="cursordefault">Assigned Videos:</h4>
      <div>
        {assignedWorkoutVideo.map(
          ({ id, assignedVideo }) =>
            id == iid &&
            assignedVideo.Video.map((video) => (
              <div>
                <ReactPlayer
                  // title={video.title}
                  id="iframe"
                  style={{ borderRadius: 10 }}
                  url={"https://player.vimeo.com/video/" + `${video.videoId}`}
                  width="100%"
                  height="100%"
                  frameborder="0"
                  controls
                />
              </div>
            ))
        )}
      </div>
    </div>
  );
};

export default AssignVideoDetails;
