import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../utils/firebase";
const AssignWorkout2 = () => {
  let { id } = useParams();
  let iid = id;
  const [data, setData] = useState([]);
  useEffect(() => {
    db.collection("CoachWorkouts")

      // .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) =>
        setData(
          snapshot.docs.map((doc) => ({ id: doc.id, workouts: doc.data() }))
        )
      );
  }, []);

  return (
    <div>
      {data.map(
        ({ id, workouts }) =>
          id == iid &&
          workouts.preWorkout.selectedExercises.map((excercise) => (
            <h1>{excercise.name}</h1>
          ))
      )}
    </div>
  );
};

export default AssignWorkout2;
