import { Button, Dialog, DialogTitle, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Form, Formik } from 'formik';
import React, { Fragment, useState } from 'react';
import Loader from "react-loader-spinner";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUser } from '../../../../features/userSlice';
import db, { fb_storage } from '../../services/Firebase';
import TestimonialValidatorDef from '../models/TestimonialFormValidator';
import { customStyles } from '../Styles/UIStyles';
import MultilineTextField from '../UIElements/MultilineTextField';
import TextField from '../UIElements/TextField';
import "./WebsiteForms.css"


export const AddTestimonial = () => {
  const history = useHistory();
  const styles = customStyles();
  const userEmail = useSelector(selectUser)
  const [imageAsFile, setImageAsFile] = useState('')
  const [imageAsUrl, setImageAsUrl] = useState('')
  const [showDialog, setShowDialog] = useState(false)

  function handleChange(e) {
    const image = e.target.files[0]
    setImageAsUrl(URL.createObjectURL(image))
    setImageAsFile(imageAsFile => (image))
  };

  return (

    <div id="uniqueforms">
      <Formik
        initialValues={{
          client_name: '',
          testimonial_details: ''
        }}
        validationSchema={TestimonialValidatorDef}
        onSubmit={values => {
          setShowDialog(!showDialog);
          var timeStamp = Date.now()
          const ref = fb_storage.ref().child(`/testimonial_images/${userEmail}/${timeStamp}_${imageAsFile.name}`)
          ref.put(imageAsFile)
            .then(() => ref.getDownloadURL())
            .then(fireBaseUrl => {
              db.collection('TestimonialsInformation')
                .add({
                  user_id: userEmail,
                  client_name: values.client_name,
                  testimonial_details: values.testimonial_details,
                  image: fireBaseUrl,
                  active: true
                })
                .then(() => {
                  setShowDialog(!showDialog);
                  history.push("/manage-testimonials");
                })
                .catch((error) => {
                  setShowDialog(!showDialog);
                  alert(error.message);
                });
            })
        }}
      >
        <Form>
          <div>
            <h2 className={styles.section_header}>
              <p>
                <label htmlFor="icon-button-file">
                  <IconButton className={styles.icon_button} aria-label="back-button" onClick={() => history.push("/manage-testimonials")} >
                    <ArrowBackIosIcon fontSize="small" />
                  </IconButton>
                </label>
                Add Testimonial
              </p>
            </h2>
          </div>
          <div id="theformone">
            <h4 className={styles.section_header}>
              Testimonials and Transformations
            </h4>
            <p className={styles.form_header}>
              <label style={{ color: 'red' }}>* </label> Name of the Client
            </p>
            <TextField className={styles.textfield}
              id="client_name"
              name="client_name"
              label="Enter name"
            />
            <p className={styles.form_header}>
              <label style={{ color: 'red' }}>* </label> Testimonial
            </p>
            <MultilineTextField className={styles.textfield}
              id="testimonial_details"
              name="testimonial_details"
              label="Enter testimonial"
            />
            <p className={styles.form_header}>
              Transformation Image
            </p>
            <div>
              <Fragment>
                <div>
                  {
                    imageAsUrl && (
                      <figure>
                        <img src={imageAsUrl} alt='' width='128' height='128' />
                      </figure>
                    )
                  }
                </div>
                <input
                  color="primary"
                  accept="image/*"
                  type="file"
                  onChange={handleChange}
                  id="icon-button-file"
                  style={{ display: 'none', }}
                />
                <p className={styles.form_header}>
                  <label htmlFor="icon-button-file">
                    <IconButton style={{ color: 'black' }} aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                  Upload Image
                </p>
              </Fragment>
            </div>
          </div>
          <Button className={styles.primary_button} style={{ width: '120px' }} type='submit' variant="outlined" >  Save  </Button>
        </Form>
      </Formik>
      {
        showDialog && (
          <Dialog open={showDialog} className={styles.dialog}>
            <DialogTitle id="testimonial_progress_title"><p>Saving Testimonial details...</p><p>Soon will be redirected to manage testimonials...</p></DialogTitle>
            <div className={styles.dialog} >
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
              />
            </div>
          </Dialog>
        )
      }
    </div>

  )
}