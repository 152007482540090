import React from "react";
import {  useSelector } from "react-redux";
import {  useHistory } from "react-router-dom";
import {
  selectUserData
} from "../../features/userSlice";
import "../Sidebar/Sidebar.css"
import "../../responsive.css"


function ViewProfile() {
    const userData = useSelector(selectUserData);
    const history = useHistory();

    return (
        <>
        <div>
          <h3>{userData?.data.name}</h3>
         <p style={{textAlign:'center'}} onClick={() => history.push("/profile")}
> <h5>view Profile</h5>  </p>
          </div>
        </>
    )
}

export default ViewProfile
