import { Button, Dialog, DialogTitle, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Loader from "react-loader-spinner";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUser } from '../../../../features/userSlice';
import db from '../../services/Firebase';
import ContactFormValidator from '../models/ContactFormValidator';
import { customStyles } from '../Styles/UIStyles';
import TextField from '../UIElements/TextField';
import "./WebsiteForms.css"
export const ManageContact = () => {
  const history = useHistory();
  const styles = customStyles();
  const userEmail = useSelector(selectUser)
  const [contactInfo, setContactInfo] = useState({});
  const [loading, setLoading] = useState(true)
  const [showDialog, setShowDialog] = useState(false)

  const fetchContactInfo = async () => {
    const contactRef = await db.collection('ContactInformation');
    const userContactInfo = contactRef.where("user_id", "==", userEmail);
    userContactInfo.get().then(snapshot => {
      snapshot.docs.forEach(doc => {
        setContactInfo({ data: doc.data(), id: doc.id });
      })
      setLoading(false)
    })
  };

  useEffect(() => {
    fetchContactInfo();
  }, [])


  return (
    <div id="contactmanage">
      <h3>
        <p>
          <label htmlFor="icon-button-file">
            <IconButton className={styles.icon_button} aria-label="back-button" onClick={() => history.push("/coach-website")} >
              <ArrowBackIosIcon fontSize="small" />
            </IconButton>
          </label>
          Manage Contact Details
        </p>
      </h3>
      {
        loading && (
          <Loader
            type="TailSpin"
            color="#00BFFF"
            height={100}
            width={100}
          />
        )
      }
      {
        !loading && (
          <Formik
            initialValues={{
              user_email: contactInfo.data ? contactInfo.data.user_email : "",
              phone_number: contactInfo.data ? contactInfo.data.phone_number : "",
              whatsapp_number: contactInfo.data ? contactInfo.data.whatsapp_number : "",
              fb_username: contactInfo.data ? contactInfo.data.fb_username : "",
              fb_link: contactInfo.data ? contactInfo.data.fb_link : "",
              insta_username: contactInfo.data ? contactInfo.data.insta_username : "",
              insta_link: contactInfo.data ? contactInfo.data.insta_link : "",
              twitter_username: contactInfo.data ? contactInfo.data.twitter_username : "",
              twitter_link: contactInfo.data ? contactInfo.data.twitter_link : ""
            }}
            validationSchema={ContactFormValidator}
            onSubmit={values => {
              setShowDialog(!showDialog);
              const doc = db.collection('ContactInformation').where("user_id", "==", userEmail).get();
              if (!doc) {
                db.collection('ContactInformation').doc(userEmail)
                  .add({
                    user_email: values.user_email,
                    phone_number: values.phone_number,
                    whatsapp_number: values.whatsapp_number,
                    fb_username: values.fb_username,
                    fb_link: values.fb_link,
                    insta_username: values.insta_username,
                    insta_link: values.insta_link,
                    twitter_username: values.twitter_username,
                    twitter_link: values.twitter_link
                  })
                  .then(() => {
                    setShowDialog(!showDialog);
                    history.push("/coach-website");
                  })
                  .catch((error) => {
                    setShowDialog(!showDialog);
                    alert(error.message);
                  });
              }
              else {
                db.collection('ContactInformation')
                  .doc(userEmail)
                  .set({
                    user_id: userEmail,
                    user_email: values.user_email,
                    phone_number: values.phone_number,
                    whatsapp_number: values.whatsapp_number,
                    fb_username: values.fb_username,
                    fb_link: values.fb_link,
                    insta_username: values.insta_username,
                    insta_link: values.insta_link,
                    twitter_username: values.twitter_username,
                    twitter_link: values.twitter_link
                  })
                  .then(() => {
                    setShowDialog(!showDialog);
                    history.push("/coach-website");
                  })
                  .catch((error) => {
                    setShowDialog(!showDialog);
                    alert(error.message);
                  });
              }
            }}
          >
            <Form>
              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Email ID
              </p>
              <TextField className={styles.textfield}
                id="user_email"
                name="user_email"
                label="Enter Email ID"
              />
              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Phone
              </p>
              <TextField className={styles.textfield}
                id="phone_number"
                name="phone_number"
                label="Enter Phone Number"
              />
              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Whatsapp
              </p>
              <TextField className={styles.textfield}
                id="whatsapp_number"
                name="whatsapp_number"
                label="Enter Number"
              />
              <p className={styles.form_header}>
                Facebook
              </p>
              <TextField className={styles.textfield}
                id="fb_username"
                name="fb_username"
                label="Enter Username"
              />
              <p></p>
              <TextField className={styles.textfield}
                id="fb_link"
                name="fb_link"
                label="Paste Link"
              />
              <p className={styles.form_header}>
                Instagram
              </p>
              <TextField className={styles.textfield}
                id="insta_username"
                name="insta_username"
                label="Enter Username"
              />
              <p></p>
              <TextField className={styles.textfield}
                id="insta_link"
                name="insta_link"
                label="Paste Link"
              />
              <p className={styles.form_header}>
                Twitter
              </p>
              <TextField className={styles.textfield}
                id="twitter_username"
                name="twitter_username"
                label="Enter Username"
              />
              <p></p>
              <TextField className={styles.textfield}
                id="twitter_link"
                name="twitter_link"
                label="Paste Link"
              />
              <br />
              <br />
              <br />
              <Button className={styles.primary_button} style={{ width: '120px' }} type='submit' variant="outlined" >Save</Button>
            </Form>
          </Formik>

        )
      }
      {
        showDialog && (
          <Dialog open={showDialog} className={styles.dialog}>
            <DialogTitle id="contact_progress"><p>Saving contact details...</p></DialogTitle>
            <div className={styles.dialog} >
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
              />
            </div>
          </Dialog>
        )
      }
    </div >
  )
}