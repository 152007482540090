import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { db } from "../../utils/firebase";
import AthleteAssessment from "./AthleteAssessment";

import {
  selectUser,
  selectUserData,
  selectUserType,
  setUserData,
  selectUserVerified,
} from "../../features/userSlice";

const OnboardingForm = () => {
  const history = useHistory();
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  return (
    <div
      style={{
        // justifyContent: "center",
        // textAlign: "center",
        marginLeft: 20,
      }}
    >
      <h1>Athlete</h1>
      <div>
        <h3>
          You can choose to fill this information now or at a later stage
          through profile page.
        </h3>
      </div>
      <div style={{ marginTop: 40, width: 700 }}>
        <AthleteAssessment
          name="Anthropometric Measurements"
          path="anthropometric-measurements"
        />
        <AthleteAssessment
          name="Medical Assessment"
          path="medical-assessment"
        />
        <AthleteAssessment
          name="Training Assessment"
          path="training-assessment"
        />
        <AthleteAssessment
          name="Food and Lifestyle Assessment"
          path="food-and-lifestyle-assessment"
        />
        <button
          type="submit"
          className="signup__button"
          style={{
            width: 180,
          }}
          onClick={() => {
            db.collection("athletes")
              .doc(userData?.id)
              .update({ onboardAthlete: false });
            dispatch(
              setUserData({
                id: userData?.id,
                data: { ...userData?.data, onboardAthlete: false },
              })
            );
            history.push("/");
          }}
        >
          Skip
        </button>
      </div>
    </div>
  );
};

export default OnboardingForm;
