import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  selectTemperoryId,
  selectUser,
  selectUserType,
  setTemperoryData,
  selectUserData,
} from "../../features/userSlice";
import { db, storage } from "../../utils/firebase";
import "./Profile.css";
import "../../responsive.css";
const timestamp = require("time-stamp");

function CoachProfileForm({ setTempProfPic, coachData }) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const userDataN = useSelector(selectUserData);
  const temperoryId = useSelector(selectTemperoryId);

  const history = useHistory();
  const inputFile = useRef(null);

  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [gender, setgender] = useState("");
  const [address, setaddress] = useState("");
  const [dob, setdob] = useState("");
  const [pin, setPin] = useState("");

  const [certificates, setCertificates] = useState("");

  const [awards, setAwards] = useState("");
  const [editable, seteditable] = useState(false);
  const userData = useSelector(selectUserData);
  const [userData1, setUserData1] = useState();
  const [profPic, setProfPic] = useState();
  const [imageUrl, setImageUrl] = useState("");

  let tillDate = timestamp();
  useEffect(() => {
    if (userType === "coach") {
      db.collection("coaches")
        // .doc(temperoryId)
        .where("email", "==", user)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            setUserData1({
              id: doc.id,
              data: doc.data(),
            });
            setImageUrl(doc.data()?.imageUrl);
            setphone(doc.data()?.phone);
            setemail(doc.data()?.email);
            setgender(doc.data()?.gender);
            setaddress(doc.data()?.address);
            setdob(doc.data()?.dob);
            setCertificates(doc.data()?.certificates);
            setAwards(doc.data()?.awards);
          });
          // dispatch(
          //   setTemperoryData({
          //     id: temperoryId,
          //     data: snap.data(),
          //   })
          // );
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    } else {
      console.log(userData?.data);
      db.collection("coaches")
        .doc(userData?.data?.listOfCoaches[0])
        .get()
        .then((snap) => {
          console.log(snap.id, snap.data()?.phone);
          setImageUrl(snap.data()?.imageUrl);
          setphone(snap.data()?.phone);
          setemail(snap.data()?.email);
          setgender(snap.data()?.gender);
          setaddress(snap.data()?.address);
          setdob(snap.data()?.dob);
          setCertificates(snap.data()?.certificates);
          setAwards(snap.data()?.awards);
          setPin(snap.data()?.pin);
        });
    }
  }, [user, userData, temperoryId]);

  console.log(imageUrl, "imgurl");

  const saveprofile = async () => {
    if (phone.length > 12) {
      alert(
        "Please enter a valid phone number. Phone number must have only 12 or less digits."
      );
      return;
    }
    let url; 
    let dob1;
    // if(dob){
    //    dob1 = dob.split("-").reverse().join("-");
    // }

    if (profPic) {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`/images/${email}/profpic`);
      await fileRef.put(profPic).then(async () => {
        if (profPic) {
          url = await fileRef.getDownloadURL();
        }
      });
    } else {
      url = imageUrl;
    }

    console.log(url, "urlw");
    db.collection("coaches")
      .doc(userDataN?.id)
      .update({
        imageUrl: url,
        phone: phone ? phone : "",
        gender: gender ? gender : "",
        address: address ? address : "",
        dob: dob ? dob : "",
        certificates: certificates ? certificates : "",
        awards: awards ? awards : "",
      })
      .then((res) => seteditable(false))
      .catch((e) => console.log(e));
  };

  const handleProfPic = (e) => {
    if (e.target.files[0]) {
      setProfPic(e.target.files[0]);
      setTempProfPic(e.target.files[0]);
    }
  };
  return (
    <div className="coachprofileform" style={{ width: "100%" }}>
      <div className="coachProfileForm">
        <div className="coachProfile__info">
          <div className="coachProfile__heading">
            <input
              type="file"
              onChange={(e) => handleProfPic(e)}
              ref={inputFile}
            />
            <h2 id="cursordefault">Profile</h2>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {userType === "coach" &&
                (!editable ? (
                  <span
                    style={{}}
                    className="editProfileButton"
                    onClick={() => seteditable(true)}
                  >
                    <h3 style={{ color: "var(--secondarycolor)" }}>
                      EDIT PROFILE
                    </h3>
                  </span>
                ) : (
                  <span
                    className="saveProfileButton"
                    onClick={() => saveprofile()}
                  >
                    <h3 style={{ color: "var(--secondarycolor)" }}>
                      SAVE PROFILE
                    </h3>
                  </span>
                ))}
              {editable && (
                <span
                  style={{
                    borderWidth: 1,
                    borderColor: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => inputFile.current.click()}
                >
                  <h3>Upload Photo</h3>
                </span>
              )}
            </div>
          </div>{" "}
        </div>
        <form>
          <h4>Mobile Number</h4>
          <input
            readOnly={!editable}
            onChange={(e) => {
              setphone(e.target.value);
            }}
            value={userType == "coach" ? phone : coachData.phone}
            type="number"
          />
          <h4>Email ID</h4>
          <input
            readOnly={true}
            onChange={(e) => {
              setemail(e.target.value);
            }}
            value={userType == "coach" ? email : coachData.email}
            type="email"
            placeholder="email"
          />
          <h4>Gender</h4>
          {!editable && (
            <input
              readOnly={!editable}
              onChange={(e) => {
                setgender(e.target.value);
              }}
              value={userType == "coach" ? gender : coachData.gender}
              type="text"
              placeholder="Enter Gender"
            />
          )}
          {editable && (
            <div>
              <input
                type="radio"
                value="Male"
                name="gender"
                checked={gender === "Male"}
                onChange={(e) => setgender(e.target.value)}
              />{" "}
              Male
              <input
                type="radio"
                value="Female"
                name="gender"
                checked={gender === "Female"}
                onChange={(e) => setgender(e.target.value)}
                style={{ marginLeft: 20 }}
              />{" "}
              Female
            </div>
          )}
          <h4>Date of Birth</h4>
          <input
            onChange={(e) => {
              setdob(e.target.value.split("-").reverse().join("-"));
            }}
            readOnly={!editable}
            type="date"
            max={tillDate}
            placeholder="Enter Date of Birth"
            value={
              userType == "coach"
                ? dob.split("-").reverse().join("-")
                : coachData.dob.split("-").reverse().join("-")
            }
          />
          <h4>Certificates</h4>
          <input
            onChange={(e) => {
              setCertificates(e.target.value);
            }}
            readOnly={!editable}
            type="text"
            placeholder={
              userType === "coach" ? "Enter certificates" : "Certificates"
            }
            value={userType == "coach" ? certificates : coachData.certificates}
          />
          <h4>Awards</h4>
          <input
            onChange={(e) => {
              setAwards(e.target.value);
            }}
            readOnly={!editable}
            type="text"
            placeholder={userType === "coach" ? "Enter Awards" : "Awards"}
            value={userType == "coach" ? awards : coachData.awards}
          />
          {userType === "coach" ? (
            <>
              <h4>Billing Address</h4>
              <textarea
                readOnly={!editable}
                onChange={(e) => {
                  setaddress(e.target.value);
                }}
                value={address}
                type="text"
                placeholder="Enter your address here"
              />
            </>
          ) : (
            <></>
          )}
        </form>
      </div>
    </div>
  );
}

export default CoachProfileForm;
