import * as Yup from 'yup';

const ProgramValidatorDef = Yup.object().shape({
    program_name: Yup.string().required('Program name cannot be left empty!')
        .max(30, 'Should be within 30 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed'),
    program_details: Yup.string().required('Program details cannot be left empty!')
        .max(120, 'Should be within 120 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed'),
    program_fees: Yup.number()
        .integer()
        .positive()
        .max(99999999, 'Program fees cannot be more than 99999999')

});


export default ProgramValidatorDef;