import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import { useSelector } from "react-redux";
import { db } from "../../utils/firebase";
import { selectUserData, selectUserType } from "../../features/userSlice";
import { useHistory } from "react-router-dom";
import "../Home/Home.css";
const Coaches = (props) => {
  const userData = useSelector(selectUserData);
  const userType = useSelector(selectUserType);
  const [sleep, setSleep] = useState(0);
  const history = useHistory();
  const [names, setNames] = useState([]);
  const [Coaches, setCoaches] = useState([]);

  // useEffect(() => {
  //   let temporaryName = [];
  //   let temporaryImage = [];
  //   if (userData?.data?.listOfCoaches?.length > 0) {
  //     userData?.data?.listOfCoaches?.map((u) =>
  //       db
  //         .collection("coaches")
  //         .doc(u)
  //         .get()
  //         .then(function (snap) {
  //           temporaryName.push(snap.data());
  //           // temporaryImage.push(snap.data()?.imageUrl);
  //           // coachName(snap.data()?.name);
  //           // setCoachimg(snap.data()?.imageUrl);
  //         })
  //     );
  //   }
  //   setNames(temporaryName);
  //   // setImages(temporaryImage);
  //   console.log("Names", temporaryName);
  //   // console.log("Images", temporaryImage);
  // }, []);

  useEffect(() => {
    db.collection("coaches").onSnapshot((snapshot) =>
      setCoaches(
        snapshot.docs.map((doc) => ({ id: doc.id, coaches: doc.data() }))
      )
    );
  }, []);

  return (
    <div className="coachProfile" style={{ marginRight: "10%" }}>
      <div className="coachProfile__container" style={{ marginBottom: 50 }}>
        <div
          className="coachProfile__leftContainer"
          id="athleteprofileform"
          style={{ padding: "0px" }}
        >
          <Header back="/" />
        </div>
      </div>
      <div
        style={{
          margin: 20,
        }}
      >
        <h1
          style={{
            fontSize: 25,
          }}
        >
          List Of Coaches
        </h1>
        <div style={{ width: "90%" }}>
          {Coaches.map(({ id, coaches }) =>
            userData?.data?.listOfCoaches.map(
              (c) =>
                c == id && (
                  <div className="allAthletes__athletes">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        className="allAthletes__athleteslogo"
                        src={
                          coaches.imageUrl
                            ? coaches.imageUrl
                            : "https://firebasestorage.googleapis.com/v0/b/stairs-web-app.appspot.com/o/userImage.jpeg?alt=media&token=b50bf709-1e26-4d1a-8fd6-3689f66322c3"
                        }
                        alt=""
                        width="40px"
                        height="40px"
                      />
                      <p
                        style={{
                          fontSize: 18,
                          fontWeight: "500",
                          marginLeft: 20,
                        }}
                      >
                        {coaches.name}
                      </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        onClick={() => history.push("/messaging")}
                        src="/assets/Icon material-message.png"
                        alt=""
                        width="15px"
                        height="15px"
                        style={{ cursor: "pointer" }}
                      />
                      <button
                        onClick={() => {
                          history.push({
                            pathname: "/coachProfile",
                            state: {
                              data: coaches,
                            },
                          });
                        }}
                        style={{
                          marginLeft: 20,
                          outline: "none",
                          border: "none",
                          padding: "5px 10px",
                          backgroundColor: "var(--primarycolor)",
                          color: "var(--secondarycolor)",
                          borderRadius: 8,
                          cursor: "pointer",
                        }}
                      >
                        View Profile
                      </button>
                    </div>
                  </div>
                )
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Coaches;
