import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CheckboxesGroups from "../../Components/Buttons/Checkboxs";
import Header from "../../Components/Header/Header";
import {
  selectTemperoryId,
  selectUser,
  selectUserType,
} from "../../features/userSlice";
import { db } from "../../utils/firebase";
import "./Profile.css";

function AthleteTrainingAssessment({ route }) {
  const navigation = useHistory();
  const user = useSelector(selectUser);
  const [userData, setUserData] = useState(null);
  const userType = useSelector(selectUserType);
  const temperoryId = useSelector(selectTemperoryId);
  const [editable, setEditable] = useState(false);
  const [trainingHours, setTrainingHours] = useState("");
  const [otherEquipments, setOtherEquipments] = useState([]);

  const dispatch = useDispatch();

  const [daysList, setDaysList] = useState([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ]);
  const [color, changeColor] = useState([]);
  const [selectedDaysOfTraining, setSelectedDaysOfTraining] = useState([]);
  const [other, setOther] = useState("");
  const [checkboxValues, setCheckboxValues] = useState([
    "Gym",
    "Kettlebells",
    "Cycle",
    "Weights",
    "Dumbells",
    "Resistance Bands",
    "Swimming Pool",
    "Other",
  ]);
  const [selected, setSelected] = useState([]);

  const isItemChecked = (abilityName) => {
    return selected.indexOf(abilityName) > -1;
  };

  const handleCheckBoxChange = (evt, abilityName) => {
    if (isItemChecked(abilityName)) {
      setSelected(selected.filter((i) => i !== abilityName));
    } else {
      setSelected([...selected, abilityName]);
    }
  };

  useEffect(() => {
    if (userType === "coach") {
      db.collection("athletes")
        .doc(temperoryId)
        .get()
        .then(function (snap) {
          setUserData({
            id: temperoryId,
            data: snap.data(),
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    } else {
      db.collection("athletes")
        .where("email", "==", user)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            setUserData({
              id: doc.id,
              data: doc.data(),
            });
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    }
  }, [user]);

  useEffect(() => {
    if (userData) {
      let temp1 = [];
      db.collection("athletes")
        .doc(userData?.id)
        .collection("Training")
        .doc("training")
        .get()
        .then(function (doc) {
          if (doc.exists) {
            setTrainingHours(doc.data().trainingHours);
            setSelected(doc.data().equipments);
            setOtherEquipments(
              doc.data()?.otherEquipments ? doc.data().otherEquipments : ""
            );
            setSelectedDaysOfTraining(doc.data().selectedDaysOfTraining);
            daysList.map((item, idx) => {
              if (doc.data().selectedDaysOfTraining.includes(item)) {
                temp1.push(idx);
              }
            });
            changeColor(temp1);
          } else {
            console.log("No such document!");
          }
        })
        .catch((e) => console.log(e));
    }
  }, [userData?.id]);

  useEffect(() => {
    daysList.map((item, idx) => {
      if (!selectedDaysOfTraining.includes(item)) {
        if (color.includes(idx)) {
          console.log("Adding...");
          setSelectedDaysOfTraining([...selectedDaysOfTraining, item]);
        }
      }
    });
  }, [color]);
  console.log({ selectedDaysOfTraining, color });

  const saveDetails = () => {
    if (route?.params?.setAddDetails) {
      route.params.setAddDetails(true);
    }
    db.collection("athletes")
      .doc(userData?.id)
      .collection("Training")
      .get()
      .then((snap) => {
        if (!snap.empty) {
          db.collection("athletes")
            .doc(userData.id)
            .collection("Training")
            .doc("training")
            .update({
              trainingHours,
              selectedDaysOfTraining,
              equipments: selected,
              otherEquipments,
            })
            .then((res) => {
              // navigation.goBack();
            })
            .catch((e) => console.log(e));
        } else {
          db.collection("athletes")
            .doc(userData.id)
            .collection("Training")
            .doc("training")
            .set({
              trainingHours,
              selectedDaysOfTraining,
              equipments: selected,
              otherEquipments,
            })
            .then((res) => {
              // navigation.goBack();
            })
            .catch((e) => console.log(e));
        }
      });

    setEditable(false);
  };

  return (
    <div
      className="athleteTrainingAssessment"
      style={{
        minHeight: "99.9vh",
        width: "100%",
        position: "relative",
        marginBottom: "120px",
      }}
    >
      <Header Id={temperoryId} />
      {userType !== "coach" && editable && (
        <div
          className="athleteTrainingAssessment__editProfileButton"
          onClick={() => {
            setEditable(false);
            saveDetails();
          }}
        >
          <h3  style={{ color: "var(--secondarycolor)" }}>SAVE PROFILE</h3>
        </div>
        //:
        //  (
        //   <div
        //     className="athleteTrainingAssessment__saveProfileButton"
        //     onClick={() => saveDetails()}
        //   >
        //     <h3>SAVE PROFILE</h3>
        //   </div>
        // )
      )}
      {userType !== "coach" && !editable && (
        <div
          className="athleteTrainingAssessment__editProfileButton"
          onClick={() => setEditable(true)}
        >
          <h3 style={{ color: "var(--secondarycolor)" }}>EDIT PROFILE</h3>
        </div>
      )}{" "}
      <h2>Training Assessment</h2>
      <h4>Select Days you wish to train</h4>
      <h5>Select days</h5>
      <div className="athleteTrainingAssessment__container">
        {daysList.map((day, idx) => (
          <button
            className="athleteTrainingAssessment__days"
            style={
              {
                // paddingTop: 8,
              }
            }
            key={idx}
            onClick={() => {
              if (color.includes(idx)) {
                var array = [...color];

                var index = array.indexOf(idx);
                if (index !== -1) {
                  array.splice(index, 1);

                  changeColor(array);
                }
                var list = selectedDaysOfTraining.filter(
                  (t) => t !== daysList[idx]
                );
                setSelectedDaysOfTraining(list);
              } else {
                changeColor([...color, idx]);
              }
            }}
            disabled={!editable}
            style={
              color.includes(idx)
                ? {
                    backgroundColor: "var(--primarycolor)",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    textAlign: "center",
                    borderRadius: 10,
                    marginRight: 8,
                    marginBottom: 5,
                    cursor: "pointer",
                    height: 30,
                    width: 60,
                    fontWeight: 600,
                    border: "none",
                  }
                : {
                    backgroundColor: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    textAlign: "center",
                    borderRadius: 10,
                    cursor: "pointer",
                    marginRight: 8,
                    marginBottom: 5,
                    height: 30,
                    width: 60,
                    fontWeight: 600,
                    border: "none",
                  }
            }
          >
            <div
              style={{
                marginTop: 2,
              }}
            >
              {day}
            </div>
          </button>
        ))}
      </div>
      <h4>Training Hours per day</h4>
      <input
        style={
          editable
            ? {
                borderWidth: 1,
                borderColor: "grey",
                width: "95%",
                borderRadius: 5,
                padding: 15,
                marginLeft: 10,
              }
            : {
                borderColor: "grey",
                borderWidth: 0,
                width: "95%",
                borderRadius: 5,
                padding: 15,
                marginLeft: 10,
              }
        }
        readOnly={!editable}
        type="text"
        placeholder="No. of hours you wish to train per day"
        defaultValue={userData?.data?.trainingHours}
        value={trainingHours}
        onChange={(e) => setTrainingHours(e.target.value)}
      />
      <h4>Select equipment you have access to</h4>
      <div className="athleteTrainingAssessment__form">
        <div className="athleteTrainingAssessment__checkBox">
          {checkboxValues.map((disease, idx) => (
            <div key={idx}>
              <label>
                <input
                  disabled={!editable}
                  type="checkbox"
                  checked={isItemChecked(disease)}
                  onChange={(evt) => handleCheckBoxChange(evt, disease)}
                  style={{
                    marginLeft: 10,
                  }}
                />
                {disease}
              </label>
            </div>
          ))}
        </div>
        <div
          style={{
            marginTop: 40,
            marginLeft: 20,
          }}
        >
          {selected.includes("Other") && (
            <div>
              <h4
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                Other:
              </h4>
              <textarea
                style={{
                  width: 710,
                  height: 70,
                  border: "none",
                }}
                readonly={!editable}
                onChange={(e) => {
                  setOtherEquipments(e.target.value);
                }}
                value={otherEquipments}
                type="text"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AthleteTrainingAssessment;
