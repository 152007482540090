import * as Yup from 'yup';


const TestimonialValidatorDef = Yup.object().shape({
    
    client_name: Yup.string().required('Client name cannot be left empty!')
        .max(60, 'Should be within 60 characters of length')
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    testimonial_details: Yup.string().required('Testimonial cannot be left empty!')
        .max(200, 'Should be within 200 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed')
});


export default TestimonialValidatorDef;