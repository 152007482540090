import React, { useState, useEffect } from "react";
import "./Profile.css";
import FormControlLabelPlacement from "../../Components/Buttons/YesNoButton";
import { db } from "../../utils/firebase";
import { useDispatch, useSelector } from "react-redux";
import "../../responsive.css";
import { useHistory } from "react-router-dom";
import {
  selectTemperoryId,
  selectUser,
  selectUserType,
} from "../../features/userSlice";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Header from "../../Components/Header/Header";
import Notification from "../../Components/Notifications/Notification";
import CheckboxesGroups from "../../Components/Buttons/Checkboxs";

function AthleteMedicalAssessment({ route }) {
  const navigation = useHistory();
  const user = useSelector(selectUser);
  const userType = useSelector(selectUserType);
  const temperoryId = useSelector(selectTemperoryId);
  const [userData, setUserData] = useState(null);
  const [editable, setEditable] = useState(false);
  const [heartCondition, setHeartCondition] = useState(false);
  const [chestPain, setChestPain] = useState(false);
  const [chestPain1, setChestPain1] = useState(false);
  const [dizziness, setDizziness] = useState(false);
  const [dizziness1, setDizziness1] = useState(false);
  const [jointProblem, setJointProblem] = useState(false);
  const [medicationForHeartProblem, setMedicationForHeartProblem] =
    useState(false);
  const [knowReason, setKnowReason] = useState(false);

  const [hadPain, setHadPain] = useState(false);
  const [applicableAreas, setApplicableAreas] = useState([
    "Ankle",
    "Knee",
    "Hip",
    "Back",
    "Shoulders",
    "Other",
  ]);
  const [selectedApplicableAreas, setSelectedApplicableAreas] = useState([]);
  const [hadSurgeries, setHadSurgeries] = useState(false);
  const [surgery, setSurgery] = useState("");
  const [chronicDisease, setChronicDisease] = useState(false);
  const [otherAilments, setOtherAilments] = useState("");
  const [otherPainAreas, setOtherPainAreas] = useState("");
  const [applicableAilments, setApplicableAilments] = useState([
    "Diabetes",
    "Hepatitis",
    "Pneumonia",
    "High Blood Pressure",
    "Low Blood Pressure",
    "Back/joint pains",
    "Kidney infection",
    "Heart murmur",
    "Heart Disease",
    "Other",
  ]);
  const [selectedApplicableAilments, setSelectedApplicableAilments] = useState(
    []
  );
  const [ankle, setAnkle] = React.useState(false);
  const [shoulder, setShoulder] = React.useState(false);
  const [back, setBack] = React.useState(false);
  const [knee, setKnee] = React.useState(false);
  const [hip, setHip] = React.useState(false);
  const [other1, setOther1] = React.useState(false);

  const [diabetes, setDiabetes] = React.useState(false);
  const [pneumonia, setPneumonia] = React.useState(false);
  const [lowbp, setLowbp] = React.useState(false);
  const [kidney, setKidney] = React.useState(false);
  const [joint, setJoint] = React.useState(false);
  const [hepatitis, setHepatitis] = React.useState(false);
  const [heart, setHeart] = React.useState(false);
  const [highbp, setHighbp] = React.useState(false);
  const [murmur, setMurmur] = React.useState(false);
  const [other2, setOther2] = React.useState(false);

  const [takingMedication, setTakingMedication] = useState(false);
  const [medicationDetails, setMedicationDetails] = useState("");
  const [otherInjury, setOtherInjury] = useState("");
  const [otherAilment, setOtherAilment] = useState("");
  const [array1, setArray1] = useState([]);
  const [array2, setArray2] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userType === "coach") {
      db.collection("athletes")
        .doc(temperoryId)
        .get()
        .then(function (snap) {
          setUserData({
            id: temperoryId,
            data: snap.data(),
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    } else {
      db.collection("athletes")
        .where("email", "==", user)
        .get()
        .then(function (querySnapshot) {
          console.log("23");
          querySnapshot.forEach(function (doc) {
            setUserData({
              id: doc.id,
              data: doc.data(),
            });
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    }
  }, [user]);

  useEffect(() => {
    if (userData) {
      db.collection("athletes")
        .doc(userData?.id)
        .collection("Medical")
        .doc("medical")
        .get()
        .then(function (doc) {
          if (doc.exists) {
            console.log("Document data:", doc.data());
            // setOtherInjury(doc.data().selectedApplicableAreas.other);
            // setOtherAilment(doc.data().selectedApplicableAilments.other);
            if (doc.data().heartCondition === "yes") {
              setHeartCondition("yes");
            } else {
              setHeartCondition("no");
            }
            if (doc.data().chestPain === "yes") {
              setChestPain("yes");
            } else {
              setChestPain("no");
            }
            if (doc.data().chestPain1 === "yes") {
              setChestPain1("yes");
            } else {
              setChestPain1("no");
            }
            if (doc.data().selectedApplicableAilments) {
              setSelectedApplicableAilments(
                doc.data().selectedApplicableAilments
              );
            } else {
              setSelectedApplicableAilments([]);
            }
            if (doc.data().selectedApplicableAreas) {
              setSelectedApplicableAreas(doc.data().selectedApplicableAreas);
            } else {
              setSelectedApplicableAreas([]);
            }

            if (doc.data().dizziness === "yes") {
              setDizziness("yes");
            } else {
              setDizziness("no");
            }
            if (doc.data().dizziness1 === "yes") {
              setDizziness1("yes");
            } else {
              setDizziness1("no");
            }
            if (doc.data().jointProblem === "yes") {
              setJointProblem("yes");
            } else {
              setJointProblem("no");
            }
            if (doc.data().medicationForHeartProblem === "yes") {
              setMedicationForHeartProblem("yes");
            } else {
              setMedicationForHeartProblem("no");
            }
            if (doc.data().knowReason === "yes") {
              setKnowReason("yes");
            } else {
              setKnowReason("no");
            }
            if (doc.data().hadPain === "yes") {
              setHadPain("yes");
            } else {
              setHadPain("no");
            }
            if (doc.data().hadSurgeries === "yes") {
              setHadSurgeries("yes");
            } else {
              setHadSurgeries("no");
            }
            setSurgery(doc.data().surgery);
            if (doc.data().chronicDisease === "yes") {
              setChronicDisease("yes");
            } else {
              setChronicDisease("no");
            }
            if (doc.data().takingMedication === "yes") {
              setTakingMedication("yes");
            } else {
              setTakingMedication("no");
            }
            setMedicationDetails(doc.data().medicationDetails);
            setOtherAilments(
              doc.data().otherAilments ? doc.data().otherAilments : ""
            );

            setOtherPainAreas(
              doc.data().otherPainAreas ? doc.data().otherPainAreas : ""
            );

            // doc
            //   .data()
            //   .selectedApplicableAreas.selectedApplicableAreas.map((s) => {
            //     s == "Ankle" && setAnkle(true);
            //     s == "Shoulder" && setShoulder(true);
            //     s == "Back" && setBack(true);
            //     s == "Knee" && setKnee(true);
            //     s == "Hip" && setHip(true);
            //     s == "Other" && setOther1(true);
            //   });

            // doc
            //   .data()
            //   .selectedApplicableAilments.selectedApplicableAilments.map(
            //     (s) => {
            //       s == "Diabetes" && setDiabetes(true);
            //       s == "Pneumonia" && setPneumonia(true);
            //       s == "High Blood Pressure" && setHighbp(true);
            //       s == "Heart murmur" && setMurmur(true);
            //       s == "Heart disease" && setHeart(true);
            //       s == "Back/Joint pains" && setJoint(true);
            //       s == "Low Blood Pressure" && setLowbp(true);
            //       s == "Kidney Infection" && setKidney(true);
            //       s == "Hepatitis" && setHepatitis(true);
            //       s == "Other" && setOther2(true);
            //     }
            //   );
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((e) => console.log(e));
    }
  }, [userData?.id]);

  const isItemChecked = (abilityName) => {
    return selectedApplicableAreas.indexOf(abilityName) > -1;
  };

  const isAilmentChecked = (abilityName) => {
    return selectedApplicableAilments.indexOf(abilityName) > -1;
  };

  const handleCheckBoxChange = (evt, abilityName) => {
    if (isItemChecked(abilityName)) {
      setSelectedApplicableAreas(
        selectedApplicableAreas.filter((i) => i !== abilityName)
      );
    } else {
      setSelectedApplicableAreas([...selectedApplicableAreas, abilityName]);
    }
  };

  const handleAilmentsCheckBoxChange = (evt, abilityName) => {
    if (isAilmentChecked(abilityName)) {
      setSelectedApplicableAilments(
        selectedApplicableAilments.filter((i) => i !== abilityName)
      );
    } else {
      setSelectedApplicableAilments([
        ...selectedApplicableAilments,
        abilityName,
      ]);
    }
  };
  // let array1 = [];
  // let array2 = [];

  // useEffect(() => {
  //   ankle && array1.push("Ankle");
  //   shoulder && array1.push("Shoulder");
  //   knee && array1.push("Knee");
  //   back && array1.push("Back");
  //   hip && array1.push("Hip");
  //   other1 && array1.push("Other");
  // }, [ankle, shoulder, back, knee, hip, other1]);

  // useEffect(() => {
  //   diabetes && array2.push("Diabetes");
  //   pneumonia && array2.push("Pneumonia");
  //   highbp && array2.push("High Blood Pressure");
  //   murmur && array2.push("Heart murmur");
  //   heart && array2.push("Heart disease");
  //   joint && array2.push("Back/Joint pains");
  //   hepatitis && array2.push("Hepatitis");
  //   lowbp && array2.push("Low Blood Pressure");
  //   kidney && array2.push("Kidney Infection");
  //   other2 && array2.push("Other");
  // }, [
  //   diabetes,
  //   pneumonia,
  //   highbp,
  //   murmur,
  //   heart,
  //   joint,
  //   hepatitis,
  //   lowbp,
  //   kidney,
  //   other2,
  // ]);

  const saveDetails = () => {
    if (route?.params?.setAddDetails) {
      route.params.setAddDetails(true);
    }
    // db.collection("athletes")
    //   .doc(userData?.id)
    //   .collection("Medical")
    //   .doc("medical")
    //   .delete();

    db.collection("athletes")
      .doc(userData?.id)
      .collection("Medical")
      .get()
      .then((snap) => {
        if (!snap.empty) {
          db.collection("athletes")
            .doc(userData?.id)
            .collection("Medical")
            .doc("medical")
            .update({
              heartCondition,
              chestPain,
              chestPain1,
              dizziness,
              dizziness1,
              jointProblem,
              medicationForHeartProblem,
              knowReason,
              hadPain,
              hadSurgeries,
              surgery,
              chronicDisease,
              otherAilments,
              otherPainAreas,
              takingMedication,
              medicationDetails,
              selectedApplicableAilments,
              selectedApplicableAreas,
            })
            .then((res) => {
              // navigation.goBack();
            })
            .catch((e) => console.log(e));
        } else {
          db.collection("athletes")
            .doc(userData?.id)
            .collection("Medical")
            .doc("medical")
            .set({
              heartCondition,
              chestPain,
              chestPain1,
              dizziness,
              dizziness1,
              jointProblem,
              medicationForHeartProblem,
              knowReason,
              hadPain,
              hadSurgeries,
              otherAilments,
              otherPainAreas,
              surgery,
              chronicDisease,
              takingMedication,
              medicationDetails,
              selectedApplicableAilments,
              selectedApplicableAreas,
            })
            .then((res) => {
              // navigation.goBack();
            })
            .catch((e) => console.log(e));
        }
      });

    setEditable(false);
  };

  // console.log(selectedApplicableAilments);

  return (
    <div
      className="AthleteMedicalAssessment "
      id="medicalone"
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      <div id="tophead" className="header">
        <Header Id={temperoryId} />
      </div>
      <div
        id="medicaltwo"
        className="athleteProfile__container"
        style={{ marginBottom: 100 }}
      >
        <div id="medicalthree" className="coachProfile__leftContainer">
          <div id="bottomhead" className="header">
            <Header Id={temperoryId} />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h2 style={{ paddingLeft: "1%" }}>Medical Assessment</h2>
            {userType !== "coach" && editable && (
              <div
                className="editProfileButton"
                onClick={() => {
                  setEditable(false);
                  saveDetails();
                }}
              >
                <h3 style={{ color: "var(--secondarycolor)" }}>SAVE PROFILE</h3>
              </div>
            )}{" "}
            {userType !== "coach" && !editable && (
              <div
                className="athleteTrainingAssessment__editProfileButton"
                style={{
                  position: "relative",
                  top: -35,
                }}
                onClick={() => setEditable(true)}
              >
                <h3 style={{ color: "var(--secondarycolor)" }}>EDIT PROFILE</h3>
              </div>
            )}
          </div>

          <div className="athleteMedicalAssessment__container">
            <form className="athleteMedicalAssessment__form">
              <p>
                Has your doctor ever said that you have a heart condition and
                that you should only perform physical activity recommended by a
                doctor?
              </p>
              <FormControlLabelPlacement
                value={heartCondition}
                setValue={setHeartCondition}
                editable={editable}
              />
              <p>
                Do you feel pain in your chest when you perform physical
                activity?
              </p>
              <FormControlLabelPlacement
                value={chestPain}
                setValue={setChestPain}
                editable={editable}
              />
              <p>
                {" "}
                In the past month, have you had chest pain when you were not
                performing any physical activity?
              </p>
              <FormControlLabelPlacement
                value={chestPain1}
                setValue={setChestPain1}
                editable={editable}
              />
              <p>
                Do you lose your balance because of dizziness or do you ever
                lose consciousness?
              </p>
              <FormControlLabelPlacement
                value={dizziness}
                setValue={setDizziness}
                editable={editable}
              />
              <p>
                Do you have a bone or joint problem that could be made worse by
                a change in your physical activity?
              </p>
              <FormControlLabelPlacement
                value={jointProblem}
                setValue={setJointProblem}
                editable={editable}
              />
              <p>
                Is your doctor currently prescribing any medication for your
                blood pressure or for a heart condition?
              </p>
              <FormControlLabelPlacement
                value={medicationForHeartProblem}
                setValue={setMedicationForHeartProblem}
                editable={editable}
              />
              <p>
                Do you know of any other reason why you should not engage in
                physical activity?
              </p>
              <FormControlLabelPlacement
                value={knowReason}
                setValue={setKnowReason}
                editable={editable}
              />
              <p>
                If you have answered “Yes” to one or more of the above
                questions, consult your physician before engaging in physical
                activity. Tell your physician which questions you answered “Yes”
                to. After a medical evaluation, seek advice from your physician
                on what type of activity is suitable for your current condition.
              </p>
              <p>Have you ever had any pain or injuries?</p>
              <FormControlLabelPlacement
                value={hadPain}
                setValue={setHadPain}
                editable={editable}
              />
              {hadPain == "yes" && (
                <div>
                  <p>Please select applicable areas</p>
                  <div>
                    {applicableAreas.map((area, idx) => (
                      <div key={idx}>
                        <label>
                          <input
                            disabled={!editable}
                            type="checkbox"
                            checked={isItemChecked(area)}
                            onChange={(evt) => handleCheckBoxChange(evt, area)}
                            style={{
                              marginLeft: 10,
                            }}
                          />
                          {area}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div>
                    {selectedApplicableAreas.includes("Other") && (
                      <div>
                        <h4
                          style={{
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          Other:
                        </h4>
                        <textarea
                          style={{
                            width: 730,
                            height: 70,
                          }}
                          disabled={!editable}
                          onChange={(e) => {
                            setOtherPainAreas(e.target.value);
                          }}
                          value={otherPainAreas}
                          type="text"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              <p>Have you ever had any surgeries?</p>
              <FormControlLabelPlacement
                value={hadSurgeries}
                setValue={setHadSurgeries}
                editable={editable}
              />
              {hadSurgeries == "yes" && (
                <div>
                  <p>Please Explain.</p>
                  <textarea
                    style={{
                      width: 730,
                      height: 70,
                    }}
                    disabled={!editable}
                    onChange={(e) => {
                      setSurgery(e.target.value);
                    }}
                    value={surgery}
                    type="text"
                    placeholder="Please provide additional details of your surgery."
                  />
                </div>
              )}
              <p>
                Has a medical doctor ever diagnosed you with a chronic disease?
              </p>
              <FormControlLabelPlacement
                value={chronicDisease}
                setValue={setChronicDisease}
                editable={editable}
              />
              {chronicDisease == "yes" && (
                <div>
                  <p>Please select applicable ailments</p>
                  <div>
                    {applicableAilments.map((ailment, idx) => (
                      <div key={idx}>
                        <label>
                          <input
                            disabled={!editable}
                            type="checkbox"
                            checked={isAilmentChecked(ailment)}
                            onChange={(evt) =>
                              handleAilmentsCheckBoxChange(evt, ailment)
                            }
                            style={{
                              marginLeft: 10,
                            }}
                          />
                          {ailment}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div>
                    {selectedApplicableAilments.includes("Other") && (
                      <div>
                        <h4>Other:</h4>
                        <textarea
                          style={{
                            width: 730,
                            height: 70,
                          }}
                          disabled={!editable}
                          onChange={(e) => {
                            setOtherAilments(e.target.value);
                          }}
                          value={otherAilments}
                          type="text"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              <p>Are you currently taking any medication?</p>
              <FormControlLabelPlacement
                value={takingMedication}
                setValue={setTakingMedication}
                editable={editable}
              />
              {takingMedication == "yes" && (
                <div>
                  <p>Please Explain.</p>
                  <textarea
                    style={{
                      width: 730,
                      height: 70,
                    }}
                    disabled={!editable}
                    onChange={(e) => {
                      setMedicationDetails(e.target.value);
                    }}
                    value={medicationDetails}
                    type="text"
                    placeholder="Please list any medication you are currently taking."
                  />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AthleteMedicalAssessment;
