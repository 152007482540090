import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserData, selectUserType } from "../../features/userSlice";
import { db } from "../../utils/firebase";
import { formatDate } from "../../functions/formatDate";
import moment from "moment";
import { formatDate1 } from "../../Components/NutritionCard/NutritionCard";
function AthleteGoals() {
  const [goal, setgoal] = useState(null);
  const userData = useSelector(selectUserData);
  const userType = useSelector(selectUserType);
  const formatDate = (str) => {
    if (str) {
      var date = str.split("-");
      date[1] = parseInt(date[1]) + 1;
      return date[2] + "-" + date[1].toString() + "-" + date[0];
    }
  };
  React.useEffect(() => {
    if (userData) {
      var localEventsHistory = [];
      var temp = null;
      var data = null;
      db.collection("athletes")
        .doc(userData?.id)
        .get()
        .then((snapshot) => {
          if (snapshot.data()?.goals) {
            console.log(snapshot.data().goals);
            snapshot.data()?.goals.forEach((item) => {
              if (typeof item.date == "string") {
                console.log(formatDate(item.date));
                let date = new Date(formatDate(item.date));
                console.log(date, new Date());
                console.log(date > new Date());
                if (!temp && date > new Date()) {
                  temp = date;
                  data = item;
                }
                if (date < temp && date > new Date()) {
                  data = item;
                }
              }
            });
            // setgoal(data);
            console.log(goal);
          }
        });
    }
  }, [userData?.id, userData?.data]);
useEffect(()=>{
if( userData?.data?.goals){
  let tempGoals=userData?.data?.goals
  let temp=[]
let array=[...tempGoals]
  if (array.length > 1) {
    array.sort(function (a, b) {
      return (
        new Date(
          a.date.seconds
            ? a.date.seconds * 1000
            : new Date(
                a.date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
              )
        ) -
        new Date(
          b.date.seconds
            ? b.date.seconds * 1000
            : new Date(
                b.date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
              )
        )
      );
    });
    array.forEach((id) => {
    if (
      moment(new Date()).valueOf() <
      moment(
        id.date.seconds
          ? id.date.seconds * 1000
          : id.date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3")
      ).valueOf()
    ) {
      temp.push(
        <div
          style={{
            flexDirection: "row",
            padding: 10,
            alignItems: "center",
            justifyContent: "space-between",
            borderBottomWidth: 1,
            marginTop: 0,
            width: "85%",
            display: "flex",
          }}
          key={id.date.seconds}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              width: "70%",
            }}
          >
            <div
              style={{
                backgroundColor: "#2E2E2E",
                height: 5,
                width: 5,
                borderRadius: 80,
              }}
            ></div>
            <div style={{ marginLeft: 15 }}>
              <p
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: "black",
                  margin: 0,
                  padding: 0,
                }}
              >
                {id.name}
              </p>
              <p
                style={{
                  fontSize: 12,
                  color: "black",
                  marginTop: 5,
                  padding: 0,
                }}
              >
                {moment(
                  id.date.seconds
                    ? id.date.seconds * 1000
                    : new Date(
                        id.date.replace(
                          /(\d{2})-(\d{2})-(\d{4})/,
                          "$2/$1/$3"
                        )
                      )
                ).format("LL")}
              </p>
            </div>
          </div>

          <div style={{ flexDirection: "row", width: "25%" }}>
            <div
              style={{
                backgroundColor: "var(--primarycolor)",
                padding: 2,
                borderRadius: 7,
                width: "100%",
              }}
              activeOpacity={1}
            >
              <p
                style={{
                  color: "var(--secondarycolor)",
                  fontSize: 12,
                  textAlign: "center",
                  margin: 0,
                  padding: 0,
                }}
              >
                {moment(
                  id.date.seconds
                    ? id.date.seconds * 1000
                    : new Date(
                        id.date.replace(
                          /(\d{2})-(\d{2})-(\d{4})/,
                          "$2/$1/$3"
                        )
                      )
                )
                  .endOf("day")
                  .fromNow()
                  .slice(3, 4) == "a"
                  ? "1" +
                    moment(
                      id.date.seconds
                        ? id.date.seconds * 1000
                        : new Date(
                            id.date.replace(
                              /(\d{2})-(\d{2})-(\d{4})/,
                              "$2/$1/$3"
                            )
                          )
                    )
                      .endOf("day")
                      .fromNow()
                      .slice(4)
                  : moment(
                      id.date.seconds
                        ? id.date.seconds * 1000
                        : new Date(
                            id.date.replace(
                              /(\d{2})-(\d{2})-(\d{4})/,
                              "$2/$1/$3"
                            )
                          )
                    )
                      .endOf("day")
                      .fromNow()
                      .slice(3)}
              </p>
              <p
                style={{
                  color: "var(--secondarycolor)",
                  fontSize: 14,
                  alignSelf: "center",
                  margin: 0,
                  padding: 0,
                  textAlign: "center",
                }}
              >
                Left
              </p>
            </div>
          </div>
        </div>
      );
    }
  });
  
  }
  setgoal(temp);
}


},[ userData?.data?.goals])
  return (
    <div className="athleteGoals"
    style={{
      height: 140,
      overflowY: "scroll",
      overflow: "scroll",
          backgroundColor: "white",
      overflowX: "hidden",
    }}
    >
      {!goal && (
        <div
          style={{
            height: 40,
            display: "flex",
            justifyContent: "space-between",
            padding: 10,
          }}
          className="athleteGoals__noGoals"
        >
          <h6 style={{ fontWeight: "normal" }}>
            Add a goal that you are training for
          </h6>
        </div>
      )}

      {goal && (
 <div >
 {goal}
</div>
        // <div
        //   style={{
        //     height: 40,
        //     display: "flex",
        //     justifyContent: "space-between",
        //     padding: 10,
        //   }}
        //   className="athleteGoals__noGoals"
        // >
        //   <div
        //     style={{
        //       display: "flex",
        //       alignItems: "center",
        //     }}
        //   >
        //     <div>
        //       <div
        //         style={{
        //           fontWeight: 700,
        //           fontSize: 17,
        //         }}
        //       >
        //         {goal?.name}
        //       </div>
        //       <div
        //         style={{
        //           fontWeight: 500,
        //           fontSize: 14,
        //         }}
        //       >
        //         {moment(formatDate(goal.date).split("-")[1], "MM").format(
        //           "MMMM"
        //         )}{" "}
        //         {goal.date.split("-")[0]}, {goal.date.split("-")[2]}{" "}
        //       </div>
        //     </div>
        //   </div>
        //   <div
        //     style={{
        //       textAlign: "center",
        //       backgroundColor: "#fcd11c",
        //       borderRadius: 10,
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "center",
        //       fontSize: 14,
        //       padding: 15,
        //     }}
        //   >
        //     {moment(new Date(formatDate(goal.date))).diff(
        //       moment(new Date()),
        //       "days"
        //     )}{" "}
        //     days
        //     {console.log(
        //       goal,
        //       moment(new Date()),
        //       formatDate(goal.date),
        //       moment(new Date(formatDate(goal.date)))
        //     )}
        //   </div>
        //   {console.log("goasl", goal)}
        // </div>
      )}
    </div>
  );
}

export default AthleteGoals;
