import React, { useState, useEffect } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import "./Calendar.css";
import Axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../utils/firebase";
import Card from "./Card";
import Card1 from "./Card1";
import {
  selectUser,
  selectUserData,
  selectUserType,
  setUserData,
} from "../../features/userSlice";
import Event_card from "./Event_card";
import Selected_events from "./SelectedEvents";
import firebase from "firebase";

import { useHistory } from "react-router-dom";
// import {Dialog, DialogTitle, DialogContent} from '@material-ui/core'
import "../../responsive.css";
import { rURL } from "../../utils/Links";
const Calendar_coach = (props) => {
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).utc().format("YYYY-MM-DD")
  );
  const history = useHistory();
  const [events, setEvents] = useState({});
  const [markedDates, setMarkedDates] = useState({});
  const [isSecret, setIsSecret] = useState(false);

  const [months, setMonths] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [curr_month, setCurr_months] = useState("");

  const [curr_year, setCurr_year] = useState(2021);
  const [upcoming, setUpcoming] = useState([]);
  const [todays, setTodays] = useState([]);
  const [markedEvents, setMarkedEvents] = useState({});
  const [goals, setGoals] = useState([]);
  const [selectedevents, setselectedevents] = useState([]);
  const [goalsData, setGoalsData] = useState(null);
  const [tdy, settdy] = useState([]);
  const [upcomingevents, setupcomingevents] = useState([]);
  const [tokens, setTokens] = useState();
  // const [Events, SetEvents] = useState([]);
  const [Eventss, SetEventss] = useState([]);
  const [Eventss1, SetEventss1] = useState([]);
  const [Eventss2, SetEventss2] = useState([]);
  const defaultValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    day: 24,
  };

  // console.log(defaultValue, new Date().getDate())
  const [selectedDay, setSelectedDay] = useState(null);
  const [showevent_count, setshowevent_count] = useState(3);
  const [showevent_count1, setshowevent_count1] = useState(3);
  const [showevent_count2, setshowevent_count2] = useState(3);
  const [isLoading, setisLoading] = useState(true);
  React.useEffect(() => {
    var el = document.getElementsByClassName("Calendar__day -selected");
    if (el.length > 0) {
      let weekname = el[0].getAttribute("aria-label").slice(0, 3).toUpperCase();

      el[0].setAttribute("week", weekname);
    }
  });
  // const [eventHistory, setEventHistory] = useState([]);
  // const [eventHistoryOpen, setEventHistoryOpen] = useState(false);
  // const [dialogOpen, setDialogOpen] = React.useState(false);

  // const handleDialogOpen = () => {
  //   setDialogOpen(true);
  // }
  // const handleDialogClose = () => {
  //   setDialogOpen(false);
  // }

  // useEffect(() => {
  //   if (userData) {
  //     db.collection("events")
  //       .where("coachID", "==", userData?.id)
  //       .orderBy("date", "asc")
  //       .get()
  //       .then((snapshot) => {
  //         SetEventss(
  //           snapshot.docs.map((doc) => ({ id: doc.id, events: doc.data() }))
  //         );
  //       });
  //   }
  // }, [userData?.id]);

  useEffect(() => {
    if (userData) {
      db.collection("events")
        .where("coachID", "==", userData?.id)
        .orderBy("date", "asc")
        .onSnapshot((snapshot) => {
          let events = [];
          snapshot.docs.forEach((item) => {
            item.data().date.toDate().toLocaleDateString() ==
              new Date().toLocaleDateString() && events.push(item.data());
          });
          SetEventss(events);
        });
    }
  }, [userData?.id]);

  useEffect(() => {
    if (userData) {
      db.collection("events")
        .where("coachID", "==", userData?.id)
        .orderBy("date", "asc")
        .onSnapshot((snapshot) => {
          let events = [];
          snapshot.docs.forEach((item) => {
            moment(item.data().date.toDate().toLocaleDateString()) >
              moment(new Date().toLocaleDateString()) &&
              events.push(item.data());
          });
          SetEventss1(events);
        });
    }
  }, [userData?.id]);

  useEffect(() => {
    if (userData) {
      db.collection("events")
        .where("coachID", "==", userData?.id)
        .orderBy("date", "asc")
        .onSnapshot((snapshot) => {
          let events = [];
          snapshot.docs.forEach((item) => {
            item.data().date.toDate().toLocaleDateString() ==
              new Date(props?.selectedDate).toLocaleDateString() &&
              events.push(item.data());
          });
          SetEventss2(events);
        });
    }
  }, [userData?.id]);

  React.useEffect(async () => {
    await db
      .collection("secrets")
      .doc(userData?.id)
      .get()
      .then(async (snap) => {
        if (snap.exists) {
          setIsSecret(true);
        }
      });
  }, [userData?.id]);
  React.useEffect(() => {
    if (selectedDay) {
      let date = selectedDay;

      let temp = new Date(date.year, date.month - 1, date.day);

      temp = temp.setHours(0, 0, 0, 0);

      if (props?.selectedDate == temp) {
        //console.log(props?.selectedDate);
      } else {
        //temp = temp.setHours(0, 0, 0, 0);
        props?.toggle_date(temp);
      }
    }
  }, [selectedDate]);

  React.useEffect(() => {
    console.log(selectedDay);
    if (selectedDay) {
      let date = selectedDay;

      setSelectedDate(moment([date.year, date.month - 1, date.day]));
    }

    setisLoading(false);
  }, [selectedDay]);

  React.useEffect(() => {
    var dates = [];
    var date_str = String(moment(moment(selectedDate).valueOf()).format("ll"));

    if (Object.keys(events).length > 0) {
      var s = "";

      Object.keys(events).forEach((item) => {
        s = String(moment(moment(events[item].eventDate)).format("ll"));

        if (s == date_str) {
          dates.push(item);
        }
      });
    }

    // console.log(Object.keys(events));
    setselectedevents(dates);
  }, [selectedDate]);

  React.useEffect(() => {
    var today_temp = [];
    var upcoming_temp = [];
    var local_events = {};
    let tdy = [];
    var local_markedEvents = {};

    let now = new Date();
    if (props?.selectedDate) {
      var d = moment(new Date(props?.selectedDate));
    } else {
      var d = moment();
    }

    console.log(d);
    setSelectedDay({
      year: d.year(),
      month: d.month() + 1,
      day: d.date(),
    });

    if (userData) {
      db.collection("events")
        .where("coachID", "==", userData.id)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((item) => {
            let currentID = item.id;

            let appObj = { ...item.data(), ["id"]: currentID };
            var key1 = String(
              moment(item.data().date.seconds * 1000)
                .format("llll")
                .split(" ")
                .join("-")
            );
            local_events[key1] = {
              eventName: item.data().name,
              eventDate: item.data().date.seconds * 1000,
              description: item.data().description,
              showVideoLink: item.data()?.showVideoLink,
              videolink: item.data()?.videolink,
              athletes: item.data().athletes,
              id: item.id,
              attendance: item.data()?.attendance,
            };

            if (
              moment(new Date()).valueOf() <=
              item.data().date.seconds * 1000
            ) {
              local_markedEvents[
                moment(item.data().date.seconds * 1000).format("YYYY-MM-DD")
              ] = {
                marked: true,
                dotColor: "black",
              };
            }
          });

          setEvents(local_events);
          setMarkedEvents(local_markedEvents);
          setMarkedDates(local_markedEvents);

          var data = local_events;
          var keys = Object.keys(local_events);
          if (keys.length > 1) {
            keys.sort(function (a, b) {
              return (
                new Date(a.split("-").join(" ")) -
                new Date(b.split("-").join(" "))
              );
            });
          }

          let tdy = [];
          let upcomingevents = [];
          keys.forEach((id) => {
            if (
              String(moment(new Date()).format("L")) ==
              moment(id.split("-").join(" ")).format("L")
            ) {
              tdy.push(data[id]);

              today_temp.push(<div key={id} id={id} data={data} />);
            } else if (
              moment(new Date()).valueOf() <
              moment(id.split("-").join(" ")).valueOf()
            ) {
              upcomingevents.push(data[id]);
              upcoming_temp.push(<div key={id} id={id} data={data} />);
            }
          });

          setTodays(today_temp);
          setUpcoming(upcoming_temp);
          settdy(tdy);
          setupcomingevents(upcomingevents);
          console.log(upcomingevents);
        })
        .catch((e) => console.log(e));
    }
  }, [userData?.id]);

  const googleSync = async () => {
    let win = null;
    let gToken;
    await db
      .collection("secrets")
      .doc(userData?.id)
      .get()
      .then(async (snap) => {
        if (!snap.exists) {
          console.log(11222);
          const data = Axios.post(
            "https://wellzapp-server.herokuapp.com/api/gmeet",
            { rURL }
          ).then((res) => {
            if (res?.data?.url) {
              let url = res?.data?.url;
              win = window.open(url, "win1", "width = 500, height = 300");
              var pollTimer = window.setInterval(async function () {
                try {
                  console.log("Widows URL", win.document.URL);
                  const queryURL = new URL(win.document.URL);
                  console.log("URLRLRLR", queryURL);
                  var url = queryURL.searchParams.get("code");
                  console.log("HIIIII", url);
                  if (url) {
                    window.clearInterval(pollTimer);
                    let axiosConfig = {
                      headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                      },
                    };

                    let code = url;
                    console.log(code);
                    win?.close();
                    await Axios.post(
                      "https://wellzapp-server.herokuapp.com/api/getToken",
                      {
                        code: code.toString(),
                        rURL,
                      },

                      axiosConfig
                    ).then(async (res) => {
                      if (res.data.success) {
                        gToken = res.data.tokens;

                        db.collection("secrets")
                          .doc(userData?.id)
                          .set({ tokens: res.data.tokens })
                          .then(async () => {
                            setTokens(res.data.tokens);
                            getMeetLink(res.data.tokens);
                            console.log("tokens  saved to db");
                          });
                      } else {
                        console.log("dont get token");
                        return null;
                      }
                    });
                  } else {
                    console.log("dont get code");
                    return null;
                  }
                } catch (error) {
                  console.log(error, "window opening problem");
                  return null;
                }
              }, 1000);
            } else {
              console.log("dont get credentials");
              return null;
            }
          });
        } else {
          let tempTokens = await snap.data().tokens;
          gToken = tempTokens;

          getMeetLink(tempTokens);
        }
      });
  };

  const getMeetLink = async (gToken) => {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };
    await Axios.post(
      "https://wellzapp-server.herokuapp.com/api/gmeet/getLink",
      {
        tokens: gToken,
        rURL,
        eventdata: {
          eventname: "Syncing",
          description: "Syncing Stairs with Google",
          videoConference: true,
          date: firebase.firestore.Timestamp.fromDate(new Date()),
          start: firebase.firestore.Timestamp.fromDate(new Date()),
        },
      },

      axiosConfig
    )
      .then((res) => {
        if (!res?.data?.success) {
          alert("Cant get URL please try again");
          return;
        }
        console.log(res?.data, "gmeet link");
        let gmeetlink = res?.data.event.data.hangoutLink;

        db.collection("coaches").doc(userData.id).update({
          videoURL: gmeetlink,
        });
      })
      .then(() => {
        console.log("url added to coach");
        setIsSecret(true);
      });
  };
  return (
    <div className="calendar_container">
      <div className="calendar_header">
        <h3 className="calendar_title">Calendar</h3>
        {!isSecret && (
          <img
            src={"/assets/google_sync.png"}
            width="140"
            className="sync_btn"
            onClick={() => googleSync()}
          />
        )}
        <span className="calendar_right">
          {/* <button
            onClick={() => {
              history.push({
                pathname:"/calendar", 
                state:{
                  page: "CreateEvent"
                }
              });
            }}
            className="add_event"
          >
            +
          </button> */}
          <span
            id="eventshistoryspan"
            onClick={() => {
              history.push({
                pathname: "/calendar",
                // state:{
                //   page: "eventsHistory"
                // }
              });
            }}
            style={{
              width: "55px",
              backgroundColor: "var(--primarycolor)",
              color: "var(--secondarycolor)",
              borderRadius: 5,
              padding: 10,
              cursor: "pointer",
              marginLeft: "15px",
            }}
          >
            Events History
          </span>
        </span>
      </div>
      {console.log("ssd", selectedDay)}
      {selectedDay && Object.keys(selectedDay).length == 3 && (
        <Calendar
          value={selectedDay}
          onChange={setSelectedDay}
          colorPrimary="var(--primarycolor)" // added this
          calendarClassName="mycal" // and this
          calendarTodayClassName="custom-today-day" // also this
        />
      )}
      <div className="events_container">
        <div class="events_today">
          <div style={{ fontWeight: 100, fontSize: 16, color: "grey" }}>
            Today
          </div>
          <div className="events_today_list">
            {Eventss.length !== 0 ? (
              <Card data={Eventss} count={showevent_count1} />
            ) : (
              <>
                <div className="events_today_">
                  No events for today
                  <br />
                </div>
              </>
            )}
            <div class="divider"> </div>
          </div>
          <div style={{}}>
            {Eventss.length > showevent_count1 && (
              <div
                class="events_more"
                style={{
                  textAlign: "center",
                  marginTop: 10,
                  color: "#acacac",
                }}
                onClick={() => {
                  setshowevent_count1(showevent_count1 + 3);
                }}
              >
                +{Eventss.length - showevent_count1} more
              </div>
            )}
          </div>
          <div class="divider"> </div>
          {Eventss2.length !== 0 && (
            <div
              style={{
                fontWeight: 100,
                fontSize: 16,
                marginTop: 10,
                color: "grey",
              }}
            >
              Events scheduled on{" "}
              {moment(moment(selectedDate).valueOf()).format("ll")}
            </div>
          )}
          <div className="events_today_list">
            {Eventss2.length !== 0 ? (
              <Card data={Eventss2} count={showevent_count2} />
            ) : (
              <>
                <div className="events_today_">
                  No events scheduled on{" "}
                  {moment(moment(selectedDate).valueOf()).format("ll")}
                  <br />
                </div>
              </>
            )}
          </div>
          {Eventss2.length > showevent_count2 && (
            <div
              class="events_more"
              style={{ textAlign: "center", marginTop: 20, color: "#acacac" }}
              onClick={() => {
                setshowevent_count2(showevent_count2 + 3);
              }}
            >
              +{Eventss2.length - showevent_count2} more
            </div>
          )}
        </div>

        {/* Upcoming events list */}

        <div
          class="upcoming_events"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              fontWeight: 100,
              color: "grey",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            Upcoming
          </div>

          {/* Upcoming events single item */}

          <div className="upcoming_event_">
            {Eventss1.length !== 0 ? (
              <Card data={Eventss1} count={showevent_count} />
            ) : (
              <div
                style={{
                  fontWeight: 600,
                }}
              >
                No events scheduled
              </div>
            )}
          </div>

          {/* {Events.map(
            ({ id, events }) =>
              events.coachID == userData?.id &&
              moment(events.date.toDate().toLocaleDateString()) >
                moment(new Date().toLocaleDateString()) && (
                <Card data={events} />
              )
          )} */}

          <div class="divider"> </div>
        </div>
        {Eventss1.length > showevent_count && (
          <div
            class="events_more"
            style={{ textAlign: "center", marginTop: 20, color: "#acacac" }}
            onClick={() => {
              setshowevent_count(showevent_count + 3);
            }}
          >
            +{Eventss1.length - showevent_count} more
          </div>
        )}
      </div>
      {/* <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>Events History</DialogTitle>
        <DialogContent></DialogContent>
      </Dialog> */}
    </div>
  );
};

export default React.memo(
  Calendar_coach,
  (prevProps, nextProps) => prevProps.selectedDate === nextProps.selectedDate
);
