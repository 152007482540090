import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  selectTemperoryId,
  selectUser,
  selectUserType,
  selectUserData,
} from "../../features/userSlice";
import Header from "../../Components/Header/Header";
import direction from "../../Images/direction.png";
import Slider, { Range } from "rc-slider";
import { db } from "../../utils/firebase";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./ParticularPhysioForm.css";
import "./StairsForm.css";
import backButton from "./left-arrow.png";
import "../../responsive.css";

const ParticularPhysioAss = ({ route }) => {
  const history = useHistory();
  const user = useSelector(selectUser);
  const userType = useSelector(selectUserType);
  const temperoryId = useSelector(selectTemperoryId);
  const userData = useSelector(selectUserData);

  const [therapy, setTherapy] = useState([]);
  const [getTherapys, setGetTheraphys] = useState([]);
  const [getUserTherapys, setGetUserTheraphys] = useState([]);

  let { id } = useParams();
  let iid = id;
  console.log(iid);

  let userId = userData?.id;

  useEffect(() => {
    if (temperoryId) {
      db.collection("athletes")
        .doc(temperoryId)
        .collection("Physiotheraphy")
        // .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) =>
          setGetTheraphys(
            snapshot.docs.map((doc) => ({ id: doc.id, therapys: doc.data() }))
          )
        );
    }
  }, [temperoryId]);

  useEffect(() => {
    if (userId) {
      db.collection("athletes")
        .doc(userId)
        .collection("Physiotheraphy")
        // .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) =>
          setGetUserTheraphys(
            snapshot.docs.map((doc) => ({ id: doc.id, therapys: doc.data() }))
          )
        );
    }
  }, [userId]);

  return (
    <>
      {userType != "athlete" && (
        <div
          style={{
            marginLeft: 30,
            maxWidth: 700,
          }}
        >
          {getTherapys.map(({ id, therapys }) => (
            <div key={id}>
              {id == iid && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        width: 16,
                        height: 16,
                        cursor: "pointer",
                      }}
                      src={backButton}
                      onClick={() => {
                        // window.open("/Athlete/reports/" + Id, "");
                        console.log(1);
                        history.push({
                          pathname: "/Previous_Physiotherapy_Assessments",
                          state: {
                            AthleteId: temperoryId,
                          },
                        });
                      }}
                    />
                    <h2
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      {therapys.Name} -
                    </h2>
                    <h2
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      Date: {therapys.Date}
                    </h2>
                  </div>
                  {/* <div className="report">
                <h1>Sport Played/Recreational Activity: {therapys.sports} </h1>
                <h1>Physical Activity level: {therapys.Proficiency} </h1>
                <h1>Hand/Foot Dominance: {therapys.LimbUsage} </h1>
              </div> */}
                  <div className="form-group">
                    <label>Physiotherapy Name:</label>
                    <textarea
                      readOnly
                      required
                      rows="3"
                      value={therapys.Name}
                      type="text"
                      className="form-control"
                      id="name"
                      name="Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Sport Played/Recreational Activity:</label>
                    <textarea
                      readOnly
                      required
                      rows="3"
                      value={therapys.sports}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Physical Activity level:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={therapys.Proficiency}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Hand/Foot Dominance:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.LimbUsage}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Uploaded Image:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.File}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Injury History:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.InjuryHistory.boolean}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  {therapys.InjuryHistory.boolean == "Yes" ||
                    (therapys.InjuryHistory.boolean == "yes" && (
                      <div className="form-group">
                        <label>Injury Name:</label>
                        <textarea
                          rows="3"
                          readOnly
                          value={therapys.InjuryHistory.InjuryHistoryText}
                          type="text"
                          className="form-control"
                          id="sport"
                          name="sport"
                        />
                        <br></br>
                      </div>
                    ))}
                  <div className="form-group">
                    <label>Current Complaints:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={therapys.Complaints}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Current Illnesses:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={therapys.CurrentIllness}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Past Injuries:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={therapys.PastInjuries}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Past Illnesses:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={therapys.PastIllness}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Medical Conditions:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={therapys.MedicalCondition}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label for="CurrentMedication">Current Medications:</label>
                    <textarea
                      rows="3"
                      type="text"
                      className="form-control"
                      id="CurrentMedication"
                      value={therapys.CurrentMedications}
                      name="sport"
                      id="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Past Surgery:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.PastSurgery}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Allergies:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Allergies}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Orthotics:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Orthotics}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <h6>Your Sleep Score</h6>
                  {/* <Slider
                    min={1}
                    max={5}
                    dots
                    marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
                    disabled
                    value={therapys.Psychosocial.Sleep}
                  /> */}
                  <h3>{therapys.Psychosocial.Sleep}/5</h3>
                  <br></br>
                  <br></br>
                  <h6>Your Work Pattern Score</h6>
                  {/* <Slider
                    min={1}
                    max={5}
                    dots
                    marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
                    disabled
                    value={therapys.Psychosocial.WorkPattern}
                  /> */}
                  <h3>{therapys.Psychosocial.WorkPattern}/5</h3>
                  <br></br>
                  <br></br>
                  <h6>Your Stress and Anxiety Score</h6>
                  {/* <Slider
                    min={1}
                    max={5}
                    dots
                    marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
                    disabled
                    value={therapys.Psychosocial.Stress_And_Anxiety}
                  /> */}
                  <h3>{therapys.Psychosocial.Stress_And_Anxiety}/5</h3>
                  <br></br>
                  <br />
                  <div className="form-group">
                    <label>Timing of Pain:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Timing}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Sequence of Pain:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Sequence}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Mechanism of Injury:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Mechanism.Type}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  {therapys.Mechanism.Type == "atraumatic" ||
                    (therapys.Mechanism.Type == "traumatic" && (
                      <>
                        <div className="form-group">
                          <label>Type of {therapys.Mechanism.Type}:</label>
                          <textarea
                            rows="3"
                            readOnly
                            required
                            value={therapys.Mechanism.Reason}
                            type="text"
                            className="form-control"
                            id="sport"
                            name="sport"
                          />
                        </div>

                        {therapys.Mechanism.Reason == "other" ||
                          (therapys.Mechanism.Reason == "others" && (
                            <div className="form-group">
                              <label>
                                {" "}
                                Other Type Of {therapys.Mechanism.Type}:
                              </label>
                              <textarea
                                rows="3"
                                readOnly
                                required
                                value={therapys.Mechanism.OtherReason}
                                type="text"
                                className="form-control"
                                id="sport"
                                name="sport"
                              />
                            </div>
                          ))}
                      </>
                    ))}
                  {therapys.Mechanism.Type == "Post Operative" && (
                    <div>
                      <div className="form-group">
                        <label>{therapys.Mechanism.Type} Surgery Name:</label>
                        <textarea
                          rows="3"
                          readOnly
                          required
                          value={therapys.PostOperative.Surgery_Name}
                          type="text"
                          className="form-control"
                          id="sport"
                          name="sport"
                        />
                      </div>
                      <br />
                      <p>Pain Score:</p>
                      {/* <Slider
                        min={0}
                        max={10}
                        dots
                        marks={{
                          0: 0,
                          1: 1,
                          2: 2,
                          3: 3,
                          4: 4,
                          5: 5,
                          6: 6,
                          7: 7,
                          8: 8,
                          9: 9,
                          10: 10,
                        }}
                        value={therapys.PostOperative.Pain_Score}
                        disabled
                      /> */}
                      <h3>{therapys.PostOperative.Pain_Score}/5</h3>
                      <br></br>
                    </div>
                  )}
                  <br></br>
                  <div className="form-group">
                    <label>Sequence of Pain:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Sequence}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Time to Increase:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.TimeToIncrease}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Time to Stay:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.TimeToStay}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Time to Reduce:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.TimeToReduce}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Nature of Pain:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Nature_Of_Pain}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Aggravating Causes:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Aggravating_Causes}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Relieving Causes:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Relieving_Causes}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Head Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Head_Posture}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Left Shoulder Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Left_Shoulder}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Right Shoulder Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Right_Shoulder}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Thoracic Spine Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Thoracic_Spine}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Lumbar Spine Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Lumbar_Spine}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Left Knee Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Left_Knee}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Right Knee Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Right_Knee}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Right Foot Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Right_Foot}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Left Foot Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Left_Foot}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Foot Index:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.FootIndex}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <h1>Upper Quarter Tests: </h1>
                  <div className="form-group">
                    <label>
                      Internal Rotation at 90° Abduction in Supine (&gt;90):
                    </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Internal_Rotation}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>
                      External Rotation at 90° Abduction in Supine(&gt;90):
                    </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.External_Rotation}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>
                      Glenohumeral Internal Rotation Deficit (GIRD):
                    </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Glenohumeral_Internal_Rotation}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>
                      External Rotation Gain (ERG) +’ve when GIRD &gt; Ratio –
                      Dominant Arm only :
                    </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Internal_Rotation_Gain}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>H-K Impingement Test:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.HK_Impingement_Test}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Full Can Test:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Full_Can_Test}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Infraspinatus Strength Test :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Infraspinatus_Strength_Test}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Kibler Lateral Slide Test :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Kibler_Lateral_Side_Test}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Position 1 (≤ 1.5cms +/-) :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Positionone}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Position 2 (≤ 1.5cms +/-) :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Positiontwo}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Position 3 (≤ 1.5cms +/-) :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Positionthree}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>SSMP/Scapular Balancing Index :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Scapular}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Horizontal adduction test/ dugas sign - :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Horizontal_Adduction}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Kims :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Kims}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Biceps load 1 and 2 :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Biceps_Load}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>O’brien test :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Obrien_Test}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>CKUEST :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Ckuest}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Y-balance :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Y_Balance}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Quadrant Test:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Quadrant_Test}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Trunk Side Flexion ROM:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Trunk_Side_Flexion_ROM}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Lumbar Spine ROM:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Lumbarspine}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Notes : </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Notes}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <h1>TEST LOWER Quarter: </h1>
                  <br></br>
                  <div className="form-group">
                    <label>Hamstring Length at 90/90 - (≥ 160):</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Hamstring_Length_At_90_By_90}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Iliopsoas Muscle Length (≤ 5):</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Iliopsoas_Length}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Rectus Femoris Muscle Length (≥ 80):</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Recturs_Femoris_Length}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Hip Internal Rotation – Prone (≥ 30) </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Internalhip_Rotation}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Hip External Rotation – Prone (≥ 45)</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Externalhip_Rotation}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Hip Quadrant Test :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Quadranthip}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Piriformis Muscle Length (&gt; 10) :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Piriformis_Length}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>DF Lunge Test (≥ 10 cms):</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Lunge}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Posterior Impingement Test :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Impingement_Posterior}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>
                      Single leg standing balance (eyes open/closed):
                    </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Singleleg_Standing_Balance}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>OH Deep Squat:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.OH_Deep_Squat}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Single Leg Squat :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Singlelegsquat}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Core stability push up/Core stability score:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Core_Stability_Push}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Ottawa Ankle Rule:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Ottawa_Ankle_Rule}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <h1>Neural Testing</h1>
                  <br />
                  <div className="form-group">
                    <label>SLUMP:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.SLUMP}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label>SLR:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.SLR}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <div className="form-group">
                    <label>ULTTs :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.ULTTs}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  {/* <h1>Side PLank : </h1>
              <div className="form-group">
                <label>Time position sustained :</label>
                <textarea
                  rows="3"
                  readOnly
                  required
                  value={Sustaineds}
                  type="text"
                  className="form-control"
                  id="sport"
                  name="sport"
                />
              </div>
              <div className="form-group">
                <label>Lumbar dipping :</label>
                <textarea
                  rows="3"
                  readOnly
                  required
                  value={Dippings}
                  type="text"
                  className="form-control"
                  id="sport"
                  name="sport"
                />
              </div>
              <div className="form-group">
                <label>Reason for cessation :</label>
                <textarea
                  rows="3"
                  readOnly
                  required
                  value={Cessations}
                  type="text"
                  className="form-control"
                  id="sport"
                  name="sport"
                />
              </div>
              <br></br>
              <h1>Prone PLank : </h1>
              <div className="form-group">
                <label>Time position sustained :</label>
                <textarea
                  rows="3"
                  readOnly
                  required
                  value={Sustainedp}
                  type="text"
                  className="form-control"
                  id="sport"
                  name="sport"
                />
              </div>
              <div className="form-group">
                <label>Lumbar dipping :</label>
                <textarea
                  rows="3"
                  readOnly
                  required
                  value={Dippingp}
                  type="text"
                  className="form-control"
                  id="sport"
                  name="sport"
                />
              </div>
              <div className="form-group">
                <label>Reason for cessation :</label>
                <textarea
                  rows="3"
                  readOnly
                  required
                  value={Cessationp}
                  type="text"
                  className="form-control"
                  id="sport"
                  name="sport"
                />
              </div>
              <br></br>
              <h1>Single Leg Glute/Hamstring Bridging : </h1>
              <div className="form-group">
                <label>Time position sustained :</label>
                <textarea
                  rows="3"
                  readOnly
                  required
                  value={Sustainedg}
                  type="text"
                  className="form-control"
                  id="sport"
                  name="sport"
                />
              </div>
              <div className="form-group">
                <label>Lumbar dipping :</label>
                <textarea
                  rows="3"
                  readOnly
                  required
                  value={Dippingg}
                  type="text"
                  className="form-control"
                  id="sport"
                  name="sport"
                />
              </div>
              <div className="form-group">
                <label>Reason for cessation :</label>
                <textarea
                  rows="3"
                  readOnly
                  required
                  value={Cessationg}
                  type="text"
                  className="form-control"
                  id="sport"
                  name="sport"
                />
              </div> */}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {userType == "athlete" && (
        <div
          style={{
            marginLeft: 30,
            maxWidth: 700,
          }}
        >
          {getUserTherapys.map(({ id, therapys }) => (
            <div key={id}>
              {id == iid && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        width: 16,
                        height: 16,
                        cursor: "pointer",
                      }}
                      src={backButton}
                      onClick={() => {
                        // window.open("/Athlete/reports/" + Id, "");
                        console.log(1);
                        history.push({
                          pathname: "/Previous_Physiotherapy_Assessments",
                          state: {
                            AthleteId: userId,
                          },
                        });
                      }}
                    />
                    <h2
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      {therapys.Name} -
                    </h2>
                    <h2
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      Date: {therapys.Date}
                    </h2>
                  </div>
                  {/* <div className="report">
              <h1>Sport Played/Recreational Activity: {therapys.sports} </h1>
              <h1>Physical Activity level: {therapys.Proficiency} </h1>
              <h1>Hand/Foot Dominance: {therapys.LimbUsage} </h1>
            </div> */}
                  <div className="form-group">
                    <label>Physiotherapy Name:</label>
                    <textarea
                      readOnly
                      required
                      rows="3"
                      value={therapys.Name}
                      type="text"
                      className="form-control"
                      id="name"
                      name="Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Sport Played/Recreational Activity:</label>
                    <textarea
                      readOnly
                      required
                      rows="3"
                      value={therapys.sports}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Physical Activity level:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={therapys.Proficiency}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Hand/Foot Dominance:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.LimbUsage}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Uploaded Image:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.File}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Injury History:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.InjuryHistory.boolean}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  {therapys.InjuryHistory.boolean == "Yes" ||
                    (therapys.InjuryHistory.boolean == "yes" && (
                      <div className="form-group">
                        <label>Injury Name:</label>
                        <textarea
                          rows="3"
                          readOnly
                          value={therapys.InjuryHistory.InjuryHistoryText}
                          type="text"
                          className="form-control"
                          id="sport"
                          name="sport"
                        />
                        <br></br>
                      </div>
                    ))}
                  <div className="form-group">
                    <label>Current Complaints:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={therapys.Complaints}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Current Illnesses:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={therapys.CurrentIllness}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Past Injuries:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={therapys.PastInjuries}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Past Illnesses:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={therapys.PastIllness}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Medical Conditions:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={therapys.MedicalCondition}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label for="CurrentMedication">Current Medications:</label>
                    <textarea
                      rows="3"
                      type="text"
                      className="form-control"
                      id="CurrentMedication"
                      value={therapys.CurrentMedications}
                      name="sport"
                      id="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Past Surgery:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.PastSurgery}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Allergies:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Allergies}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Orthotics:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Orthotics}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <h6>Your Sleep Score</h6>
                  {/* <Slider
                    min={1}
                    max={5}
                    dots
                    marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
                    disabled
                    value={therapys.Psychosocial.Sleep}
                  /> */}
                  <h3>{therapys.Psychosocial.Sleep}/5</h3>
                  <br></br>
                  <br></br>
                  <h6>Your Work Pattern Score</h6>
                  {/* <Slider
                    min={1}
                    max={5}
                    dots
                    marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
                    disabled
                    value={therapys.Psychosocial.WorkPattern}
                  /> */}
                  <h3>{therapys.Psychosocial.WorkPattern}/5</h3>
                  <br></br>
                  <br></br>
                  <h6>Your Stress and Anxiety Score</h6>
                  {/* <Slider
                    min={1}
                    max={5}
                    dots
                    marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
                    disabled
                    value={therapys.Psychosocial.Stress_And_Anxiety}
                  /> */}
                  <h3>{therapys.Psychosocial.Stress_And_Anxiety}/5</h3>
                  <br></br>
                  <br />
                  <div className="form-group">
                    <label>Timing of Pain:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Timing}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Sequence of Pain:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Sequence}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Mechanism of Injury:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Mechanism.Type}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  {therapys.Mechanism.Type == "atraumatic" ||
                    (therapys.Mechanism.Type == "traumatic" && (
                      <>
                        <div className="form-group">
                          <label>Type of {therapys.Mechanism.Type}:</label>
                          <textarea
                            rows="3"
                            readOnly
                            required
                            value={therapys.Mechanism.Reason}
                            type="text"
                            className="form-control"
                            id="sport"
                            name="sport"
                          />
                        </div>

                        {therapys.Mechanism.Reason == "other" ||
                          (therapys.Mechanism.Reason == "others" && (
                            <div className="form-group">
                              <label>
                                {" "}
                                Other Type Of {therapys.Mechanism.Type}:
                              </label>
                              <textarea
                                rows="3"
                                readOnly
                                required
                                value={therapys.Mechanism.OtherReason}
                                type="text"
                                className="form-control"
                                id="sport"
                                name="sport"
                              />
                            </div>
                          ))}
                      </>
                    ))}
                  {therapys.Mechanism.Type == "Post Operative" && (
                    <div>
                      <div className="form-group">
                        <label>{therapys.Mechanism.Type} Surgery Name:</label>
                        <textarea
                          rows="3"
                          readOnly
                          required
                          value={therapys.PostOperative.Surgery_Name}
                          type="text"
                          className="form-control"
                          id="sport"
                          name="sport"
                        />
                      </div>
                      <br />
                      <p>Pain Score:</p>
                      {/* <Slider
                        min={0}
                        max={10}
                        dots
                        marks={{
                          0: 0,
                          1: 1,
                          2: 2,
                          3: 3,
                          4: 4,
                          5: 5,
                          6: 6,
                          7: 7,
                          8: 8,
                          9: 9,
                          10: 10,
                        }}
                        value={therapys.PostOperative.Pain_Score}
                        disabled
                      /> */}
                      <h3>{therapys.PostOperative.Pain_Score}/5</h3>
                      <br></br>
                    </div>
                  )}
                  <br></br>
                  <div className="form-group">
                    <label>Sequence of Pain:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Sequence}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Time to Increase:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.TimeToIncrease}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Time to Stay:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.TimeToStay}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Time to Reduce:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.TimeToReduce}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Nature of Pain:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Nature_Of_Pain}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Aggravating Causes:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Aggravating_Causes}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Relieving Causes:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Relieving_Causes}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Head Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Head_Posture}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Left Shoulder Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Left_Shoulder}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Right Shoulder Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Right_Shoulder}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Thoracic Spine Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Thoracic_Spine}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Lumbar Spine Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Lumbar_Spine}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Left Knee Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Left_Knee}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Right Knee Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Right_Knee}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Right Foot Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Right_Foot}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Left Foot Posture:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Left_Foot}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>{" "}
                  <div className="form-group">
                    <label>Foot Index:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.FootIndex}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <h1>Upper Quarter Tests: </h1>
                  <div className="form-group">
                    <label>
                      Internal Rotation at 90° Abduction in Supine (&gt;90):
                    </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Internal_Rotation}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>
                      External Rotation at 90° Abduction in Supine(&gt;90):
                    </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.External_Rotation}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>
                      Glenohumeral Internal Rotation Deficit (GIRD):
                    </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Glenohumeral_Internal_Rotation}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>
                      External Rotation Gain (ERG) +’ve when GIRD &gt; Ratio –
                      Dominant Arm only :
                    </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Internal_Rotation_Gain}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>H-K Impingement Test:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.HK_Impingement_Test}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Full Can Test:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Full_Can_Test}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Infraspinatus Strength Test :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Infraspinatus_Strength_Test}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Kibler Lateral Slide Test :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Kibler_Lateral_Side_Test}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Position 1 (≤ 1.5cms +/-) :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Positionone}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Position 2 (≤ 1.5cms +/-) :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Positiontwo}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Position 3 (≤ 1.5cms +/-) :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Positionthree}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>SSMP/Scapular Balancing Index :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Scapular}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Horizontal adduction test/ dugas sign - :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Horizontal_Adduction}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Kims :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Kims}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Biceps load 1 and 2 :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Biceps_Load}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>O’brien test :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Obrien_Test}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>CKUEST :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Ckuest}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Y-balance :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Y_Balance}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Quadrant Test:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Quadrant_Test}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Trunk Side Flexion ROM:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Trunk_Side_Flexion_ROM}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Lumbar Spine ROM:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Lumbarspine}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Notes : </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Notes}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <h1>TEST LOWER Quarter: </h1>
                  <br></br>
                  <div className="form-group">
                    <label>Hamstring Length at 90/90 - (≥ 160):</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Hamstring_Length_At_90_By_90}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Iliopsoas Muscle Length (≤ 5):</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Iliopsoas_Length}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Rectus Femoris Muscle Length (≥ 80):</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Recturs_Femoris_Length}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Hip Internal Rotation – Prone (≥ 30) </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Internalhip_Rotation}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Hip External Rotation – Prone (≥ 45)</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Externalhip_Rotation}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Hip Quadrant Test :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Quadranthip}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Piriformis Muscle Length (&gt; 10) :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Piriformis_Length}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>DF Lunge Test (≥ 10 cms):</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Lunge}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Posterior Impingement Test :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Impingement_Posterior}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>
                      Single leg standing balance (eyes open/closed):
                    </label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Singleleg_Standing_Balance}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>OH Deep Squat:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.OH_Deep_Squat}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Single Leg Squat :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Singlelegsquat}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Core stability push up/Core stability score:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Core_Stability_Push}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <div className="form-group">
                    <label>Ottawa Ankle Rule:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.Ottawa_Ankle_Rule}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br></br>
                  <h1>Neural Testing</h1>
                  <br />
                  <div className="form-group">
                    <label>SLUMP:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.SLUMP}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label>SLR:</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.SLR}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  <div className="form-group">
                    <label>ULTTs :</label>
                    <textarea
                      rows="3"
                      readOnly
                      required
                      value={therapys.ULTTs}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>
                  {/* <h1>Side PLank : </h1>
            <div className="form-group">
              <label>Time position sustained :</label>
              <textarea
                rows="3"
                readOnly
                required
                value={Sustaineds}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>Lumbar dipping :</label>
              <textarea
                rows="3"
                readOnly
                required
                value={Dippings}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>Reason for cessation :</label>
              <textarea
                rows="3"
                readOnly
                required
                value={Cessations}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <h1>Prone PLank : </h1>
            <div className="form-group">
              <label>Time position sustained :</label>
              <textarea
                rows="3"
                readOnly
                required
                value={Sustainedp}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>Lumbar dipping :</label>
              <textarea
                rows="3"
                readOnly
                required
                value={Dippingp}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>Reason for cessation :</label>
              <textarea
                rows="3"
                readOnly
                required
                value={Cessationp}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <h1>Single Leg Glute/Hamstring Bridging : </h1>
            <div className="form-group">
              <label>Time position sustained :</label>
              <textarea
                rows="3"
                readOnly
                required
                value={Sustainedg}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>Lumbar dipping :</label>
              <textarea
                rows="3"
                readOnly
                required
                value={Dippingg}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>Reason for cessation :</label>
              <textarea
                rows="3"
                readOnly
                required
                value={Cessationg}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div> */}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ParticularPhysioAss;
