import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import "./AllAthletes.css";
import {
  selectUser,
  selectUserData,
  selectUserType,
  setUserData,
} from "../../features/userSlice";

function InviteAthlete() {
  const [athleteName, setAthleteName] = useState('Athlete')
  const [text, setText] = useState();
  const userData = useSelector(selectUserData);

  useEffect(() => {
    if (athleteName) {
      setText(`Hi ${athleteName}! Join the Stairs app today and get started on your fitness journey! Download the app here, and link with your coach by typing in the Coach ID as ` + userData?.data?.pin)

    } else (
      setText(`Hi Athlete! Get started on your fitness journey! Download the app here, and link with your coach by typing in the Coach ID as ` + userData?.data?.pin)
    )
  }, [athleteName])
  const history = useHistory();

  const nameHandler = (e) => {
    e.preventDefault()
    setAthleteName(e.target.value)
  }

  return (
    <div className="InviteAthlete" id="allath">
      <div className="inviteAthlete__info">
        <div
          className="inviteAthlete__backButton"
          onClick={() => history.goBack()}
        >
          <img src="/assets/left_arrow.png" alt="" width="15px" height="15px" />{" "}
        </div>
        <h1>Invites Athlete</h1>
      </div>
      <div className="inviteAthlete__form">
        <h3 style={{color:'black'}}>Athlete Name</h3>
        <input type="text" placeholder="Enter Athlete Name" onChange={(e) => nameHandler(e)} />
        <h2>Send Invite</h2>
        <textarea
        style={{width:'90%'}}
          className="shareInvite"
          type="text"
          onChange={(e) => e.target.value}
          value={text}
        >

        </textarea>
      </div>
      <div className="inviteAthlete__shareInvite"
          onClick={() => {navigator.clipboard.writeText(text)}}>
            Copy Invite to Clipboard
      </div>
    </div>
  );
}

export default InviteAthlete;
