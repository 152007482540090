import React from "react";
import moment from "moment";
import { db } from "../../utils/firebase";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  selectUserData,
  selectUserType,
  setUserData,
} from "../../features/userSlice";
import firebase from "firebase";
import Axios from "axios";
import "./Calendar.css";
import "../../responsive.css";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import { rURL } from "../../utils/Links";

var win = "";

function Event_card(props) {
  const userData = useSelector(selectUserData);
  const userType = useSelector(selectUserType);
  var events = props.data;
  let eventslength = events.length;

  if (events.length > props.count) {
    var events = events.slice(0, props.count);
  }

  return (
    <div>
      {events.map((events) => (
        <div className="eachevent" key={events.id}>
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
              width: 300,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <i
                class="fa fa-circle"
                style={{ fontSize: 10, marginRight: 8 }}
              ></i>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  className="upcoming_event_title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    width: 100,
                    marginBottom: 3,
                  }}
                >
                  {events.name && events.name}
                </div>
                <div className="upcoming_event_time" style={{ fontSize: 13 }}>
                  {events.date.toDate().toLocaleDateString()}
                </div>
              </div>
            </div>
            <div className="upcoming_event_right">
              <button
                style={{
                  height: 35,
                  marginLeft: 40,
                  backgroundColor: "var(--primarycolor)",
                  color: "var(--secondarycolor)",
                }}
              >
                {events.date.toDate().toLocaleTimeString(navigator.language, {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </button>

              {moment(new Date()).valueOf() >
              events.date.toDate().toLocaleTimeString() - 60000 * 20 ? (
                <a
                  style={{ cursor: "pointer" }}
                  href={events.showVideoLink && events.videolink}
                >
                  <button
                    style={{
                      height: 35,
                      backgroundColor: "var(--primarycolor)",
                      color: "var(--secondarycolor)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (userType == "athlete") {
                        db.collection("events")
                          .doc(events.id)
                          .update({
                            attendance:
                              firebase.firestore.FieldValue.arrayUnion(
                                userData.id
                              ),
                          });
                      }
                    }}
                  >
                    Join now
                  </button>{" "}
                </a>
              ) : null}
            </div>{" "}
            {
              <div
                className="tooltip"
                onClick={() => {
                  db.collection("secrets")
                    .doc(userData?.id)
                    .get()
                    .then(async (snap) => {
                      console.log(snap.exists);
                      if (snap.exists) {
                        let tokens = snap.data().tokens;

                        let axiosConfig = {
                          headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "Access-Control-Allow-Origin": "*",
                          },
                        };

                        await Axios.post(
                          "https://wellzapp-server.herokuapp.com/api/addToCalendarMobile",
                          {
                            tokens: tokens,
                            rURL,
                            eventdata: {
                              eventname: events.name
                                ? events.name
                                : "no name added",
                              description: events.videolink,
                              date: events.date.seconds * 1000,
                            },
                          },

                          axiosConfig
                        ).then(async (res) => {
                          console.log(res?.data);
                          if (res.data.success) {
                            alert("added to calendar");
                          } else {
                            alert("failed adding to calendar");
                          }
                        });
                      } else {
                        alert("Sync your email first");
                      }
                    });
                }}
              >
                <InsertInvitationIcon />

                <span class="tooltiptext">Add to Calendar</span>
              </div>
            }
          </div>
          <div style={{ marginLeft: 20, cursor: "pointer" }}>
            {moment(new Date()).valueOf() >
            events.date.toDate().toLocaleTimeString() - 60000 * 20 ? (
              <a
                id="meetlink"
                style={{ textDecoration: "none" }}
                onClick={() => {
                  if (userType == "athlete") {
                    db.collection("events")
                      .doc(events.id)
                      .update({
                        attendance: firebase.firestore.FieldValue.arrayUnion(
                          userData.id
                        ),
                      });
                  }
                }}
                href={events.showVideoLink && events.videolink}
              >
                {events.showVideoLink && events.videolink}
              </a>
            ) : (
              <span
                id="timespan"
                style={{
                  cursor: "",
                  textDecoration: "underline",
                  marginBottom: "40px",
                }}
                onClick={() =>
                  window.open(events.showVideoLink && events.videolink)
                }
              >
                {events.showVideoLink && events.videolink}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Event_card;
