import { Button, Dialog, DialogTitle, Grid, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Delete from '@material-ui/icons/DeleteOutline';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Form, Formik } from 'formik';
import React, { Fragment, useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from "react-loader-spinner";
import { useHistory, useParams } from 'react-router-dom';
import db, { fb_storage } from '../../services/Firebase';
import BlogValidatorDef from '../models/BlogFormValidator';
import { customStyles } from '../Styles/UIStyles';
import TextField from '../UIElements/TextField';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../features/userSlice';


export const UpdateBlog = () => {
  const history = useHistory();
  const styles = customStyles();
  const params = useParams()
  const userEmail = useSelector(selectUser)
  const [blog_info, setBlogInfo] = useState({})
  const [coverImageAsFile, setCoverImageAsFile] = useState('')
  const [coverImageAsUrl, setCoverImageAsUrl] = useState('')
  const [otherImagesAsFiles, setOtherImagesAsFiles] = useState([])
  const [otherImagesAsUrls, setOtherImagesAsUrls] = useState([])
  const [existingOtherImages, setOtherExistingImages] = useState([])
  const [showDialog, setShowDialog] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [loading, setLoading] = useState(true)


  function onEditorStateChange(editorState) {
    setEditorState(editorState)
  };

  function handleChange(e) {
    const image = e.target.files[0]
    setCoverImageAsUrl(URL.createObjectURL(image))
    setCoverImageAsFile(image)
  }

  function handleMultipleChange(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      const image = e.target.files[i]
      setOtherImagesAsUrls((prevState) => [...prevState, URL.createObjectURL(image)]);
      setOtherImagesAsFiles((prevState) => [...prevState, image]);
    }
  };

  function deleteOtherImage(image, imageType) {
    if (imageType === "New") {
      const index = otherImagesAsUrls.indexOf(image)
      otherImagesAsFiles.splice(index, 1)
      otherImagesAsUrls.splice(index, 1)
      setOtherImagesAsUrls([...otherImagesAsUrls]);
      setOtherImagesAsFiles([...otherImagesAsFiles]);
    }
    else {
      // console.log(existingOtherImages)
      const index = existingOtherImages.indexOf(image)
      existingOtherImages.splice(index, 1)
      setOtherExistingImages([...existingOtherImages]);
    }
  }

  const uploadImageCallBack = (file) => {
    return new Promise(
      (resolve, reject) => {
        // console.log('Uploading image...');
        firebaseUpload(file)
          .then(link => {
            resolve({ data: { link } });
          })
          .catch(error => {
            reject(error);
          })
      }
    );
  }

  const firebaseUpload = (file) => {
    return new Promise(
      (resolve, reject) => {
        if (!file) {
          reject('Invalid file.');
        }
        var timeStamp = Date.now()
        const ref = fb_storage.ref().child(`/blog_images/${userEmail}/${timeStamp}_${file.name}`).put(file)
        ref.on('state_changed',
          (snapshot) => {
            // console.log(snapshot)
          },
          (error) => {
            // console.log(error)
          }, (complete) => {
            fb_storage.ref(`/blog_images/${userEmail}`).child(`${timeStamp}_${file.name}`).getDownloadURL()
              .then(url => resolve(url))
          })
      }
    );
  }

  const fetchBlogInfo = async () => {
    const blogRef = await db.collection('BlogsInformation').doc(params.id).get();
    setBlogInfo({ data: blogRef.data(), id: blogRef.id })
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(blogRef.data().blog_content))))
    setLoading(false);

  };


  useEffect(() => {
    fetchBlogInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <div id="uniqueforms">
      {
        loading && (
          <div className={styles.dialog} >
            <Loader
              type="TailSpin"
              color="#00BFFF"
              height={100}
              width={100}
            />
          </div>
        )
      }
      {
        !loading &&
        <Formik
          initialValues={{
            blog_title: blog_info.data.blog_title,
            blog_subtitle: blog_info.data.blog_subtitle
          }}
          validationSchema={BlogValidatorDef}
          onSubmit={values => {
            const button_clicked = window.event.submitter.name;
            setShowDialog(!showDialog);
            const images_list = []
            if (coverImageAsFile) {
              images_list.push(coverImageAsFile)
            }
            if (otherImagesAsFiles.length > 0) {
              images_list.push.apply(images_list, otherImagesAsFiles)
            }
            if (images_list.length) {
              const promises = images_list.map(image => {
                var timeStamp = Date.now()
                const ref = fb_storage.ref().child(`/blog_images/${userEmail}/${timeStamp}_${image.name}`)
                return ref.put(image)
                  .then(() => ref.getDownloadURL());
              });
              Promise.all(promises)
                .then((fileDownloadUrls) => {
                  var other_image_list = []
                  var blog_cover_image = ""
                  if (coverImageAsFile) {
                    blog_cover_image = fileDownloadUrls.shift()
                  } else {
                    blog_cover_image = blog_info.data.cover_image
                  }
                  if (existingOtherImages.length > 0) {
                    other_image_list.push.apply(other_image_list, existingOtherImages)
                  }
                  if (otherImagesAsFiles.length > 0) {
                    other_image_list.push.apply(other_image_list, fileDownloadUrls)
                  }
                  db.collection('BlogsInformation')
                    .doc(blog_info.id)
                    .set({
                      user_id: userEmail,
                      blog_title: values.blog_title,
                      blog_subtitle: values.blog_subtitle,
                      blog_content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                      cover_image: blog_cover_image,
                      other_images: other_image_list
                    })
                    .then((blog_entry) => {
                      setShowDialog(!showDialog);
                      if (button_clicked === 'blog_preview') {
                        window.open(`/blog-preview/${blog_info.id}`)
                        window.location.reload()
                      }
                      else {
                        history.push("/manage-blogs");
                      }
                    })
                    .catch((error) => {
                      setShowDialog(!showDialog);
                      console.log('Error messages:', error.message);
                    });
                })
                .catch((err) => {
                  setShowDialog(!showDialog);
                  console.log('Error messages:', err)
                });
            } else {
              db.collection('BlogsInformation')
                .doc(blog_info.id)
                .set({
                  user_id: userEmail,
                  blog_title: values.blog_title,
                  blog_subtitle: values.blog_subtitle,
                  blog_content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                  cover_image: blog_info.data.cover_image,
                  other_images: blog_info.data.other_images
                })
                .then(() => {
                  setShowDialog(!showDialog);
                  if (button_clicked === 'blog_preview') {
                    window.open(`/blog-preview/${blog_info.id}`)
                    window.location.reload()
                  }
                  else {
                    history.push("/manage-blogs");
                  }
                })
                .catch((error) => {
                  setShowDialog(!showDialog);
                  console.log('Error messages:', error.message);
                });
            }
          }}
        >
          <Form>
            <div>
              <h2 className={styles.section_header}>
                <p>
                  <label htmlFor="icon-button-file">
                    <IconButton className={styles.icon_button} aria-label="back-button" onClick={() => history.push("/manage-blogs")} >
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                  </label>
                  Update Blog
                </p>
              </h2>
            </div>
            <div id="theformone" >
              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Title
              </p>
              <TextField className={styles.textfield}
                id="blog_title"
                name="blog_title"
                label="Enter title"
              />
              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Sub Title
              </p>
              <TextField className={styles.textfield}
                id="blog_subtitle"
                name="blog_subtitle"
                label="Enter sub title"
              />
              <p className={styles.form_header}>
                Cover Image
              </p>
              <div>
                <Fragment>
                  <div>
                    {
                      coverImageAsUrl ? coverImageAsUrl && (
                        <figure>
                          <img src={coverImageAsUrl} alt='' width='128' height='128' />
                        </figure>
                      ) : blog_info.data ? blog_info.data.cover_image && (
                        <figure>
                          <img src={blog_info.data.cover_image} alt='' width='128' height='128' />
                        </figure>
                      ) : null
                    }
                  </div>
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    onChange={handleChange}
                    id="cover"
                    className={styles.hide_component}
                  />
                  <p className={styles.form_header}>
                    <label htmlFor="cover">
                      <IconButton className={styles.icon_button} aria-label="upload picture" component="span">
                        <PhotoCamera />
                      </IconButton>
                    </label>
                    Upload Image
                  </p>
                </Fragment>
              </div>
              <p className={styles.form_header}>
                Other Images
              </p>
              <div>
                <Fragment>
                  <Grid container>
                    {otherImagesAsUrls.length > 0 && otherImagesAsUrls.map(each_image => {
                      return (
                        <Grid item key={each_image}>
                          <img style={{ marginLeft: '.5rem' }} src={each_image} alt='' width='128' height='128' />
                          <IconButton aria-label="delete" onClick={() => deleteOtherImage(each_image, "New")} >
                            <Delete />
                          </IconButton>
                        </Grid>
                      )
                    })
                    }
                    {existingOtherImages.length > 0 && existingOtherImages.map(each_image => {
                      return (
                        <Grid item key={each_image}>
                          <img style={{ marginLeft: '.5rem' }} src={each_image} alt='' width='128' height='128' />
                          <IconButton aria-label="delete" onClick={() => deleteOtherImage(each_image, "Old")} >
                            <Delete />
                          </IconButton>
                        </Grid>
                      )
                    })
                    }
                  </Grid>
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    multiple
                    onChange={handleMultipleChange}
                    id="other"
                    className={styles.hide_component}
                  />
                  <p className={styles.form_header}>
                    <label htmlFor="other">
                      <IconButton className={styles.icon_button} aria-label="upload picture" component="span">
                        <PhotoCamera />
                      </IconButton>
                    </label>
                    Upload Images
                  </p>
                </Fragment>
              </div>
              <p className={styles.form_header}>
                Blog Editor
              </p>
              <div>
                <Editor
                  defaultEditorState={editorState}
                  toolbarClassName="demo-toolbar-custom"
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor-custom"
                  toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'image'],
                    inline: {
                      options: ['bold', 'italic', 'underline'],
                    },
                    image: {
                      urlEnabled: true,
                      uploadEnabled: true,
                      alignmentEnabled: true,
                      previewImage: true,
                      uploadCallback: uploadImageCallBack,
                      inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: 'auto',
                        width: 'auto',
                      },
                    }
                  }}
                  onEditorStateChange={onEditorStateChange}
                />
              </div>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
            <p></p>
            <Grid container>
              <Grid item style={{ padding: '0.5em' }}>
                <Button type='submit' id='blog_preview' name='blog_preview' variant="outlined" className={styles.secondary_button} style={{ width: '120px' }} > Preview </Button>
              </Grid>
              <Grid item style={{ padding: '0.5em' }}>
                <Button type='submit' id='blog_save' name='blog_save' variant="outlined" className={styles.primary_button} style={{ width: '120px' }}> Update </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      }
      {
        showDialog && (
          <Dialog open={showDialog} className={styles.dialog}>
            <DialogTitle id="blog_progress_title"><p>Updating Blog details...</p><p>Soon will be redirected to manage blogs...</p></DialogTitle>
            <div className={styles.dialog} >
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
              />
            </div>
          </Dialog>
        )
      }
    </div >

  )
}