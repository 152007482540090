import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Grid, IconButton, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Delete from '@material-ui/icons/DeleteOutline';
import React, { useEffect, useState } from 'react';
import Loader from "react-loader-spinner";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUser } from '../../../../features/userSlice';
import db from '../../services/Firebase';
import { customStyles } from '../Styles/UIStyles';
import "./WebsiteForms.css"




export const ManagePrograms = () => {
  const styles = customStyles();
  const history = useHistory();
  const userEmail = useSelector(selectUser)
  const [activePrograms, setActivePrograms] = useState([]);
  const [inactivePrograms, setInactivePrograms] = useState([]);
  const [activeLoading, setActiveLoading] = useState(true)
  const [inactiveLoading, setInactiveLoading] = useState(true)

  const fetchProgramsInfo = async () => {
    const activeList = [];
    const inactiveList = [];
    var activeSnapshot = await db.collection('ProgramsInformation')
      .where("user_id", "==", userEmail).where('active', '==', true)
      .get();
    activeSnapshot.forEach((doc) => {
      activeList.push({ data: doc.data(), id: doc.id });
    });
    setActivePrograms(activeList);
    setActiveLoading(!activeLoading);
    var inactiveSnapshot = await db.collection('ProgramsInformation')
      .where("user_id", "==", userEmail).where('active', '==', false)
      .get();
    inactiveSnapshot.forEach((doc) => {
      inactiveList.push({ data: doc.data(), id: doc.id });
    });
    setInactivePrograms(inactiveList);
    setInactiveLoading(!inactiveLoading);

  };

  useEffect(() => {
    fetchProgramsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function activateProgram(program) {
    if (activePrograms.length < 6) {
      db.collection('ProgramsInformation')
        .doc(program.id)
        .set({
          user_id: userEmail,
          program_name: program.data.program_name,
          program_details: program.data.program_details,
          program_fees: program.data.program_fees,
          image: program.data.image,
          active: true
        })
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          alert(error.message);
        });
    }
    else {
      alert("Only 6 programs can be active.")
    }
  }

  function deactivateProgram(program) {
    db.collection('ProgramsInformation')
      .doc(program.id)
      .set({
        user_id: userEmail,
        program_name: program.data.program_name,
        program_details: program.data.program_details,
        program_fees: program.data.program_fees,
        image: program.data.image,
        active: false
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        alert(error.message);
      });
  }

  function deleteProgram(program) {
    db.collection('ProgramsInformation')
      .doc(program.id)
      .delete()
      .then(resp => {
        window.location.reload();
      })
      .catch((error) => {
        alert(error.message);
      });
  }


  return (

    <div id="uniqueforms">
      <div>
        <div>
          <h2 className={styles.section_header}>
            <p>
              <label htmlFor="icon-button-file">
                <IconButton className={styles.icon_button} aria-label="back-button" onClick={() => history.push("/coach-website")} >
                  <ArrowBackIosIcon fontSize="small" />
                </IconButton>
              </label>
              Manage Programs
            </p>
          </h2>
          <Button variant="outlined" className={styles.primary_button} onClick={() => history.push("/add-program")}>
            Add Programs
          </Button>
        </div>
        <h3 className={styles.section_header}>
          Active Programs
        </h3>
        <br />
        <Grid container justifyContent="flex-start" spacing={1} alignItems="stretch">
          {
            activeLoading && (
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
              />
            )
          }
          {
            !activeLoading && activePrograms.map(each_program => {
              return (
                <Grid item key={each_program.id}>
                  <div>
                    <Card
                      className={styles.program_card}
                    >
                      <CardHeader className={styles.program_header}
                        action={
                          <IconButton aria-label="delete" onClick={() => deleteProgram(each_program)}>
                            <Delete />
                          </IconButton>
                        }
                      />
                      <CardActionArea className={styles.program_actionarea}
                        onClick={() => history.push(`/update-program/${each_program.id}`)}
                      >
                        <CardMedia
                          className={styles.program_media}
                          component="img"
                          title='click to edit this program'
                          src={each_program.data.image}
                        />
                        <CardContent className={styles.program_content}>
                          <Typography variant="h6" color="textPrimary">
                            {each_program.data.program_name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            {each_program.data.program_details}
                          </Typography>
                          <Typography variant="body2" color="textPrimary" component="p">
                            Fee: Rs.{each_program.data.program_fees}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button variant="outlined" className={styles.program_card_button}> Enroll </Button>
                      </CardActions>
                    </Card>
                    <Button className={styles.red_label} onClick={() => deactivateProgram(each_program)}>Deactivate</Button>
                  </div>
                </Grid>

              )
            })
          }
          {
            !activeLoading && activePrograms.length === 0 &&
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} className={styles.rectangle}>
              <p className={styles.form_header}>
                Activate a program to see it on your website.
              </p>
            </Grid>
          }
        </Grid>
        <h3 className={styles.section_header}>
          Inactive Programs
        </h3>
        <br />
        <Grid container justifyContent="flex-start" spacing={1} alignItems="stretch">
          {
            inactiveLoading && (
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
              />
            )
          }
          {
            !inactiveLoading && inactivePrograms.map(each_program => {
              return (
                <Grid item key={each_program.id}>
                  <div>
                    <Card
                      className={styles.program_card}
                    >
                      <CardHeader className={styles.program_header}
                        action={
                          <IconButton aria-label="delete" onClick={() => deleteProgram(each_program)}>
                            <Delete />
                          </IconButton>
                        }
                      />
                      <CardActionArea className={styles.program_actionarea}
                        onClick={() => history.push({ pathname: '/update-program', state: { program_info: each_program } })}
                      >
                        <CardMedia
                          className={styles.program_media}
                          component="img"
                          title={each_program.data.program_name}
                          src={each_program.data.image}
                        />
                        <CardContent className={styles.program_content}>
                          <Typography variant="h6" color="textPrimary">
                            {each_program.data.program_name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            {each_program.data.program_details}
                          </Typography>
                          <Typography variant="body2" color="textPrimary" component="p">
                            Fee: Rs.{each_program.data.program_fees}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Button variant="outlined" className={styles.program_card_button}> Enroll </Button>
                      </CardActions>
                    </Card>
                    <Button className={styles.green_label} onClick={() => activateProgram(each_program)}>Activate</Button>
                  </div>
                </Grid>
              )
            })
          }
          {
            !inactiveLoading && inactivePrograms.length === 0 &&
            <Grid item xs={12} lg={12} md={12} sm={12} xl={12} className={styles.rectangle}>
              <p className={styles.form_header}>
                Deactivate a program to remove it from your website.
              </p>
            </Grid>
          }
        </Grid>
      </div>
    </div >

  )
}