import React, { useState } from "react";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { db } from "../../utils/firebase";
import { useSelector } from "react-redux";
import Header from "../../Components/Header/Header";
import direction from "../../Images/direction.png";
import { useHistory } from "react-router-dom";
import backButton from "./left-arrow.png";
import { storage } from "../../utils/firebase";

import {
  selectTemperoryId,
  selectUser,
  selectUserType,
  selectTemperoryData,
} from "../../features/userSlice";
// import { Tune } from '@material-ui/icons';
import "./StairsForm.css";
import firebase from "firebase";
import uniqid from "uniqid";

const timestamp = require("time-stamp");

function StairsForm() {
  const history = useHistory();
  const user = useSelector(selectUser);
  const userType = useSelector(selectUserType);
  const temperoryId = useSelector(selectTemperoryId);
  const temperoryData = useSelector(selectTemperoryData);

  const [therapyName, setTherapyName] = useState("");
  const [Sport, SetSport] = useState("");
  const [Proficiency, SetProficiency] = useState("");
  const [Limb, SetLimb] = useState("");
  const [InjuryHistory, SetInjuryHistory] = useState("");
  const [File, SetFile] = useState("No File Uploaded!!");

  const [Diagnosis, SetDiagnosis] = useState(" ");
  const [Complaints, SetComplaints] = useState("");
  const [CurrentIllness, SetCurrentIllness] = useState("");
  const [PastInjuries, SetPastInjuries] = useState("");
  const [PastIllness, SetPastIllness] = useState("");
  const [PastSurgery, SetPastSurgery] = useState("");
  const [Allergies, SetAllergies] = useState("");
  const [Orthotics, SetOrthotics] = useState("");
  const [Timing, SetTiming] = useState("");
  const [Sequence, SetSequence] = useState("");
  const [Trauma, SetTrauma] = useState("Impact with equipment");
  const [Atrauma, SetAtrauma] = useState("Repetitive");
  const [PostOperative, SetPostOperative] = useState("");
  const [TimeToIncrease, SetTimeToIncrease] = useState("");
  const [TimeToStay, SetTimeToStay] = useState("");
  const [TimeToReduce, SetTimeToReduce] = useState("");
  const [OthersTrauma, SetOthersTrauma] = useState("");
  const [OthersAtrauma, SetOthersAtrauma] = useState("");
  const [Nature, SetNature] = useState("Pulling");
  const [Depth, SetDepth] = useState("");
  const [DepthOther, SetDepthOther] = useState("");
  const [Head, SetHead] = useState("");
  const [LShoulder, SetLShoulder] = useState("");
  const [RShoulder, SetRShoulder] = useState("");
  const [Aggravating, SetAggravating] = useState("");
  const [Thoracic, SetThoracic] = useState("");
  const [Lumbar, SetLumbar] = useState("");
  const [Knee, SetKnee] = useState("");
  const [Rknee, SetRknee] = useState("");
  const [Rfoot, SetRfoot] = useState("");
  const [Lfoot, SetLfoot] = useState("");
  const [FootIndex, SetFootIndex] = useState("");
  const [Medical, SetMedical] = useState("");
  const [Sleep, SetSleep] = useState(3);
  const [Anxiety, SetAnxiety] = useState(3);
  const [WorkPressure, SetWorkPressure] = useState(3);
  const [Relieving, SetRelieveing] = useState("");
  const [MechanismReason, SetMechanismReason] = useState("");
  const [MechanismOther, SetMechanismOther] = useState("");
  const [Mechanism, SetMechanism] = useState("null");
  const [CurrentMedications, SetCurrentMedications] = useState("");
  const [PainScore, SetPainScore] = useState("3");
  const [Abduction, SetAbdcution] = useState("");
  const [Exrotation, SetExrotation] = useState("");
  const [Glenohumeral, SetGlenohumeral] = useState("");
  const [Rotationgain, SetRotationgain] = useState("");
  const [Infraspinatus, SetInfraspinatus] = useState("");
  const [Fullcan, SetFullcan] = useState("");
  const [Kibler, SetKibler] = useState("");
  const [Positionone, SetPositionone] = useState("");
  const [Positiontwo, SetPositiontwo] = useState("");
  const [Positionthree, SetPositionthree] = useState("");
  const [Scapular, SetScapular] = useState("");
  const [Adduction, SetAdduction] = useState("");
  const [Kims, SetKims] = useState("");
  const [Biceps, SetBiceps] = useState("");
  const [Brien, SetBrien] = useState("");
  const [Ckuest, SetCkuest] = useState("");
  const [Balance, SetBalance] = useState("");
  const [Hamstring, SetHamstring] = useState("");
  const [Iliopsoas, SetIliopsoas] = useState("");
  const [Femoris, SetFemoris] = useState("");
  const [Internalhip, SetInternalhip] = useState("");
  const [Externalhip, SetExternalhip] = useState("");
  const [Quadranthip, SetQuadranthip] = useState("");
  const [Lunge, SetLunge] = useState("");
  const [Stability, SetStability] = useState("");
  const [Piriformis, SetPiriformis] = useState("");
  const [Singleleg, SetSingleleg] = useState("");
  const [Singlelegsquat, SetSinglelegsquat] = useState("");
  const [Ottawa, SetOttawa] = useState("");
  const [Impingement, SetImpingement] = useState("");
  const [Squat, SetSquat] = useState("");
  const [Impingementp, SetImpingementp] = useState("");
  const [Trunk, SetTrunk] = useState("");
  const [Lumbarspine, SetLumbarspine] = useState("");
  const [Quadranttest, SetQuadranttest] = useState("");
  const [Notes, SetNotes] = useState("");
  const [SLUMP, SetSLUMP] = useState("");
  const [SLR, SetSLR] = useState("");
  const [ULTTs, SetULTTs] = useState("");
  const [Sustainedp, SetSustainedp] = useState("");
  const [Sustainedg, SetSustainedg] = useState("");
  const [Sustaineds, SetSustaineds] = useState("");
  const [Dippingp, SetDippingp] = useState("");
  const [Dippingg, SetDippingg] = useState("");
  const [Dippings, SetDippings] = useState("");
  const [Cessationp, SetCessationp] = useState("");
  const [Cessationg, SetCessationg] = useState("");
  const [Cessations, SetCessations] = useState("");
  // const [physiotherapyImage, setPhysiotherapyImage] = useState(null);

  const [ViewForm, SetViewForm] = useState(true);
  const [userData, setUserData] = useState(null);
  if (ViewForm == true && userType == "athlete") {
    db.collection("athletes")
      .where("email", "==", user)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          setUserData(doc.id);
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
    if (userData != null) {
      setTimeout(function () {
        db.collection("Physiotherapy")
          .doc(userData)
          .get()
          .then(function (snap) {
            SetSport(snap?.data()?.sports ? snap.data().sports : "");
            SetProficiency(
              snap?.data()?.Proficiency ? snap.data().Proficiency : ""
            );
            SetLimb(snap?.data()?.LimbUsage ? snap.data().LimbUsage : "");
            SetFile(snap?.data()?.File ? snap.data().File : "");
            SetInjuryHistory(
              snap?.data()?.InjuryHistory.boolean
                ? snap.data().InjuryHistory.boolean
                : ""
            );
            SetDiagnosis(
              snap?.data()?.InjuryHistory.InjuryHistoryText
                ? snap.data().InjuryHistory.InjuryHistoryText
                : ""
            );
            SetPastIllness(
              snap?.data()?.PastIllness ? snap.data().PastIllness : ""
            );
            SetPastInjuries(
              snap?.data()?.PastInjuries ? snap.data().PastInjuries : ""
            );
            SetComplaints(
              snap?.data()?.Complaints ? snap.data().Complaints : ""
            );
            SetCurrentIllness(
              snap?.data()?.CurrentIllness ? snap.data().CurrentIllness : ""
            );
            SetAllergies(snap?.data()?.Allergies ? snap.data().Allergies : "");
            SetOrthotics(snap?.data()?.Orthotics ? snap.data().Orthotics : "");
            SetPastSurgery(
              snap?.data()?.PastSurgery ? snap.data().PastSurgery : ""
            );
            SetMedical(
              snap?.data()?.MedicalCondition ? snap.data().MedicalCondition : ""
            );
            SetSleep(
              snap?.data()?.Psychosocial.Sleep
                ? snap.data().Psychosocial.Sleep
                : ""
            );
            SetAnxiety(
              snap?.data()?.Psychosocial.Stress_And_Anxiety
                ? snap.data().Psychosocial.Stress_And_Anxiety
                : ""
            );
            SetWorkPressure(
              snap?.data()?.Psychosocial.WorkPattern
                ? snap.data().Psychosocial.WorkPattern
                : ""
            );
            SetTiming(snap?.data()?.Timing ? snap.data().Timing : "");
            SetSequence(snap?.data()?.Sequence ? snap.data().Sequence : "");
            SetTimeToIncrease(
              snap?.data()?.TimeToIncrease ? snap.data().TimeToIncrease : ""
            );
            SetTimeToStay(
              snap?.data()?.TimeToStay ? snap.data().TimeToStay : ""
            );
            SetTimeToReduce(
              snap?.data()?.TimeToReduce ? snap.data().TimeToReduce : ""
            );
            SetNature(
              snap?.data()?.Nature_Of_Pain ? snap.data().Nature_Of_Pain : ""
            );
            SetAggravating(
              snap?.data()?.Aggravating_Causes
                ? snap.data().Aggravating_Causes
                : ""
            );
            SetRelieveing(
              snap?.data()?.Relieving_Causes ? snap.data().Relieving_Causes : ""
            );
            SetHead(snap?.data()?.Head ? snap.data().Head : "");
            SetLShoulder(
              snap?.data()?.Left_Shoulder ? snap.data().Left_Shoulder : ""
            );
            SetRShoulder(
              snap?.data()?.Right_Shoulder ? snap.data().Right_Shoulder : ""
            );
            SetThoracic(
              snap?.data()?.Thoracic_Spine ? snap.data().Thoracic_Spine : ""
            );
            SetLumbar(
              snap?.data()?.Lumbar_Spine ? snap.data().Lumbar_Spine : ""
            );
            SetLfoot(snap?.data()?.Left_Foot ? snap.data().Left_Foot : "");
            SetRfoot(snap?.data()?.Right_Foot ? snap.data().Right_Foot : "");
            SetRknee(snap?.data()?.Right_Knee ? snap.data().Right_Knee : "");
            SetKnee(snap?.data()?.Left_Knee ? snap.data().Left_Knee : "");
            SetFootIndex(snap?.data()?.FootIndex ? snap.data().FootIndex : "");
            SetMechanism(
              snap?.data()?.Mechanism.Type ? snap.data().Mechanism.Type : ""
            );
            SetMechanismReason(
              snap?.data()?.Mechanism.Reason ? snap.data().Mechanism.Reason : ""
            );
            SetMechanismOther(
              snap?.data()?.Mechanism.OtherReason
                ? snap.data().Mechanism.OtherReason
                : ""
            );
            SetPainScore(
              snap?.data()?.Mechanism.OtherReason
                ? snap.data().Mechanism.OtherReason
                : ""
            );
            SetPostOperative(
              snap?.data()?.PostOperative.Surgery_Name
                ? snap.data().PostOperative.Surgery_Name
                : ""
            );
            SetCurrentMedications(
              snap?.data()?.CurrentMedications
                ? snap.data().CurrentMedications
                : ""
            );

            SetAbdcution(
              snap?.data()?.Internal_Rotation
                ? snap.data().Internal_Rotation
                : ""
            );
            SetAdduction(snap?.data()?.Adduction ? snap.data().Adduction : "");
            SetBalance(snap?.data()?.Y_Balance ? snap.data().Y_Balance : "");
            SetBiceps(snap?.data()?.Biceps_Load ? snap.data().Biceps_Load : "");
            SetBrien(snap?.data()?.Obrien_Test ? snap.data().Obrien_Test : "");
            SetCkuest(snap?.data()?.Ckuest ? snap.data().Ckuest : "");
            SetFemoris(
              snap?.data()?.Recturs_Femoris_Length
                ? snap.data().Recturs_Femoris_Length
                : ""
            );
            SetExrotation(
              snap?.data()?.External_Rotation
                ? snap.data().External_Rotation
                : ""
            );
            SetExternalhip(
              snap?.data()?.Externalhip_Rotation
                ? snap.data().Externalhip_Rotation
                : ""
            );
            SetGlenohumeral(
              snap?.data()?.Glenohumeral_Internal_Rotation
                ? snap.data().Glenohumeral_Internal_Rotation
                : ""
            );
            SetHamstring(
              snap?.data()?.Hamstring_Length_At_90_By_90
                ? snap.data().Hamstring_Length_At_90_By_90
                : ""
            );
            SetIliopsoas(
              snap?.data()?.Iliopsoas_Length ? snap.data().Iliopsoas_Length : ""
            );
            SetImpingement(
              snap?.data()?.HK_Impingement_Test
                ? snap.data().HK_Impingement_Test
                : ""
            );
            SetImpingementp(
              snap?.data()?.Impingement_Posterior
                ? snap.data().Impingement_Posterior
                : ""
            );
            SetInfraspinatus(
              snap?.data()?.Infraspinatus_Strength_Test
                ? snap.data().Infraspinatus_Strength_Test
                : ""
            );
            SetKibler(
              snap?.data()?.Kibler_Lateral_Side_Test
                ? snap.data().Kibler_Lateral_Side_Test
                : ""
            );
            SetKims(snap?.data()?.Kims ? snap.data().Kims : "");
            SetNotes(snap?.data()?.Notes ? snap.data().Notes : "");
            SetOttawa(
              snap?.data()?.Ottawa_Ankle_Rule
                ? snap.data().Ottawa_Ankle_Rule
                : ""
            );
            SetPiriformis(
              snap?.data()?.Piriformis_Length
                ? snap.data().Piriformis_Length
                : ""
            );
            SetPositionone(
              snap?.data()?.Positionone ? snap.data().Positionone : ""
            );
            SetPositionthree(
              snap?.data()?.Positionthree ? snap.data().Positionthree : ""
            );
            SetPositiontwo(
              snap?.data()?.Positiontwo ? snap.data().Positiontwo : ""
            );
            SetQuadranthip(
              snap?.data()?.Quadranthip ? snap.data().Quadranthip : ""
            );
            SetQuadranttest(
              snap?.data()?.Quadrant_Test ? snap.data().Quadrant_Test : ""
            );
            SetRotationgain(
              snap?.data()?.Internal_Rotation_Gain
                ? snap.data().Internal_Rotation_Gain
                : ""
            );
            SetScapular(snap?.data()?.Scapular ? snap.data().Scapular : "");
            SetSingleleg(
              snap?.data()?.Singleleg_Standing_Balance
                ? snap.data().Singleleg_Standing_Balance
                : ""
            );
            SetSinglelegsquat(
              snap?.data()?.Singlelegsquat ? snap.data().Singlelegsquat : ""
            );
            SetSquat(
              snap?.data()?.OH_Deep_Squat ? snap.data().OH_Deep_Squat : ""
            );
            SetStability(
              snap?.data()?.Core_Stability_Push
                ? snap.data().Core_Stability_Push
                : ""
            );
            SetTrunk(
              snap?.data()?.Trunk_Side_Flexion_ROM
                ? snap.data().Trunk_Side_Flexion_ROM
                : ""
            );
            SetSLUMP(snap?.data()?.SLUMP ? snap.data().SLUMP : "");
            SetSLR(snap?.data()?.SLR ? snap.data().SLR : "");
            SetULTTs(snap?.data()?.ULTTs ? snap.data().ULTTs : "");
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
      }, 10);
    }
  }

  // if (ViewForm == true && userType != "athlete") {
  //   db.collection("Physiotherapy")
  //     .doc(temperoryId)
  //     .get()
  //     .then(function (snap) {
  //       SetSport(snap?.data()?.sports ? snap.data().sports : "");
  //       SetProficiency(
  //         snap?.data()?.Proficiency ? snap.data().Proficiency : ""
  //       );
  //       SetLimb(snap?.data()?.LimbUsage ? snap.data().LimbUsage : "");
  //       SetFile(snap?.data()?.File ? snap.data().File : "");
  //       SetInjuryHistory(
  //         snap?.data()?.InjuryHistory.boolean
  //           ? snap.data().InjuryHistory.boolean
  //           : ""
  //       );
  //       SetDiagnosis(
  //         snap?.data()?.InjuryHistory.InjuryHistoryText
  //           ? snap.data().InjuryHistory.InjuryHistoryText
  //           : ""
  //       );
  //       SetPastIllness(
  //         snap?.data()?.PastIllness ? snap.data().PastIllness : ""
  //       );
  //       SetPastInjuries(
  //         snap?.data()?.PastInjuries ? snap.data().PastInjuries : ""
  //       );
  //       SetComplaints(snap?.data()?.Complaints ? snap.data().Complaints : "");
  //       SetCurrentIllness(
  //         snap?.data()?.CurrentIllness ? snap.data().CurrentIllness : ""
  //       );
  //       SetAllergies(snap?.data()?.Allergies ? snap.data().Allergies : "");
  //       SetOrthotics(snap?.data()?.Orthotics ? snap.data().Orthotics : "");
  //       SetPastSurgery(
  //         snap?.data()?.PastSurgery ? snap.data().PastSurgery : ""
  //       );
  //       SetMedical(
  //         snap?.data()?.MedicalCondition ? snap.data().MedicalCondition : ""
  //       );
  //       SetSleep(
  //         snap?.data()?.Psychosocial.Sleep ? snap.data().Psychosocial.Sleep : ""
  //       );
  //       SetAnxiety(
  //         snap?.data()?.Psychosocial.Stress_And_Anxiety
  //           ? snap.data().Psychosocial.Stress_And_Anxiety
  //           : ""
  //       );
  //       SetWorkPressure(
  //         snap?.data()?.Psychosocial.WorkPattern
  //           ? snap.data().Psychosocial.WorkPattern
  //           : ""
  //       );
  //       SetTiming(snap?.data()?.Timing ? snap.data().Timing : "");
  //       SetSequence(snap?.data()?.Sequence ? snap.data().Sequence : "");
  //       SetTimeToIncrease(
  //         snap?.data()?.TimeToIncrease ? snap.data().TimeToIncrease : ""
  //       );
  //       SetTimeToStay(snap?.data()?.TimeToStay ? snap.data().TimeToStay : "");
  //       SetTimeToReduce(
  //         snap?.data()?.TimeToReduce ? snap.data().TimeToReduce : ""
  //       );
  //       SetNature(
  //         snap?.data()?.Nature_Of_Pain ? snap.data().Nature_Of_Pain : ""
  //       );
  //       SetAggravating(
  //         snap?.data()?.Aggravating_Causes ? snap.data().Aggravating_Causes : ""
  //       );
  //       SetRelieveing(
  //         snap?.data()?.Relieving_Causes ? snap.data().Relieving_Causes : ""
  //       );
  //       SetHead(snap?.data()?.Head ? snap.data().Head : "");
  //       SetLShoulder(
  //         snap?.data()?.Left_Shoulder ? snap.data().Left_Shoulder : ""
  //       );
  //       SetRShoulder(
  //         snap?.data()?.Right_Shoulder ? snap.data().Right_Shoulder : ""
  //       );
  //       SetThoracic(
  //         snap?.data()?.Thoracic_Spine ? snap.data().Thoracic_Spine : ""
  //       );
  //       SetLumbar(snap?.data()?.Lumbar_Spine ? snap.data().Lumbar_Spine : "");
  //       SetLfoot(snap?.data()?.Left_Foot ? snap.data().Left_Foot : "");
  //       SetRfoot(snap?.data()?.Right_Foot ? snap.data().Right_Foot : "");
  //       SetRknee(snap?.data()?.Right_Knee ? snap.data().Right_Knee : "");
  //       SetKnee(snap?.data()?.Left_Knee ? snap.data().Left_Knee : "");
  //       SetFootIndex(snap?.data()?.FootIndex ? snap.data().FootIndex : "");
  //       SetMechanism(
  //         snap?.data()?.Mechanism.Type ? snap.data().Mechanism.Type : ""
  //       );
  //       SetMechanismReason(
  //         snap?.data()?.Mechanism.Reason ? snap.data().Mechanism.Reason : ""
  //       );
  //       SetMechanismOther(
  //         snap?.data()?.Mechanism.OtherReason
  //           ? snap.data().Mechanism.OtherReason
  //           : ""
  //       );
  //       SetPainScore(
  //         snap?.data()?.Mechanism.OtherReason
  //           ? snap.data().Mechanism.OtherReason
  //           : ""
  //       );
  //       SetPostOperative(
  //         snap?.data()?.PostOperative.Surgery_Name
  //           ? snap.data().PostOperative.Surgery_Name
  //           : ""
  //       );
  //       SetCurrentMedications(
  //         snap?.data()?.CurrentMedications ? snap.data().CurrentMedications : ""
  //       );

  //       SetAbdcution(
  //         snap?.data()?.Internal_Rotation ? snap.data().Internal_Rotation : ""
  //       );
  //       SetAdduction(snap?.data()?.Adduction ? snap.data().Adduction : "");
  //       SetBalance(snap?.data()?.Y_Balance ? snap.data().Y_Balance : "");
  //       SetBiceps(snap?.data()?.Biceps_Load ? snap.data().Biceps_Load : "");
  //       SetBrien(snap?.data()?.Obrien_Test ? snap.data().Obrien_Test : "");
  //       SetCkuest(snap?.data()?.Ckuest ? snap.data().Ckuest : "");
  //       SetFemoris(
  //         snap?.data()?.Recturs_Femoris_Length
  //           ? snap.data().Recturs_Femoris_Length
  //           : ""
  //       );
  //       SetExrotation(
  //         snap?.data()?.External_Rotation ? snap.data().External_Rotation : ""
  //       );
  //       SetExternalhip(
  //         snap?.data()?.Externalhip_Rotation
  //           ? snap.data().Externalhip_Rotation
  //           : ""
  //       );
  //       SetGlenohumeral(
  //         snap?.data()?.Glenohumeral_Internal_Rotation
  //           ? snap.data().Glenohumeral_Internal_Rotation
  //           : ""
  //       );
  //       SetHamstring(
  //         snap?.data()?.Hamstring_Length_At_90_By_90
  //           ? snap.data().Hamstring_Length_At_90_By_90
  //           : ""
  //       );
  //       SetIliopsoas(
  //         snap?.data()?.Iliopsoas_Length ? snap.data().Iliopsoas_Length : ""
  //       );
  //       SetImpingement(
  //         snap?.data()?.HK_Impingement_Test
  //           ? snap.data().HK_Impingement_Test
  //           : ""
  //       );
  //       SetImpingementp(
  //         snap?.data()?.Impingement_Posterior
  //           ? snap.data().Impingement_Posterior
  //           : ""
  //       );
  //       SetInfraspinatus(
  //         snap?.data()?.Infraspinatus_Strength_Test
  //           ? snap.data().Infraspinatus_Strength_Test
  //           : ""
  //       );
  //       SetKibler(
  //         snap?.data()?.Kibler_Lateral_Side_Test
  //           ? snap.data().Kibler_Lateral_Side_Test
  //           : ""
  //       );
  //       SetKims(snap?.data()?.Kims ? snap.data().Kims : "");
  //       SetNotes(snap?.data()?.Notes ? snap.data().Notes : "");
  //       SetOttawa(
  //         snap?.data()?.Ottawa_Ankle_Rule ? snap.data().Ottawa_Ankle_Rule : ""
  //       );
  //       SetPiriformis(
  //         snap?.data()?.Piriformis_Length ? snap.data().Piriformis_Length : ""
  //       );
  //       SetPositionone(
  //         snap?.data()?.Positionone ? snap.data().Positionone : ""
  //       );
  //       SetPositionthree(
  //         snap?.data()?.Positionthree ? snap.data().Positionthree : ""
  //       );
  //       SetPositiontwo(
  //         snap?.data()?.Positiontwo ? snap.data().Positiontwo : ""
  //       );
  //       SetQuadranthip(
  //         snap?.data()?.Quadranthip ? snap.data().Quadranthip : ""
  //       );
  //       SetQuadranttest(
  //         snap?.data()?.Quadrant_Test ? snap.data().Quadrant_Test : ""
  //       );
  //       SetRotationgain(
  //         snap?.data()?.Internal_Rotation_Gain
  //           ? snap.data().Internal_Rotation_Gain
  //           : ""
  //       );
  //       SetScapular(snap?.data()?.Scapular ? snap.data().Scapular : "");
  //       SetSingleleg(
  //         snap?.data()?.Singleleg_Standing_Balance
  //           ? snap.data().Singleleg_Standing_Balance
  //           : ""
  //       );
  //       SetSinglelegsquat(
  //         snap?.data()?.Singlelegsquat ? snap.data().Singlelegsquat : ""
  //       );
  //       SetSquat(snap?.data()?.OH_Deep_Squat ? snap.data().OH_Deep_Squat : "");
  //       SetStability(
  //         snap?.data()?.Core_Stability_Push
  //           ? snap.data().Core_Stability_Push
  //           : ""
  //       );
  //       SetTrunk(
  //         snap?.data()?.Trunk_Side_Flexion_ROM
  //           ? snap.data().Trunk_Side_Flexion_ROM
  //           : ""
  //       );
  //       SetSLUMP(snap?.data()?.SLUMP ? snap.data().SLUMP : "");
  //       SetSLR(snap?.data()?.SLR ? snap.data().SLR : "");
  //       SetULTTs(snap?.data()?.ULTTs ? snap.data().ULTTs : "");
  //     })
  //     .catch(function (error) {
  //       console.log("Error getting documents: ", error);
  //     });
  // }
  // const HandleFile = (e) => {
  //   SetFile(e.target.value);
  // };
  const HandleName = (e) => {
    setTherapyName(e.target.value);
  };
  const HandleSport = (e) => {
    SetSport(e.target.value);
  };
  const HandleProficiency = (e) => {
    SetProficiency(e.target.value);
  };

  const HandleSustainedp = (e) => {
    SetSustainedp(e.target.value);
  };
  const HandleDippingp = (e) => {
    SetDippingp(e.target.value);
  };
  const HandleCessationp = (e) => {
    SetCessationp(e.target.value);
  };
  const HandleCessations = (e) => {
    SetCessations(e.target.value);
  };
  const HandleSustainedg = (e) => {
    SetSustainedg(e.target.value);
  };

  const HandleSustaineds = (e) => {
    SetSustaineds(e.target.value);
  };
  const HandleImpingement = (e) => {
    SetImpingement(e.target.value);
  };

  const HandleCessationg = (e) => {
    SetCessationg(e.target.value);
  };
  const HandleDippingg = (e) => {
    SetDippingg(e.target.value);
  };
  const HandleDippings = (e) => {
    SetDippings(e.target.value);
  };
  const HandlePositionone = (e) => {
    SetPositionone(e.target.value);
  };

  const HandlePositiontwo = (e) => {
    SetPositiontwo(e.target.value);
  };

  const HandlePositionthree = (e) => {
    SetPositionthree(e.target.value);
  };
  const HandleKibler = (e) => {
    SetKibler(e.target.value);
  };
  const HandleCkuest = (e) => {
    SetCkuest(e.target.value);
  };
  const HandleScapular = (e) => {
    SetScapular(e.target.value);
  };
  const HandleKims = (e) => {
    SetKims(e.target.value);
  };
  const HandleBiceps = (e) => {
    SetBiceps(e.target.value);
  };
  const HandleLimb = (e) => {
    SetLimb(e.target.value);
  };
  const HandleNotes = (e) => {
    SetNotes(e.target.value);
  };
  const HandleChangeInjuryHistory = (e) => {
    SetInjuryHistory(e.target.value);
    console.log(InjuryHistory);
  };

  const HandleDiagnosis = (e) => {
    SetDiagnosis(e.target.value);
  };

  const HandleComplaints = (e) => {
    SetComplaints(e.target.value);
  };

  const HandleCurrentIllness = (e) => {
    SetCurrentIllness(e.target.value);
  };

  const HandlePastInjuries = (e) => {
    SetPastInjuries(e.target.value);
  };

  const HandlePastIllness = (e) => {
    SetPastIllness(e.target.value);
  };

  const HandlePastSurgery = (e) => {
    SetPastSurgery(e.target.value);
  };

  const HandleAllergies = (e) => {
    SetAllergies(e.target.value);
  };

  const HandleOrthotics = (e) => {
    SetOrthotics(e.target.value);
  };

  const HandleTiming = (e) => {
    SetTiming(e.target.value);
  };

  const HandleSequence = (e) => {
    SetSequence(e.target.value);
  };

  const HandleMechanism = (e) => {
    SetMechanism(e.target.value);
  };

  const HandleTrauma = (e) => {
    SetTrauma(e.target.value);
  };
  const HandleAtrauma = (e) => {
    SetAtrauma(e.target.value);
  };

  const HandlePostOperative = (e) => {
    SetPostOperative(e.target.value);
  };

  const HandleTimeToIncrease = (e) => {
    SetTimeToIncrease(e.target.value);
  };

  const HandleTimeToStay = (e) => {
    SetTimeToStay(e.target.value);
  };

  const HandleTimeToReduce = (e) => {
    SetTimeToReduce(e.target.value);
  };

  const HandleOthersTrauma = (e) => {
    SetOthersTrauma(e.target.value);
  };

  const HandleOthersAtrauma = (e) => {
    SetOthersAtrauma(e.target.value);
  };

  const HandleNature = (e) => {
    SetNature(e.target.value);
  };

  const HandleDepth = (e) => {
    SetDepth(e.target.value);
  };
  const HandleDepthOther = (e) => {
    SetDepthOther(e.target.value);
  };
  const HandleAggravating = (e) => {
    SetAggravating(e.target.value);
  };

  const HandleHead = (e) => {
    SetHead(e.target.value);
  };
  const HandleLshoulder = (e) => {
    SetLShoulder(e.target.value);
  };

  const HandleRshoulder = (e) => {
    SetRShoulder(e.target.value);
  };
  const HandleThoracic = (e) => {
    SetThoracic(e.target.value);
  };

  const HandleLumbar = (e) => {
    SetLumbar(e.target.value);
  };
  const Handleknee = (e) => {
    SetKnee(e.target.value);
  };

  const HandleRknee = (e) => {
    SetRknee(e.target.value);
  };
  const HandleRfoot = (e) => {
    SetRfoot(e.target.value);
  };
  const HandleLfoot = (e) => {
    SetLfoot(e.target.value);
  };

  const HandleFootIndex = (e) => {
    SetFootIndex(e.target.value);
  };
  const HandleInfraspinatus = (e) => {
    SetInfraspinatus(e.target.value);
  };
  const HandleFullcan = (e) => {
    SetFullcan(e.target.value);
  };
  const HandleAbduction = (e) => {
    SetAbdcution(e.target.value);
  };
  const HandleBalance = (e) => {
    SetBalance(e.target.value);
  };

  const HandleExrotation = (e) => {
    SetExrotation(e.target.value);
  };
  const HandleGlenohumeral = (e) => {
    SetGlenohumeral(e.target.value);
  };
  const HandleRotationgain = (e) => {
    SetRotationgain(e.target.value);
  };
  const HandleAdduction = (e) => {
    SetAdduction(e.target.value);
  };
  const HandleBrien = (e) => {
    SetBrien(e.target.value);
  };
  const HandleHamstring = (e) => {
    SetHamstring(e.target.value);
  };
  const HandleIliopsoas = (e) => {
    SetIliopsoas(e.target.value);
  };
  const HandleFemoris = (e) => {
    SetFemoris(e.target.value);
  };
  const HandleInternalhip = (e) => {
    SetInternalhip(e.target.value);
  };
  const HandleExternalhip = (e) => {
    SetExternalhip(e.target.value);
  };
  const HandleQuadranthip = (e) => {
    SetQuadranthip(e.target.value);
  };

  const HandleQuadranttest = (e) => {
    SetQuadranttest(e.target.value);
  };
  const HandleTrunk = (e) => {
    SetTrunk(e.target.value);
  };
  const HandleLumbarspine = (e) => {
    SetLumbarspine(e.target.value);
  };
  const HandlePiriformis = (e) => {
    SetPiriformis(e.target.value);
  };
  const HandleLunge = (e) => {
    SetLunge(e.target.value);
  };
  const HandleOttawa = (e) => {
    SetOttawa(e.target.value);
  };
  const HandleSquat = (e) => {
    SetSquat(e.target.value);
  };

  const HandleSingleleg = (e) => {
    SetSingleleg(e.target.value);
  };

  const HandleSinglelegsquat = (e) => {
    SetSinglelegsquat(e.target.value);
  };
  const HandleSLUMP = (e) => {
    SetSLUMP(e.target.value);
  };
  const HandleSLR = (e) => {
    SetSLR(e.target.value);
  };

  const HandleImpingementp = (e) => {
    SetImpingementp(e.target.value);
  };

  const HandleStability = (e) => {
    SetStability(e.target.value);
  };
  const HandleULTTs = (e) => {
    SetULTTs(e.target.value);
  };

  const HandleCurrentMedications = (e) => {
    SetCurrentMedications(e.target.value);
  };
  const toggleviewform = (e) => {
    SetSport("");
    SetProficiency("");
    SetLimb("");
    SetFile("");
    SetInjuryHistory("");
    SetDiagnosis("");
    SetPastIllness("");
    SetPastInjuries("");
    SetComplaints("");
    SetCurrentIllness("");
    SetAllergies("");
    SetOrthotics("");
    SetPastSurgery("");
    SetMedical("");
    SetSleep("");
    SetAnxiety("");
    SetWorkPressure("");
    SetTiming("");
    SetSequence("");
    SetTimeToIncrease("");
    SetTimeToStay("");
    SetTimeToReduce("");
    SetNature("");
    SetAggravating("");
    SetRelieveing("");
    SetHead("");
    SetLShoulder("");
    SetRShoulder("");
    SetThoracic("");
    SetLumbar("");
    SetLfoot("");
    SetRfoot("");
    SetRknee("");
    SetKnee("");
    SetFootIndex("");
    SetMechanism("");
    SetMechanismReason("");
    SetMechanismOther("");
    SetPainScore("");
    SetPostOperative("");
    SetCurrentMedications("");
    SetAbdcution("");
    SetAdduction("");
    SetBalance("");
    SetBiceps("");
    SetBrien("");
    SetCkuest("");
    SetFemoris("");
    SetExrotation("");
    SetExternalhip("");
    SetGlenohumeral("");
    SetHamstring("");
    SetIliopsoas("");
    SetImpingement("");
    SetImpingementp("");
    SetInfraspinatus("");
    SetKibler("");
    SetKims("");
    SetNotes("");
    SetOttawa("");
    SetPiriformis("");
    SetPositionone("");
    SetPositionthree("");
    SetPositiontwo("");
    SetQuadranthip("");
    SetQuadranttest("");
    SetRotationgain("");
    SetScapular("");
    SetSingleleg("");
    SetSinglelegsquat("");
    SetSquat("");
    SetStability("");
    SetTrunk("");
    SetSLUMP("");
    SetSLR("");
    SetULTTs("");

    SetViewForm(!ViewForm);
  };

  let temail = temperoryData?.data?.email;
  const SaveUserValue = (e) => {
    e.preventDefault();
    if (File == "No File Uploaded!!") {
      alert("Please Upload Any File!");
      return;
    } else {
      storage.ref(`images/${temail}/Physiotherapy/${File.name}`).put(File);
    }

    alert("Are you sure ?");
    history.push({
      pathname: "Previous_Physiotherapy_Assessments",
      state: {
        AthleteId: temperoryId,
      },
    });

    window.scrollTo(0, 0);
    SetViewForm(true);
    var checkboxes = " ";
    var checkboxestwo = " ";
    var checkboxesthree = " ";
    var checkboxesfour = " ";

    var cardiac = e.target.cardiac;
    var dm = e.target.dm;
    var familyhistory = e.target.familyhistory;
    var epilepsy = e.target.epilepsy;
    var asthma = e.target.asthma;
    var none = e.target.none;
    var headinjury = e.target.headinjury;
    var Diagnosistext = "";
    var Reason = "";
    var OtherReason = "";
    var OtherDepth = "";
    var Ice = e.target.Ice;
    var Heat = e.target.Heat;
    var Rest = e.target.Rest;
    var Movement = e.target.Movement;
    var Medication = e.target.Medication;
    var Taping = e.target.Taping;
    var Stretching = e.target.Stretching;
    var Foam = e.target.Foam;
    var Pulling = e.target.Pulling;
    var Aching = e.target.Aching;
    var Radiating = e.target.Radiating;
    var Pins = e.target.Pins;
    var Pinching = e.target.Pinching;
    var Soreness = e.target.Soreness;
    var Catch = e.target.Catch;
    var Posteromedialc = e.target.Posteromedialc;
    var Lateralc = e.target.Lateralc;
    var Posteriorc = e.target.Posteriorc;
    var Posterolateralc = e.target.Posterolateralc;
    var Medialc = e.target.Medialc;
    var Anteriorc = e.target.Anteriorc;
    var Anterolateralc = e.target.Anterolateralc;
    var Anteromedialc = e.target.Anteromedialc;

    if (Ice.checked) checkboxestwo += Ice.value + " ";
    if (Heat.checked) checkboxestwo += Heat.value + " ";
    if (Rest.checked) checkboxestwo += Rest.value + " ";
    if (Movement.checked) checkboxestwo += Movement.value + " ";
    if (Medication.checked) checkboxestwo += Medication.value + " ";
    if (Taping.checked) checkboxestwo += Taping.value + " ";
    if (Stretching.checked) checkboxestwo += Stretching.value + " ";
    if (Foam.checked) checkboxestwo += Foam.value + " ";

    if (Pulling.checked) checkboxesthree += Pulling.value + " ";
    if (Soreness.checked) checkboxesthree += Soreness.value + " ";
    if (Radiating.checked) checkboxesthree += Radiating.value + " ";
    if (Pins.checked) checkboxesthree += Pins.value + " ";
    if (Catch.checked) checkboxesthree += Catch.value + " ";
    if (Pinching.checked) checkboxesthree += Pinching.value + " ";
    if (Aching.checked) checkboxesthree += Aching.value + " ";

    if (Posteriorc.checked) checkboxesfour += Posteriorc.value + " ";
    if (Posteromedialc.checked) checkboxesfour += Posteromedialc.value + " ";
    if (Medialc.checked) checkboxesfour += Medialc.value + " ";
    if (Anteromedialc.checked) checkboxesfour += Anteromedialc.value + " ";
    if (Anteriorc.checked) checkboxesfour += Anteriorc.value + " ";
    if (Anterolateralc.checked) checkboxesfour += Anterolateralc.value + " ";
    if (Posterolateralc.checked) checkboxesfour += Posterolateralc.value + " ";
    if (Lateralc.checked) checkboxesfour += Lateralc.value + " ";

    if (Depth == "others") OtherDepth = DepthOther;

    if (Mechanism == "traumatic") {
      if (Trauma == "others") {
        Reason = "other";
        OtherReason = OthersTrauma;
      } else {
        Reason = Trauma;
        OtherReason = "";
      }
    }
    if (Mechanism == "atraumatic") {
      if (Atrauma == "others") {
        Reason = "other";
        OtherReason = OthersAtrauma;
      } else {
        Reason = Atrauma;
        OtherReason = "";
      }
    }
    if (Mechanism == "Post Operative") Reason = "Post Operative";

    if (none.checked) {
      checkboxes += none.value + " ";
    } else {
      if (cardiac.checked) checkboxes += cardiac.value + " ";
      if (dm.checked) checkboxes += dm.value + " ";
      if (familyhistory.checked) checkboxes += familyhistory.value + " ";
      if (epilepsy.checked) checkboxes += epilepsy.value + " ";
      if (asthma.checked) checkboxes += asthma.value + " ";
      if (headinjury.checked) checkboxes += headinjury.value + " ";
    }
    if (InjuryHistory == "no") Diagnosistext = "";
    if (InjuryHistory == "yes") Diagnosistext = Diagnosis;

    db.collection("athletes")
      .doc(temperoryId)
      .collection("Physiotheraphy")
      .doc(timestamp())
      .set(
        {
          Name: therapyName,
          Date: timestamp.utc("DD/MM/YYYY"),
          temperoryId: temperoryId,
          sports: Sport,
          Proficiency: Proficiency,
          LimbUsage: Limb,
          InjuryHistory: {
            boolean: InjuryHistory,
            InjuryHistoryText: Diagnosistext,
          },
          Complaints: Complaints,
          File: File.name,
          CurrentIllness: CurrentIllness,
          PastInjuries: PastInjuries,
          PastIllness: PastIllness,
          MedicalCondition: checkboxes,
          PastSurgery: PastSurgery,
          Allergies: Allergies,
          Orthotics: Orthotics,
          Psychosocial: {
            Sleep: 3,
            WorkPattern: 3,
            Stress_And_Anxiety: 3,
          },
          Timing: Timing,
          Sequence: Sequence,
          TimeToIncrease: TimeToIncrease,
          TimeToStay: TimeToStay,
          TimeToReduce: TimeToReduce,
          Mechanism: {
            Type: Mechanism,
            Reason: Reason,
            OtherReason: OtherReason,
          },
          PostOperative: {
            Surgery_Name: PostOperative,
            Pain_Score: 0,
          },
          Depth: {
            Depth_Type: Depth,
            Other_type: OtherDepth,
          },
          Aggravating_Causes: Aggravating,
          Relieving_Causes: checkboxestwo,
          Pain_diagram: checkboxesfour,
          Nature_Of_Pain: checkboxesthree,
          Head_Posture: Head,
          Left_Shoulder: LShoulder,
          Right_Shoulder: RShoulder,
          Thoracic_Spine: Thoracic,
          Lumbar_Spine: Lumbar,
          Right_Knee: Rknee,
          Left_Knee: Knee,
          Left_Foot: Lfoot,
          Right_Foot: Rfoot,
          FootIndex: FootIndex,
          CurrentMedications: CurrentMedications,
          Internal_Rotation: Abduction,
          External_Rotation: Exrotation,
          Glenohumeral_Internal_Rotation: Glenohumeral,
          Internal_Rotation_Gain: Rotationgain,
          HK_Impingement_Test: Impingement,
          Full_Can_Test: Fullcan,
          Infraspinatus_Strength_Test: Infraspinatus,
          Kibler_Lateral_Side_Test: Kibler,
          Positionone: Positionone,
          Positionthree: Positionthree,
          Positiontwo: Positiontwo,
          Scapular: Scapular,
          Horizontal_Adduction: Adduction,
          Kims: Kims,
          Biceps_Load: Biceps,
          Obrien_Test: Brien,
          Ckuest: Ckuest,
          Y_Balance: Balance,
          Quadrant_Test: Quadranttest,
          Trunk_Side_Flexion_ROM: Trunk,
          Lumbarspine: Lumbarspine,
          Notes: Notes,
          Hamstring_Length_At_90_By_90: Hamstring,
          Iliopsoas_Length: Iliopsoas,
          Recturs_Femoris_Length: Femoris,
          Externalhip_Rotation: Externalhip,
          Internalhip_Rotation: Internalhip,
          Quadranthip: Quadranthip,
          Piriformis_Length: Piriformis,
          Lunge: Lunge,
          Impingement_Posterior: Impingementp,
          Singleleg_Standing_Balance: Singleleg,
          OH_Deep_Squat: Squat,
          Singlelegsquat: Singlelegsquat,
          Core_Stability_Push: Stability,
          Ottawa_Ankle_Rule: Ottawa,
          SLUMP: SLUMP,
          SLR: SLR,
          ULTTs: ULTTs,
        },
        { merge: true }
      );
    db.collection("AthleteForm").add({
      temperoryId: temperoryId,
      sports: Sport,
      Proficiency: Proficiency,
      LimbUsage: Limb,
      InjuryHistory: {
        boolean: InjuryHistory,
        InjuryHistoryText: Diagnosistext,
      },
      Complaints: Complaints,
      File: File.name,
      CurrentIllness: CurrentIllness,
      PastInjuries: PastInjuries,
      PastIllness: PastIllness,
      MedicalCondition: checkboxes,
      PastSurgery: PastSurgery,
      Allergies: Allergies,
      Orthotics: Orthotics,
      Psychosocial: {
        Sleep: 3,
        WorkPattern: 3,
        Stress_And_Anxiety: 3,
      },
      Timing: Timing,
      Sequence: Sequence,
      TimeToIncrease: TimeToIncrease,
      TimeToStay: TimeToStay,
      TimeToReduce: TimeToReduce,
      Mechanism: {
        Type: Mechanism,
        Reason: Reason,
        OtherReason: OtherReason,
      },
      PostOperative: {
        Surgery_Name: PostOperative,
        Pain_Score: 0,
      },

      Depth: {
        Depth_Type: Depth,
        Other_type: OtherDepth,
      },
      Aggravating_Causes: Aggravating,
      Relieving_Causes: checkboxestwo,
      Pain_diagram: checkboxesfour,
      Nature_Of_Pain: checkboxesthree,
      Head_Posture: Head,
      Left_Shoulder: LShoulder,
      Right_Shoulder: RShoulder,
      Thoracic_Spine: Thoracic,
      Lumbar_Spine: Lumbar,
      Right_Knee: Rknee,
      Left_Knee: Knee,
      Left_Foot: Lfoot,
      Right_Foot: Rfoot,
      FootIndex: FootIndex,
      CurrentMedications: CurrentMedications,
      Internal_Rotation: Abduction,
      External_Rotation: Exrotation,
      Glenohumeral_Internal_Rotation: Glenohumeral,
      Internal_Rotation_Gain: Rotationgain,
      HK_Impingement_Test: Impingement,
      Full_Can_Test: Fullcan,
      Infraspinatus_Strength_Test: Infraspinatus,
      Kibler_Lateral_Side_Test: Kibler,
      Positionone: Positionone,
      Positionthree: Positionthree,
      Positiontwo: Positiontwo,
      Scapular: Scapular,
      Horizontal_Adduction: Adduction,
      Kims: Kims,
      Biceps_Load: Biceps,
      Obrien_Test: Brien,
      Ckuest: Ckuest,
      Y_Balance: Balance,
      Quadrant_Test: Quadranttest,
      Trunk_Side_Flexion_ROM: Trunk,
      Lumbarspine: Lumbarspine,
      Notes: Notes,
      Hamstring_Length_At_90_By_90: Hamstring,
      Iliopsoas_Length: Iliopsoas,
      Recturs_Femoris_Length: Femoris,
      Externalhip_Rotation: Externalhip,
      Internalhip_Rotation: Internalhip,
      Quadranthip: Quadranthip,
      Piriformis_Length: Piriformis,
      Lunge: Lunge,
      Impingement_Posterior: Impingementp,
      Singleleg_Standing_Balance: Singleleg,
      OH_Deep_Squat: Squat,
      Singlelegsquat: Singlelegsquat,
      Core_Stability_Push: Stability,
      Ottawa_Ankle_Rule: Ottawa,
      SLUMP: SLUMP,
      SLR: SLR,
      ULTTs: ULTTs,
    });
  };
  return (
    <>
      <div id="FormContainer" id="physiotherapyform">
        <form onSubmit={SaveUserValue}>
          <p style={{ textAlign: "start" }}>
            <img
              style={{
                width: 16,
                height: 16,
                cursor: "pointer",
              }}
              src={backButton}
              onClick={() => {
                // window.open("/Athlete/reports/" + Id, "");
                console.log(1);
                history.push({
                  pathname: "/Previous_Physiotherapy_Assessments",
                  state: {
                    AthleteId: temperoryId,
                  },
                });
              }}
            />
          </p>
          <div className="form-group">
            <label>Physiotherapy Name:</label>
            <textarea
              rows="3"
              onChange={HandleName}
              value={therapyName}
              type="text"
              className="form-control"
              id="sport"
              name="sport"
              placeholder="Enter Name"
            />
          </div>
          <br></br>
          <div className="form-group">
            <label>Sport Played/Recreational Activity:</label>
            <textarea
              rows="3"
              onChange={HandleSport}
              value={Sport}
              type="text"
              className="form-control"
              id="sport"
              name="sport"
              placeholder="Enter Sport Played/Recreational Activity:"
            />
          </div>

          <br></br>
          <label for="proficiencylevel">Physical Activity level:</label>
          <div className="form-check" id="proficiencylevel">
            <input
              onChange={HandleProficiency}
              value="Professional"
              className="form-check-input"
              type="radio"
              name="activitylevel"
              id="flexRadioDefault1"
            />
            <label className="form-check-label" for="flexRadioDefault1">
              Professional
            </label>
            <input
              onChange={HandleProficiency}
              value="Recreational"
              className="form-check-input"
              style={{ marginLeft: "30px" }}
              type="radio"
              name="activitylevel"
              id="flexRadioDefault2"
            />
            <label className="form-check-label" for="flexRadioDefault2">
              Recreational
            </label>
          </div>
          <br></br>
          <label for="limbusage">Hand/Foot Dominance:</label>
          <div className="form-check" id="limbusage">
            <input
              className="form-check-input"
              value="Left"
              type="radio"
              onChange={HandleLimb}
              name="limbusage"
              id="leftlimb"
            />
            <label className="form-check-label" for="leftlimb">
              Left
            </label>
            <input
              className="form-check-input"
              value="Right"
              onChange={HandleLimb}
              type="radio"
              name="limbusage"
              id="rightlimb"
            />
            <label className="form-check-label" for="rightlimb">
              Right
            </label>
          </div>
          <br></br>
          <p>Attach File:</p>
          <label for="file-upload" className="custom-file-upload">
            <i className="fa fa-cloud-upload"></i> Upload
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={(e) => {
              SetFile(e.target.files[0]);
            }}
          />
          <small className="ml-2">only pdf, jpeg, word</small>
          <p style={{ fontSize: "13px", color: "blue" }}>{File.name}</p>

          <label for="InjuryHistory">INJURY / ILLNESS HISTORY:</label>
          <div className="form-check" id="InjuryHistory">
            <input
              className="form-check-input"
              type="radio"
              name="InjuryHistory"
              value="yes"
              id="Yes"
              onChange={HandleChangeInjuryHistory}
            />
            <label className="form-check-label" for="Yes">
              Yes
            </label>
            <input
              className="form-check-input"
              type="radio"
              value="no"
              name="InjuryHistory"
              id="No"
              onChange={HandleChangeInjuryHistory}
            />
            <label className="form-check-label" for="No">
              No
            </label>
          </div>

          {InjuryHistory == "yes" && (
            <div className="form-group">
              <br></br>
              <label for="InjuryHistoryText">Provisional Diagnosis:</label>
              <textarea
                rows="3"
                type="text"
                className="form-control"
                id="InjuryHistoryText"
                name="InjuryHistoryText"
                onChange={HandleDiagnosis}
                value={Diagnosis}
                placeholder="Write Note On INJURY / ILLNESS HISTORY"
              />
            </div>
          )}
          <br></br>
          <div className="form-group">
            <label for="CurrentComplaints">Current Complaints:</label>
            <textarea
              rows="3"
              type="text"
              className="form-control"
              id="CurrentComplaints"
              name="CurrentComplaints"
              onChange={HandleComplaints}
              value={Complaints}
              placeholder="Enter Current Complaints:"
            />
          </div>
          <br></br>
          <div className="form-group">
            <label for="CurrentIllnesses">Current Illnesses:</label>
            <textarea
              rows="3"
              onChange={HandleCurrentIllness}
              value={CurrentIllness}
              type="text"
              className="form-control"
              id="CurrentIllnesses"
              name="CurrentIllnesses"
              placeholder="Enter Current Illnesses:"
            />
          </div>
          <br></br>
          <div className="form-group">
            <label for="PastInjuries">Past Injuries:</label>
            <textarea
              rows="3"
              type="text"
              className="form-control"
              id="PastInjuries"
              onChange={HandlePastInjuries}
              value={PastInjuries}
              name="PastInjuries"
              placeholder="Enter Past Injuries:"
            />
          </div>
          <br></br>
          <div className="form-group">
            <label for="PastIllnesses">Past Illnesses:</label>
            <textarea
              rows="3"
              type="text"
              className="form-control"
              id="PastIllnesses"
              onChange={HandlePastIllness}
              value={PastIllness}
              name="PastIllnesses"
              placeholder="Enter Past Illnesses:"
            />
          </div>
          <br></br>
          <p>Medical Conditions</p>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              on
              name="asthma"
              value="asthma"
              id="Asthma"
            />
            <label className="form-check-label" for="Asthma">
              Asthma
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Head injury"
              name="headinjury"
              id="HeadInjury"
            />
            <label className="form-check-label" for="HeadInjury">
              Head Injury
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="epilepsy"
              name="epilepsy"
              id="Epilepsy"
            />
            <label className="form-check-label" for="Epilepsy">
              Epilepsy
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="family history"
              name="familyhistory"
              id="FamilyHistory"
            />
            <label className="form-check-label" for="FamilyHistory">
              Family History
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="dm"
              name="dm"
              id="DM"
            />
            <label className="form-check-label" for="DM">
              DM
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="cardiac disease"
              name="cardiac"
              id="CardiacDisease"
            />
            <label className="form-check-label" for="CardiacDisease">
              Cardiac Disease
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="none"
              name="none"
              id="None"
            />
            <label className="form-check-label" for="None">
              None
            </label>
          </div>

          <br></br>
          <div className="form-group">
            <label for="CurrentMedications">Current Medications:</label>
            <textarea
              rows="3"
              type="text"
              className="form-control"
              id="CurrentMedications"
              onChange={HandleCurrentMedications}
              value={CurrentMedications}
              name="CurrentMedications"
              placeholder="Enter Current Medications: Example aspirin"
            />
          </div>
          <br></br>
          <div className="form-group">
            <label for="PastSurgery">Past Surgery:</label>
            <textarea
              rows="3"
              type="text"
              onChange={HandlePastSurgery}
              value={PastSurgery}
              className="form-control"
              id="PastSurgery"
              name="PastSurgery"
              placeholder="Enter Past Surgery:"
            />
          </div>
          <br></br>
          <div className="form-group">
            <label for="Allergies">Allergies:</label>
            <textarea
              rows="3"
              type="text"
              className="form-control"
              id="Allergies"
              name="Allergies"
              onChange={HandleAllergies}
              value={Allergies}
              placeholder="Allergies"
            />
          </div>
          <br></br>
          <div className="form-group">
            <label for="Orthotics">Orthotics:</label>
            <textarea
              rows="3"
              type="text"
              className="form-control"
              id="Orthotics"
              name="Orthotics"
              onChange={HandleOrthotics}
              value={Orthotics}
              placeholder="Enter Orthotics:"
            />
          </div>
          <br></br>
          <h3>Psychosocial:</h3>
          <br></br>

          <h4 style={{ fontWeight: "400" }}>Rate Your Sleep Between 1 to 5</h4>
          <Slider
            min={1}
            max={5}
            dots
            marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
          />
          <br></br>
          <br></br>
          <h4 style={{ fontWeight: "400" }}>
            Rate Your Work Pattern Between 1 to 5
          </h4>
          <Slider
            min={1}
            max={5}
            dots
            marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
          />
          <br></br>
          <br></br>
          <h4 style={{ fontWeight: "400" }}>
            Rate Your Stress and Anxiety Between 1 to 5
          </h4>
          <Slider
            min={1}
            max={5}
            dots
            marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
          />
          <br></br>
          <br />
          <h3>Areas Of Pain</h3>

          <label className="form-check-label" for="Acute">
            Timing
          </label>
          <div className="form-check" id="Acute">
            <input
              className="form-check-input"
              type="radio"
              name="Chronic"
              value="Acute"
              id="Acuteaa"
              onChange={HandleTiming}
            />
            <label className="form-check-label" for="Acuteaa">
              Acute
            </label>
            <input
              classNameName="form-check-input"
              value="Chronic"
              style={{ marginLeft: "20px" }}
              type="radio"
              name="Chronic"
              onChange={HandleTiming}
              id=" Chronic"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for=" Chronic"
            >
              Chronic
            </label>
            <input
              classNameName="form-check-input"
              style={{ marginLeft: "20px" }}
              type="radio"
              value="Acute and Chronic"
              onChange={HandleTiming}
              name="Chronic"
              id="AcuteChronic"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="AcuteChronic"
            >
              Acute and Chronic
            </label>
          </div>
          <br></br>

          <div className="form-group">
            <label for="PastSurgery" style={{ marginTop: "15px" }}>
              Sequence of Pain Onset:
            </label>
            <textarea
              rows="3"
              value={Sequence}
              onChange={HandleSequence}
              type="text"
              className="form-control"
              id="Sequence"
              name="Sequence"
              placeholder="Enter Sequence of Pain Onset:"
            />
          </div>
          <br />
          <h4 style={{ fontWeight: "400" }}>Mechanism of Injury</h4>
          <div className="form-check" id="Acute">
            <input
              onChange={HandleMechanism}
              className="form-check-input"
              type="radio"
              name="Mechanism"
              value="traumatic"
              id="Traumatic"
            />
            <label className="form-check-label" for="Traumatic">
              Traumatic
            </label>
            <input
              onChange={HandleMechanism}
              classNameName="form-check-input"
              style={{ marginLeft: "20px" }}
              value="atraumatic"
              type="radio"
              name="Mechanism"
              id="Atraumatic"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="Atraumatic"
            >
              Atraumatic
            </label>
            <input
              onChange={HandleMechanism}
              classNameName="form-check-input"
              style={{ marginLeft: "20px" }}
              value="Post Operative"
              type="radio"
              name="Mechanism"
              id="PostOperative"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="PostOperative"
            >
              Post Operative
            </label>
          </div>
          <br></br>

          {Mechanism == "traumatic" && (
            <div>
              <select
                onChange={HandleTrauma}
                value={Trauma}
                className="form-control"
                name="selecttraumatic"
                style={{ background: "white" }}
                id="selecttraumatic"
              >
                <option disabled value="nill">
                  Type of trauma
                </option>
                <option value="Impact with equipment">
                  Impact with equipment
                </option>
                <option value="Impact with person">Impact with person</option>
                <option value="fall">Fall</option>
                <option value="Surface Interaction">Surface Interaction</option>
                <option value="others">Others</option>
              </select>
              {Trauma == "others" && (
                <div>
                  <br></br>
                  <div className="form-group">
                    <label for="OthersTrauma">Other:</label>
                    <textarea
                      rows="3"
                      type="text"
                      className="form-control"
                      id="OthersTrauma"
                      name="OthersTrauma"
                      onChange={HandleOthersTrauma}
                      value={OthersTrauma}
                      placeholder="Enter Orthotics:"
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {Mechanism == "atraumatic" && (
            <div>
              <select
                className="form-control"
                name="selectexcercise"
                onChange={HandleAtrauma}
                value={Atrauma}
                style={{ background: "white" }}
                id="excercises"
              >
                <option value="nill">Type of Atrauma</option>
                <option value="Repetitive">Repetitive</option>
                <option value="CTD/Overuse">CTD/Overuse</option>
                <option value="Equipment">Equipment</option>
                <option value="Technique">Technique</option>
                <option value="Systemic">Systemic</option>
                <option value="others">Others</option>
              </select>
              {Atrauma == "others" && (
                <div className="form-group">
                  <br></br>
                  <label for="OthersAtrauma">Other:</label>
                  <textarea
                    rows="3"
                    type="text"
                    className="form-control"
                    id="OthersAtrauma"
                    value={OthersAtrauma}
                    name="OthersAtrauma"
                    onChange={HandleOthersAtrauma}
                    placeholder="Atruama Injury:"
                  />
                </div>
              )}
            </div>
          )}

          {Mechanism == "Post Operative" && (
            <div className="form-group">
              <div>
                <label for="postoperative">Name of Surgery:</label>
                <br></br>
                <textarea
                  rows="3"
                  type="text"
                  className="form-control"
                  id="postoperative"
                  value={PostOperative}
                  onChange={HandlePostOperative}
                  name="postoperative"
                  placeholder="Enter Name of Surgery:"
                />
              </div>
              <br />
              <p>Pain Score 0-10</p>
              <Slider
                min={0}
                max={10}
                dots
                marks={{
                  0: 0,
                  1: 1,
                  2: 2,
                  3: 3,
                  4: 4,
                  5: 5,
                  6: 6,
                  7: 7,
                  8: 8,
                  9: 9,
                  10: 10,
                }}
              />
              <br></br>
            </div>
          )}
          <br></br>
          <div className="form-group">
            <label for="Timetoincrease">Time to increase:</label>
            <textarea
              rows="3"
              type="text"
              value={TimeToIncrease}
              onChange={HandleTimeToIncrease}
              className="form-control"
              id="Timetoincrease"
              name="Timetoincrease"
              placeholder="Example 3 hours"
            />
          </div>
          <br></br>
          <div className="form-group">
            <label for="Timetostay">Time to stay:</label>
            <textarea
              rows="3"
              type="text"
              className="form-control"
              value={TimeToStay}
              onChange={HandleTimeToStay}
              id="Timetostay"
              name="Timetostay"
              placeholder="Example 1 hour"
            />
          </div>
          <br></br>
          <div className="form-group">
            <label for="Timetoreduce">Time to reduce:</label>
            <textarea
              rows="3"
              type="text"
              className="form-control"
              id="Timetoreduce"
              value={TimeToReduce}
              onChange={HandleTimeToReduce}
              name="Timetoreduce"
              placeholder="Example 2 hours"
            />
          </div>
          <br></br>
          <h4 style={{ fontWeight: "400" }}> Nature Of Pain</h4>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              on
              name="Pulling"
              value="Pulling"
              id="Pulling"
            />
            <label className="form-check-label" for="Pulling">
              Pulling
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Aching"
              name="Aching"
              id="Aching"
            />
            <label className="form-check-label" for="Aching">
              Aching
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Pins and Needles"
              name="Pins"
              id="Pins"
            />
            <label className="form-check-label" for="Pins">
              Pins and Needles
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Radiating"
              name="Radiating"
              id="Radiating"
            />
            <label className="form-check-label" for="Radiating">
              Radiating
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Pinching"
              name="Pinching"
              id="Pinching"
            />
            <label className="form-check-label" for="Pinching">
              Pinching
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Catch or hold"
              name="Catch"
              id="Catch"
            />
            <label className="form-check-label" for="Catch">
              Catch or hold
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Soreness"
              name="Soreness"
              id="Soreness"
            />
            <label className="form-check-label" for="Soreness">
              Soreness
            </label>
          </div>
          <br />
          <h4 style={{ fontWeight: "400", marginTop: "30px" }}>
            Depth of Injury
          </h4>
          <div className="form-check" style={{ marginTop: "0px" }}>
            <input
              onChange={HandleDepth}
              className="form-check-input"
              type="radio"
              name="Depth"
              value="Superficial"
              id="Superficial"
            />
            <label className="form-check-label" for="Superficial">
              Superficial
            </label>
            <input
              onChange={HandleDepth}
              classNameName="form-check-input"
              style={{ marginLeft: "20px" }}
              value="Deep"
              type="radio"
              name="Depth"
              id="Deep"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="Deep"
            >
              Deep
            </label>
            <input
              onChange={HandleDepth}
              classNameName="form-check-input"
              style={{ marginLeft: "20px" }}
              value="others"
              type="radio"
              name="Depth"
              id="others"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="others"
            >
              others
            </label>
            {Depth == "others" && (
              <div className="form-group" style={{ marginTop: "15px" }}>
                <br />
                <label for="Other">Other:</label>
                <textarea
                  rows="3"
                  className="form-control"
                  value={DepthOther}
                  onChange={HandleDepthOther}
                  id="Other"
                  name="DepthOther"
                  placeholder="Enter The Other Depth"
                />
              </div>
            )}
          </div>
          <br></br>
          <div className="form-group" style={{ marginTop: "15px" }}>
            <label for="Aggravating">Aggravating Causes:</label>
            <textarea
              rows="3"
              type="text"
              className="form-control"
              value={Aggravating}
              onChange={HandleAggravating}
              id="aggravating"
              name="Aggravating"
              placeholder="When It Aggravates Describe briefly"
            />
          </div>
          <br></br>
          <p>Relieving Factors:</p>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="Rest"
              value="Rest"
              id="Rest"
            />
            <label className="form-check-label" for="Rest">
              Rest
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Movement"
              name="Movement"
              id="Movement"
            />
            <label className="form-check-label" for="Movement">
              Movement
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Ice"
              name="Ice"
              id="Ice"
            />
            <label className="form-check-label" for="Ice">
              Ice
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Heat"
              name="Heat"
              id="Heat"
            />
            <label className="form-check-label" for="Heat">
              Heat
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Medication"
              name="Medication"
              id="Medication"
            />
            <label className="form-check-label" for="Medication">
              Medication
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Taping"
              name="Taping"
              id="Taping"
            />
            <label className="form-check-label" for="Taping">
              Taping
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Foam Rolling"
              name="Foam"
              id="Foam"
            />
            <label className="form-check-label" for="Foam">
              Foam Rolling
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Stretching"
              name="Stretching"
              id="Stretching"
            />
            <label className="form-check-label" for="Stretching">
              Stretching
            </label>
          </div>
          <br></br>
          <h4> POSTURAL ASSESSMENT</h4>

          <label for="Head">Head:</label>
          <div className="form-check" id="Head">
            <input
              onChange={HandleHead}
              value="Neutral"
              className="form-check-input"
              type="radio"
              name="Head"
              id="Neutral"
            />
            <label className="form-check-label" for="Neutral">
              Neutral
            </label>
            <input
              onChange={HandleHead}
              value="Forward"
              className="form-check-input"
              type="radio"
              name="Head"
              id="Forward"
            />
            <label className="form-check-label" for="Forward">
              Forward
            </label>
          </div>
          <br></br>

          <div>
            <label for="LShoulder">Left Shoulder:</label>
            <div className="form-check" id="LShoulder">
              <input
                onChange={HandleLshoulder}
                value="Neutral"
                className="form-check-input"
                type="radio"
                name="LShoulderNeutral"
                id="LShoulderNeutral"
              />
              <label className="form-check-label" for="LShoulderNeutral">
                Neutral
              </label>
              <br></br>
              <input
                onChange={HandleLshoulder}
                value="Elevated"
                className="form-check-input"
                type="radio"
                name="LShoulderNeutral"
                id="LShoulderElevated"
              />
              <label className="form-check-label" for="LShoulderElevated">
                Elevated
              </label>
              <br></br>
              <input
                onChange={HandleLshoulder}
                value="Depressed	Neutral"
                className="form-check-input"
                type="radio"
                name="LShoulderNeutral"
                id="LShoulderDepressed"
              />
              <label className="form-check-label" for="LShoulderDepressed">
                Depressed Neutral
              </label>
              <br></br>
              <input
                onChange={HandleLshoulder}
                value="Rounded"
                className="form-check-input"
                type="radio"
                name="LShoulderNeutral"
                id="LShoulderRounded"
              />
              <label className="form-check-label" for="LShoulderRounded">
                Rounded
              </label>
            </div>
          </div>

          <br></br>

          <div>
            <label for="RShoulder">Right Shoulder:</label>
            <div className="form-check" id="RShoulder">
              <input
                onChange={HandleRshoulder}
                value="Neutral"
                className="form-check-input"
                type="radio"
                name="RShoulderNeutral"
                id="RShoulderNeutral"
              />
              <label className="form-check-label" for="RShoulderNeutral">
                Neutral
              </label>
              <br></br>
              <input
                onChange={HandleRshoulder}
                value="Elevated"
                className="form-check-input"
                type="radio"
                name="RShoulderNeutral"
                id="RShoulderElevated"
              />
              <label className="form-check-label" for="RShoulderElevated">
                Elevated
              </label>
              <br></br>
              <input
                onChange={HandleRshoulder}
                value="Depressed	Neutral"
                className="form-check-input"
                type="radio"
                name="RShoulderNeutral"
                id="RShoulderDepressed"
              />
              <label className="form-check-label" for="RShoulderDepressed">
                Depressed Neutral
              </label>
              <br></br>
              <input
                onChange={HandleRshoulder}
                value="Rounded"
                className="form-check-input"
                type="radio"
                name="RShoulderNeutral"
                id="RShoulderRounded"
              />
              <label className="form-check-label" for="RShoulderRounded">
                Rounded
              </label>
            </div>
          </div>
          <br></br>
          <h4 style={{ fontWeight: "400" }}>Thoracic Spine</h4>
          <div className="form-check" id="Thoracic">
            <input
              className="form-check-input"
              type="radio"
              name="Neutral"
              value="Neutral"
              id="thorone"
              onChange={HandleThoracic}
            />
            <label className="form-check-label" for="thorone">
              Neutral
            </label>
            <input
              classNameName="form-check-input"
              value="Flattened"
              style={{ marginLeft: "20px" }}
              type="radio"
              name="Neutral"
              onChange={HandleThoracic}
              id="Flattened"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="Flattened"
            >
              Flattened
            </label>
            <input
              classNameName="form-check-input"
              style={{ marginLeft: "20px" }}
              type="radio"
              value="Kyphosis	Scoliosis"
              onChange={HandleThoracic}
              name="Neutral"
              id="Kyphosis"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="Kyphosis"
            >
              Kyphosis Scoliosis
            </label>
          </div>
          <br></br>
          <h4 style={{ fontWeight: "400" }}>Lumbar Spine</h4>
          <div className="form-check" id="Thoracic">
            <input
              className="form-check-input"
              type="radio"
              name="Increased"
              value="Neutral"
              id="lumone"
              onChange={HandleLumbar}
            />
            <label className="form-check-label" for="lumone">
              Neutral
            </label>
            <input
              classNameName="form-check-input"
              value="Increased"
              style={{ marginLeft: "20px" }}
              type="radio"
              name="Increased"
              onChange={HandleLumbar}
              id="Increased"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="Increased"
            >
              Increased
            </label>
            <input
              classNameName="form-check-input"
              style={{ marginLeft: "20px" }}
              type="radio"
              value="Decreased Lordosis"
              onChange={HandleLumbar}
              name="Increased"
              id="lumthree"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="lumthree"
            >
              Decreased Lordosis
            </label>
          </div>
          <br></br>
          <h4 style={{ fontWeight: "400" }}>Left Knee</h4>
          <div className="form-check" id="Thoracic">
            <input
              className="form-check-input"
              type="radio"
              name="knee"
              value="Neutral"
              id="kneeone"
              onChange={Handleknee}
            />
            <label className="form-check-label" for="kneeone">
              Neutral
            </label>
            <input
              classNameName="form-check-input"
              value="Varus"
              style={{ marginLeft: "20px" }}
              type="radio"
              name="knee"
              onChange={Handleknee}
              id="kneetwo"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="kneetwo"
            >
              Varus
            </label>
            <input
              classNameName="form-check-input"
              style={{ marginLeft: "20px" }}
              type="radio"
              value="Valgus"
              onChange={Handleknee}
              name="knee"
              id="kneethree"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="kneethree"
            >
              Valgus
            </label>
          </div>
          <br></br>
          <h4 style={{ fontWeight: "400" }}>Right Knee</h4>
          <div className="form-check" id="Thoracic">
            <input
              className="form-check-input"
              type="radio"
              name="kneer"
              value="Neutral"
              id="kneeoner"
              onChange={HandleRknee}
            />
            <label className="form-check-label" for="kneeoner">
              Neutral
            </label>
            <input
              classNameName="form-check-input"
              value="Varus"
              style={{ marginLeft: "20px" }}
              type="radio"
              name="kneer"
              onChange={HandleRknee}
              id="kneetwor"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="kneetwor"
            >
              Varus
            </label>
            <input
              classNameName="form-check-input"
              style={{ marginLeft: "20px" }}
              type="radio"
              value="Valgus"
              onChange={HandleRknee}
              name="kneer"
              id="kneethreer"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="kneethreer"
            >
              Valgus
            </label>
          </div>
          <br></br>
          <h4 style={{ fontWeight: "400" }}>Left Foot</h4>
          <div className="form-check" id="rfoot">
            <input
              className="form-check-input"
              type="radio"
              name="footl"
              value="Flat"
              id="footonel"
              onChange={HandleLfoot}
            />
            <label className="form-check-label" for="footonel">
              Flat
            </label>
            <input
              classNameName="form-check-input"
              value="Medial Arch"
              style={{ marginLeft: "20px" }}
              type="radio"
              name="footl"
              onChange={HandleLfoot}
              id="foottwol"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="foottwol"
            >
              Medial Arch
            </label>
            <input
              classNameName="form-check-input"
              style={{ marginLeft: "20px" }}
              type="radio"
              value="Pronated"
              onChange={HandleLfoot}
              name="footl"
              id="footthreel"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="footthreel"
            >
              Pronated
            </label>
          </div>

          <br></br>
          <h4 style={{ fontWeight: "400" }}>Right Foot</h4>
          <div className="form-check" id="rfoot">
            <input
              className="form-check-input"
              type="radio"
              name="footr"
              value="Flat"
              id="footoner"
              onChange={HandleRfoot}
            />
            <label className="form-check-label" for="footoner">
              Flat
            </label>
            <input
              classNameName="form-check-input"
              value="Medial Arch"
              style={{ marginLeft: "20px" }}
              type="radio"
              name="footr"
              onChange={HandleRfoot}
              id="foottwor"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="foottwor"
            >
              Medial Arch
            </label>
            <input
              classNameName="form-check-input"
              style={{ marginLeft: "20px" }}
              type="radio"
              value="Pronated"
              onChange={HandleRfoot}
              name="footr"
              id="footthreer"
            />
            <label
              className="form-check-label"
              style={{ marginLeft: "6px" }}
              for="footthreer"
            >
              Pronated
            </label>
          </div>
          <br></br>
          <div className="form-group">
            <label for="footindex">Foot Posture Index:</label>
            <textarea
              rows="3"
              onChange={HandleFootIndex}
              value={FootIndex}
              type="text"
              className="form-control"
              id="footindex"
              name="footindex"
              placeholder="Enter Foot Posture Index: Example 5"
            />
          </div>

          <br></br>
          <img src={direction} />
          <br></br>

          <h1>Posterior</h1>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              on
              name="Posteriorc"
              value="posterior"
              id="Posterior"
            />
            <label className="form-check-label" for="Posterior">
              Posterior
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Posteromedial"
              name="Posteromedialc"
              id="Posteromedial"
            />
            <label className="form-check-label" for="Posteromedial">
              Posteromedial
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Medial"
              name="Medialc"
              id="Medial"
            />
            <label className="form-check-label" for="Medial">
              Medial
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Anteromedial"
              name="Anteromedialc"
              id="Anteromedial"
            />
            <label className="form-check-label" for="Anteromedial">
              Anteromedial
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Anterior"
              name="Anteriorc"
              id="Anterior"
            />
            <label className="form-check-label" for="Anterior">
              Anterior
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Anterolateral"
              name="Anterolateralc"
              id="Anterolateral"
            />
            <label className="form-check-label" for="Anterolateral">
              Anterolateral
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Posterolateral"
              name="Posterolateralc"
              id="Posterolateral"
            />
            <label className="form-check-label" for="Posterolateral">
              Posterolateral
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value="Lateral"
              name="Lateralc"
              id="Lateral"
            />
            <label className="form-check-label" for="Lateral">
              Lateral
            </label>
          </div>
          <br></br>
          <h1>Upper Quarter Tests: </h1>
          <div className="form-group">
            <label for="abduction">
              Internal Rotation at 90° Abduction in Supine (&gt;90):
            </label>
            <textarea
              rows="3"
              onChange={HandleAbduction}
              value={Abduction}
              type="text"
              className="form-control"
              id="abduction"
              name="abduction"
              placeholder="Enter Value"
            />
          </div>
          <div className="form-group">
            <label for="exrotation">
              External Rotation at 90° Abduction in Supine(&gt;90):
            </label>
            <textarea
              rows="3"
              onChange={HandleExrotation}
              value={Exrotation}
              type="text"
              className="form-control"
              id="exrotation"
              name="exrotation"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="footindex">
              Glenohumeral Internal Rotation Deficit (GIRD):
            </label>
            <textarea
              rows="3"
              onChange={HandleGlenohumeral}
              value={Glenohumeral}
              type="text"
              className="form-control"
              id="glenohumeral"
              name="glenohumeral"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="rotationgain"></label>
            External Rotation Gain (ERG) +’ve when GIRD &gt; Ratio – Dominant
            Arm only :{" "}
            <textarea
              rows="3"
              onChange={HandleRotationgain}
              value={Rotationgain}
              type="text"
              className="form-control"
              id="rotationgain"
              name="rotationgain"
              placeholder="Enter value"
            />
          </div>

          <label for="impingement">H-K Impingement Test:</label>
          <div className="form-check" id="impingement">
            <input
              onChange={HandleImpingement}
              value="Positive"
              className="form-check-input"
              type="radio"
              name="impingement"
              id="impingement"
            />
            <label className="form-check-label" for="impingementone">
              Positive
            </label>
            <input
              onChange={HandleImpingement}
              value="Negative"
              className="form-check-input"
              type="radio"
              name="impingement"
              id="impingement2"
            />
            <label className="form-check-label" for="impingement2">
              Negative
            </label>
          </div>
          <br></br>
          <div className="form-group">
            <label for="footindex">Full Can Test:</label>
            <textarea
              rows="3"
              onChange={HandleFullcan}
              value={Fullcan}
              type="text"
              className="form-control"
              id="fullcan"
              name="fullcan"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="footindex">Infraspinatus Strength Test :</label>
            <textarea
              rows="3"
              onChange={HandleInfraspinatus}
              value={Infraspinatus}
              type="text"
              className="form-control"
              id="infraspinatus"
              name="infraspinatus"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="footindex">Kibler Lateral Slide Test :</label>
            <textarea
              rows="3"
              onChange={HandleKibler}
              value={Kibler}
              type="text"
              className="form-control"
              id="kibler"
              name="Kibler"
              placeholder="Enter Value"
            />
          </div>
          <div className="form-group">
            <label for="positionone">Position 1 (≤ 1.5cms +/-) :</label>
            <textarea
              rows="3"
              onChange={HandlePositionone}
              value={Positionone}
              type="text"
              className="form-control"
              id="positionone"
              name="positionone"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="positiontwo">Position 2 (≤ 1.5cms +/-) :</label>
            <textarea
              rows="3"
              onChange={HandlePositiontwo}
              value={Positiontwo}
              type="text"
              className="form-control"
              id="positiontwo"
              name="positiontwo"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="positionthree">Position 3 (≤ 1.5cms +/-) :</label>
            <textarea
              rows="3"
              onChange={HandlePositionthree}
              value={Positionthree}
              type="text"
              className="form-control"
              id="positionthree"
              name="positionthree"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="scapular">SSMP/Scapular Balancing Index :</label>
            <textarea
              rows="3"
              onChange={HandleScapular}
              value={Scapular}
              type="text"
              className="form-control"
              id="scapular"
              name="scapular"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="adduction">
              Horizontal adduction test/ dugas sign - :
            </label>
            <textarea
              rows="3"
              onChange={HandleAdduction}
              value={Adduction}
              type="text"
              className="form-control"
              id="adduction"
              name="adduction"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="kims">Kims :</label>
            <textarea
              rows="3"
              onChange={HandleKims}
              value={Kims}
              type="text"
              className="form-control"
              id="kims"
              name="kims"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="biceps">Biceps load 1 and 2 :</label>
            <textarea
              rows="3"
              onChange={HandleBiceps}
              value={Biceps}
              type="text"
              className="form-control"
              id="biceps"
              name="biceps"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="brien">O’brien test :</label>
            <textarea
              rows="3"
              onChange={HandleBrien}
              value={Brien}
              type="text"
              className="form-control"
              id="brien"
              name="brien"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="ckuest">CKUEST :</label>
            <textarea
              rows="3"
              onChange={HandleCkuest}
              value={Ckuest}
              type="text"
              className="form-control"
              id="ckuest"
              name="ckuest"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="balance">Y-balance :</label>
            <textarea
              rows="3"
              onChange={HandleBalance}
              value={Balance}
              type="text"
              className="form-control"
              id="balance"
              name="balance"
              placeholder="Enter value"
            />
          </div>
          <br></br>

          <label for="quadranttest">Quadrant Test:</label>
          <div className="form-check" id="impingement">
            <input
              onChange={HandleQuadranttest}
              value="Positive"
              className="form-check-input"
              type="radio"
              name="quadranttest"
              id="quadranttest"
            />
            <label className="form-check-label" for="quadranttestone">
              Positive
            </label>
            <input
              onChange={HandleQuadranttest}
              value="Negative"
              className="form-check-input"
              type="radio"
              name="quadranttest"
              id="quadranttest2"
            />
            <label className="form-check-label" for="impingement2">
              Negative
            </label>
          </div>
          <br></br>

          <label for="trunk">Trunk Side Flexion ROM:</label>
          <div className="form-check" id="trunk">
            <input
              onChange={HandleTrunk}
              value="+≤ 3.0cms"
              className="form-check-input"
              type="radio"
              name="trunk"
              id="trunk"
            />
            <label className="form-check-label" for="trunkone">
              +≤ 3.0cms
            </label>
            <input
              onChange={HandleTrunk}
              value="2≤ 3.0cms"
              className="form-check-input"
              type="radio"
              name="trunk "
              id="trunk2"
            />
            <label className="form-check-label" for="trunk2">
              -≤ 3.0cms
            </label>
          </div>
          <br></br>

          <label for="lumbarspine">Lumbar Spine ROM:</label>
          <div className="form-check" id="lumbarspine">
            <input
              onChange={HandleLumbarspine}
              value=">+5.0cms"
              className="form-check-input"
              type="radio"
              name="lumbarspine"
              id="lumbarspine"
            />
            <label className="form-check-label" for="lumbarspineone">
              &gt; +5.0cms
            </label>
            <input
              onChange={HandleLumbarspine}
              value=">-2.0cms"
              className="form-check-input"
              type="radio"
              name="lumbarspine"
              id="lumbarspine2"
            />
            <label className="form-check-label" for="lumbarspine2">
              &gt; -2.0cms
            </label>
          </div>
          <br></br>
          <div className="form-group">
            <label for="notes">NOTES :</label>
            <textarea
              rows="3"
              onChange={HandleNotes}
              value={Notes}
              type="text"
              className="form-control"
              id="notes"
              name="notes"
              placeholder="Enter Value"
            />
          </div>

          <h1>TEST LOWER Quarter: </h1>
          <div className="form-group">
            <label for="hamstring">Hamstring Length at 90/90 - (≥ 160):</label>
            <textarea
              rows="3"
              onChange={HandleHamstring}
              value={Hamstring}
              type="text"
              className="form-control"
              id="hamstring"
              name="hamstring"
              placeholder="Enter Value"
            />
          </div>
          <div className="form-group">
            <label for="iliopsoas">Iliopsoas Muscle Length (≤ 5):</label>
            <textarea
              rows="3"
              onChange={HandleIliopsoas}
              value={Iliopsoas}
              type="text"
              className="form-control"
              id="iliopsoas"
              name="iliopsoas"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="femoris">Rectus Femoris Muscle Length (≥ 80):</label>
            <textarea
              rows="3"
              onChange={HandleFemoris}
              value={Femoris}
              type="text"
              className="form-control"
              id="femoris"
              name="femoris"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="internalhip">
              Hip Internal Rotation – Prone (≥ 30) :
            </label>
            <textarea
              rows="3"
              onChange={HandleInternalhip}
              value={Internalhip}
              type="text"
              className="form-control"
              id="internalhip"
              name="internalhip"
              placeholder="Enter value"
            />
          </div>

          <br></br>
          <div className="form-group">
            <label for="externalhip">
              Hip External Rotation – Prone (≥ 45):
            </label>
            <textarea
              rows="3"
              onChange={HandleExternalhip}
              value={Externalhip}
              type="text"
              className="form-control"
              id="externalhip"
              name="externalhip"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="quadranthip">Hip Quadrant Test :</label>
            <textarea
              rows="3"
              onChange={HandleQuadranthip}
              value={Quadranthip}
              type="text"
              className="form-control"
              id="quadranthip"
              name="quadranthip"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="piriformis">Piriformis Muscle Length (&gt; 10) :</label>
            <textarea
              rows="3"
              onChange={HandlePiriformis}
              value={Piriformis}
              type="text"
              className="form-control"
              id="piriformis"
              name="piriformis"
              placeholder="Enter Value"
            />
          </div>
          <div className="form-group">
            <label for="lunge">DF Lunge Test (≥ 10 cms):</label>
            <textarea
              rows="3"
              onChange={HandleLunge}
              value={Lunge}
              type="text"
              className="form-control"
              id="lunge"
              name="lunge"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="impingementp">Posterior Impingement Test :</label>
            <textarea
              rows="3"
              onChange={HandleImpingementp}
              value={Impingementp}
              type="text"
              className="form-control"
              id="impingementp"
              name="impingementp"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="singleleg">
              Single leg standing balance (eyes open/closed):
            </label>
            <textarea
              rows="3"
              onChange={HandleSingleleg}
              value={Singleleg}
              type="text"
              className="form-control"
              id="singleleg"
              name="singleleg"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="squat">OH Deep Squat:</label>
            <textarea
              rows="3"
              onChange={HandleSquat}
              value={Squat}
              type="text"
              className="form-control"
              id="squat"
              name="squat"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="singlelegsquat">Single Leg Squat :</label>
            <textarea
              rows="3"
              onChange={HandleSinglelegsquat}
              value={Singlelegsquat}
              type="text"
              className="form-control"
              id="singlelegsquat"
              name="singlelegsquat"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="stability">
              Core stability push up/Core stability score:
            </label>
            <textarea
              rows="3"
              onChange={HandleStability}
              value={Stability}
              type="text"
              className="form-control"
              id="stability"
              name="stability"
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label for="ottawa">Ottawa Ankle Rule:</label>
            <textarea
              rows="3"
              onChange={HandleOttawa}
              value={Ottawa}
              type="text"
              className="form-control"
              id="ottawa"
              name="ottawa"
              placeholder="Enter value"
            />
          </div>
          <br />
          <label for="impingement">SLUMP :</label>
          <div className="form-check" id="SLUMP">
            <input
              onChange={HandleSLUMP}
              value="Positive"
              className="form-check-input"
              type="radio"
              name="SLUMP"
              id="SLUMPone"
            />
            <label className="form-check-label" for="SLUMPone">
              Positive
            </label>
            <input
              onChange={HandleSLUMP}
              value="Negative"
              className="form-check-input"
              type="radio"
              name="SLUMP"
              id="SLUMP2"
            />
            <label className="form-check-label" for="SLUMP2">
              Negative
            </label>
          </div>
          <br></br>

          <div className="form-group">
            <label for="SLRs">SLR:</label>
            <textarea
              rows="3"
              onChange={HandleSLR}
              value={SLR}
              type="text"
              className="form-control"
              id="SLRs"
              name="SLR"
              placeholder="Enter value"
            />
          </div>
          <br />

          <label for="ULTTs">ULTTs :</label>
          <div className="form-check" id="ULTTs">
            <input
              onChange={HandleULTTs}
              value="Positive"
              className="form-check-input"
              type="radio"
              name="ULTTs"
              id="ULTTsone"
            />
            <label className="form-check-label" for="ULTTsone">
              Positive
            </label>
            <input
              onChange={HandleULTTs}
              value="Negative"
              className="form-check-input"
              type="radio"
              name="ULTTs"
              id="ULTTs2"
            />
            <label className="form-check-label" for="ULTTs2">
              Negative
            </label>
          </div>
          <br></br>
          <h1> Side Plank</h1>
          <div className="form-group">
            <label for="sustained">Time position sustained: </label>
            <textarea
              rows="3"
              onChange={HandleSustaineds}
              value={Sustaineds}
              type="text"
              className="form-control"
              id="sustained"
              name="sustained"
              placeholder="Enter value"
            />
          </div>
          <br />
          <br></br>

          <div className="form-group">
            <label for="dipping">dipping:</label>
            <textarea
              rows="3"
              onChange={HandleDippings}
              value={Dippings}
              type="text"
              className="form-control"
              id="dipping"
              name="dipping"
              placeholder="Enter value"
            />
          </div>
          <br />
          <br></br>

          <div className="form-group">
            <label for="cessation">Reason for cessation:</label>
            <textarea
              rows="3"
              onChange={HandleCessations}
              value={Cessations}
              type="text"
              className="form-control"
              id="cessation"
              name="cessation"
              placeholder="Enter value"
            />
          </div>
          <br></br>
          <h1> Prone Plank</h1>
          <div className="form-group">
            <label for="sustainedp">Time position sustained: </label>
            <textarea
              rows="3"
              onChange={HandleSustainedp}
              value={Sustainedp}
              type="text"
              className="form-control"
              id="sustainedp"
              name="sustainedp"
              placeholder="Enter value"
            />
          </div>
          <br />
          <br></br>

          <div className="form-group">
            <label for="dippingp">dipping:</label>
            <textarea
              rows="3"
              onChange={HandleDippingp}
              value={Dippingp}
              type="text"
              className="form-control"
              id="dippingp"
              name="dippingp"
              placeholder="Enter value"
            />
          </div>
          <br />
          <br></br>

          <div className="form-group">
            <label for="cessationp">Reason for cessation:</label>
            <textarea
              rows="3"
              onChange={HandleCessationp}
              value={Cessationp}
              type="text"
              className="form-control"
              id="cessationp"
              name="cessationp"
              placeholder="Enter value"
            />
          </div>
          <br />
          <br></br>
          <h1> Single Leg Glute/Hamstring Bridging </h1>
          <div className="form-group">
            <label for="sustainedg">Time position sustained: </label>
            <textarea
              rows="3"
              onChange={HandleSustainedg}
              value={Sustainedg}
              type="text"
              className="form-control"
              id="sustainedg"
              name="sustainedg"
              placeholder="Enter value"
            />
          </div>
          <br />
          <br></br>

          <div className="form-group">
            <label for="dippingg">dipping:</label>
            <textarea
              rows="3"
              onChange={HandleDippingg}
              value={Dippingg}
              type="text"
              className="form-control"
              id="dippingg"
              name="dippingg"
              placeholder="Enter value"
            />
          </div>
          <br />
          <br></br>

          <div className="form-group">
            <label for="cessationg">Reason for cessation:</label>
            <textarea
              rows="3"
              onChange={HandleCessationg}
              value={Cessationg}
              type="text"
              className="form-control"
              id="cessationg"
              name="cessationg"
              placeholder="Enter value"
            />
          </div>
          <br />
          <p style={{ textAlign: "center" }} className="mt-5">
            <input
              style={{ height: "50px" }}
              className="login__button btn-lg"
              type="submit"
              value="submit"
            />
          </p>
        </form>
      </div>

      {/* {ViewForm == true && (
        <div id="FormContainer" id="physiotherapyform">
          {userType == "athlete" ? <Header /> : <Header Id={temperoryId} />}

          {/* <p style={{ textAlign: "center" }}>
             <h1 className="mt-4 mb-4"> Your Physiotherapy Report </h1>
            
          </p> */}
      {/* <form style={{ marginTop: 20, paddingLeft: "1%" }}>
            {userType != "athlete" && (
              <p style={{ textAlign: "end" }}>
                <button
                  className="login__button mt-4 mb-4"
                  style={{ width: "100px" }}
                  onClick={toggleviewform}
                >
                  {ViewForm == false && <span>View Form</span>}
                  {ViewForm == true && <span>Edit Form</span>}
                </button>
              </p>
            )}
            <div className="form-group">
              <label>Sport Played/Recreational Activity:</label>
              <textarea
                readOnly
                rows="3"
                value={Sport}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Physical Activity level:</label>
              <textarea
                rows="3"
                readOnly
                value={Proficiency}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Hand/Foot Dominance:</label>
              <textarea
                rows="3"
                readOnly
                value={Limb}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Uploaded Image:</label>
              <textarea
                rows="3"
                readOnly
                value={File}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Injury History:</label>
              <textarea
                rows="3"
                readOnly
                value={InjuryHistory}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            {InjuryHistory == "Yes" ||
              (InjuryHistory == "yes" && (
                <div className="form-group">
                  <label>Injury Name:</label>
                  <textarea
                    rows="3"
                    readOnly
                    value={Diagnosis}
                    type="text"
                    className="form-control"
                    id="sport"
                    name="sport"
                  />
                  <br></br>
                </div>
              ))}
                          <h3>Injuries:</h3>

            <div className="form-group">
              <label>Current Complaints:</label>
              <textarea
                rows="3"
                readOnly
                value={Complaints}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Current Illnesses:</label>
              <textarea
                rows="3"
                readOnly
                value={CurrentIllness}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Past Injuries:</label>
              <textarea
                rows="3"
                readOnly
                value={PastInjuries}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Past Illnesses:</label>
              <textarea
                rows="3"
                readOnly
                value={PastIllness}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Medical Conditions:</label>
              <textarea
                rows="3"
                readOnly
                value={Medical}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label for="CurrentMedication">Current Medications:</label>
              <textarea
                rows="3"
                type="text"
                className="form-control"
                id="CurrentMedication"
                value={CurrentMedications}
                name="sport"
                id="sport"
              />
            </div>
            <br></br>
            
            <div className="form-group">
              <label>Past Surgery:</label>
              <textarea
                rows="3"
                readOnly
                value={PastSurgery}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Allergies:</label>
              <textarea
                rows="3"
                readOnly
                value={Allergies}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Orthotics:</label>
              <textarea
                rows="3"
                readOnly
                value={Orthotics}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <h3>Psychosocial:</h3>
            <p style={{fontSize:'18px',fontWeight:'500'}}>Your Sleep Score</p>
            <Slider
              min={1}
              max={5}
              dots
              marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
              disabled
              value={Sleep}
            />
            <br></br>
            <br></br>
            <p style={{fontSize:'18px',fontWeight:'500'}}>Your Work Pattern Score</p>
            <Slider
              min={1}
              max={5}
              dots
              marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
              disabled
              value={WorkPressure}
            />
            <br></br>
            <br></br>
            <p style={{fontSize:'18px',fontWeight:'500'}}>Your Stress and Anxiety Score</p>
            <Slider
              min={1}
              max={5}
              dots
              marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5 }}
              disabled
              value={Anxiety}
            />
            <br></br>
            <br />
            <h3>Areas Of Pain:</h3>
            <div className="form-group">
              <label>Timing of Pain:</label>
              <textarea
                rows="3"
                readOnly
                value={Timing}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Sequence of Pain:</label>
              <textarea
                rows="3"
                readOnly
                value={Sequence}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Mechanism of Injury:</label>
              <textarea
                rows="3"
                readOnly
                value={Mechanism}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            {Mechanism == "atraumatic" ||
              (Mechanism == "traumatic" && (
                <>
                  <div className="form-group">
                    <label>Type of {Mechanism}:</label>
                    <textarea
                      rows="3"
                      readOnly
                      value={MechanismReason}
                      type="text"
                      className="form-control"
                      id="sport"
                      name="sport"
                    />
                  </div>

                  {MechanismReason == "other" ||
                    (MechanismReason == "others" && (
                      <div className="form-group">
                        <label> Other Type Of {Mechanism}:</label>
                        <textarea
                          rows="3"
                          readOnly
                          value={MechanismOther}
                          type="text"
                          className="form-control"
                          id="sport"
                          name="sport"
                        />
                      </div>
                    ))}
                </>
              ))}
            {Mechanism == "Post Operative" && (
              <div>
                <div className="form-group">
                  <label>{Mechanism} Surgery Name:</label>
                  <textarea
                    rows="3"
                    readOnly
                    value={PostOperative}
                    type="text"
                    className="form-control"
                    id="sport"
                    name="sport"
                  />
                </div>
                <br />
                <p>Pain Score:</p>
                <Slider
                  min={0}
                  max={10}
                  dots
                  marks={{
                    0: 0,
                    1: 1,
                    2: 2,
                    3: 3,
                    4: 4,
                    5: 5,
                    6: 6,
                    7: 7,
                    8: 8,
                    9: 9,
                    10: 10,
                  }}
                  value={PainScore}
                  disabled
                />
                <br></br>
              </div>
            )}
            <br></br>
            <div className="form-group">
              <label>Sequence of Pain:</label>
              <textarea
                rows="3"
                readOnly
                value={Sequence}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <h3>Irritability:</h3>
            <div className="form-group">
              <label>Time to Increase:</label>
              <textarea
                rows="3"
                readOnly
                value={TimeToIncrease}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Time to Stay:</label>
              <textarea
                rows="3"
                readOnly
                value={TimeToStay}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Time to Reduce:</label>
              <textarea
                rows="3"
                readOnly
                value={TimeToReduce}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Nature of Pain:</label>
              <textarea
                rows="3"
                readOnly
                value={Nature}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Aggravating Causes:</label>
              <textarea
                rows="3"
                readOnly
                value={Aggravating}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>{" "}
            <div className="form-group">
              <label>Relieving Causes:</label>
              <textarea
                rows="3"
                readOnly
                value={Relieving}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>{" "}
            <h3>Observation Postural Assessment</h3>

            <div className="form-group">
              <label>Head Posture:</label>
              <textarea
                rows="3"
                readOnly
                value={Head}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>{" "}
            <div className="form-group">
              <label>Left Shoulder Posture:</label>
              <textarea
                rows="3"
                readOnly
                value={LShoulder}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>{" "}
            <div className="form-group">
              <label>Right Shoulder Posture:</label>
              <textarea
                rows="3"
                readOnly
                value={RShoulder}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>{" "}
            <div className="form-group">
              <label>Thoracic Spine Posture:</label>
              <textarea
                rows="3"
                readOnly
                value={Thoracic}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>{" "}
            <div className="form-group">
              <label>Lumbar Spine Posture:</label>
              <textarea
                rows="3"
                readOnly
                value={Lumbar}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>{" "}
            <div className="form-group">
              <label>Left Knee Posture:</label>
              <textarea
                rows="3"
                readOnly
                value={Knee}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>{" "}
            <div className="form-group">
              <label>Right Knee Posture:</label>
              <textarea
                rows="3"
                readOnly
                value={Rknee}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>{" "}
            <div className="form-group">
              <label>Right Foot Posture:</label>
              <textarea
                rows="3"
                readOnly
                value={Rfoot}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>{" "}
            <div className="form-group">
              <label>Left Foot Posture:</label>
              <textarea
                rows="3"
                readOnly
                value={Lfoot}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>{" "}
            <div className="form-group">
              <label>Foot Index:</label>
              <textarea
                rows="3"
                readOnly
                value={FootIndex}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <h1>Upper Quarter Tests: </h1>
            <div className="form-group">
              <label>
                Internal Rotation at 90° Abduction in Supine (&gt;90):
              </label>
              <textarea
                rows="3"
                readOnly
                value={Abduction}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>
                External Rotation at 90° Abduction in Supine(&gt;90):
              </label>
              <textarea
                rows="3"
                readOnly
                value={Exrotation}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Glenohumeral Internal Rotation Deficit (GIRD):</label>
              <textarea
                rows="3"
                readOnly
                value={Glenohumeral}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>
                External Rotation Gain (ERG) +’ve when GIRD &gt; Ratio –
                Dominant Arm only :
              </label>
              <textarea
                rows="3"
                readOnly
                value={Rotationgain}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>H-K Impingement Test:</label>
              <textarea
                rows="3"
                readOnly
                value={Impingement}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Full Can Test:</label>
              <textarea
                rows="3"
                readOnly
                value={Fullcan}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Infraspinatus Strength Test :</label>
              <textarea
                rows="3"
                readOnly
                value={Infraspinatus}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Kibler Lateral Slide Test :</label>
              <textarea
                rows="3"
                readOnly
                value={Kibler}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Position 1 (≤ 1.5cms +/-) :</label>
              <textarea
                rows="3"
                readOnly
                value={Positionone}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Position 2 (≤ 1.5cms +/-) :</label>
              <textarea
                rows="3"
                readOnly
                value={Positiontwo}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Position 3 (≤ 1.5cms +/-) :</label>
              <textarea
                rows="3"
                readOnly
                value={Positionthree}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>SSMP/Scapular Balancing Index :</label>
              <textarea
                rows="3"
                readOnly
                value={Scapular}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Horizontal adduction test/ dugas sign - :</label>
              <textarea
                rows="3"
                readOnly
                value={Adduction}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Kims :</label>
              <textarea
                rows="3"
                readOnly
                value={Kims}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Biceps load 1 and 2 :</label>
              <textarea
                rows="3"
                readOnly
                value={Biceps}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>O’brien test :</label>
              <textarea
                rows="3"
                readOnly
                value={Brien}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>CKUEST :</label>
              <textarea
                rows="3"
                readOnly
                value={Ckuest}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Y-balance :</label>
              <textarea
                rows="3"
                readOnly
                value={Balance}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <h3>TEST SPINE</h3>
            <div className="form-group">
              <label>Quadrant Test:</label>
              <textarea
                rows="3"
                readOnly
                value={Quadranttest}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Trunk Side Flexion ROM:</label>
              <textarea
                rows="3"
                readOnly
                value={Trunk}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Lumbar Spine ROM:</label>
              <textarea
                rows="3"
                readOnly
                value={Lumbarspine}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Notes : </label>
              <textarea
                rows="3"
                readOnly
                value={Notes}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <h1>TEST LOWER Quarter: </h1>
            <br></br>
            <div className="form-group">
              <label>Hamstring Length at 90/90 - (≥ 160):</label>
              <textarea
                rows="3"
                readOnly
                value={Hamstring}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Iliopsoas Muscle Length (≤ 5):</label>
              <textarea
                rows="3"
                readOnly
                value={Iliopsoas}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Rectus Femoris Muscle Length (≥ 80):</label>
              <textarea
                rows="3"
                readOnly
                value={Femoris}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Hip Internal Rotation – Prone (≥ 30) </label>
              <textarea
                rows="3"
                readOnly
                value={Internalhip}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Hip External Rotation – Prone (≥ 45)</label>
              <textarea
                rows="3"
                readOnly
                value={Externalhip}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Hip Quadrant Test :</label>
              <textarea
                rows="3"
                readOnly
                value={Quadranthip}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Piriformis Muscle Length (&gt; 10) :</label>
              <textarea
                rows="3"
                readOnly
                value={Piriformis}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>DF Lunge Test (≥ 10 cms):</label>
              <textarea
                rows="3"
                readOnly
                value={Lunge}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Posterior Impingement Test :</label>
              <textarea
                rows="3"
                readOnly
                value={Impingementp}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Single leg standing balance (eyes open/closed):</label>
              <textarea
                rows="3"
                readOnly
                value={Singleleg}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>OH Deep Squat:</label>
              <textarea
                rows="3"
                readOnly
                value={Squat}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Single Leg Squat :</label>
              <textarea
                rows="3"
                readOnly
                value={Singlelegsquat}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Core stability push up/Core stability score:</label>
              <textarea
                rows="3"
                readOnly
                value={Stability}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <div className="form-group">
              <label>Ottawa Ankle Rule:</label>
              <textarea
                rows="3"
                readOnly
                value={Ottawa}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <h1>Neural Testing</h1>
            <br />
            <div className="form-group">
              <label>SLUMP:</label>
              <textarea
                rows="3"
                readOnly
                value={SLUMP}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br />
            <div className="form-group">
              <label>SLR:</label>
              <textarea
                rows="3"
                readOnly
                value={SLR}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>ULTTs :</label>
              <textarea
                rows="3"
                readOnly
                value={ULTTs}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <h1>Side PLank : </h1>
            <div className="form-group">
              <label>Time position sustained :</label>
              <textarea
                rows="3"
                readOnly
                value={Sustaineds}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>Lumbar dipping :</label>
              <textarea
                rows="3"
                readOnly
                value={Dippings}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>Reason for cessation :</label>
              <textarea
                rows="3"
                readOnly
                value={Cessations}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <h1>Prone PLank : </h1>
            <div className="form-group">
              <label>Time position sustained :</label>
              <textarea
                rows="3"
                readOnly
                value={Sustainedp}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>Lumbar dipping :</label>
              <textarea
                rows="3"
                readOnly
                value={Dippingp}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>Reason for cessation :</label>
              <textarea
                rows="3"
                readOnly
                value={Cessationp}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <br></br>
            <h1>Single Leg Glute/Hamstring Bridging : </h1>
            <div className="form-group">
              <label>Time position sustained :</label>
              <textarea
                rows="3"
                readOnly
                value={Sustainedg}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>Lumbar dipping :</label>
              <textarea
                rows="3"
                readOnly
                value={Dippingg}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
            <div className="form-group">
              <label>Reason for cessation :</label>
              <textarea
                rows="3"
                readOnly
                value={Cessationg}
                type="text"
                className="form-control"
                id="sport"
                name="sport"
              />
            </div>
          </form> */}
      {/* </div>
      )} */}
    </>
  );
}
export default StairsForm;
