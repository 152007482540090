import React from "react";
import AthleteAssessment from "./AthleteAssessment";
import "./Profile.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectTemperoryId } from "../../features/userSlice";
function AthleteAssessments() {
  const history = useHistory();
  let temperoryId = useSelector(selectTemperoryId);
  return (
    <div
      id="athleteAssessments"
      style={{ marginLeft: "10px" }}
      className="athleteAssessments" 
    >
      <h2>Assessments and Measurements</h2>
      <AthleteAssessment
        name="Anthropometric Measurements"
        path="anthropometric-measurements"
      />
      <AthleteAssessment name="Medical Assessment" path="medical-assessment" />
      <AthleteAssessment
        name="Training Assessment"
        path="training-assessment"
      />
      <AthleteAssessment
        name="Food and Lifestyle Assessment"
        path="food-and-lifestyle-assessment"
      />
      <div
        className="viewReport"
        onClick={() =>
          history.push({
            pathname: "/athlete-add-nutrition",
            state: {
              id: temperoryId,
            },
          })
        }
      >
        <div className="viewReport__button">Nutrition Details</div>
        <img src="/assets/white_right.png" alt="" />
      </div>

      <div
        className="viewReport"
        onClick={() =>
          history.push({
            pathname: "/athlete-history/",
            state: {
              id: temperoryId,
            },
          })
        }
      >
        <div className="viewReport__button">View Athlete History</div>
        <img src="/assets/white_right.png" alt="" />
      </div>

      <div
        className="viewReport"
        onClick={() => {
          // window.open("/Athlete/reports/" + Id, "");
          console.log(1);
          history.push({
            pathname: "/Previous_Physiotherapy_Assessments",
            state: {
              AthleteId: temperoryId,
            },
          });
        }}
      >
        <div className="viewReport__button">Physiotherapy Assessment</div>
        <img src="/assets/white_right.png" alt="" />
      </div>
      <div className="viewReport" onClick={() => history.push("/reports")}>
        <div className="viewReport__button">View Report</div>
        <img src="/assets/white_right.png" alt="" />
      </div>
    </div>
  );
}

export default AthleteAssessments;
