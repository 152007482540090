import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserData, selectUserType } from "../../features/userSlice";
import { db } from "../../utils/firebase";
import NutritionScreenHeader from "./NutritionScreenHeader";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import NoSsr from "@material-ui/core/NoSsr";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import sendPushNotification from "../../utils/sendPushNotification";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import formatSpecificDate from "../../functions/formatSpecificDate";
import { formatDate } from "../../functions/formatDate";
import incr_date from "../../functions/incr_date";
import formatSpecificDate1 from "../../functions/formatSpecificDate1";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { useHistory, useLocation } from "react-router";
import firebase from "firebase";
import "./CoachNutrition.css";
import AddFoodCard from "./AddFoodCard";
import { Grid } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  margin-left: 4%;
  margin-top: 20px;
`;

const InputWrapper = styled("div")`
  width: 350px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 4%;

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;

  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled("ul")`
  width: 350px;
  margin: 2px 0 0;
  margin-left: 2.1%;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 150px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected="true"] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus="true"] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

function CreateNutrition() {
  const [options, setoptions] = useState(null);
  const [show_data, setshow_data] = useState([]);
  const classes = useStyles();
  const userData = useSelector(selectUserData);
  const userType = useSelector(selectUserType);
  const [nutritionId, setNutritionId] = useState("");
  const [nutritionName, setNutritionName] = useState("");
  const [plan, setPlan] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const [currentStartWeek, setCurrentStartWeek] = useState(null);
  const [currentEndWeek, setCurrentEndWeek] = useState(null);
  const [Nutrition, setNutrition] = useState(null);
  const [daysList, setDaysList] = useState([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ]);
  const [entireFood, setEntireFood] = useState([
    {
      meal: "",
      description: "",
      food: [
        {
          foodName: "",
          proteins: 0,
          carbs: 0,
          fat: 0,
          calories: 0,
          quantity: 1,
        },
      ],
      addFood: false,
    },
  ]);

  //const [nutrition, setNutrition] = useState(null);

  const [serverData, setServerData] = useState([]);
  const [addFood, setAddFood] = useState(false);
  const [selectedAthletes1, setSelectedAthletes1] = useState([]);
  const [athlete_selecteddays, setathlete_selecteddays] = useState({});
  const [specificDates, setSpecificDates] = useState([]);
  const [athlete_dates, setathlete_dates] = useState({});
  const [type, setType] = useState("");
  const location = useLocation();
  const history = useHistory();
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: athletes,
    getOptionLabel: (option) => option.name,
  });

  const [comments, setComments] = useState("");

  useEffect(() => {
    if (currentStartWeek) {
      let temp = currentStartWeek;
      let datesCollection = [];

      for (var i = 0; i < 7; i++) {
        datesCollection.push(temp);
        temp = incr_date(temp);
      }

      setSpecificDates(datesCollection);
    }
  }, [currentStartWeek]);
  useEffect(() => {
    value.map((v) => {
      v.selectedDays = [];
    });
    setSelectedAthletes(value);
  }, [value]);

  useEffect(() => {
    if (location.state.nutrition) {
      if (location.state.type === "update") {
        setType(location.state.type);
        setNutrition(location.state.nutrition.data.nutrition);
        setNutritionId(location.state.nutrition.id);
        if (location.state.nutrition.data?.nutrition?.entireFood) {
          setEntireFood(location.state.nutrition.data?.nutrition?.entireFood);
          setAddFood(
            location.state.nutrition.data?.nutrition?.entireFood[0]?.addFood
          );
        }

        setNutritionName(
          location?.state?.nutrition?.data?.nutrition?.nutritionName
        );
        setSelectedAthletes(location.state.nutrition.data.selectedAthletes);
      } else if (location.state.type === "create") {
        setType(location.state.type);
        setNutrition(location.state.nutrition.data.nutrition);
        setNutritionId(location.state.nutrition.id);
        setEntireFood(location.state.nutrition.data.nutrition.entireFood);
        setAddFood(
          location.state?.nutrition?.data?.nutrition?.entireFood[0]?.addFood
        );
        setNutritionName(
          location?.state?.nutrition?.data?.nutrition?.nutritionName
        );
      } else if (location.state.type === "view") {
        console.log("loc", location.state.nutrition);
        if (location.state.nutrition?.data?.nutrition?.entireFood) {
          setType(location.state.type);
          setNutrition(location.state.nutrition.data.nutrition);
          setNutritionId(location.state.nutrition.id);
          setEntireFood(location.state.nutrition?.data?.nutrition?.entireFood);
          setAddFood(
            location.state?.nutrition.data?.nutrition?.entireFood[0]?.addFood
          );
          setNutritionName(
            location?.state?.nutrition?.data?.nutrition?.nutritionName
          );
          setSelectedAthletes(
            location.state.nutrition.data.selectedAthletes || []
          );
        }
      } else {
        setNutritionName(location.state.nutrition.nutritionName);
        setEntireFood(location.state.nutrition.entireFood);
        setAddFood(location.state.nutrition.entireFood[0]?.addFood);
      }
    }
  }, [location]);

  useEffect(() => {
    fetch("https://rongoeirnet.herokuapp.com/getFood")
      .then((response) => response.json())
      .then((responseJson) => {
        //Successful response from the API Call
        setServerData(responseJson.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    let temp = [];
    if (selectedAthletes?.length > 0) {
      // if (selectedAthletes[0]) {
      //   let temp = [];
      //   temp.push(selectedAthletes[0]);
      //   setshow_data(temp);
      // }
      if (userType == "athlete") {
        selectedAthletes.forEach((item) => {
          if (item.id == userData?.id) {
            let temp = [];
            temp.push(item);
            setshow_data(temp);
          }
        });
      } else {
        selectedAthletes.forEach((item) => {
          item.value = item["id"];
          temp.push(item);
          if (temp.length == selectedAthletes.length) {
            setoptions(temp);
          }
        });
      }
    }
  }, [selectedAthletes, userData, userType]);

  useEffect(() => {
    let temp = [...selectedAthletes];
    selectedAthletes.map((athlete, idx) => {
      if (athlete_selecteddays[athlete.id]) {
        temp[idx].selectedDays = athlete_selecteddays[athlete.id];
      } else {
        if (athlete_dates[athlete.id]) {
          // temp[idx].selectedDays = athlete_selecteddays[athlete.id];
        } else {
          let tmp = { ...athlete_dates };

          var curr = new Date(); // get current date
          var first = curr.getDate() - curr.getDay(); // First day is the  day of the month - the day of the week \

          var firstday = new Date(curr.setDate(first)).toUTCString();
          var lastday = new Date(
            curr.setDate(curr.getDate() + 6)
          ).toUTCString();

          temp[idx].currentStartWeek = formatSpecificDate(firstday);
          temp[idx].currentEndWeek = formatSpecificDate(lastday);

          let t1 = formatSpecificDate(firstday);

          let datesCollection = [];

          for (var i = 0; i < 7; i++) {
            datesCollection.push(t1);
            t1 = incr_date(t1);
          }

          tmp[athlete.id] = datesCollection;

          setathlete_dates(tmp);
        }
      }
    });
    setSelectedAthletes1(temp);
  }, [selectedAthletes]);

  useEffect(() => {
    if (type === "non-editable") {
      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the  day of the month - the day of the week \

      var firstday = new Date(curr.setDate(first)).toUTCString();
      var lastday = new Date(curr.setDate(curr.getDate() + 6)).toUTCString();

      setCurrentStartWeek(formatSpecificDate(firstday));
      setCurrentEndWeek(formatSpecificDate(lastday));
    } else {
      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the  day of the month - the day of the week \

      var firstday = new Date(curr.setDate(first)).toUTCString();
      var lastday = new Date(curr.setDate(curr.getDate() + 6)).toUTCString();

      setCurrentStartWeek(formatSpecificDate(firstday));
      setCurrentEndWeek(formatSpecificDate(lastday));
    }
  }, []);

  useEffect(() => {
    if (currentStartWeek) {
      let temp = currentStartWeek;
      let datesCollection = [];

      for (var i = 0; i < 7; i++) {
        datesCollection.push(temp);
        temp = incr_date(temp);
      }

      setSpecificDates(datesCollection);
    }
  }, []);

  useEffect(() => {
    if (userData?.id && userType == "coach") {
      const data = [];
      db.collection("athletes")
        .orderBy("name", "asc")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((athlete) => {
            if (userData?.data.listOfAthletes.includes(athlete.id)) {
              let currentID = athlete.id;
              let appObj = { ...athlete.data(), ["id"]: currentID };
              data.push(appObj);
            }
          });
          setAthletes(data);
        });
    }
  }, [userData?.id, userType]);

  useEffect(() => {
    if (userData?.id) {
      const data = [];
      db.collection("athletes")
        .orderBy("name", "asc")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((athlete) => {
            if (userData?.data.listOfAthletes.includes(athlete.id)) {
              let currentID = athlete.id;
              let appObj = { ...athlete.data(), ["id"]: currentID };
              data.push(appObj);
            }
          });
          setAthletes(data);
        });
    }
  }, [userData?.id]);

  function incr_date(date_str) {
    var parts = date_str.split("-");
    var dt = new Date(
      parseInt(parts[0], 10), // year
      parseInt(parts[1], 10) - 1, // month (starts with 0)
      parseInt(parts[2], 10) // date
    );
    dt.setDate(dt.getDate() + 1);
    parts[0] = "" + dt.getFullYear();
    parts[1] = "" + (dt.getMonth() + 1);
    if (parts[1].length < 2) {
      parts[1] = "0" + parts[1];
    }
    parts[2] = "" + dt.getDate();
    if (parts[2].length < 2) {
      parts[2] = "0" + parts[2];
    }
    return parts.join("-");
  }

  useEffect(() => {
    if (userData?.id && userType == "coach") {
      const data = [];
      db.collection("athletes")
        .orderBy("name", "asc")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((athlete) => {
            if (userData?.data.listOfAthletes.includes(athlete.id)) {
              let currentID = athlete.id;
              let appObj = { ...athlete.data(), ["id"]: currentID };
              data.push(appObj);
            }
          });
          setAthletes(data);
        });
    }
  }, [userData?.id, userType]);
  useEffect(() => {
    let temp = [...selectedAthletes];
    selectedAthletes.map((athlete, idx) => {
      if (athlete_selecteddays[athlete.id]) {
        temp[idx].selectedDays = athlete_selecteddays[athlete.id];
      } else {
        if (athlete_dates[athlete.id]) {
          // temp[idx].selectedDays = athlete_selecteddays[athlete.id];
        } else {
          let tmp = { ...athlete_dates };

          var curr = new Date(); // get current date
          var first = curr.getDate() - curr.getDay(); // First day is the  day of the month - the day of the week \

          var firstday = new Date(curr.setDate(first)).toUTCString();
          var lastday = new Date(
            curr.setDate(curr.getDate() + 6)
          ).toUTCString();

          temp[idx].currentStartWeek = formatSpecificDate(firstday);
          temp[idx].currentEndWeek = formatSpecificDate(lastday);

          let t1 = formatSpecificDate(firstday);

          let datesCollection = [];

          for (var i = 0; i < 7; i++) {
            datesCollection.push(t1);
            t1 = incr_date(t1);
          }

          tmp[athlete.id] = datesCollection;

          setathlete_dates(tmp);
        }
      }
    });
    setSelectedAthletes1(temp);
  }, [selectedAthletes]);
  return (
    <div className="createNutrition assign-nutrition">
      <NutritionScreenHeader back="/nutrition" name="Create Nutrition" />
      <img src="/assets/nutrition.png" alt="" />

      <div>
        <div {...getRootProps()}>
          {/* <Label {...getInputLabelProps()}>Search for Athletes</Label> */}
          <h3 style={{ margin: "10px 10px 10px 30px" }}>Search For Athletes</h3>

          <InputWrapper
            ref={setAnchorEl}
            style={{ marginLeft: 17, zIndex: "100" }}
            className={focused ? "focused" : ""}
          >
            {value.map((option, index) => (
              <Tag label={option.name} {...getTagProps({ index })} />
            ))}

            <input {...getInputProps()} />
          </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <li {...getOptionProps({ option, index })}>
                <span>{option.name}</span>
                <CheckIcon fontSize="small" />
              </li>
            ))}
          </Listbox>
        ) : null}
      </div>
      {/* specificDates[idx] */}

      <div>
        <Grid container spacing={4} className="coachNutrition__homeContainer">
          {selectedAthletes1?.map((athlete, index) => (
            <Grid item xs={5} className="coachNutrition__homeRightContainer">
              <div
                id="backdivwkt"
                className="backdivwkt"
                key={index}
                style={{
                  marginTop: "25px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "white",
                  borderRadius: 5,
                  padding: 15,
                  boxSizing: "border-box",
                  marginLeft: "33px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "var(--primarycolor)",
                    borderRadius: "10px",
                    height: "45px",
                    width: "100%",
                  }}
                >
                  <img
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "18px",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                    src={
                      athlete.imageUrl
                        ? athlete.imageUrl
                        : "https://firebasestorage.googleapis.com/v0/b/stairs-web-app.appspot.com/o/userImage.jpeg?alt=media&token=b50bf709-1e26-4d1a-8fd6-3689f66322c3"
                    }
                  />
                  <h2
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      lineHeight: "28px",
                      color: "black",
                    }}
                  >
                    {athlete.name}
                  </h2>
                </div>
                <h2
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    marginTop: "10px",
                    lineHeight: "28px",
                    marginLeft: "1%",
                  }}
                >
                  Select days
                </h2>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "300px",
                    }}
                  >
                    <ChevronLeftIcon
                      style={{ cursor: "pointer" }}
                      className="leftbtnwkt"
                      onClick={() => {
                        var curr = new Date(athlete.currentStartWeek); // get current date
                        var first = curr.getDate() - curr.getDay() - 7; // First day is the  day of the month - the day of the week \

                        var firstday = new Date(
                          curr.setDate(first)
                        ).toUTCString();
                        var lastday = new Date(
                          curr.setDate(curr.getDate() + 6)
                        ).toUTCString();
                        if (new Date(athlete.currentStartWeek) > new Date()) {
                          let temp = { ...athlete_dates };
                          let tm = [...selectedAthletes1];
                          tm[index].currentStartWeek =
                            formatSpecificDate(firstday);

                          tm[index].currentEndWeek =
                            formatSpecificDate(lastday);
                          setSelectedAthletes1(tm);

                          let tmp = formatSpecificDate(firstday);

                          let datesCollection = [];

                          for (var i = 0; i < 7; i++) {
                            datesCollection.push(tmp);
                            tmp = incr_date(tmp);
                          }

                          temp[athlete.id] = datesCollection;

                          setathlete_dates(temp);
                        }
                      }}
                    />

                    {daysList.map((day, idx) => (
                      <div
                        id="insidescrollwkt"
                        className="insidescrollwkt"
                        key={idx}
                        onClick={() => {
                          if (type !== "view") {
                            if (
                              athlete?.selectedDays?.includes(
                                athlete_dates[athlete.id][idx]
                              )
                            ) {
                              let selected =
                                selectedAthletes[index].selectedDays;
                              var index1 = selected.indexOf(
                                athlete_dates[athlete.id][idx]
                              );
                              if (index1 !== -1) {
                                selected.splice(index1, 1);
                                selectedAthletes[index] = {
                                  ...selectedAthletes[index],
                                  selected,
                                };
                                setSelectedAthletes([...selectedAthletes]);
                              }
                            } else {
                              if (
                                new Date(athlete_dates[athlete.id][idx]) >
                                  new Date() ||
                                athlete_dates[athlete.id][idx] === formatDate()
                              ) {
                                let selectedDays =
                                  selectedAthletes[index].selectedDays;
                                selectedAthletes[index] = {
                                  ...selectedAthletes[index],
                                  selectedDays: [
                                    ...selectedDays,
                                    athlete_dates[athlete.id][idx],
                                  ],
                                };
                                let temp = athlete_selecteddays;
                                temp[selectedAthletes[index].id] =
                                  selectedAthletes[index].selectedDays;

                                setathlete_selecteddays(temp);
                                setSelectedAthletes([...selectedAthletes]);
                              }
                            }
                          }
                        }}
                        style={
                          athlete?.selectedDays?.includes(
                            athlete_dates[athlete.id][idx]
                          )
                            ? {
                                backgroundColor: "var(--primarycolor)",
                                color: "#fff",
                                minWidth: "40px",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                borderRadius: "8px",
                                marginRight: "2px",

                                cursor: "pointer",
                              }
                            : {
                                width: "40px",

                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                borderRadius: "8px",
                                marginRight: "2px",
                                cursor: "pointer",
                              }
                        }
                      >
                        <div>
                          <div
                            className="daywkt"
                            style={{
                              fontSize: "12px",
                              fontWeight: "600",
                              lineHeight: "20px",
                              width: "80%",
                              textAlign: "center",
                              padding: "5px",
                              color:
                                new Date(athlete_dates[athlete.id][idx]) <
                                new Date(formatDate())
                                  ? "grey"
                                  : "black",
                            }}
                          >
                            {day}
                          </div>

                          <div
                            style={{
                              width: "45px",
                              height: "30px",
                            }}
                            key={idx}
                          >
                            <div
                              className="datewkt"
                              style={{
                                fontSize: "10px",
                                fontWeight: "500",
                                lineHeight: "18px",
                                width: "100%",

                                paddingBottom: "5px",
                                textAlign: "center",
                                color:
                                  new Date(athlete_dates[athlete.id][idx]) <
                                  new Date(formatDate())
                                    ? "grey"
                                    : "black",
                              }}
                            >
                              {formatSpecificDate1(
                                athlete_dates[athlete.id][idx]
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <ChevronRightIcon
                      style={{ cursor: "pointer" }}
                      className="rightbtnwkt"
                      onClick={() => {
                        var curr = new Date(athlete.currentStartWeek); // get current date
                        var first = curr.getDate() - curr.getDay() + 7; // First day is the  day of the month - the day of the week \

                        var firstday = new Date(
                          curr.setDate(first)
                        ).toUTCString();
                        var lastday = new Date(
                          curr.setDate(curr.getDate() + 6)
                        ).toUTCString();

                        let temp = { ...athlete_dates };
                        let tm = [...selectedAthletes1];

                        tm[index].currentStartWeek =
                          formatSpecificDate(firstday);

                        tm[index].currentEndWeek = formatSpecificDate(lastday);
                        setSelectedAthletes1(tm);

                        let tmp = formatSpecificDate(firstday);

                        let datesCollection = [];

                        for (var i = 0; i < 7; i++) {
                          datesCollection.push(tmp);
                          tmp = incr_date(tmp);
                        }

                        temp[athlete.id] = datesCollection;

                        setathlete_dates(temp);
                      }}
                      className="rightbtnwkt"
                    />
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="createNutrition__input">
        <h4 style={{ marginTop: "40px" }}>Nutrition Plan Name</h4>
        <input
          style={{ width: "96%" }}
          type="text"
          placeholder="Enter Nutrition Plan Name"
          value={nutritionName}
          onChange={(e) => {
            setNutritionName(e.target.value);
          }}
        />
      </div>
      <div className="coachAddMeal__form">
        <div className="athleteAddMeal__typeOfMeal">
          {entireFood?.map((item, idx) => (
            <div className="athleteAddMealfood__container">
              <FormControl className={classes.formControl}>
                <h4>Select the type of meal</h4>
                <Select
                  labelId="meal-select-label"
                  id="meal-select-label"
                  value={item.meal}
                  onChange={(e) => {
                    let temp = [...entireFood];
                    temp[idx].meal = e.target.value;
                    setEntireFood(temp);
                  }}
                >
                  <MenuItem value={"Breakfast"}>Breakfast</MenuItem>
                  <MenuItem value={"Lunch"}>Lunch</MenuItem>
                  <MenuItem value={"Snack"}>Snack</MenuItem>
                  <MenuItem value={"Pre Workout"}>Pre Workout</MenuItem>
                  <MenuItem value={"Post Workout"}>Post Workout</MenuItem>
                  <MenuItem value={"Dinner"}>Dinner</MenuItem>
                </Select>
              </FormControl>
              {addFood ? (
                <div>
                  {item.food?.map((item2, idx2) => {
                    return (
                      <AddFoodCard
                        type={type}
                        item={item2}
                        idx={idx2}
                        key={idx2}
                        ent={item}
                        entireFood={entireFood}
                        index={idx}
                        serverData={serverData}
                        setEntireFood={setEntireFood}
                      />
                    );
                  })}
                  {/* {props.isLongTerm ? null : (
                    <div
                      className="foodCard__addfoodButton"
                      onClick={() => {
                        let foodData = [...entireFood];
                        let temp = [...item.food];
                        temp.push({
                          foodName: "",
                          proteins: 0,
                          carbs: 0,
                          fat: 0,
                          calories: 0,
                          quantity: 1,
                        });
                        foodData[idx].food = temp;

                        setEntireFood(foodData);
                      }}
                    >
                      <h3>Add Food</h3>
                    </div>
                  )} */}
                </div>
              ) : (
                <FormControl className={classes.formControl}>
                  <div className="coachAddMeal__textArea">
                    <h4 style={{ margin: 0, marginBottom: 10 }}>Description</h4>
                    <textarea
                      type="text"
                      placeholder="Enter Food Description"
                      value={item.description}
                      readOnly={type === "view" ? true : false}
                      onChange={(e) => {
                        let temp = [...entireFood];
                        temp[idx] = {
                          ...temp[idx],
                          description: e.target.value,
                        };
                        setEntireFood(temp);
                      }}
                    />
                  </div>
                </FormControl>
              )}
            </div>
          ))}

          <div className="createNutrition__input coachAddMeal__textArea">
            <h4>Comments</h4>
            <textarea
              type="text"
              placeholder="Enter Comments"
              value={comments}
              onChange={(e) => {
                setComments(e.target.value);
              }}
            />
          </div>

          {userType !== "athlete" && (
            <div
              className="coachFoodCard__addmealButton"
              onClick={() => {
                setEntireFood([
                  ...entireFood,
                  {
                    meal: "",
                    description: "",
                  },
                ]);
              }}
            >
              <h3 style={{ color: "var(--secondarycolor)" }}>Add Meal</h3>
            </div>
          )}
          {userType !== "athlete" && (
            <div
              className="coachFoodCard__submitMealButton scroll-to-top"
              onClick={() => {
                if (type === "view") {
                  history.goBack();
                } else {
                  if (type === "update") {
                    db.collection("Food")
                      .doc(nutritionId)
                      .update({
                        nutrition: {
                          nutritionName: nutritionName,
                          entireFood,
                          comments,
                        },
                        saved: false,
                      });
                    if (type === "update") {
                      history.goBack();
                    } else {
                      history.push("/post-add-screen");
                    }
                  } else {
                    let tempDate1 = [];
                    selectedAthletes.map((athlete) => {
                      athlete.selectedDays.map((d) => {
                        tempDate1.push(d);
                      });
                    });

                    if (
                      selectedAthletes &&
                      tempDate1.length > 0 &&
                      nutritionName
                    ) {
                      selectedAthletes.map((athlete, idx) => {
                        // sendPushNotification(
                        //   athlete.token,
                        //   "new Nutrition Plan assigned"
                        // );
                        console.log(plan);
                        db.collection("CoachFood")
                          .add({
                            from_id: userData?.id,
                            assignedTo_id: athlete.id,
                            selectedDays: athlete.selectedDays,
                            nutrition: {
                              nutritionName: nutritionName,
                              entireFood,
                            },
                            saved: false,
                            selectedAthletes,

                            timestamp:
                              firebase.firestore.FieldValue.serverTimestamp(),
                            date: firebase.firestore.FieldValue.serverTimestamp(),
                          })
                          .then((docRef) => {
                            var userIdList = [];
                            userIdList.push(athlete.id);
                            sendPushNotification(userIdList, {
                              title: `New Nutrition Plan Assign`,
                              body: `${nutritionName}`,
                            });
                            db.collection("Food").add({
                              from_id: userData?.id,
                              assignedTo_id: athlete.id,
                              selectedDays: athlete.selectedDays,
                              nutrition: {
                                nutritionName: nutritionName,
                                entireFood,
                              },
                              saved: false,
                              selectedAthletes,
                              timestamp:
                                firebase.firestore.FieldValue.serverTimestamp(),
                              date: firebase.firestore.FieldValue.serverTimestamp(),
                              coachFoodId: docRef.id,
                            });
                          });
                      });

                      selectedAthletes.forEach((id) => {
                        db.collection("AthleteNotifications")
                          .doc(id.id)
                          .collection("notifications")
                          .add({
                            message: "New Nutrition plan assigned",
                            seen: false,
                            timestamp:
                              firebase.firestore.FieldValue.serverTimestamp(),
                            coach_id: userData.id,
                          });
                      });
                      history.push({
                        pathname: "/nutrition",
                      });
                    } else {
                      alert("Please select an athlete and assign a date");
                    }
                  }
                }
              }}
            >
              <h3
                style={{ color: "var(--secondarycolor)" }}
                className="scroll-to-top"
              >
                {" "}
                {type === "view"
                  ? "Return"
                  : type === "update"
                  ? "Update Plan"
                  : "Complete Nutrition"}
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateNutrition;
