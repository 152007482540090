import firebase from "firebase";

if (!firebase.apps.length) {
  const firebaseApp = firebase.initializeApp({
    // apiKey: "AIzaSyDXG6ZCuWnZ86KsIqz9-SVrJSl7y9dnSHU",
    // authDomain: "stairs-web-app.firebaseapp.com",
    // projectId: "stairs-web-app",
    // storageBucket: "stairs-web-app.appspot.com",
    // messagingSenderId: "212495420280",
    // appId: "1:212495420280:web:0acd4a9fba63f9b646168d",
    // measurementId: "G-1PY4RVHHD6",

    apiKey: "AIzaSyDlHXSMfzmKjPJGcDJYwR_QhzBZbR3nmH8",
    authDomain: "stairs-prod-7aa7b.firebaseapp.com",
    projectId: "stairs-prod-7aa7b",
    storageBucket: "stairs-prod-7aa7b.appspot.com",
    messagingSenderId: "729539314855",
    appId: "1:729539314855:web:2ea8b0a59f233148d84782",
  });
} else {
  firebase.app(); // if already initialized, use that one
}

const firestore = firebase.firestore;
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

db.settings({
  ignoreUndefinedProperties: true,
});

export { db, auth, storage, firestore };
