import React, { useEffect, useState } from "react";
import "./Notification.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  selectUserData,
  selectUserType,
  setUserData,
} from "../../features/userSlice";
import { db } from "../../utils/firebase";
import { ChevronRightRounded } from "@material-ui/icons";
import Unreadanimation from "./unreadanimation";
function Notification({ route }) {
  const userData = useSelector(selectUserData);
  const [readMessages, setReadMessages] = React.useState([]);
  const [unreadMessages, setUnreadMessages] = React.useState([]);
  const [switchScreen, setSwitchScreen] = React.useState(false);
  const [show, setshow] = React.useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [openmail , setopenmail] = useState(false);
  const [closemail , setclosemail] = useState(true);
  let userId = userData?.id;
  const  handleopenmail= (e) => {
    setclosemail(false)
    setopenmail(true);
  };
  const  handleclosemail= (e) => {
    setopenmail(false)
    setclosemail(true);
    
  };
  React.useEffect(() => {
    if (userData) {
      let temp1 = [];
      let temp2 = [];
      db.collection("CoachNotifications")
        .doc(userData.id)
        .collection("notifications")
        .where("seen", "==", false)
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            let currentID = doc.id;
            let appObj = { ...doc.data(), ["id"]: currentID };
            temp1.push(appObj);
          });
          setUnreadMessages(temp1);
        });
      db.collection("CoachNotifications")
        .doc(userData.id)
        .collection("notifications")
        .where("seen", "==", true)
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            let currentID = doc.id;
            let appObj = { ...doc.data(), ["id"]: currentID };
            temp2.push(appObj);
          });
          setReadMessages(temp2);
        });
    }
  }, [userData?.id]);

  const getData = () => {
    let temp1 = [];
    let temp2 = [];
    db.collection("CoachNotifications")
      .doc(userData.id)
      .collection("notifications")
      .where("seen", "==", false)
      .onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          let currentID = doc.id;
          let appObj = { ...doc.data(), ["id"]: currentID };
          temp1.push(appObj);
        });
        setUnreadMessages(temp1);
      });
    db.collection("CoachNotifications")
      .doc(userData.id)
      .collection("notifications")
      .where("seen", "==", true)
      .onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          let currentID = doc.id;
          let appObj = { ...doc.data(), ["id"]: currentID };
          temp2.push(appObj);
        });
        setReadMessages(temp2);
      });
  };

  useEffect(() => {
    if (userId) {
      db.collection("CoachNotifications")
        .doc(userId)
        .collection("notifications")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) =>
          setNotifications(
            snapshot.docs.map((doc) => ({ id: doc.id, notify: doc.data() }))
          )
        );
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      db.collection("CoachNotifications")
        .doc(userId)
        .collection("notifications")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) =>
          setUnreadNotifications(
            snapshot.docs.map((doc) => ({ id: doc.id, unnotify: doc.data() }))
          )
        );
    }
  }, [userId]);

  const button = () => {
    if (show == true) {
      db.collection("CoachNotifications")
        .doc(userData.id)
        .collection("notifications")
        .get()
        .then(function (querySnapshot) {
          var batch = db.batch();

          querySnapshot.forEach(function (doc) {
            // For each doc, add a delete operation to the batch
            batch.update(doc.ref, "seen", true);
            // batch.update(doc.ref);
          });

          // Commit the batch
          return batch.commit();
        })
        .then(function () {
          // Delete completed!
          // ...
          getData();
        });
    } else {
      setshow(!show);
    }
  };
  return (
    <div style={{ flex: 1 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          justifyContent: "space-between",
          marginLeft: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3>Notifications </h3>
          <div
            onClick={() => {
              setshow(!show);
            }}
            style={{
              transform: show ? "rotate(180deg)" : "rotate(90deg)",
              transition: "transform 0.2s",
              marginTop: 5,
              position: "absolute",
              right: 10,
            }}
            // class="arrow-right"
          >
            <ChevronRightRounded style={{ height: 30, width: 30 }} />
          </div>
        </div>
        {switchScreen === false && (
          <div
            style={{
              borderColor: "#d3d3d3",
              borderwidth: 1,
              borderRadius: 8,
              cursor: "pointer",
            }}
            onClick={
              () => button()

              // const batch = db.batch()

              // .onSnapshot(function(querySnapshot) {
              //     querySnapshot.forEach(function(doc) {
              //         doc.ref.update({
              //             seen: true
              //         });
              //     });
              // });f
            }
          >
            {show ? (
              <h3
                style={{
                  textAlign: "left",
                  marginRight: 30,
                  transition: "all 0.1s",
                  width: 150,
                  fontWeight: "normal",
                  fontSize: 15,
                  transition: "all 0.1s",
                  padding: 5,
                  marginTop: 20,
                }}
              >
                Mark All as Read
              </h3>
            ) : (
              <h3
                style={{
                  textAlign: "center",
                  marginRight: 30,
                  transition: "all 0.1s",
                  padding: 5,
                  borderRadius: 10,
                  marginTop: 20,
                  display: unreadMessages.length > 0 ? "block" : "none",
                  fontWeight: "normal",
                  transition: "all 0.1s",
                  fontWeight:'bolder',
                  textTransform:'uppercase',
                  fontSize: 15,
                  width: 150,
                }}
              >
                
                <div className="glow" onMouseEnter={handleopenmail} onMouseLeave={handleclosemail} onClick={handleclosemail}>
                {unreadMessages.length > 0 && `${unreadMessages.length} unread`}
&nbsp;

            {openmail && <i class="fa fa-envelope-open-o" aria-hidden="true"></i>}
            {closemail && <i class="fa fa-envelope" aria-hidden="true"></i>}
          </div>
              </h3>
            )}
          </div>
        )}
      </div>

      <div
        style={{
          overflow: "hidden",
          height: show ? 200 : 0,
          transition: "all 0.5s",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              backgroundColor:
                switchScreen === false ? "var(--primarycolor)" : "#fff",
              borderRadius: 5,
              paddingHorizontal: 4,
              paddingVertical: 8,
              cursor: "pointer",
            }}
            onClick={() => setSwitchScreen(false)}
          >
            <p
              style={{
                fontWeight: "700",
                margin: 0,
                padding: 5,
                color:
                  switchScreen === false ? "var(--secondarycolor)" : "#000",
              }}
            >
              Unread Notifications
            </p>
          </div>
          <div
            style={{
              backgroundColor:
                switchScreen === true ? "var(--primarycolor)" : "#fff",
              borderRadius: 5,
              paddingHorizontal: 4,
              paddingVertical: 8,
              cursor: "pointer",
            }}
            onClick={() => setSwitchScreen(true)}
          >
            <p
              style={{
                fontWeight: "700",
                margin: 0,
                padding: 5,
                color: switchScreen === true ? "var(--secondarycolor)" : "#000",
              }}
            >
              Read Notifications
            </p>
          </div>
        </div>
        <div
          style={{
            margin: 10,
            flex: 1,
            alignItems: "center",
            justifyContent: "space-between",
            overflow: "scroll",
            height:180,
             paddingLeft:'20px',
             paddingRight:'20px',

          }}
        >
          {switchScreen === false
            ? unreadNotifications.map(
                ({ id, unnotify }) =>
                  unnotify.seen == false && (
                    <div
                      key={id}
                      style={{
                        backgroundColor: "white",
                        padding: 10,
                        margin: 10,
                        marginLeft: 10,
                        borderRadius: 5,
                      }}
                    >
                      <p style={{ margin: 0, fontSize: 14 }}>
                        {unnotify.message}
                      </p>
                      <p
                        style={{ textAlign: "right", margin: 0, fontSize: 13 }}
                      >
                        {unnotify.timestamp.toDate().toDateString() +
                          " at " +
                          unnotify.timestamp.toDate().toLocaleTimeString()}
                      </p>
                    </div>
                  )
              )
            : notifications.map(
                ({ id, notify }) =>
                  notify.seen == true && (
                    <div
                      key={id}
                      style={{
                        backgroundColor: "white",
                        padding: 10,
                        width: "95%",
                        margin: 10,
                        marginLeft: -5,
                        borderRadius: 5,
                      }}
                    >
                      <p style={{ margin: 0, fontSize: 14 }}>
                        {notify.message}
                      </p>
                      <p
                        style={{ textAlign: "right", margin: 0, fontSize: 13 }}
                      >
                        {notify.timestamp.toDate().toDateString() +
                          " at " +
                          notify.timestamp.toDate().toLocaleTimeString()}
                      </p>
                    </div>
                  )
              )}
        </div>
      </div>
    </div>
  );
}

export default Notification;
