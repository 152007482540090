import React, { useState, useEffect, useCallback } from "react";

import { auth, db } from "../../utils/firebase";
import firebase from "firebase";

import { useDispatch, useSelector } from "react-redux";
import {
  setDbID,
  selectDbId,
  selectUser,
  setUserDetails,
  selectShowData,
  logout,
  setUserData,
  selectUserData,
} from "../../features/userSlice";
import "./vod.css";
import "../../responsive.css";
//import Notification from "../components/Notification";
import VideocamIcon from "@material-ui/icons/Videocam";
import { Formik } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  DialogContentText,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import VODScreenHeader from "./VODScreenHeader";
import ReactPlayer from "react-player";
import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function VODHome({ navigation }) {
  const [userDetails, setUserDetails] = useState(null);
  const [athleteDetails, setAthleteDetails] = useState([]);
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [athletes, setAthletes] = useState([]);
  const [listOfAthletes, setListOfAthletes] = useState(null);
  const [videoId, setVideoId] = useState(["576142998", "576142998"]);
  const [title, settitle] = useState("");
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);

  const [verifiedModal, setVerifiedModal] = useState(false);
  const [onboardModal, setOnboardModal] = useState(false);

  const [SearchList, setSearchList] = React.useState(null);
  const [SearchLoading, SetSearhLoading] = React.useState(false);
  const [workoutVideoUrl, setWorkoutVideoUrl] = useState("");
  const [filterAsc, setFilterAsc] = useState(false);
  const [sorting, setsorting] = React.useState("desc");
  const history = useHistory();
  const [assignedData, setAssignedData] = useState();
  const [assignedWorkoutVideo, setAssignedWorkoutVideo] = useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [videoData, setVideoData] = useState([
    {
      videoId: 576142998,
      title: "a",
    },
    {
      videoId: 576142998,
      title: "b",
    },
  ]);
  const [AssignedVideos, setAssignedVideos] = useState([]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setWorkoutVideoUrl("");
  };

  useEffect(() => {
    var temp = [];
    const data = [];

    if (userData?.id) {
      db.collection("coaches")
        .doc(userData?.id)
        .collection("videos")
        .orderBy("timestamp")
        .limit(4)
        .get()
        .then((snapshot) => {
          snapshot.forEach((dat) => {
            data.push(dat.data());
          });
          setVideoData(data);
        });

      db.collection("WorkoutVideo")
        .where("AssignedById", "==", userData?.id)
        .orderBy("timestamp", "desc")
        .limit(4)
        .onSnapshot(
          (snap) => {
            let data = [];
            snap.forEach((s) => {
              data.push(s.data());
            });
            setAssignedVideos(data);
          },
          (error) => {
            console.log("Error in workout video", error);
          }
        );
    }
  }, [user, userData?.id]);

  React.useEffect(() => {
    setSearchList(videoData);
    console.log(videoData);
  }, [videoData]);

  React.useEffect(() => {
    console.log(search);
    (async () => {
      if (search?.length > 0 && videoData) {
        const names = await videoData?.filter((data) => {
          return data.title.toLowerCase().includes(search.toLowerCase());
        });

        setSearchList(names);
        SetSearhLoading(false);
      } else {
        setSearchList(videoData);
        SetSearhLoading(false);
      }
    })();
  }, [search, videoData]);

  useEffect(() => {
    db.collection("WorkoutVideo")
      .where("AssignedById", "==", userData?.id)
      .onSnapshot(
        (snap) => {
          let data = [];
          snap.forEach((s) => {
            data.push(s.data());
          });
          setAssignedData(data);
          console.log(assignedData);
        },
        (error) => {
          console.log("Error in workout video", error);
        }
      );
  }, []);

  useEffect(() => {
    db.collection("CoachWorkoutVideo")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) =>
        setAssignedWorkoutVideo(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            assignedVideo: doc.data(),
          }))
        )
      );
  }, []);

  return (
    <div className="vodmain" id="vodhome">
      <VODScreenHeader back="/" name="Video on Demand" />

      <div
        style={{
          boxSizing: "border-box",
          minHeight: "100vh",
          margin: 10,
        }}
      >
        <Grid
          container
          spacing={2}
          style={{}}
          className="workouts__homeContainer"
        >
          <Grid item xs={6} className="workouts__homeLeftContainer">
            <div
              style={{ width: "90%", paddingLeft: 10 }}
              className="workoutHeading__row"
            >
              <h1 id="cursordefault">Assigned Videos</h1>
              <div onClick={() => history.push("/assigned-videos")}>
                View All
              </div>
            </div>
            {AssignedVideos?.length > 0 ? (
              assignedWorkoutVideo.map(({ id, assignedVideo }) => (
                <div
                // className="prevtherapy"
                // onClick={() => {
                //   history.push({
                //     pathname: `assigned-videos-details/${id}`,
                //     // state: {
                //     //   VideoId: temperoryId,
                //     // },
                //   });
                // }}
                >
                  <h1
                    style={{
                      color: "white",
                      fontSize: 18,
                      padding: 8,
                    }}
                    key={id}
                  >
                    <button
                      onClick={() => {
                        history.push({
                          pathname: `assigned-videos-details/${id}`,
                          // state: {
                          //   VideoId: temperoryId,
                          // },
                        });
                      }}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#ee664f",
                        color: "white",
                        fontSize: 12,
                        fontWeight: "600",
                        borderRadius: 20,
                        width: 60,
                        heigth: 30,
                        padding: 4,
                        border: "none",
                      }}
                    >
                      Details
                    </button>
                    {assignedVideo.Video.map((video) => (
                      <div
                        onClick={() => {
                          if (video.videoId) {
                            setWorkoutVideoUrl(video.videoId);
                            setOpenDialog(true);
                          }
                        }}
                      >
                        {console.log("HI", assignedVideo.Video)}
                        <img
                          style={{
                            width: "300px",
                            height: "150px",
                            borderRadius: "8px",
                            backgroundColor: "#d3d3d3",
                            objectFit: "cover",
                            marginRight: "15px",
                            marginTop: 20,
                          }}
                          src={
                            `https://vumbnail.com/${video.videoId}.jpg`
                              ? `https://vumbnail.com/${video.videoId}.jpg`
                              : "/assets/illustration.png"
                          }
                        />
                      </div>
                    ))}
                  </h1>
                </div>
              ))
            ) : (
              <div
                style={{
                  fontSize: "13px",
                  backgroundColor: "#fff",
                  width: "90%",
                  padding: "10px 20px",
                  textAlign: "center",
                  borderRadius: "5px",
                  fontWeight: "normal",
                  marginLeft: 10,
                  boxSizing: "border-box",
                }}
              >
                <h5> There are no assigned videos for now </h5>
              </div>
            )}
          </Grid>
          <Grid item xs={6} className="workouts__homeLeftContainer">
            <div
              style={{ width: "90%", paddingLeft: 10 }}
              className="workoutHeading__row"
            >
              <h1 id="cursordefault">Uploaded Videos</h1>

              <div onClick={() => history.push("/uploaded-videos")}>
                View All
              </div>
            </div>

            {console.log("b", videoData)}
            <div
              style={{
                marginTop: 45,
              }}
            >
              {videoData?.length > 0 ? (
                videoData?.map((video, idx) => (
                  <div style={{}}>
                    <div
                      class="iframe_container"
                      onClick={() => {
                        if (video.videoId) {
                          setWorkoutVideoUrl(video.videoId);
                          setOpenDialog(true);
                        }
                      }}
                    >
                      {console.log("vi", videoData)}
                      <img
                        style={{
                          width: "300px",
                          height: "150px",
                          borderRadius: "8px",
                          backgroundColor: "#d3d3d3",
                          objectFit: "cover",
                          marginRight: "15px",
                          marginTop: 20,
                          cursor: "pointer",
                        }}
                        src={
                          `https://vumbnail.com/${video.videoId}.jpg`
                            ? `https://vumbnail.com/${video.videoId}.jpg`
                            : "/assets/illustration.png"
                        }
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    fontSize: "13px",
                    backgroundColor: "#fff",
                    width: "90%",
                    padding: "10px 20px",
                    textAlign: "center",
                    borderRadius: "5px",
                    fontWeight: "normal",
                    marginLeft: 10,
                    boxSizing: "border-box",
                  }}
                >
                  <h5> There are no uploaded videos for now </h5>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        // fullWidth
        onClose={handleCloseDialog}
      >
        <DialogContent>
          {/* <video width="500" height="500" controls>
            <source src={workoutVideoUrl} type="video/mp4" />
          </video> */}
          {/* <div
              dangerouslySetInnerHTML={{
                __html: `<iframe title="video" height="470" width="730" frameborder="0" src="https://player.vimeo.com/video/${workoutVideoUrl.substring(
                  workoutVideoUrl.lastIndexOf("/") + 1
                )}"></iframe>`,
              }}
            /> */}

          <ReactPlayer
            // title={video.title}
            // id="iframe"
            style={{ borderRadius: 10 }}
            url={"https://player.vimeo.com/video/" + workoutVideoUrl}
            width="600px"
            height="400px"
            frameborder="0"
            controls
          />

          {console.log("HUI", workoutVideoUrl)}
          <div
            onClick={handleCloseDialog}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: 0,
              top: 0,
              padding: 12,
            }}
          >
            {" "}
            <CloseIcon />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default VODHome;
