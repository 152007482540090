import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserData, selectUserType } from "../../features/userSlice";
import { db } from "../../utils/firebase";
import NutritionScreenHeader from "./NutritionScreenHeader";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import NoSsr from "@material-ui/core/NoSsr";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import formatSpecificDate from "../../functions/formatSpecificDate";
import { formatDate } from "../../functions/formatDate";
import incr_date from "../../functions/incr_date";
import formatSpecificDate1 from "../../functions/formatSpecificDate1";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { useHistory, useLocation } from "react-router";
import firebase from "firebase";
import AddFoodCard from "./AddFoodCard";
import "./CoachNutrition.css";
import { Grid } from "@material-ui/core";
import moment from "moment";
import DatePicker from "react-datepicker";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  margin-left: 4%;
  margin-top: 20px;
`;

const InputWrapper = styled("div")`
  width: 350px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;

  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled("ul")`
  width: 350px;
  margin: 2px 0 0;
  margin-left: 2.1%;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 150px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected="true"] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus="true"] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

function ViewNutrition(props) {
  const classes = useStyles();
  const userData = useSelector(selectUserData);
  const userType = useSelector(selectUserType);
  const [nutritionId, setNutritionId] = useState("");
  const [nutritionName, setNutritionName] = useState("");
  const [entireFood, setEntireFood] = useState([]);
  const [nutrition, setNutrition] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const [selectedAthletes1, setSelectedAthletes1] = useState([]);
  const [currentStartWeek, setCurrentStartWeek] = useState(null);
  const [currentEndWeek, setCurrentEndWeek] = useState(null);
  const [show_data, setshow_data] = useState([]);
  const [options, setoptions] = useState(null);
  const [addFood, setAddFood] = useState(false);
  const [serverData, setServerData] = useState([]);
  const [athlete_selecteddays, setathlete_selecteddays] = useState({});
  const [daysList, setDaysList] = useState([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ]);
  const [specificDates, setSpecificDates] = useState([]);
  const [athlete_dates, setathlete_dates] = useState({});
  const [type, setType] = useState("");
  const location = useLocation();
  const dates = location.state?.nutrition?.data.selectedDays;
  const useDatePicker = !dates;
  const [date, setDate] = useState(dates ? dates[0] : "");
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const history = useHistory();
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: athletes,
    getOptionSelected: (option, value) => value.id === option.id,
    getOptionLabel: (option) => option.name,
  });

  useEffect(() => {
    value.map((v) => {
      v.selectedDays = [];
    });
    setSelectedAthletes(value);
  }, [value]);

  useEffect(() => {
    if (type === "view" && userData?.id && !location.state.nutrition) {
      db.collection("Food")
        .where("assignedTo_id", "==", userData.id)
        .where("selectedDays", "array-contains", selectedDate)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            setEntireFood([]);
            setAddFood(true);
            setNutritionName();
          } else {
            const doc = snapshot.docs[0].data();
            const entireFood = doc.nutrition.entireFood;
            setEntireFood(entireFood);
            setAddFood(entireFood[0].addFood);
            setNutritionName(doc.nutrition.nutritionName);
          }

          db.collection("AthleteNutrition")
            .doc(userData.id)
            .collection("nutrition")
            .doc(selectedDate)
            .get()
            .then((snapshot) => {
              if (!snapshot.exists) {
                if (entireFood.length === 0) {
                  entireFood.push({
                    meal: "",
                    description: "",
                    addedByAthlete: true,
                    allowUpdate: true,
                    addFood: true,
                    food: [
                      {
                        foodName: "",
                        proteins: 0,
                        carbs: 0,
                        fat: 0,
                        calories: 0,
                        quantity: 1,
                        addedByAthlete: true,
                        logged: true,
                      },
                    ],
                  });
                  setEntireFood(entireFood);
                  setAddFood(true);
                }
                return;
              }
              const athleteFood = snapshot.data().entireFood;
              const newEntireFood = entireFood.slice();
              athleteFood.forEach((meal) => {
                if (meal.addedByAthlete) {
                  newEntireFood.push(meal);
                } else {
                  meal.food.forEach((foodItem) => {
                    const index = newEntireFood.findIndex(
                      (m) => m.meal === meal.meal && !m.addedByAthlete
                    );
                    if (index !== -1) {
                      const newFood = newEntireFood[index].food.slice();
                      if (foodItem.addedByAthlete) {
                        newFood.push(foodItem);
                      } else {
                        const foodIndex = newFood.findIndex(
                          (f) => f.foodName === foodItem.foodName
                        );
                        if (foodIndex !== -1) {
                          newFood[foodIndex] = {
                            ...newFood[foodIndex],
                            ...foodItem,
                            logged: true,
                          };
                        }
                      }
                      newEntireFood[index] = {
                        ...newEntireFood[index],
                        food: newFood,
                      };
                    }
                  });
                }
              });
              setEntireFood(newEntireFood);
            });
        });
    }
  }, [type, selectedDate, userData]);

  useEffect(() => {
    setType(location.state.type);
    if (location.state.nutrition) {
      if (location.state.type === "update") {
        setNutrition(location.state.nutrition.data.nutrition);
        setNutritionId(location.state.nutrition.id);
        if (location.state.nutrition.data?.nutrition?.entireFood) {
          setEntireFood(location.state.nutrition.data?.nutrition?.entireFood);
          setAddFood(
            location.state.nutrition.data?.nutrition?.entireFood[0]?.addFood
          );
        }

        setNutritionName(
          location?.state?.nutrition?.data?.nutrition?.nutritionName
        );
        setSelectedAthletes(location.state.nutrition.data.selectedAthletes);
      } else if (location.state.type === "create") {
        setNutrition(location.state.nutrition.data.nutrition);
        setNutritionId(location.state.nutrition.id);
        setEntireFood(location.state.nutrition.data.nutrition.entireFood);
        setAddFood(
          location.state?.nutrition?.data?.nutrition?.entireFood[0]?.addFood
        );
        setNutritionName(
          location?.state?.nutrition?.data?.nutrition?.nutritionName
        );
      } else if (location.state.type === "view") {
        if (location.state.nutrition?.data?.nutrition?.entireFood) {
          setNutrition(location.state.nutrition.data.nutrition);
          setNutritionId(location.state.nutrition.id);
          setEntireFood(location.state.nutrition?.data?.nutrition?.entireFood);
          setAddFood(
            location.state?.nutrition.data?.nutrition?.entireFood[0]?.addFood
          );
          setNutritionName(
            location?.state?.nutrition?.data?.nutrition?.nutritionName
          );
          setSelectedAthletes(
            location.state.nutrition.data.selectedAthletes || []
          );
        }
      } else {
        setNutritionName(location.state.nutrition.nutritionName);
        setEntireFood(location.state.nutrition.entireFood);
        setAddFood(location.state.nutrition.entireFood[0]?.addFood);
      }
    }
  }, [location]);

  useEffect(() => {
    if (props.isLongTerm) {
      setNutritionName(props.selectedDayData.nutrition.nutritionName);
      setEntireFood(props.selectedDayData.nutrition.entireFood);
      setAddFood(props.selectedDayData.nutrition.entireFood[0]?.addFood);
    }
  }, [props.isLongTerm]);

  useEffect(() => {
    fetch("https://rongoeirnet.herokuapp.com/getFood")
      .then((response) => response.json())
      .then((responseJson) => {
        //Successful response from the API Call
        setServerData(responseJson.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    let temp = [];
    if (selectedAthletes?.length > 0) {
      // if (selectedAthletes[0]) {
      //   let temp = [];
      //   temp.push(selectedAthletes[0]);
      //   setshow_data(temp);
      // }
      if (userType == "athlete") {
        selectedAthletes.forEach((item) => {
          if (item.id == userData?.id) {
            let temp = [];
            temp.push(item);
            setshow_data(temp);
          }
        });
      } else {
        selectedAthletes.forEach((item) => {
          item.value = item["id"];
          temp.push(item);
          if (temp.length == selectedAthletes.length) {
            setoptions(temp);
          }
        });
      }
    }
  }, [selectedAthletes, userData, userType]);

  useEffect(() => {
    let temp = [...selectedAthletes];
    selectedAthletes.map((athlete, idx) => {
      if (athlete_selecteddays[athlete.id]) {
        temp[idx].selectedDays = athlete_selecteddays[athlete.id];
      } else {
        if (athlete_dates[athlete.id]) {
          // temp[idx].selectedDays = athlete_selecteddays[athlete.id];
        } else {
          let tmp = { ...athlete_dates };

          var curr = new Date(); // get current date
          var first = curr.getDate() - curr.getDay(); // First day is the  day of the month - the day of the week \

          var firstday = new Date(curr.setDate(first)).toUTCString();
          var lastday = new Date(
            curr.setDate(curr.getDate() + 6)
          ).toUTCString();

          temp[idx].currentStartWeek = formatSpecificDate(firstday);
          temp[idx].currentEndWeek = formatSpecificDate(lastday);

          let t1 = formatSpecificDate(firstday);

          let datesCollection = [];

          for (var i = 0; i < 7; i++) {
            datesCollection.push(t1);
            t1 = incr_date(t1);
          }

          tmp[athlete.id] = datesCollection;

          setathlete_dates(tmp);
        }
      }
    });
    setSelectedAthletes1(temp);
  }, [selectedAthletes]);

  useEffect(() => {
    if (type === "non-editable") {
      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the  day of the month - the day of the week \

      var firstday = new Date(curr.setDate(first)).toUTCString();
      var lastday = new Date(curr.setDate(curr.getDate() + 6)).toUTCString();

      setCurrentStartWeek(formatSpecificDate(firstday));
      setCurrentEndWeek(formatSpecificDate(lastday));
    } else {
      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the  day of the month - the day of the week \

      var firstday = new Date(curr.setDate(first)).toUTCString();
      var lastday = new Date(curr.setDate(curr.getDate() + 6)).toUTCString();

      setCurrentStartWeek(formatSpecificDate(firstday));
      setCurrentEndWeek(formatSpecificDate(lastday));
    }
  }, []);

  useEffect(() => {
    if (currentStartWeek) {
      let temp = currentStartWeek;
      let datesCollection = [];

      for (var i = 0; i < 7; i++) {
        datesCollection.push(temp);
        temp = incr_date(temp);
      }

      setSpecificDates(datesCollection);
    }
  }, []);

  useEffect(() => {
    if (userData?.id && userType == "coach") {
      const data = [];
      db.collection("athletes")
        .orderBy("name", "asc")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((athlete) => {
            if (userData?.data.listOfAthletes.includes(athlete.id)) {
              let currentID = athlete.id;
              let appObj = { ...athlete.data(), ["id"]: currentID };
              data.push(appObj);
            }
          });
          setAthletes(data);
        });
    }
  }, [userData?.id, userType]);

  useEffect(() => {
    if (addFood && date) {
      return db
        .collection("AthleteNutrition")
        .doc(userData?.id)
        .collection("nutrition")
        .doc(date)
        .get()
        .then((snapshot) => {
          if (!snapshot.exists) return;
          const athleteFood = snapshot.data().entireFood;
          const newEntireFood = entireFood.slice();
          athleteFood.forEach((meal) => {
            if (meal.addedByAthlete) {
              newEntireFood.push(meal);
            } else {
              meal.food.forEach((foodItem) => {
                const index = newEntireFood.findIndex(
                  (m) => m.meal === meal.meal && !m.addedByAthlete
                );
                if (index !== -1) {
                  const newFood = newEntireFood[index].food.slice();
                  if (foodItem.addedByAthlete) {
                    newFood.push(foodItem);
                  } else {
                    const foodIndex = newFood.findIndex(
                      (f) => f.foodName === foodItem.foodName
                    );
                    if (foodIndex !== -1) {
                      newFood[foodIndex] = {
                        ...newFood[foodIndex],
                        ...foodItem,
                        logged: true,
                      };
                    }
                  }
                  newEntireFood[index] = {
                    ...newEntireFood[index],
                    food: newFood,
                  };
                }
              });
            }
          });
          setEntireFood(newEntireFood);
        });
    }
  }, [userData?.id, date, addFood]);

  function handleDateChange(e) {
    setDate(e.target.value);
  }

  function getSubmitAction() {
    if (type === "view") {
      return userType === "coach" || !addFood ? "Back" : "Log Nutrition";
    }
    if (type === "update") {
      return "Update Plan";
    }
    return "Complete Nutrition";
  }

  function setEntireFoodInWeeks(entireFood) {
    const { selectedWeekNum, selectedDay, weeks, setWeeks } = props;
    const weekIndex = weeks.findIndex((w) => w.weeknum === selectedWeekNum);
    if (weekIndex !== -1) {
      const dayData = weeks[weekIndex].days[selectedDay];
      if (dayData) {
        const newWeeks = weeks.slice();
        newWeeks[weekIndex] = {
          ...newWeeks[weekIndex],
          days: {
            ...newWeeks[weekIndex].days,
            [selectedDay]: {
              nutrition: {
                ...newWeeks[weekIndex].days[selectedDay].nutrition,
                entireFood,
              },
            },
          },
        };
        setWeeks(newWeeks);
      }
    }
  }

  function setNutritionNameInWeeks(nutritionName) {
    const { selectedWeekNum, selectedDay, weeks, setWeeks } = props;
    const weekIndex = weeks.findIndex((w) => w.weeknum === selectedWeekNum);
    if (weekIndex !== -1) {
      const dayData = weeks[weekIndex].days[selectedDay];
      if (dayData) {
        const newWeeks = weeks.slice();
        newWeeks[weekIndex] = {
          ...newWeeks[weekIndex],
          days: {
            ...newWeeks[weekIndex].days,
            [selectedDay]: {
              nutrition: {
                ...newWeeks[weekIndex].days[selectedDay].nutrition,
                nutritionName,
              },
            },
          },
        };
        setWeeks(newWeeks);
      }
    }
  }

  console.log("entiregood", entireFood);

  return (
    <div className="createNutrition">
      {props.headerflagnutcreate != "true" && (
        <NutritionScreenHeader
          name={type === "view" ? "View Nutrition" : "Create Nutrition"}
        />
      )}
      <img src="/assets/nutrition.png" alt="" />
      {(type !== "view" || nutritionName) && (
        <div
          className="createNutrition__input"
          style={{
            padding: 20,
          }}
        >
          <h4 style={{ marginTop: 10, marginBottom: 10 }}>
            Nutrition Plan Name
          </h4>
          <input
            type="text"
            placeholder="Enter Nutrition Plan Name"
            value={nutritionName}
            readOnly={type === "view"}
            onChange={(e) => {
              setNutritionName(e.target.value);
              if (props.isLongTerm) {
                setNutritionNameInWeeks(e.target.value);
              }
            }}
          />
        </div>
      )}
      {type !== "view" || userType === "coach" ? (
        <div>
          {type !== "view" && props.type != "longTerm-create" && (
            <div
              {...getRootProps()}
              style={{
                marginLeft: 20,
              }}
            >
              <h4 style={{ marginTop: 10, marginBottom: 10 }}>
                Search for Athletes
              </h4>
              <InputWrapper
                ref={setAnchorEl}
                className={focused ? "focused" : ""}
              >
                {value.map((option, index) => (
                  <Tag label={option.name} {...getTagProps({ index })} />
                ))}

                <input {...getInputProps()} />
              </InputWrapper>
            </div>
          )}
          {groupedOptions.length > 0 ? (
            <Listbox {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <span>{option.name}</span>
                  <CheckIcon fontSize="small" />
                </li>
              ))}
            </Listbox>
          ) : null}
          <div>
            <Grid
              container
              spacing={4}
              className="coachNutrition__homeContainer"
            >
              {selectedAthletes1?.map((athlete, index) => (
                <Grid
                  item
                  xs={5}
                  className="coachNutrition__homeRightContainer"
                >
                  <div
                    id="backdivwkt"
                    className="backdivwkt"
                    key={index}
                    style={{
                      marginTop: "25px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: 5,
                      padding: 15,
                      boxSizing: "border-box",
                      marginLeft: "33px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "var(--primarycolor)",
                        borderRadius: "10px",
                        height: "45px",
                        width: "100%",
                      }}
                    >
                      <img
                        style={{
                          width: "36px",
                          height: "36px",
                          borderRadius: "18px",
                          marginLeft: "20px",
                          marginRight: "20px",
                        }}
                        src={
                          athlete.imageUrl
                            ? athlete.imageUrl
                            : "https://firebasestorage.googleapis.com/v0/b/wellzap-22b06.appspot.com/o/images%2FuserImage.jpeg?alt=media&token=92ce4f61-3c75-421a-888f-df954a58c516"
                        }
                      />
                      <h2
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          lineHeight: "28px",
                          color: "black",
                        }}
                      >
                        {athlete.name}
                      </h2>
                    </div>
                    <h2
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        marginTop: "10px",
                        lineHeight: "28px",
                        marginLeft: "1%",
                      }}
                    >
                      Select days
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "300px",
                        }}
                      >
                        <ChevronLeftIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            var curr = new Date(athlete.currentStartWeek); // get current date
                            // get current date
                            var first = curr.getDate() - curr.getDay() - 7; // First day is the  day of the month - the day of the week \

                            var firstday = new Date(
                              curr.setDate(first)
                            ).toUTCString();
                            var lastday = new Date(
                              curr.setDate(curr.getDate() + 6)
                            ).toUTCString();
                            // if (
                            //   new Date(athlete.currentStartWeek) > new Date()
                            // ) {
                              let temp = { ...athlete_dates };
                              let tm = [...selectedAthletes1];
                              tm[index].currentStartWeek =
                                formatSpecificDate(firstday);

                              tm[index].currentEndWeek =
                                formatSpecificDate(lastday);
                              setSelectedAthletes1(tm);

                              let tmp = formatSpecificDate(firstday);

                              let datesCollection = [];

                              for (var i = 0; i < 7; i++) {
                                datesCollection.push(tmp);
                                tmp = incr_date(tmp);
                              }

                              temp[athlete.id] = datesCollection;

                              setathlete_dates(temp);
                            // }
                          }}
                          className="leftbtnwkt"
                        />

                        {daysList.map((day, idx) => (
                          <button
                            disabled={type == "update"}
                            id="insidescrollwkt"
                            className="insidescrollwkt"
                            key={idx}
                            onClick={() => {
                              if (type !== "view") {
                                if (
                                  athlete?.selectedDays?.includes(
                                    athlete_dates[athlete.id][idx]
                                  )
                                ) {
                                  let selected =
                                    selectedAthletes[index].selectedDays;
                                  var index1 = selected.indexOf(
                                    athlete_dates[athlete.id][idx]
                                  );
                                  if (index1 !== -1) {
                                    selected.splice(index1, 1);
                                    selectedAthletes[index] = {
                                      ...selectedAthletes[index],
                                      selected,
                                    };
                                    setSelectedAthletes([...selectedAthletes]);
                                  }
                                } else {
                                  // if (
                                  //   new Date(athlete_dates[athlete.id][idx]) >
                                  //     new Date() ||
                                  //   athlete_dates[athlete.id][idx] ===
                                  //     formatDate()
                                  // ) {
                                    let selectedDays =
                                      selectedAthletes[index].selectedDays;
                                    selectedAthletes[index] = {
                                      ...selectedAthletes[index],
                                      selectedDays: [
                                        ...selectedDays,
                                        athlete_dates[athlete.id][idx],
                                      ],
                                    };
                                    let temp = athlete_selecteddays;
                                    temp[selectedAthletes[index].id] =
                                      selectedAthletes[index].selectedDays;

                                    setathlete_selecteddays(temp);
                                    setSelectedAthletes([...selectedAthletes]);
                                  // }
                                }
                              }
                            }}
                            style={
                              athlete?.selectedDays?.includes(
                                athlete_dates[athlete.id][idx]
                              )
                                ? {
                                    backgroundColor: "var(--primarycolor)",
                                    color: "#fff",
                                    border: "none",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "relative",
                                    borderRadius: "8px",
                                    marginRight: "2px",

                                    cursor: "pointer",
                                  }
                                : {
                                    border: "none",
                                    width: "40px",

                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "relative",
                                    borderRadius: "8px",
                                    marginRight: "2px",
                                    cursor: "pointer",
                                  }
                            }
                          >
                            <div>
                              <div
                                className="daywkt"
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                  width: "80%",
                                  textAlign: "center",
                                  padding: "5px",
                                  color:"black"
                                    // new Date(athlete_dates[athlete.id][idx]) <
                                    // new Date(formatDate())
                                    //   ? "grey"
                                    //   : "black",
                                }}
                              >
                                {day}
                              </div>

                              <div
                                style={{
                                  width: "45px",
                                  height: "30px",
                                }}
                                key={idx}
                              >
                                <div
                                  className="datewkt"
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "500",
                                    lineHeight: "18px",
                                    width: "100%",
                                    marginLeft: -5,
                                    paddingBottom: "5px",
                                    textAlign: "center",
                                    color:"black"
                                      // new Date(athlete_dates[athlete.id][idx]) <
                                      // new Date(formatDate())
                                      //   ? "grey"
                                      //   : "black",
                                  }}
                                >
                                  {formatSpecificDate1(
                                    athlete_dates[athlete.id][idx]
                                  )}
                                </div>
                              </div>
                            </div>
                          </button>
                        ))}

                        <ChevronRightIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            var curr = new Date(athlete.currentStartWeek); // get current date
                            var first = curr.getDate() - curr.getDay() + 7; // First day is the  day of the month - the day of the week \

                            var firstday = new Date(
                              curr.setDate(first)
                            ).toUTCString();
                            var lastday = new Date(
                              curr.setDate(curr.getDate() + 6)
                            ).toUTCString();

                            let temp = { ...athlete_dates };
                            let tm = [...selectedAthletes1];

                            tm[index].currentStartWeek =
                              formatSpecificDate(firstday);

                            tm[index].currentEndWeek =
                              formatSpecificDate(lastday);
                            setSelectedAthletes1(tm);

                            let tmp = formatSpecificDate(firstday);

                            let datesCollection = [];

                            for (var i = 0; i < 7; i++) {
                              datesCollection.push(tmp);
                              tmp = incr_date(tmp);
                            }

                            temp[athlete.id] = datesCollection;

                            setathlete_dates(temp);
                          }}
                          className="rightbtnwkt"
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      ) : null}
      {type === "view" && userType === "athlete" && dates && (
        <div className="createNutrition__input" style={{ padding: "0 20px" }}>
          {useDatePicker ? (
            <DatePicker
              placeholder="Set Date"
              dateFormat="yyyy-MM-dd"
              confirmBtnText="Confirm"
              cancelBtnText="Cancel"
              showIcon={false}
              selected={moment(selectedDate, "YYYY-MM-DD").toDate()}
              onChange={(date) => {
                setSelectedDate(moment(date).format("YYYY-MM-DD"));
              }}
            />
          ) : (
            <FormControl className={classes.formControl}>
              <InputLabel id="nutrition-date-label">Select date</InputLabel>
              <Select
                labelId="nutrition-date-label"
                id="nutrition-date"
                value={date}
                onChange={handleDateChange}
              >
                {dates.map((d) => (
                  <MenuItem key={d} value={d}>
                    {d}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      )}

      <div className="coachAddMeal__form" style={{ marginTop: "40px" }}>
        <div className="athleteAddMeal__typeOfMeal">
          {entireFood?.map((item, idx) => (
            <div className="athleteAddMealfood__container" key={idx}>
              <FormControl className={classes.formControl}>
                <InputLabel id="meal-select-label">
                  Select the type of meal
                </InputLabel>
                <Select
                  labelId="meal-select-label"
                  id="meal-select-label"
                  value={item.meal}
                  onChange={(e) => {
                    let temp = [...entireFood];
                    temp[idx].meal = e.target.value;
                    setEntireFood(temp);
                    if (props.isLongTerm) {
                      setEntireFoodInWeeks(temp);
                    }
                  }}
                  readOnly={type === "view" && !item.allowUpdate}
                >
                  <MenuItem value={"Breakfast"}>Breakfast</MenuItem>
                  <MenuItem value={"Lunch"}>Lunch</MenuItem>
                  <MenuItem value={"Snack"}>Snack</MenuItem>
                  <MenuItem value={"Pre Workout"}>Pre Workout</MenuItem>
                  <MenuItem value={"Post Workout"}>Post Workout</MenuItem>
                  <MenuItem value={"Dinner"}>Dinner</MenuItem>
                </Select>
              </FormControl>

              {addFood ? (
                <div>
                  {item.food?.map((item2, idx2) => {
                    return (
                      <AddFoodCard
                        type={type}
                        userType={userType}
                        item={item2}
                        idx={idx2}
                        key={idx2}
                        ent={item}
                        entireFood={entireFood}
                        index={idx}
                        serverData={serverData}
                        setEntireFood={setEntireFood}
                      />
                    );
                  })}
                  {props.isLongTerm ||
                  (type === "view" && userType === "coach") ? null : (
                    <div style={{ display: "flex" }}>
                      <div
                        className="foodCard__addfoodButton"
                        onClick={() => {
                          let foodData = [...entireFood];
                          let temp = [...item.food];
                          temp.push({
                            foodName: "",
                            proteins: 0,
                            carbs: 0,
                            fat: 0,
                            calories: 0,
                            quantity: 1,
                            logged: type === "view" ? true : undefined,
                            addedByAthlete: type === "view" ? true : undefined,
                          });
                          foodData[idx].food = temp;

                          setEntireFood(foodData);
                          if (props.isLongTerm) {
                            setEntireFoodInWeeks(foodData);
                          }
                        }}
                      >
                        <h3 style={{ color: "var(--secondarycolor)" }}>
                          Add Food
                        </h3>
                      </div>
                      {item.addedByAthlete && (
                        <div
                          className="foodCard__addfoodButton"
                          onClick={() => {
                            let foodData = [...entireFood];
                            foodData.splice(idx, 1);
                            setEntireFood(foodData);
                            if (props.isLongTerm) {
                              setEntireFoodInWeeks(foodData);
                            }
                          }}
                        >
                          <h3 style={{ color: "var(--secondarycolor)" }}>
                            Delete Meal
                          </h3>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="coachAddMeal__textArea">
                  <h4 style={{ margin: 0, marginBottom: 10 }}>Description</h4>
                  <textarea
                    type="text"
                    placeholder="Enter Meal Description"
                    value={item.description ? item.description : null}
                    readOnly={type === "view" && userType === "coach"}
                    onChange={(e) => {
                      let temp = [...entireFood];
                      temp[idx].description = e.target.value;
                      setEntireFood(temp);
                      if (props.isLongTerm) {
                        setEntireFoodInWeeks(temp);
                      }
                    }}
                  />
                </div>
              )}
            </div>
          ))}

          {/* {(!props.isLongTerm && !(type === "view" && userType === "coach")) ||
            (props.type == "longTerm-create" && (
              <div
                className="coachFoodCard__addmealButton"
                onClick={() => {
                  setEntireFood([
                    ...entireFood,
                    {
                      meal: "",
                      description: "",
                      allowUpdate: true,
                      addedByAthlete:
                        type === "view" && userType === "athlete"
                          ? true
                          : undefined,
                      food: addFood
                        ? [
                            {
                              foodName: "",
                              proteins: 0,
                              carbs: 0,
                              fat: 0,
                              calories: 0,
                              quantity: 1,
                              logged:
                                type === "view" && userType === "athlete"
                                  ? true
                                  : undefined,
                              addedByAthlete:
                                type === "view" && userType === "athlete"
                                  ? true
                                  : undefined,
                            },
                          ]
                        : [],
                    },
                  ]);
                }}
              >
                <h3 style={{ color: "var(--secondarycolor)" }}>Add Meal</h3>
              </div>
            ))} */}

          {type !== "view" && (
            <div
              className="coachFoodCard__addmealButton"
              onClick={() => {
                const newEntireFood = [
                  ...entireFood,
                  {
                    meal: "",
                    description: "",
                    addedByAthlete: userType === "athlete",
                    allowUpdate: true,
                    addFood: true,
                    food: [
                      {
                        foodName: "",
                        proteins: 0,
                        carbs: 0,
                        fat: 0,
                        calories: 0,
                        quantity: 1,
                        addedByAthlete: userType === "athlete",
                        logged: userType === "athlete",
                      },
                    ],
                  },
                ];
                setEntireFood(newEntireFood);
                if (props.isLongTerm) {
                  setEntireFoodInWeeks(newEntireFood);
                }
              }}
            >
              <h3 style={{ color: "var(--secondarycolor)" }}>Add Meal</h3>
            </div>
          )}

          {userType !== "coach" && (
            <div
              className="coachFoodCard__addmealButton"
              onClick={() => {
                const newEntireFood = [
                  ...entireFood,
                  {
                    meal: "",
                    description: "",
                    addedByAthlete: userType === "athlete",
                    allowUpdate: true,
                    addFood: true,
                    food: [
                      {
                        foodName: "",
                        proteins: 0,
                        carbs: 0,
                        fat: 0,
                        calories: 0,
                        quantity: 1,
                        addedByAthlete: userType === "athlete",
                        logged: userType === "athlete",
                      },
                    ],
                  },
                ];
                setEntireFood(newEntireFood);
                if (props.isLongTerm) {
                  setEntireFoodInWeeks(newEntireFood);
                }
              }}
            >
              <h3 style={{ color: "var(--secondarycolor)" }}>Add Meal</h3>
            </div>
          )}

          {!props.isLongTerm && (
            <div
              className="coachFoodCard__submitMealButton"
              onClick={() => {
                if (type === "view" && userType === "athlete" && addFood) {
                  const meals = [];
                  const isFoodNameEmpty = entireFood.some((meal) => {
                    return meal.food.some((foodItem) => !foodItem.foodName);
                  });
                  if (isFoodNameEmpty) {
                    alert("Please enter food name");
                  } else {
                    entireFood.forEach((meal) => {
                      const mealClone = { ...meal };
                      mealClone.food = mealClone.food.filter(
                        (foodItem) => foodItem.logged
                      );
                      if (mealClone.food.length > 0) {
                        meals.push(mealClone);
                      }
                    });
                    db.collection("AthleteNutrition")
                      .doc(userData?.id)
                      .collection("nutrition")
                      .doc(useDatePicker ? selectedDate : date)
                      .set({
                        date: firebase.firestore.FieldValue.serverTimestamp(),
                        entireFood: meals,
                      });
                    history.goBack();
                  }
                } else if (
                  (type === "view" && userType === "coach") ||
                  (type === "view" && userType === "athlete" && !addFood)
                ) {
                  history.goBack();
                } else {
                  if (type === "update") {
                    db.collection("Food")
                      .doc(nutritionId)
                      .update({
                        nutrition: {
                          nutritionName: nutritionName,
                          entireFood,
                        },
                        saved: false,
                      });
                    history.goBack();
                  } else {
                    let tempDate1 = [];
                    selectedAthletes.map((athlete) => {
                      athlete.selectedDays.map((d) => {
                        tempDate1.push(d);
                      });
                    });

                    if (selectedAthletes && tempDate1.length > 0) {
                      selectedAthletes.map((athlete, idx) => {
                        // sendPushNotification(
                        //   athlete.token,
                        //   "new Nutrition Plan assigned"
                        // );
                        db.collection("Food").add({
                          from_id: userData?.id,
                          assignedTo_id: athlete.id,
                          selectedDays: athlete.selectedDays,
                          nutrition: {
                            nutritionName: nutritionName,
                            entireFood,
                          },
                          saved: false,
                          selectedAthletes,
                          timestamp:
                            firebase.firestore.FieldValue.serverTimestamp(),
                          date: firebase.firestore.FieldValue.serverTimestamp(),
                        });
                      });

                      selectedAthletes.forEach((id) => {
                        db.collection("AthleteNotifications")
                          .doc(id.id)
                          .collection("notifications")
                          .add({
                            message: "New Nutrition plan assigned",
                            seen: false,
                            timestamp:
                              firebase.firestore.FieldValue.serverTimestamp(),
                            coach_id: userData.id,
                          });
                      });
                      history.push({
                        pathname: "/nutrition",
                      });
                    } else {
                      alert("Please select an athlete and assign a date");
                    }
                  }
                }
              }}
            >
              <h3 style={{ color: "var(--secondarycolor)" }}>
                {getSubmitAction()}
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewNutrition;
