import Button from '@material-ui/core/Button';
import React from 'react';
import { customStyles } from '../Styles/UIStyles';


export const WebsiteContactForm = () => {
    const styles = customStyles();
    return (
        <div>
            <h4 className={styles.section_header}>
                Contact
            </h4>
            <br />
            <br />
            <Button className={styles.primary_button} type="submit" id="manage_contact" name="manage_contact" variant="outlined">
                Manage Contact
            </Button>
            <br />
            <br />
        </div >
    )
}
