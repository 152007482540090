import { Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Grid, IconButton, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Delete from '@material-ui/icons/DeleteOutline';
import React, { useEffect, useState } from 'react';
import Loader from "react-loader-spinner";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUser } from '../../../../features/userSlice';
import db from '../../services/Firebase';
import { customStyles } from '../Styles/UIStyles';
import "./WebsiteForms.css"



export const ManageBlogs = () => {
  const styles = customStyles();
  const history = useHistory();
  const userEmail = useSelector(selectUser)
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true)

  const fetchBlogsInfo = async () => {
    const list = [];
    var snapshot = await db.collection('BlogsInformation')
      .where("user_id", "==", userEmail)
      .get();
    snapshot.forEach((doc) => {
      list.push({ data: doc.data(), id: doc.id });
    });
    setBlogs(list);
    setLoading(false);
  };

  useEffect(() => {
    fetchBlogsInfo();
  }, [])

  function deleteBlog(blog) {
    db.collection('BlogsInformation')
      .doc(blog.id)
      .delete()
      .then(resp => {
        window.location.reload();
      })
      .catch((error) => {
        alert(error.message);
      });
  }

  return (

    <div id="manageblogs">
      <div>
        <h2 className={styles.section_header}>
          <p>
            <label htmlFor="icon-button-file">
              <IconButton style={{ color: 'black' }} aria-label="back-button" onClick={() => history.push("/coach-website")} >
                <ArrowBackIosIcon fontSize="small" />
              </IconButton>
            </label>
            Manage Blogs
          </p>
        </h2>
        <Button variant="outlined" className={styles.primary_button} onClick={() => history.push("/add-blog")}> Add Blogs </Button>
      </div>
      <br />
      <br />
      <Grid container justifyContent="flex-start" spacing={1}>
        {
          loading && (
            <div className={styles.dialog} >
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
              />
            </div>
          )
        }
        {
          !loading && blogs.map(each_blog => {
            return (
              <Grid item key={each_blog.id}>
                <Card variant='outlined'
                  className={styles.blog_card}
                >
                  <CardHeader className={styles.blog_header}
                    action={
                      <IconButton className={styles.blog_header} aria-label="delete" onClick={() => deleteBlog(each_blog)}>
                        <Delete />
                      </IconButton>
                    }
                  />
                  <CardActionArea className={styles.blog_actionarea}
                    onClick={() => history.push(`/update-blog/${each_blog.id}`)}
                  >
                    <CardMedia className={styles.blog_media}
                      component="img"
                      title='Click to edit this blog information'
                      src={each_blog.data.cover_image}
                    />
                    <CardContent className={styles.blog_content}>
                      <Typography variant="h6" color="textPrimary">
                        {each_blog.data.blog_title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
    </div>
  )
}
