import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { green, red } from '@material-ui/core/colors';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import React, { useEffect, useState } from 'react';
import Loader from "react-loader-spinner";
import { useHistory } from 'react-router-dom';
import db from '../../services/Firebase';
import TemplateOne from '../../static/images/Template/TemplateOne.jpeg';
import TemplateThree from '../../static/images/Template/TemplateThree.jpeg';
import TemplateTwo from '../../static/images/Template/TemplateTwo.jpeg';
import { customStyles, DarkSwitch } from '../Styles/UIStyles';
import { useSelector } from "react-redux";
import { selectUser } from '../../../../features/userSlice'
import "./WebsiteForms.css"

export const Themes = () => {
    const history = useHistory();
    const styles = customStyles()
    const userEmail = useSelector(selectUser)


    const [isDarkMode, setIsDarkMode] = useState(() => false);
    const [selectedTemplate, setSelectedTemplate] = useState("TemplateOne")
    const [selectedBgColor, setSelectedBgColor] = useState("#FFE486")
    const [loading, setLoading] = useState(true)
    const [errorText, setErrorText] = useState("")
    const [existingDomainName, setExistingDomainName] = useState("")
    const [domainName, setDomainName] = useState("")
    const [isValid, checkIsValid] = useState("")
    const [showDialog, setShowDialog] = useState(false)
    const [confirmButton, setConfirmButton] = useState()
    const templateOneUrl = process.env.REACT_APP_WEBSITE_TEMPLATE_BASE_URL + "/ShowTemplateOne"
    const templateTwoUrl = process.env.REACT_APP_WEBSITE_TEMPLATE_BASE_URL + "/ShowTemplateTwo"
    const templateThreeUrl = process.env.REACT_APP_WEBSITE_TEMPLATE_BASE_URL + "/ShowTemplateThree"

    const handleChange = () => {
        setIsDarkMode(!isDarkMode)
    };

    function saveTemplateDetails() {
        setShowDialog(!showDialog);
        const doc = db.collection('WebsiteThemeInformation')
            .doc(userEmail)
            .get();
        if (!doc) {
            db.collection('WebsiteThemeInformation').doc(userEmail)
                .add({
                    user_id: userEmail,
                    template_name: selectedTemplate,
                    background_color: selectedBgColor,
                    dark_mode: isDarkMode,
                    domain_name: domainName
                })
                .then(() => {
                    setShowDialog(!showDialog);
                    history.push("/coach-website")
                })
                .catch((error) => {
                    alert(error.message);
                });
        }
        else {
            db.collection('WebsiteThemeInformation')
                .doc(userEmail)
                .set({
                    user_id: userEmail,
                    template_name: selectedTemplate,
                    background_color: selectedBgColor,
                    dark_mode: isDarkMode,
                    domain_name: domainName
                })
                .then(() => {
                    setShowDialog(!showDialog);
                    history.push("/coach-website")
                })
                .catch((error) => {
                    alert(error.message);
                });
        }
    };

    function validateDomain(event) {
        const website_name = event.target.value.toLowerCase()
        if (website_name !== existingDomainName && website_name.length > 0) {
            const execute = () => {
                const record = db.collection('WebsiteThemeInformation').where("domain_name", "==", website_name);
                return record.get()
                    .then((doc) => {
                        if (doc.docs.length > 0) {
                            checkIsValid("NOK")
                            setConfirmButton(false)
                        } else {
                            checkIsValid("OK")
                            setConfirmButton(true)
                        }
                    });
            };
            execute()
        }
        else if (website_name.length === 0 || website_name === existingDomainName) {
            checkIsValid("")
        }
        else {
            checkIsValid("NOK")
            setConfirmButton(false)
        }
    };

    function checkDomainName(event) {
        const website_name = event.target.value
        if (website_name.length === 0) {
            setErrorText('Website name is mandatory')
            setDomainName("")
            setConfirmButton(false)
        } else if (website_name.length > 50) {
            setErrorText('Should be less than 50 characters')
            setDomainName(website_name.toLowerCase())
            setConfirmButton(false)
        } else if (!website_name.match(/^[aA-zZ0-9\s]+$/)) {
            setErrorText('Only alphabets and digits are allowed')
            setDomainName(website_name.toLowerCase())
            setConfirmButton(false)
        } else {
            setErrorText("")
            setDomainName(website_name.toLowerCase())
            setConfirmButton(true)
        }
        validateDomain(event);
    };

    const fetchWebsiteThemeInfo = async () => {
        const contactRef = await db.collection('WebsiteThemeInformation');
        const userContactInfo = contactRef.where("user_id", "==", userEmail);
        userContactInfo.get().then(snapshot => {
            snapshot.docs.forEach(doc => {
                setSelectedTemplate(doc.data().template_name)
                setSelectedBgColor(doc.data().background_color)
                setIsDarkMode(doc.data().dark_mode)
                setExistingDomainName(doc.data().domain_name)
                setDomainName(doc.data().domain_name)
                setConfirmButton(true)
            })
            setLoading(false)
        })
    };

    useEffect(() => {
        fetchWebsiteThemeInfo();
    }, [])

    return (
        <div id="uniqueforms">
            <h1 className={styles.section_header}>
                <label htmlFor="icon-button-file">
                    <IconButton className={styles.icon_button} aria-label="back-button" onClick={() => history.push("/")} >
                        <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                </label>
                Create/Update Website
            </h1>
            <br />
            <h2 className={styles.section_header}>
                Website Builder
            </h2>
            <br />
            <p>Welcome to the personal website builder</p>
            <p>Select a Name for your Website</p>
            <br />
            {
                loading && (
                    <Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                )
            }
            {
                !loading && (
                    <div>
                        {
                            existingDomainName ?
                                <div>
                                    <h4 className={styles.section_header}>
                                        Domain Name
                                    </h4>
                                    <h3 style={{ display: "inline-block", color: 'black' }}> https://{existingDomainName}.wellzap.net </h3>
                                    <br />
                                    <p style={{ display: 'inline-block', color: 'red' }}>* Contact system administrator for changing domain name.</p>
                                </div>
                                :
                                <div className="url">
                                    <p style={{ display: 'inline-block', color: 'red' }}>* Domain name can be configured only once</p>
                                    <br />
                                    <p style={{ display: "inline-block" }}>https://{' '}</p>
                                    <TextField className={styles.textfield} autoFocus id="subdomain_name" name="subdomain_name" label="Enter a name" variant='outlined' error={errorText.length === 0 ? false : true} helperText={errorText} value={domainName} style={{ display: "inline-block" }} onChange={checkDomainName} onFocus={checkDomainName} autoComplete="off" />
                                    <p style={{ display: "inline-block" }}>{' '}.wellzap.net</p>
                                </div>
                        }
                        {
                            isValid.length ? isValid === 'OK' ?
                                <div> <p style={{ display: "inline-block" }}> https://{domainName}.wellzap.io </p> <CheckCircleIcon style={{ color: green[500] }} /> <label style={{ color: 'green' }}>Name Available</label></div>
                                :
                                <div> <p style={{ display: "inline-block" }}> https://{domainName}.wellzap.io </p> <InfoIcon style={{ color: red[500] }} /> <label style={{ color: 'red' }}>Name is in use</label></div>
                                :
                                null
                        }
                        <br />
                        <br />
                        <div>
                            <h4 className={styles.section_header}>
                                Select a Template
                            </h4>
                            <br />
                            <br />
                            <Grid container justifyContent="flex-start" spacing={1} alignItems="stretch">
                                {
                                    selectedTemplate === 'TemplateOne' ?
                                        <Grid item className={styles.template_item} >
                                            <Card className={styles.selected_template_card} onClick={() => setSelectedTemplate("TemplateOne")}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={styles.template_media}
                                                        image={TemplateOne}
                                                        title="TemplateOne"
                                                    />
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        :
                                        <Grid item className={styles.template_item} >
                                            <Card className={styles.select_template_card} onClick={() => setSelectedTemplate("TemplateOne")}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={styles.template_media}
                                                        image={TemplateOne}
                                                        title="TemplateOne"
                                                    />
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                }
                                {
                                    selectedTemplate === 'TemplateTwo' ?
                                        <Grid item className={styles.template_item} >
                                            <Card className={styles.selected_template_card} onClick={() => setSelectedTemplate("TemplateTwo")}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={styles.template_media}
                                                        image={TemplateTwo}
                                                        title="TemplateTwo"
                                                    />
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        :
                                        <Grid item className={styles.template_item} >
                                            <Card className={styles.select_template_card} onClick={() => setSelectedTemplate("TemplateTwo")}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={styles.template_media}
                                                        image={TemplateTwo}
                                                        title="TemplateTwo"
                                                    />
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                }
                                {
                                    selectedTemplate === 'TemplateThree' ?
                                        <Grid item className={styles.template_item} >
                                            <Card className={styles.selected_template_card} onClick={() => setSelectedTemplate("TemplateThree")}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={styles.template_media}
                                                        image={TemplateThree}
                                                        title="TemplateThree"
                                                    />
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                        :
                                        <Grid item className={styles.template_item} >
                                            <Card className={styles.select_template_card} onClick={() => setSelectedTemplate("TemplateThree")}>
                                                <CardActionArea>
                                                    <CardMedia
                                                        className={styles.template_media}
                                                        image={TemplateThree}
                                                        title="TemplateThree"
                                                    />
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                }
                            </Grid>
                        </div>
                        <br />
                        <br />
                        <div>
                            <h4 className={styles.section_header}>
                                Select Colour Scheme
                            </h4>
                            <br />
                            <br />
                            <div>
                                {
                                    selectedTemplate === 'TemplateThree' ?
                                        null
                                        :
                                        <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item><p>Dark Mode</p></Grid>
                                            <Grid item>
                                                <DarkSwitch checked={isDarkMode} onChange={handleChange} name="dark_mode" />
                                            </Grid>
                                        </Grid>
                                }

                            </div>
                        </div >
                        <br />
                        <br />
                        <div>
                            <div>
                                <FormGroup row>
                                    {
                                        selectedBgColor === "#FFE486" ?
                                            <FormControlLabel className={styles.color_box} control={
                                                <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#FFE486", border: '3px solid black' }} square name='#FFE486' onClick={() => setSelectedBgColor("#FFE486")} />
                                            } />
                                            :
                                            <FormControlLabel className={styles.color_box} control={
                                                <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#FFE486" }} square name='#FFE486' onClick={() => setSelectedBgColor("#FFE486")} />
                                            } />
                                    }
                                    <br />
                                    <br />
                                    {
                                        selectedBgColor === "#BD8FDE" ?
                                            <FormControlLabel className={styles.color_box} control={
                                                <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#BD8FDE", border: '3px solid black' }} square name='#BD8FDE' onClick={() => setSelectedBgColor("#BD8FDE")} />
                                            } />
                                            :
                                            <FormControlLabel className={styles.color_box} control={
                                                <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#BD8FDE" }} square name='#BD8FDE' onClick={() => setSelectedBgColor("#BD8FDE")} />
                                            } />
                                    }
                                    <br />
                                    <br />
                                    {
                                        selectedBgColor === "#F86B6B" ?
                                            <FormControlLabel className={styles.color_box} control={
                                                <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#F86B6B", border: '3px solid black' }} square name='#F86B6B' onClick={() => setSelectedBgColor("#F86B6B")} />
                                            } />
                                            :
                                            <FormControlLabel className={styles.color_box} control={
                                                <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#F86B6B" }} square name='#F86B6B' onClick={() => setSelectedBgColor("#F86B6B")} />
                                            } />
                                    }
                                    <br />
                                    <br />
                                    {
                                        selectedBgColor === "#9486FF" ?
                                            <FormControlLabel className={styles.color_box} control={
                                                <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#9486FF", border: '3px solid black' }} square name='#9486FF' onClick={() => setSelectedBgColor("#9486FF")} />
                                            } />
                                            :
                                            <FormControlLabel className={styles.color_box} control={
                                                <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#9486FF" }} square name='#9486FF' onClick={() => setSelectedBgColor("#9486FF")} />
                                            } />
                                    }
                                    <br />
                                    <br />
                                    {
                                        selectedBgColor === "#CFA9FC,#FFE3E6" ?
                                            <FormControlLabel className={styles.color_box} control={
                                                <div className={styles.multicolor_border_div} onClick={() => setSelectedBgColor("#CFA9FC,#FFE3E6")}>
                                                    <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#CFA9FC", width: '50%' }} square name='#CFA9FC' />
                                                    <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#FFE3E6", width: '50%' }} square name='#FFE3E6' />
                                                </div>
                                            } />
                                            :
                                            <FormControlLabel className={styles.color_box} control={
                                                <div className={styles.multicolor_div} onClick={() => setSelectedBgColor("#CFA9FC,#FFE3E6")}>
                                                    <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#CFA9FC", width: '50%' }} square name='#CFA9FC' />
                                                    <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#FFE3E6", width: '50%' }} square name='#FFE3E6' />
                                                </div>
                                            } />
                                    }
                                    <br />
                                    <br />
                                    {
                                        selectedBgColor === "#FF86B5,#FFB086" ?
                                            <FormControlLabel className={styles.color_box} control={
                                                <div className={styles.multicolor_border_div} onClick={() => setSelectedBgColor("#FF86B5,#FFB086")}>
                                                    <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#FF86B5", width: '50%' }} square name='#FF86B5' />
                                                    <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#FFB086", width: '50%' }} square name='#FFB086' />
                                                </div>
                                            } />
                                            :
                                            <FormControlLabel className={styles.color_box} control={
                                                <div className={styles.multicolor_div} onClick={() => setSelectedBgColor("#FF86B5,#FFB086")}>
                                                    <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#FF86B5", width: '50%' }} square name='#FF86B5' />
                                                    <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#FFB086", width: '50%' }} square name='#FFB086' />
                                                </div>
                                            } />
                                    }
                                    <br />
                                    <br />
                                    {
                                        selectedBgColor === "#81E8B9,#7BACFC" ?
                                            <FormControlLabel className={styles.color_box} control={
                                                <div className={styles.multicolor_border_div} onClick={() => setSelectedBgColor("#81E8B9,#7BACFC")}>
                                                    <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#81E8B9", width: '50%' }} square name='#81E8B9' />
                                                    <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#7BACFC", width: '50%' }} square name='#7BACFC' />
                                                </div>
                                            } />
                                            :
                                            <FormControlLabel className={styles.color_box} control={
                                                <div className={styles.multicolor_div} onClick={() => setSelectedBgColor("#81E8B9,#7BACFC")}>
                                                    <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#81E8B9", width: '50%' }} square name='#81E8B9' />
                                                    <Paper variant='outlined' elevation={3} style={{ backgroundColor: "#7BACFC", width: '50%' }} square name='#7BACFC' />
                                                </div>
                                            } />
                                    }
                                </FormGroup>
                            </div>
                        </div>
                        <br />
                        <br />
                        <Grid container justifyContent="flex-start" spacing={1} alignItems="stretch">
                            <Grid item className={styles.template_item} style={{ padding: '0.5em' }} >
                                {
                                    selectedTemplate === 'TemplateOne' &&
                                    <Button variant="outlined" className={styles.secondary_button} style={{ width: '120px' }}>
                                        <a style={{ textDecoration: 'none' }} href={templateOneUrl} target="_blank" rel="noreferrer"> Preview </a>
                                    </Button>
                                }
                                {
                                    selectedTemplate === 'TemplateTwo' &&
                                    <Button variant="outlined" className={styles.secondary_button} style={{ width: '120px' }}>
                                        <a style={{ textDecoration: 'none' }} href={templateTwoUrl} target="_blank" rel="noreferrer"> Preview </a>
                                    </Button>
                                }
                                {
                                    selectedTemplate === 'TemplateThree' &&
                                    <Button variant="outlined" className={styles.secondary_button} style={{ width: '120px' }}>
                                        <a style={{ textDecoration: 'none' }} href={templateThreeUrl} target="_blank" rel="noreferrer"> Preview </a>
                                    </Button>
                                }

                            </Grid>
                            <Grid item className={styles.template_item} style={{ padding: '0.5em' }} >
                                {
                                    // errorText.length > 0 || isValid.length === 0 || isValid !== 'OK' ?
                                    !confirmButton ?
                                        <Button variant="outlined" className={styles.primary_button} style={{ width: '120px' }} disabled> Confirm </Button>
                                        :
                                        <Button variant="outlined" className={styles.primary_button} style={{ width: '120px' }} onClick={saveTemplateDetails}> Confirm </Button>
                                }
                            </Grid>
                        </Grid>
                    </div>
                )
            }
            {
                showDialog && (
                    <Dialog open={showDialog} className={styles.dialog} >
                        <DialogTitle id="theme_form"><p>Saving website template settings...</p></DialogTitle>
                        <div className={styles.dialog} >
                            <Loader
                                type="TailSpin"
                                color="#00BFFF"
                                height={100}
                                width={100}
                            />
                        </div>
                    </Dialog>
                )
            }
        </div>
    )
}