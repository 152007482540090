import React from "react";
import {  useSelector } from "react-redux";
import {  useHistory } from "react-router-dom";
import {
  selectUserData
} from "../../features/userSlice";
import "../Sidebar/Sidebar.css"
import "../../responsive.css"

function Profilepic() {
    const userData = useSelector(selectUserData);
  const history = useHistory();
    return (
        <>
           <img

          src={
            userData?.data?.imageUrl
              ? userData?.data?.imageUrl
              : "https://firebasestorage.googleapis.com/v0/b/stairs-web-app.appspot.com/o/userImage.jpeg?alt=media&token=b50bf709-1e26-4d1a-8fd6-3689f66322c3"
          }
          alt={userData?.data.name}
          width="100px"
          height="100px"
          onClick={() => history.push("/profile")}
          style={{ cursor: "pointer",borderRadius:'50%' }}
        />  
        </>
    )
}

export default Profilepic
