import * as Yup from 'yup';

const validatorSchemaDef = Yup.object().shape({
    header_title: Yup.string()
        .required('Website title is required')
        .max(30, 'Should be within 30 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/!:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed'),
    header_subtitle: Yup.string()
        .required('Website subtitle is required')
        .max(60, 'Should be within 60 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/!:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed'),
    about_title: Yup.string()
        .required('About title is required')
        .max(30, 'Should be within 30 characters of length')
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    about_description: Yup.string()
        .required('About description is required')
        .max(500, 'Should be within 500 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/!:;"'*/\n/()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed'),
    program_title: Yup.string()
        .max(30, 'Should be within 30 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/!:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed'),
    program_subtitle: Yup.string()
        .max(120, 'Should be within 120 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/!:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed'),
    blog_title: Yup.string()
        .max(30, 'Should be within 30 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/!:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed'),
    blog_subtitle: Yup.string()
        .max(120, 'Should be within 120 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/!:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed'),
    download_title: Yup.string()
        .max(30, 'Should be within 30 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/!:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed'),
    download_subtitle: Yup.string()
        .max(120, 'Should be within 120 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/!:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed')

});

export default validatorSchemaDef;
