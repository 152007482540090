import React, { useState , useEffect} from "react";
import { useSelector } from "react-redux";
import { selectUserData } from "../../features/userSlice";
import { db } from "../../utils/firebase";
import ProgressBarComponent from "../ProgressComponent/ProgressBarComponent";
import "./WaterCard.css";
import  formatDate3  from "../../functions/formatDate3";
function WaterCard(props) {
  const userData = useSelector(selectUserData);
  const [updatedWater, setUpdatedWater] = useState(false);
  const [water, setWater] = React.useState(0);
  const [requestDate, setRequestDate] = useState(props.date);
  useEffect(() => {
    
    console.log(userData?.id);
    console.log("zzza" + requestDate)
    if (userData?.id) {
      db.collection("athletes")
        .doc(userData?.id)
        .get()
        .then((doc) => {
          // console.log(doc.data());

          if (doc.data()?.metrics) {
            if (doc.data().metrics[requestDate]) {
              if (doc.data().metrics[requestDate]?.water) {
                setUpdatedWater(true);
                console.log("water : ", doc.data().metrics[requestDate].water);
                setWater(doc.data().metrics[requestDate].water);
              } else {
                setWater(0);
                setUpdatedWater(false);
              }
              
            } else {
              setUpdatedWater(false);
              setWater(0);
            }
          }
        });
    }
  }, [userData?.id, requestDate]);
  return (
    <div className="waterCard">
      <div className="waterCard__container">
        <div style={{ marginLeft: 15, fontSize: 20, fontWeight: 400 }}>
          Water
        </div>
        <div className="waterCard__buttons" style={{ height: 50 }}>
           <button
          style={{padding:'2%'}}
            className="waterCard__button"
            onClick={() => {
              if (water > 0) {
                let temp = { ...userData?.data?.metrics };

                if (temp[props.date]) {
                  let t = { ...temp[props.date] };
                  t.water = water - 1;
                  temp[props.date] = t;
                } else {
                  temp[props.date] = {
                    water: water - 1,
                  };
                }

                db.collection("athletes").doc(userData?.id).update({
                  metrics: temp,
                });
                setWater(water - 1);
              }
            }}
          >
            <p style={{position:'relative',top:'-0.8px',left:'0.3px'}}>-</p>
          </button> 
         <button
            className="waterCard__button"
            onClick={() => {
              let temp = { ...userData?.data?.metrics };

              if (temp[props.date]) {
                let t = { ...temp[props.date] };
                t.water = water + 1;
                temp[props.date] = t;
              } else {
                temp[props.date] = {
                  water: water + 1,
                };
              }

              db.collection("athletes").doc(userData?.id).update({
                metrics: temp,
              });
              setWater(water + 1);
            }}
          >
            <p style={{position:'relative',left:'1px'}}>+</p>
          </button>
        </div>
      </div>
      <div className="waterCard__progress">
        <ProgressBarComponent
          containerWidth={"90%"}
          progress={(water / 10) * 100}
          progressColor="var(--primarycolor)"
        />
      </div>
      <h4 style={{ marginLeft: 15 }}>{water}/10 glasses(250ml)</h4>
    </div>
  );
}

export default WaterCard;
