import React from "react";
import {  useHistory } from "react-router-dom";
import "../Sidebar/Sidebar.css"
import "../../responsive.css"
import { useDispatch, useSelector } from "react-redux";
import {
logout    
  } from "../../features/userSlice";
  import { auth } from "../../utils/firebase"
function Signoutdialog() {
    const dispatch = useDispatch();
    const history = useHistory();
    return (
        <>
            <div style={{textAlign:'center',display:'flex',justifyContent:'center'}}>
        <div
        style={{minWidth:'100px',borderRadius:'15px'}}
        onClick={() => {
          auth.signOut();
          dispatch(logout());
          history.push("/");
        }}
      >
        <h2 className="dialogsignout" style={{borderRadius:'10px',minHeight:'30px'}}>Signout</h2>
      </div>
        </div>
        </>
    )
}

export default Signoutdialog
