import { max } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTemperoryId,
  selectUser,
  selectUserType,
  setTemperoryData,
} from "../../features/userSlice";
import { db, storage } from "../../utils/firebase";
import "./Profile.css";
const timestamp = require("time-stamp");

function AthleteProfileForm({ setTempProfPic }) {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const userType = useSelector(selectUserType);
  const temperoryId = useSelector(selectTemperoryId);

  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [gender, setgender] = useState("");
  const [address, setaddress] = useState("");
  const [dob, setdob] = useState("");
  const [editable, seteditable] = useState(false);
  const [userData, setUserData] = useState(null);
  const [nutrition, setNutrition] = useState([]);
  const [workouts, setWorkouts] = useState([]);
  const [pastWorkouts, setPastWorkouts] = useState([]);
  const [diet, setDiet] = useState("");
  const [carbs, setCarbs] = useState(0);
  const [fat, setFat] = useState(0);
  const [protein, setProtein] = useState(0);
  const [calories, setCalories] = useState(0);
  const [weight, setWeight] = useState(0);
  const [mealHistory, setMealHistory] = useState([]);
  const [profPic, setProfPic] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  let tillDate = timestamp();
  useEffect(() => {
    if (protein != 0 && carbs != 0 && fat != 0) {
      setCalories(
        String(4 * Number(protein) + 4 * Number(carbs) + 9 * Number(fat))
      );
    }
  }, [fat, protein, carbs]);

  const ChangeDiet = (diet) => {
    if (weight && weight != 0) {
      if (diet == "weight maintainance") {
        setCalories(
          String(4 * 1.5 * weight + 4 * 3.5 * weight + 9 * 1 * weight)
        );
        setCarbs(String(3.5 * weight));
        setFat(String(1 * weight));
        setProtein(String(1.5 * weight));
      } else if (diet == "high performance") {
        setCalories(
          String(4 * 1.5 * weight + 4 * 6 * weight + 0.8 * 9 * weight)
        );
        setCarbs(String(6 * weight));
        setFat(String(0.8 * weight));
        setProtein(String(1.5 * weight));
      } else if (diet == "fat loss") {
        setCalories(String(4 * 2 * weight + 4 * 3 * weight + 1 * 9 * weight));
        setCarbs(String(2 * weight));
        setFat(String(1 * weight));
        setProtein(String(2 * weight));
      }
    }
  };

  useEffect(() => {
    if (userType === "coach") {
      db.collection("athletes")
        .doc(temperoryId)
        .get()
        .then(function (snap) {
          setUserData({
            id: temperoryId,
            data: snap.data(),
          });
          setImageUrl(snap.data()?.imageUrl);
          setphone(snap.data().phone);
          setemail(snap.data().email);
          setgender(snap.data().gender);
          setaddress(snap.data().address);
          setDiet(
            snap.data()?.diet ? snap.data().diet.name : "weight maintainance"
          );
          setCarbs(snap?.data()?.diet ? snap.data().diet.carbs : "300");
          setFat(snap.data()?.diet ? snap.data().diet.fat : "50");
          setProtein(snap.data()?.diet ? snap.data().diet.protein : "70");
          setCalories(snap?.data()?.diet ? snap.data().diet.calories : "1930");
          setWeight(snap?.data()?.weight ? snap.data().weight : "80");
          dispatch(
            setTemperoryData({
              id: temperoryId,
              data: snap.data(),
            })
          );

          ChangeDiet();
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    } else {
      db.collection("athletes")
        .where("email", "==", user)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            setUserData({
              id: doc.id,
              data: doc.data(),
            });
            setImageUrl(doc.data()?.imageUrl);
            setphone(doc.data().phone);
            setemail(doc.data().email);
            setgender(doc.data().gender);
            setaddress(doc.data().address);
            setdob(doc.data().dob);
            setDiet(
              doc.data()?.diet?.name
                ? doc.data().diet.name
                : "weight maintainance"
            );
            setCarbs(doc?.data()?.diet?.carbs ? doc.data().diet.carbs : "300");
            setFat(doc.data()?.diet?.fat ? doc.data().diet.fat : "60");
            setProtein(
              doc.data()?.diet?.protein ? doc.data().diet.protein : "100"
            );
            setCalories(
              doc?.data()?.diet?.calories ? doc.data().diet.calories : "2140"
            );
            setWeight(doc?.data()?.weight ? doc.data().weight : "80");
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    }
  }, [user, temperoryId]);

  useEffect(() => {
    let temp = [];

    if (temperoryId) {
      db.collection("AthleteNutrition")
        .doc(temperoryId)
        .collection("nutrition")
        .limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data().entireFood) {
              let tempCal = 0;
              let tempCarbs = 0;
              let tempFat = 0;
              let tempProtein = 0;
              //setEntireFood(doc.data().entireFood);
              doc.data().entireFood.map((foodContainer) => {
                foodContainer.food.map((f) => {
                  tempCal = tempCal + f.calories;
                  tempCarbs = tempCarbs + f.carbs;
                  tempFat = tempFat + f.fat;
                  tempProtein = tempProtein + f.proteins;
                });
              });
              let t = { ...doc.data() };
              t.calories = tempCal;
              t.carbs = tempCarbs;
              t.fat = tempFat;
              t.proteins = tempProtein;
              temp.push({ id: doc.id, data: t });
            }
          });
          setMealHistory(temp);
        });
    }
  }, [temperoryId]);

  const saveprofile = async () => {
    if(phone.length > 12){
      alert("Please enter a valid phone number. Phone number length should not exceed 12 digits.")
      return;
    } 
    let url;
    if (profPic) {
      const storageRef = storage.ref();
      const fileRef = storageRef.child(`/images/${email}/profpic`);
      await fileRef.put(profPic).then(async () => {
        if (profPic) {
          url = await fileRef.getDownloadURL();
        }
      });
    } else {
      url = imageUrl;
    }

    db.collection("athletes")
      .doc(userData?.id)
      .update({
        imageUrl: url ? url : imageUrl,
        phone,
        email,
        gender,
        address,
        dob: dob,
        diet: {
          name: diet,
          carbs,
          fat,
          calories,
          protein,
        },
      })
      .then((res) => seteditable(false))
      .catch((e) => console.log(e));
  };
  const handleProfPic = (e) => {
    if (e.target.files[0]) {
      setProfPic(e.target.files[0]);
      setTempProfPic(e.target.files[0]);
    }
  };
  return (
    <div className="coachProfileForm" style={{ marginLeft: "20px" }}>
      <div className="coachProfile__info">
        <div className="coachProfile__heading">
          <h2 id="profilehead" style={{cursor:'default'}}>Profile</h2>

          <input type="file" onChange={handleProfPic} ref={inputFile} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            {userType !== "coach" &&
              (!editable ? (
                <span
                  style={{ marginLeft: "10px" }}
                  className="editProfileButton"
                  onClick={() => seteditable(true)}
                >
                  <h3 style={{ color: "var(--secondarycolor)" }}>
                    EDIT PROFILE
                  </h3>
                </span>
              ) : (
                <span
                  className="saveProfileButton"
                  onClick={() => saveprofile()}
                >
                  <h3 style={{ color: "var(--secondarycolor)" }}>
                    SAVE PROFILE
                  </h3>
                </span>
              ))}
            {editable && (
              <span
                style={{
                  borderWidth: 1,
                  borderColor: "black",
                  cursor: "pointer",
                }}
                onClick={() => inputFile.current.click()}
              >
                <h3>Update Profile Photo</h3>
              </span>
            )}
          </div>
        </div>{" "}
      </div>
      <form>
        <h4>Mobile Number</h4>
        <input
   type="number"
          onChange={(e) => {
            setphone(e.target.value);
          }}
          value={phone}
          placeholder="+91 56985 45955"
        />
        <h4>Email ID</h4>
        <input
          readOnly
          onChange={(e) => {
            setemail(e.target.value);
          }}
          value={email}
          type="email"
          placeholder="anishchandra@gmail.com"
        />
        <h4>Gender</h4>
        {!editable && (
          <input
            readOnly={!editable}
            onChange={(e) => {
              setgender(e.target.value);
            }}
            value={gender}
            type="text"
            placeholder="Enter Gender"
          />
        )}
        {editable && (
          <div>
            <input
              type="radio"
              value="Male"
              name="gender"
              checked={gender === "Male"}
              onChange={(e) => setgender(e.target.value)}
            />{" "}
            Male
            <input
              type="radio"
              value="Female"
              name="gender"
              checked={gender === "Female"}
              onChange={(e) => setgender(e.target.value)}
              style={{ marginLeft: 20 }}
            />{" "}
            Female
          </div>
        )}
        {/* <input
          readOnly={!editable}
          onChange={(e) => {
            setgender(e.target.value);
          }}
          value={gender}
          type="text"
          placeholder="Enter Gender"
        /> */}
        <h4>Date of Birth</h4>
        <input
          onChange={(e) => {
            setdob(e.target.value.split("-").reverse().join("-"));
          }}
          readOnly={!editable}
          type="date"
          max={tillDate}
          placeholder="Enter Date of Birth"
          value={dob.split("-").reverse().join("-")}
        />
        <h4>Billing Address</h4>
        <textarea
          readOnly={!editable}
          onChange={(e) => {
            setaddress(e.target.value);
          }}
          value={address}
          type="text"
          placeholder="300, Baneerghatta Main Rd, opp to Apollo Hospitals, Sundar Ram Shetty Nagar, Bilekahali, Bengaluru, Karnataka - 560076"
        />
      </form>
    </div>
  );
}
export default AthleteProfileForm;
