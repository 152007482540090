import React, { useEffect, useState } from "react";
import NutritionScreenHeader from "./NutritionScreenHeader";
import "./Nutrition.css";
import { useHistory } from "react-router";
import { selectUserData } from "../../features/userSlice";
import { useSelector } from "react-redux";
import { db, firestore } from "../../utils/firebase";
import NutritionCard from "../../Components/NutritionCard/NutritionCard";
import { Grid } from "@material-ui/core";

function CoachNutritionHome() {
  const history = useHistory();
  const userData = useSelector(selectUserData);
  const [nutrition1, setNutrition1] = useState([]);
  const [nutrition, setNutrition] = useState([]);
  const [savedNutrition, setSavedNutrition] = useState([]);
  const [savedLongTermNutrition, setsavedLongTermNutrition] = useState([]);
  const [LongTermNutrition, setLongTermNutrition] = useState([]);
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    if (nutrition1) {
      let temp = [];

      nutrition1.map((n, i) => {
        if (temp.length === 0) {
          temp.push(n);
        } else {
          temp.map((t, idx) => {
            if (t.data.id !== n.data.id) {
              temp.push(n);
            }
          });
        }
      });
      setNutrition(temp);
    }
  }, [nutrition1]);

  useEffect(() => {
    let data = [];
    if (userData) {
      let coachIds = [userData.id];
      coachIds = coachIds.concat(userData.data?.listOfCoaches || []);
      db.collection("CoachFood")
        // .where("from_id", "in", coachIds)
        .where("saved", "==", false)
        .limit(3)
        .onSnapshot((snapshot) => {
          if (snapshot) {
            data = snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }));

            let filteredData = data.filter((a) =>
              coachIds.includes(a.data.from_id)
            );

            setNutrition(filteredData);
          }
        });

      db.collection("CoachFood")
        .where("from_id", "==", userData?.id)
        .where("assignedTo_id", "==", "")
        .limit(4)
        .onSnapshot((snapshot) => {
          setSavedNutrition(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );
        });

      db.collection("longTermMeal")
        .where("assignedById", "==", userData.id)
        .where("assignedToId", "==", "")
        .limit(4)
        .onSnapshot((snapshot) => {
          setsavedLongTermNutrition(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );
        });
      let data1 = [];
      db.collection("longTermMeal")
        // .where("assignedById", "in", coachIds)
        .where("saved", "==", false)

        .onSnapshot((snapshot) => {
          data1 = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }));

          let filterLong = data1.filter((a) =>
            coachIds.includes(a.data.assignedById)
          );

          setLongTermNutrition(filterLong);
        });
    }
  }, [userData?.id]);

  useEffect(() => {
    if (userData?.data?.listOfCoaches?.length) {
      let coaches = [];
      db.collection("coaches")
        // .where(
        //   firestore.FieldPath.documentId(),
        //   "in",
        //   userData.data.listOfCoaches
        // )
        .get()
        .then((snapshot) => {
          coaches = snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
          }));
          let filteredCoaches = coaches.filter((a) =>
            userData.data.listOfCoaches.includes(a.id)
          );
          filteredCoaches.push({
            id: userData.id,
            name: userData.data.name,
          });
          setCoaches(filteredCoaches);
        });
    }
  }, [userData, userData?.data?.listOfCoaches]);

  return (
    <div
      style={{ minHeight: "99vh" }}
      id="nutid"
      className="coachNutritionHome"
    >
      <NutritionScreenHeader back="/" name="Nutrition" />

      <div
        style={{
          marginTop: 20,
        }}
      >
        <Grid container spacing={2} className="coachNutrition__homeContainer">
          <Grid item xs={6} className="coachNutrition__homeLeftContainer">
            <div
              style={{ width: "90%", paddingLeft: 20 }}
              className="coachNutrition__row"
            >
              <h1 id="cursordefault">Assigned Meal Plans</h1>
              <div onClick={() => history.push("/view-all-nutrition")}>
                View All
              </div>
            </div>
            <div
              style={{ width: "90%", paddingLeft: 20 }}
              className="coachNutrition__list"
            >
              {nutrition.length > 0 ? (
                nutrition?.map((food, idx) => (
                  <NutritionCard
                    key={idx}
                    nutrition={nutrition}
                    food={food}
                    idx={idx}
                    type="view"
                    foodId={food.id}
                    coach={coaches.find((c) => c.id === food.data.from_id)}
                  />
                ))
              ) : (
                <div
                  style={{
                    fontSize: "13px",
                    backgroundColor: "#fff",

                    padding: "10px 20px",
                    textAlign: "center",
                    borderRadius: "5px",
                    fontWeight: "normal",

                    boxSizing: "border-box",
                    marginTop: 20,
                  }}
                >
                  <h5> There are no assigned Meals for now </h5>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={6} className="coachNutrition__homeRightContainer">
            <div style={{ width: "90%" }} className="coachNutrition__row">
              <h1 id="cursordefault">Saved Meal Plans</h1>
              <div onClick={() => history.push("/view-all-saved-nutrition")}>
                View All
              </div>
            </div>
            <div style={{ width: "90%" }} className="coachNutrition__list">
              {savedNutrition.length > 0 ? (
                savedNutrition?.map((food, idx) => (
                  <NutritionCard
                    key={idx}
                    nutrition={nutrition}
                    food={food}
                    idx={idx}
                    foodId={food.id}
                  />
                ))
              ) : (
                <div
                  style={{
                    fontSize: "13px",
                    backgroundColor: "#fff",

                    padding: "10px 20px",
                    textAlign: "center",
                    borderRadius: "5px",
                    fontWeight: "normal",
                    marginTop: 10,

                    boxSizing: "border-box",
                  }}
                >
                  <h5> There are no Saved meals for now </h5>
                </div>
              )}
            </div>
          </Grid>

          <Grid item xs={6} className="coachNutrition__homeLeftContainer">
            <div
              style={{ width: "90%", marginLeft: 20 }}
              className="coachNutrition__row"
            >
              <h1 id="cursordefault">Assigned Long Term Plans</h1>
              <div onClick={() => history.push("/all-LongTerm-Nutrition")}>
                View All
              </div>
            </div>
            <div
              style={{ width: "90%", marginLeft: 20 }}
              className="coachNutrition__list"
            >
              {LongTermNutrition.length > 0 ? (
                LongTermNutrition?.map((food, idx) => (
                  <NutritionCard
                    key={idx}
                    weeks={food.data.weeks}
                    isLongTerm={true}
                    idx={idx}
                    food={food.data}
                    selectedWeekNum={food.data.weeks[0].weeknum}
                    navigate={true}
                    type="view"
                    nutritionName={food?.data?.nutritionName}
                    foodId={food.id}
                    coach={coaches.find((c) => c.id === food.data.assignedById)}
                  />
                ))
              ) : (
                <div
                  style={{
                    fontSize: "13px",
                    backgroundColor: "#fff",

                    padding: "10px 20px",
                    textAlign: "center",
                    borderRadius: "5px",
                    fontWeight: "normal",
                    marginTop: 10,

                    boxSizing: "border-box",
                  }}
                >
                  <h5> There are no assigned meal plans for now </h5>
                </div>
              )}
            </div>
          </Grid>

          <Grid item xs={6} className="coachNutrition__homeRightContainer">
            <div style={{ width: "90%" }} className="coachNutrition__row">
              <h1 id="cursordefault"> Saved Long Term Plans</h1>
              <div
                onClick={() => history.push("/all-saved-LongTerm-Nutrition")}
              >
                View All
              </div>
            </div>
            <div style={{ width: "90%" }} className="coachNutrition__list">
              {console.log("slt", savedLongTermNutrition)}
              {savedLongTermNutrition.length > 0 ? (
                savedLongTermNutrition?.map((food, idx) => (
                  <NutritionCard
                    key={idx}
                    weeks={food.data.weeks}
                    isLongTerm={true}
                    idx={idx}
                    food={food.data}
                    selectedWeekNum={food.data.weeks[0].weeknum}
                    navigate={true}
                    nutritionName={food?.data?.nutritionName}
                    foodId={food.id}
                  />
                ))
              ) : (
                <div
                  style={{
                    fontSize: "13px",
                    backgroundColor: "#fff",

                    padding: "10px 20px",
                    textAlign: "center",
                    borderRadius: "5px",
                    fontWeight: "normal",
                    marginTop: 10,

                    boxSizing: "border-box",
                  }}
                >
                  <h5> There are no Saved Long Term meals for now </h5>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default CoachNutritionHome;
