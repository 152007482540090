import { Button, Dialog, DialogTitle, Grid, IconButton } from '@material-ui/core';
import { Delete, PhotoCamera } from '@material-ui/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Form, Formik } from 'formik';
import React, { Fragment, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from "react-loader-spinner";
import { useHistory } from 'react-router-dom';
import db, { fb_storage } from '../../services/Firebase';
import BlogValidatorDef from '../models/BlogFormValidator';
import { customStyles } from '../Styles/UIStyles';
import TextField from '../UIElements/TextField';
import { useSelector } from "react-redux";
import { selectUser } from '../../../../features/userSlice'
import "./WebsiteForms.css"

export const AddBlog = () => {
  const history = useHistory();
  const styles = customStyles();
  const userEmail = useSelector(selectUser)
  const [coverImageAsFile, setCoverImageAsFile] = useState('')
  const [coverImageAsUrl, setCoverImageAsUrl] = useState('')
  const [otherImagesAsFiles, setOtherImagesAsFiles] = useState([])
  const [otherImagesAsUrls, setOtherImagesAsUrl] = useState([])
  const [showDialog, setShowDialog] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())


  function onEditorStateChange(editorState) {
    setEditorState(editorState)
  };

  function handleChange(e) {
    const image = e.target.files[0]
    setCoverImageAsUrl(URL.createObjectURL(image))
    setCoverImageAsFile(coverImageAsFile => (image))
  }

  function handleMultipleChange(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      const image = e.target.files[i]
      setOtherImagesAsUrl((prevState) => [...prevState, URL.createObjectURL(image)]);
      setOtherImagesAsFiles((prevState) => [...prevState, image]);
    }
  };

  function deleteOtherIamge(image) {
    const index = otherImagesAsUrls.indexOf(image)
    otherImagesAsFiles.splice(index, 1)
    otherImagesAsUrls.splice(index, 1)
    setOtherImagesAsUrl((prevState) => [...otherImagesAsUrls]);
    setOtherImagesAsFiles((prevState) => [...otherImagesAsFiles]);
  }

  const uploadImageCallBack = (file) => {
    return new Promise(
      (resolve, reject) => {
        // console.log('Uploading image...');
        firebaseUpload(file)
          .then(link => {
            resolve({ data: { link } });
          })
          .catch(error => {
            reject(error);
          })
      }
    );
  }

  const firebaseUpload = (file) => {
    return new Promise(
      (resolve, reject) => {
        if (!file) {
          reject('Invalid file.');
        }
        var timeStamp = Date.now()
        const ref = fb_storage.ref().child(`/blog_images/${userEmail}/${timeStamp}_${file.name}`).put(file)
        ref.on('state_changed',
          (snapshot) => {
            // console.log(snapshot)
          },
          (error) => {
            // console.log(error)
          }, (complete) => {
            fb_storage.ref(`/blog_images/${userEmail}`).child(`${timeStamp}_${file.name}`).getDownloadURL()
              .then(url => resolve(url))
          })
      }
    );
  }



  return (

    <div id="uniqueforms">
      <Formik
        initialValues={{
          blog_title: '',
          blog_subtitle: ''
        }}
        validationSchema={BlogValidatorDef}
        onSubmit={values => {
          const button_clicked = window.event.submitter.name;
          console.log(button_clicked)
          setShowDialog(!showDialog);
          const images_list = []
          if (coverImageAsFile) {
            images_list.push(coverImageAsFile)
          }
          if (otherImagesAsFiles) {
            images_list.push.apply(images_list, otherImagesAsFiles)
          }
          const promises = images_list.map(image => {
            var timeStamp = Date.now()
            const ref = fb_storage.ref().child(`/blog_images/${userEmail}/${timeStamp}_${image.name}`)
            return ref.put(image)
              .then(() => ref.getDownloadURL());
          });
          Promise.all(promises)
            .then((fileDownloadUrls) => {
              db.collection('BlogsInformation')
                .add({
                  user_id: userEmail,
                  blog_title: values.blog_title,
                  blog_subtitle: values.blog_subtitle,
                  blog_content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                  cover_image: coverImageAsFile ? fileDownloadUrls.shift() : "",
                  other_images: otherImagesAsFiles ? fileDownloadUrls : ""
                })
                .then((blog_entry) => {
                  setShowDialog(!showDialog);
                  if (button_clicked === 'blog_preview') {
                    console.log(blog_entry.id)
                    // history.push(`/blog-preview/${blog_entry.id}`);
                    window.open(`/blog-preview/${blog_entry.id}`)
                    window.location.reload()
                  }
                  else {
                    history.push("/manage-blogs");
                  }
                })
                .catch((error) => {
                  setShowDialog(!showDialog);
                  console.log('Error messages:', error.message);
                });
            })
            .catch((err) => {
              setShowDialog(!showDialog);
              console.log('Error messages:', err)
            });
        }}
      >
        <Form>
          <div>
            <h2 className={styles.section_header}>
              <p>
                <label htmlFor="icon-button-file">
                  <IconButton className={styles.icon_button} aria-label="back-button" onClick={() => history.push("/manage-blogs")} >
                    <ArrowBackIosIcon fontSize="small" />
                  </IconButton>
                </label>
                Add Blog
              </p>
            </h2>
          </div>
          <div id="theformone">
            <p className={styles.form_header}>
              <label style={{ color: 'red' }}>* </label> Title
            </p>
            <TextField className={styles.textfield}
              id="blog_title"
              name="blog_title"
              label="Enter title"
            />
            <p className={styles.form_header}>
              <label style={{ color: 'red' }}>* </label> Sub Title
            </p>
            <TextField className={styles.textfield}
              id="blog_subtitle"
              name="blog_subtitle"
              label="Enter sub title"
            />
            <p className={styles.form_header}>
              Cover Image
            </p>
            <div>
              <Fragment>
                <div>
                  {
                    coverImageAsUrl && (
                      <figure>
                        <img src={coverImageAsUrl} alt='' width='128' height='128' />
                      </figure>
                    )
                  }
                </div>
                <input
                  color="primary"
                  accept="image/*"
                  type="file"
                  onChange={handleChange}
                  id="cover"
                  style={{ display: 'none', }}
                />
                <p>
                  <label htmlFor="cover">
                    <IconButton className={styles.icon_button} aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                  Upload Image
                </p>
              </Fragment>
            </div>
            <p className={styles.form_header}>
              Other Images
            </p>
            <div>
              <Fragment>
                <Grid container>
                  {
                    otherImagesAsUrls && otherImagesAsUrls.map(each_image => {
                      return (
                        <Grid item key={each_image}>
                          <img key={each_image} style={{ marginLeft: '.5rem' }} src={each_image} alt='' width='128' height='128' />
                          <IconButton aria-label="delete" onClick={() => deleteOtherIamge(each_image)} >
                            <Delete />
                          </IconButton>
                        </Grid>
                      )
                    })
                  }
                </Grid>
                <input
                  color="primary"
                  accept="image/*"
                  type="file"
                  multiple
                  onChange={handleMultipleChange}
                  id="other"
                  style={{ display: 'none', }}
                />
                <p>
                  <label htmlFor="other">
                    <IconButton className={styles.icon_button} aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                  Upload Images
                </p>
              </Fragment>
            </div>
            <p className={styles.form_header}>
              Blog Editor
            </p>
            <div>
              <Editor style={{ background: 'white' }}
                initialEditorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'image'],
                  inline: {
                    options: ['bold', 'italic', 'underline'],
                  },
                  image: {
                    urlEnabled: true,
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    previewImage: true,
                    uploadCallback: uploadImageCallBack,
                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: 'auto',
                      width: 'auto',
                    },
                  }
                }}
                onEditorStateChange={onEditorStateChange}
              />
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
          <p></p>
          <Grid container>
            <Grid item style={{ padding: '0.5em' }}>
              <Button type='submit' id='blog_preview' name='blog_preview' variant="outlined" className={styles.secondary_button} style={{ width: '120px' }} > Preview </Button>
            </Grid>
            <Grid item style={{ padding: '0.5em' }}>
              <Button type='submit' id='blog_save' name='blog_save' variant="outlined" className={styles.primary_button} style={{ width: '120px' }}> Save </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
      {
        showDialog && (
          <Dialog open={showDialog} className={styles.dialog}>
            <DialogTitle id="blog_progress_title"><p>Saving Blog details...</p><p>Soon will be redirected to manage blogs...</p></DialogTitle>
            <div className={styles.dialog}>
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
              />
            </div>
          </Dialog>
        )
      }
    </div >

  )
}