import React, { useState, useEffect } from "react";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { Typography, Avatar } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { db, storage } from "../../utils/firebase";

import {
  selectUserData,
  selectUser,
  selectTemperoryId,
  selectUserType,
  setTemperoryID,
} from "../../features/userSlice";

const AddNutrition = () => {
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const temperoryId = location.state?.AthleteId;
  const [editable, seteditable] = useState(false);
  const [carbs, setCarbs] = useState("");
  const [fats, setFats] = useState("");
  const [proteins, setProteins] = useState("");
  const [calories1, setCalories] = useState();
  const [data, setData] = useState([]);

  // setCalories(carbs * 4 + protiens * 4 + fats * 9);
  var path = "/Athlete";
  if (userType === "athlete") path = "/profile";

  useEffect(async () => {
    if (userType === "athlete") {
      const dataRef = db.collection("athletes").doc(userData?.id);
      const doc = await dataRef.get();

      setData(doc?.data());
    } else {
      const dataRef = db.collection("athletes").doc(temperoryId);
      const doc = await dataRef.get();

      setData(doc?.data());
    }
  }, []);

  useEffect(() => {
    setCarbs(data?.diet?.carbs);
    setFats(data?.diet?.fat);
    setProteins(data?.diet?.protein);
    setCalories(data?.diet?.calories);
  }, [data]);

  let cal;
  // useEffect(() => {
  //   cal = carbs * 4 + proteins * 4 + fats * 9;
  // }, [carbs, proteins, fats]);

  const saveNutrition = () => {
    if (carbs == "" || proteins == "" || fats == "") {
      alert("Fields can't be empty");
    } else {
      setCalories(String(carbs * 4 + proteins * 4 + fats * 9));
      if (userType === "athlete") {
        db.collection("athletes")
          .doc(userData?.id)
          .update({
            diet: {
              calories: String(carbs * 4 + proteins * 4 + fats * 9),
              carbs: carbs,
              fat: fats,
              protein: proteins,
            },
          })
          .then((res) => seteditable(false));
      } else {
        db.collection("athletes")
          .doc(temperoryId)
          .update({
            diet: {
              calories: String(carbs * 4 + proteins * 4 + fats * 9),
              carbs: carbs,
              fat: fats,
              protein: proteins,
            },
          })
          .then((res) => seteditable(false));
      }
    }

    // seteditable(false);
  };

  return (
    <div style={{marginLeft:'3%'}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <div
          onClick={() => {
            dispatch(setTemperoryID(temperoryId));
            history.push({
              pathname: path,
              state: {
                AthleteId: temperoryId,
              },
            });
          }}
          style={{}}
        >
          <ArrowBackIosRoundedIcon
            style={{ height: 18, width: 18, padding: 5, cursor: "pointer" }}
          />
        </div>
        <Typography variant="h6" style={{ fontSize: 25, marginLeft: 5 }}>
          Add Nutrition
        </Typography>
      </div>

      <div className="coachProfile__info">
        <div className="coachProfile__heading">
          <div style={{ display: "flex", flexDirection: "row" }}>
           
          </div>
        </div>{" "}
      </div>
      <form>
        <h4>Carbs</h4>
        <input
          readOnly={!editable}
          onChange={(e) => {
            setCarbs(e.target.value);
          }}
          value={carbs}
          type="number"
          placeholder="Enter Carbs"
        />
        <h4>Fats</h4>
        <input
          readOnly={!editable}
          onChange={(e) => {
            setFats(e.target.value);
          }}
          value={fats}
          type="number"
          placeholder="Enter Fats"
        />
        <h4>Proteins</h4>
        <input
          readOnly={!editable}
          onChange={(e) => {
            setProteins(e.target.value);
          }}
          value={proteins}
          type="number"
          placeholder="Enter proteins"
        />

        <h4>Calories</h4>
        <input
          readOnly
          // onChange={() => setCalories(carbs * 4 + proteins * 4 + fats * 9)}
          value={String(carbs * 4 + proteins * 4 + fats * 9) || calories1}
        />
      </form>
      <br/>
      {!editable ? (
              <span
                style={{ marginLeft: "10px" }}
                className="editProfileButton"
                onClick={() => seteditable(true)}
              >
                <h3 style={{ color: "var(--secondarycolor)" }}>
                  EDIT NUTRITION
                </h3>
              </span>
            ) : (
              <span
                className="saveProfileButton"
                onClick={() => saveNutrition()}
              >
                <h3 style={{ color: "var(--secondarycolor)" }}>
                  SAVE NUTRITION
                </h3>
              </span>
            )}
    </div>
  );
};

export default AddNutrition;
