import React, { useEffect, useLayoutEffect } from "react";
import AthleteAssessment from "./AthleteAssessment";
import "./Profile.css";
import "../Profile/Profile.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTemperoryId,
  selectUser,
  selectUserType,
} from "../../features/userSlice";
import { useHistory, useParams, useLocation } from "react-router-dom";
function AthleteAssessments(props) {
  var location = useLocation();

  var Id = location?.state?.AthleteId;

  const history = useHistory();

  const userType = useSelector(selectUserType);
  const dispatch = useDispatch();
  let temperoryId = useSelector(selectTemperoryId);

  useEffect(() => {
    console.log(temperoryId, "tempp");
    if (temperoryId == undefined) {
      temperoryId = props?.AthleteId;
    }
    console.log(temperoryId, "tempp2");
  }, [temperoryId]);
  return (
    <div
      className="athleteAssessments"
      id="athleteAssessments"
      style={{ marginLeft: "10px" }}
    >
      <h2>Assessments and Measurements </h2>
      <AthleteAssessment
        name="Anthropometric Measurements"
        path="anthropometric-measurements"
        Id={temperoryId}
      />
      <AthleteAssessment
        Id={props?.Id}
        name="Medical Assessment"
        path="medical-assessment"
      />
      <AthleteAssessment
        name="Training Assessment"
        path="training-assessment"
        Id={temperoryId}
      />
      <AthleteAssessment
        name="Food and Lifestyle Assessment"
        path="food-and-lifestyle-assessment"
        Id={temperoryId}
      />
      <div
        style={{}}
        className="viewReport"
        onClick={() =>
          history.push({
            pathname: "/athlete-add-nutrition",
            state: {
              AthleteId: temperoryId,
            },
          })
        }
      >
        <div style={{}} className="viewReport__button">
          Nutrition Details
        </div>
        <img src="/assets/white_right.png" alt="" />
      </div>

      <div
        style={{}}
        className="viewReport"
        onClick={() =>
          history.push({
            pathname: "/athlete-history",
            state: {
              AthleteId: temperoryId,
            },
          })
        }
      >
        <div style={{}} className="viewReport__button">
          View Athlete History
        </div>
        <img src="/assets/white_right.png" alt="" />
      </div>

      <div
        className="viewReport"
        onClick={() => {
          // window.open("/Athlete/reports/" + Id, "");
          console.log(1);
          history.push({
            pathname: "/Athlete/reports",
            state: {
              AthleteId: temperoryId,
            },
          });
        }}
      >
        <div className="viewReport__button">View Report</div>
        <img src="/assets/white_right.png" alt="" />
      </div>
      <div
      onClick={() => {
        // window.open("/Athlete/reports/" + Id, "");
        console.log(1);
        history.push({
          pathname: "Previous_Physiotherapy_Assessments",
          state: {
            AthleteId: temperoryId,
          },
        });
      }}
      className="viewReport">
        <div
          className="viewReport__button"
          
        >
          Physiotherapy Assessment
        </div>
        <img src="/assets/white_right.png" alt="" />
      </div>
    </div>
  );
}

export default AthleteAssessments;
