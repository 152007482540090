import Button from '@material-ui/core/Button';
import React from 'react';
import { customStyles } from '../Styles/UIStyles';


export const TestimonialsForm = () => {
    const styles = customStyles();
    return (
        <div>
            <br />
            <br />
            <Button className={styles.primary_button} type="submit" id="manage_testimonials" name="manage_testimonials" variant="outlined" >
                Manage Testimonials
            </Button>
            <br />
        </div >
    )
}
