import { lightGreen } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const customStyles = makeStyles((theme) => ({
    primary_button: {
        color: 'black',
        borderRadius: 50,
        backgroundColor: "#fee485",
        textTransform: 'none'
    },
    secondary_button: {
        color: 'black',
        borderRadius: 50,
        backgroundColor: "#ffffff",
        textTransform: 'none'
    },
    form_header: {
        color: '#173540',
        fontFamily: 'Open Sans',
        fontWeight: 'normal',
        textTransform: 'none'
    },
    section_header: {
        color: '#173540',
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        textTransform: 'none'
    },
    show_button: {
        display: 'inline-block',
        color: 'green',
        float: 'right',
        textTransform: 'none',
        fontFamily: 'Open Sans',
        fontWeight: 'normal',
    },
    hide_button: {
        display: 'inline-block',
        color: 'red',
        float: 'right',
        textTransform: 'none',
        fontFamily: 'Open Sans',
        fontWeight: 'normal',
    },
    icon_button: {
        color: 'black'
    },
    hide_component: {
        display: 'none'
    },
    color_box: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
    },
    dialog: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    multicolor_div: {
        display: 'flex',
        flexDirection: 'col'
    },
    multicolor_border_div: {
        display: 'flex',
        flexDirection: 'col',
        border: '3px solid black'
    },
    select_template_card: {
        width: 250,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        overflowY: 'auto'
    },
    selected_template_card: {
        width: 250,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        overflowY: 'auto',
        border: '3px solid black'
    },
    template_item: {
        display: 'flex',
        flexDirection: 'row'
    },
    template_media: {
        height: 210,
    },
    paper_button: {
        height: 150,
        textAlign: 'center',
        width: 200,
        padding: theme.spacing(6),
        textTransform: 'none',
        '&:hover': {
            // Set hover color
            background: '#fee485',
        },
    },
    textfield: {
        background: 'white'
    },
    blog_card: {
        width: '270px',
        height: '380px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    blog_header: {
        height: '50px'
    },
    blog_actionarea: {
        height: '350px',
    },
    blog_media: {
        height: '180px',
        width: '100%',
        objectFit: 'cover',
    },
    blog_content: {
        height: '120px'
    },
    testimonial_card: {
        width: '350px',
        height: '450px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'auto'
    },
    testimonial_header: {
        height: '50px'
    },
    testimonial_actionarea: {
        height: '400px'
    },
    testimonial_media: {
        height: '180px',
        width: '100%',
        objectFit: 'cover'
    },
    testimonial_content: {
        height: '120px'
    },
    green_label: {
        display: 'inline-block',
        color: 'green',
        textTransform: 'none',
        fontFamily: 'Open Sans',
        fontWeight: 'normal',
    },
    red_label: {
        display: 'inline-block',
        color: 'red',
        textTransform: 'none',
        fontFamily: 'Open Sans',
        fontWeight: 'normal',
    },
    program_card: {
        width: '250px',
        height: '470px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        overflowY: 'auto'
    },
    program_header: {
        height: '50px'
    },
    program_actionarea: {
        height: '420px'
    },
    program_media: {
        height: '160px',
        width: '100%',
        objectFit: 'cover',
    },
    program_content: {
        height: '160px'
    },
    program_card_button: {
        color: 'black',
        borderRadius: 50,
        backgroundColor: "#bd8fde",
        textTransform: 'none'
    },
    rectangle: {
        display: 'inline-block',
        borderStyle: 'dashed',
        borderRadius: 15,
        textAlign: 'center',
        alignItems: 'center'
    }
}));

export const DarkSwitch = withStyles({
    switchBase: {
        color: lightGreen[300],
        '&$checked': {
            color: lightGreen[500],
        },
        '&$checked + $track': {
            backgroundColor: lightGreen[500],
        },
    },
    checked: {},
    track: {},
})(Switch);