import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { db } from "../../utils/firebase";
import { selectUserType, selectUserData } from "../../features/userSlice";
import "./WorkoutCard.css";
import { formatDate } from "../../functions/formatDate";
import CloseIcon from "@material-ui/icons/Close";
function WorkoutCard({
  removecross,
  types,
  extraclass,
  workouts,
  idx,
  item,
  workoutType,
  showDate,
  type,
  completed,
  athlete_id,
  selectedWeekNum,
  isLongTerm,
  weeks,
  handleCloseworkout,
  setWeeks,
  selectedDay,
  navigate,
  workoutName,
  buildDate,
  coach,
}) {
  console.log(coach, 'aaa')
  const userData = useSelector(selectUserData);
  const userType = useSelector(selectUserType);
  const [date, setDate] = useState("");
  const [workout, setWorkout] = useState([]);
  const [savedWorkouts, setSavedWorkouts] = useState([]);
  const history = useHistory();
  const [Weeks, setWeeks_data] = useState([]);
  // useEffect(() => {
  //   if (weeks) {
  //     let week = weeks[0]?.days;
  //     let days = Object.keys(weeks[0]?.days);
  //     weeks &&
  //       days.forEach((day) => {
  //         if (week[day] != "") {
  //           setworkoutName(week[day]?.preWorkout?.workoutName);
  //         }
  //       });
  //   }
  // }, [weeks]);

  useEffect(() => {
    if (userData) {
      db.collection("CoachWorkouts")
        .where("assignedById", "==", userData?.id)
        .where("saved", "==", false)
        .where("selectedDates", "array-contains", formatDate())
        .limit(3)
        .onSnapshot((snapshot) => {
          setWorkout(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );
        });
      db.collection("CoachWorkouts")
        .where("assignedById", "==", userData?.id)
        .where("assignedToId", "==", "")
        // .where("date", "==", formatDate()) // replace with formatDate() for realtime data
        .limit(5)
        .onSnapshot((snapshot) => {
          setSavedWorkouts(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );
        });
    }
  }, [userData?.id]);

  useEffect(() => {
    if (showDate) {
      if (item?.data?.date) {
        setDate(item?.data?.date.split("-").reverse().join("-"));
      }
    }
  }, [type, item]);

  const deleteHandler = (e) => {
    e.stopPropagation();

    if (workoutType == "Your Workout") {
      var r = window.confirm("Are you sure you want to delete workout?");

      if (r == true) {
        db.collection("AthleteWorkouts")
          .doc(athlete_id)
          .collection(buildDate)
          .doc(item.id)
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      }
    } else {
      var r = window.confirm("Are you sure you want to delete workout?");
      if (r == true) {
        if (!isLongTerm) {
          db.collection("CoachWorkouts")
            .doc(item.id)
            .delete()
            .then(() => {
              console.log("Document successfully deleted!");
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
            });

          db.collection("workouts")
            .where("coachWorkoutId", "==", item.id)
            .get()
            .then(function (querySnapshot) {
              // Once we get the results, begin a batch
              var batch = db.batch();

              querySnapshot.forEach(function (doc) {
                // For each doc, add a delete operation to the batch
                batch.delete(doc.ref);
              });

              // Commit the batch
              return batch.commit();
            })
            .then(function () {
              // Delete completed!
              // ...

              console.log("Delete completed");
            });
        } else {
          console.log(item.id);
          db.collection("longTermWorkout")
            .doc(item.id)
            .delete()
            .then(() => {
              console.log("Document successfully deleted!");
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
            });

          db.collection("workouts")
            .where("coachWorkoutId", "==", item.id)
            .get()
            .then(function (querySnapshot) {
              // Once we get the results, begin a batch
              var batch = db.batch();

              querySnapshot.forEach(function (doc) {
                // For each doc, add a delete operation to the batch
                batch.delete(doc.ref);
              });

              // Commit the batch
              return batch.commit();
            })
            .then(function () {
              // Delete completed!
              // ...

              console.log("Delete completed");
            });
        }
      }
    }
  };

  return (
    <div
      id="savedworkout"
      style={{
        position: "relative",
      }}
      className={`workoutCard ${extraclass}`}
      onClick={() => {
        if (isLongTerm) {
          if (navigate) {
            if (navigate) {
              history.push({
                pathname: "/long-term-training",
                state: {
                  workout: workouts,
                  workoutName: item?.data?.workoutName,
                  weeks: weeks,
                  assignType: type,
                },
              });
            }
          } else {
            var lweeks = weeks;
            var lselectedWeekNum = selectedWeekNum;
            var lselectedDay = selectedDay;
            lweeks[lselectedWeekNum - 1].days[lselectedDay] = item?.data;

            setWeeks_data(lweeks);

            handleCloseworkout();
          }
        } else {
          if (userType === "coach") {
            if (type === "non-editable" && !completed) {
              console.log("clicked 1");
              history.push({
                pathname: "/assign-workout", // new screen -> pathname: `/assign-workout2/${item.id}`,
                state: {
                  workout: workouts[idx],
                  workoutName: workoutName
                    ? workoutName
                    : item?.data?.preWorkout?.workoutName,
                  assignType: "non-editable",
                  coach,
                },
              });
            } else if (completed == true) {
              console.log("asd", item);
              history.push({
                pathname: "/post-workout",
                state: {
                  types: types,
                  workout: item,
                  workoutName: workoutName
                    ? workoutName
                    : item?.data?.preWorkout?.workoutName,
                  completed: true,
                },
              });
            } else {
              if (item?.data?.assignedToId) {
                console.log("clicked 3");
                history.push({
                  pathname: "/assign-workout",
                  state: {
                    workout: item,
                    workoutName: item?.data?.preWorkout?.workoutName,
                    assignType: "update",
                    athlete_id: athlete_id,
                    coach,
                  },
                });
              } else {
                console.log("clicked 4");
                history.push({
                  pathname: "/assign-workout",
                  state: {
                    workout: workouts[idx],
                    workoutName: item?.data?.preWorkout?.workoutName,
                    assignType: "create",
                    savedtemplates: "true",
                  },
                });
              }
            }
          } else {
            if (navigate == "create-workout") {
              history.push({
                pathname: "/create-workout",
                state: {
                  workout: item,
                  workoutName: item?.data?.preWorkout?.workoutName,
                  completed: true,
                },
              });
            } else {
              if (completed === true) {
                console.log("nak", item);
                history.push({
                  pathname: "/post-workout",
                  state: {
                    types: types,
                    workout: item,
                    workoutName: workoutName
                      ? workoutName
                      : item?.data?.preWorkout?.workoutName,
                    completed: true,
                  },
                });
              } else {
                console.log("opening past workouts");
                history.push({
                  pathname: "/post-workout",
                  state: {
                    workout: item,
                    workoutName: workoutName
                      ? workoutName
                      : item?.data?.preWorkout?.workoutName,
                    assignType: "view",
                  },
                });
              }
            }
          }
        }
      }}
    >
      <img
        src="/assets/illustration.png"
        alt=""
        width="110px"
        height="110px"
      />
      <div className="workoutCard__info">
        <h1>
          {/* {workoutName ? workoutName : item?.data?.preWorkout?.workoutName} */}
          {item?.data?.isLongTerm == true
            ? item?.data?.workoutName
            : item?.data?.preWorkout?.workoutName}
        </h1>
        {workoutType == "Your Workout" ? (
          <></>
        ) : (
          <>
            {" "}
            <div className="workoutCard__macroNutrients">
              <h3>Calories</h3>
              <h3>{item?.data?.preWorkout?.caloriesBurnEstimate}</h3>
            </div>
            <div className="workoutCard__macroNutrients">
              <h3>Difficulty</h3>
              <h3>{item?.data?.preWorkout?.workoutDifficulty}</h3>
            </div>
          </>
        )}
        <div className="workoutCard__macroNutrients">
          <h3>Duration</h3>
          <h3>{item?.data?.preWorkout?.workoutDuration}</h3>
        </div>
      </div>
      <div>
        <img className="right__arrow" src="/assets/right__arrow.png" alt="" />
        {isLongTerm && console.log(item.data)}
        {userType === "coach" && (
          <div
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={(e) => deleteHandler(e)}
          >
            {removecross != "true" && (
              <CloseIcon
                style={{
                  width: 30,
                }}
              />
            )}
          </div>
        )}
      </div>
      {userType == "athlete" && (
        <div
          style={{
            fontSize: 12,
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        >
          {item.data.date}
        </div>
      )}
      {coach && (
        <div
          style={{
            fontSize: 12,
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        >
          {coach.name}
        </div>
      )}
    </div>
  );
}

export default WorkoutCard;
