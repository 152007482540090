import { Button, Dialog, DialogTitle, Grid, IconButton } from '@material-ui/core';
import Delete from '@material-ui/icons/DeleteOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/Description';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Form, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import Loader from "react-loader-spinner";
import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory } from 'react-router-dom';
import db, { fb_storage } from '../services/Firebase';
import { Header } from './Header';
import validatorSchemaDef from './models/Validator';
import { customStyles } from './Styles/UIStyles';
import MultilineTextField from './UIElements/MultilineTextField';
import TextField from './UIElements/TextField';
import { BlogsForm } from './WebsiteForms/WebsiteBlogsForm';
import { WebsiteContactForm } from './WebsiteForms/WebsiteContactForm';
import { ProgramForm } from './WebsiteForms/WebsiteProgramsForm';
import { TestimonialsForm } from './WebsiteForms/WebsiteTestimonialsForm';
import { useSelector } from "react-redux";
import { selectUser } from '../../../features/userSlice'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


export const CreateUpdate = () => {

  const history = useHistory();
  const styles = customStyles();
  const userEmail = useSelector(selectUser);

  const [dataPresent, setDataPresent] = useState(false)
  // State variable to store collection data from firestore
  const [websiteBasicInfo, setWebsiteBasicInfo] = useState({});

  // Show/Hide Functionality state variables
  const [showProgramForm, setShowProgramForm] = useState(true)
  const [showBlogsForm, setShowBlogsForm] = useState(true)
  const [showTestimonialsForm, setShowTestimonialsForm] = useState(true)
  const [showGalleryForm, setShowGalleryForm] = useState(true)
  const [showDownloadForm, setShowDownloadForm] = useState(true)

  // Image storage purpose state variables
  const [headerImageAsFile, setHeaderImageAsFile] = useState('')
  const [headerImageAsUrl, setHeaderImageAsUrl] = useState('')
  const [hearderImageError, setHearderImageError] = useState('')
  const [aboutImageAsFile, setAboutImageAsFile] = useState('')
  const [aboutImageAsUrl, setAboutImageAsUrl] = useState('')
  const [aboutImageError, setAboutImageError] = useState('')
  const [galleryImageAsFiles, setGalleryImageAsFiles] = useState([])
  const [galleryImageAsUrls, setGalleryImagesAsUrls] = useState([])
  const [existingGalleryImages, setGalleryExistingImages] = useState([])
  const [downloadFilesAsFiles, setDownloadFilesAsFiles] = useState([])
  const [downloadFileAsUrls, setDownloadFilesAsUrls] = useState([])
  const [downloadFileNames, setdownloadFileNames] = useState([])
  const [existingDownloadFiles, setDownloadExistingFiles] = useState([])

  // Progress state showcase variables
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(true)

  const onProgramsClick = () => setShowProgramForm(!showProgramForm);
  const onBlogsClick = () => setShowBlogsForm(!showBlogsForm)
  const onTestimonialsClick = () => setShowTestimonialsForm(!showTestimonialsForm)
  const onGalleryClick = () => setShowGalleryForm(!showGalleryForm)
  const onDownloadClick = () => setShowDownloadForm(!showDownloadForm)


  const fetchWebsiteBasicInfo = async () => {
    const contactRef = await db.collection('WebsiteBasicInformation');
    const userContactInfo = contactRef.where("user_id", "==", userEmail);
    userContactInfo.get().then(snapshot => {
      snapshot.docs.forEach(doc => {
        setWebsiteBasicInfo({ data: doc.data() });
        setShowProgramForm(doc.data().show_programs_form)
        setShowBlogsForm(doc.data().show_blogs_form)
        setShowTestimonialsForm(doc.data().show_testimonials_form)
        setShowDownloadForm(doc.data().show_download_form)
        setShowGalleryForm(doc.data().show_gallery_form)
        setGalleryExistingImages(doc.data().gallery_images)
        setDownloadExistingFiles(doc.data().download_files)
        setHeaderImageAsUrl(doc.data().header_cover_image)
        setAboutImageAsUrl(doc.data().about_image)
        setDataPresent(true)
      })
      setLoading(false)
      // setShowDialog(false)
    })
  };

  useEffect(() => {
    fetchWebsiteBasicInfo();
  }, [])

  function handleHeaderImage(e) {
    const image = e.target.files[0];
    setHeaderImageAsUrl(URL.createObjectURL(image))
    setHeaderImageAsFile(headerImageAsFile => (image))
  }

  function handleAboutImage(e) {
    const image = e.target.files[0];
    setAboutImageAsUrl(URL.createObjectURL(image))
    setAboutImageAsFile(aboutImageAsFile => (image))
  }

  function handleGalleryImages(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      const image = e.target.files[i]
      setGalleryImagesAsUrls((prevState) => [...prevState, URL.createObjectURL(image)]);
      setGalleryImageAsFiles((prevState) => [...prevState, image]);
    }
  };

  function handleDownloadFiles(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i]
      const name = file.name
      fetch(URL.createObjectURL(file))
        .then(response => response.blob())
        .then(response => setDownloadFilesAsUrls((prevState) => [...prevState, response]))
      setDownloadFilesAsFiles((prevState) => [...prevState, file]);
      setdownloadFileNames((prevState) => [...prevState, name.split(".")[0]])
    }
  };

  function deleteGalleryImages(image, imageType) {
    if (imageType === "New") {
      const index = galleryImageAsUrls.indexOf(image)
      galleryImageAsFiles.splice(index, 1)
      galleryImageAsUrls.splice(index, 1)
      setGalleryImagesAsUrls((prevState) => [...galleryImageAsUrls]);
      setGalleryImageAsFiles((prevState) => [...galleryImageAsFiles]);
    }
    else {
      const index = existingGalleryImages.indexOf(image)
      existingGalleryImages.splice(index, 1)
      setGalleryExistingImages((prevState) => [...existingGalleryImages]);
    }
  }

  function deleteDownloadFiles(file, fileType) {
    if (fileType === "New") {
      const index = downloadFileAsUrls.indexOf(file)
      downloadFilesAsFiles.splice(index, 1)
      downloadFileAsUrls.splice(index, 1)
      setDownloadFilesAsUrls((prevState) => [...downloadFileAsUrls]);
      setDownloadFilesAsFiles((prevState) => [...downloadFilesAsFiles]);
    }
    else {
      const index = existingDownloadFiles.indexOf(file)
      existingDownloadFiles.splice(index, 1)
      setDownloadExistingFiles((prevState) => [...existingDownloadFiles]);
    }
  }


  return (
    <div id="createupdate">
      <Header />
      {
        loading && (
          <Loader
            type="TailSpin"
            color="#00BFFF"
            height={100}
            width={100}
          />
        )
      }
      {
        !loading && (
          <Formik
            enableReinitialize
            initialValues={{
              header_title: websiteBasicInfo.data ? websiteBasicInfo.data.header_title : "",
              header_subtitle: websiteBasicInfo.data ? websiteBasicInfo.data.header_subtitle : "",
              about_title: websiteBasicInfo.data ? websiteBasicInfo.data.about_title : "",
              about_description: websiteBasicInfo.data ? websiteBasicInfo.data.about_description : "",
              program_title: websiteBasicInfo.data ? websiteBasicInfo.data.program_title : "",
              program_subtitle: websiteBasicInfo.data ? websiteBasicInfo.data.program_subtitle : "",
              blog_title: websiteBasicInfo.data ? websiteBasicInfo.data.blog_title : "",
              blog_subtitle: websiteBasicInfo.data ? websiteBasicInfo.data.blog_subtitle : "",
              download_title: websiteBasicInfo.data ? websiteBasicInfo.data.download_title : "",
              download_subtitle: websiteBasicInfo.data ? websiteBasicInfo.data.download_subtitle : "",
              download_files_names: websiteBasicInfo.data ? websiteBasicInfo.data.download_files_names : ""
            }}
            validationSchema={validatorSchemaDef}
            onSubmit={values => {
              const button_clicked = window.event.submitter.name;
              setShowDialog(!showDialog);
              const images_list = []
              if (headerImageAsFile) {
                images_list.push(headerImageAsFile)
              }
              if (aboutImageAsFile) {
                images_list.push(aboutImageAsFile)
              }
              if (galleryImageAsFiles.length > 0) {
                images_list.push.apply(images_list, galleryImageAsFiles)
              }
              if (downloadFilesAsFiles.length > 0) {
                images_list.push.apply(images_list, downloadFilesAsFiles)
              }
              const promises = images_list.map(image => {
                var timeStamp = Date.now()
                const ref = fb_storage.ref().child(`/website_images/${userEmail}/${timeStamp}_${image.name}`)
                return ref.put(image)
                  .then(() => ref.getDownloadURL());
              });
              if (headerImageAsUrl && aboutImageAsUrl) {
                Promise.all(promises)
                  .then((fileDownloadUrls) => {
                    const doc = db.collection('WebsiteBasicInformation')
                      .doc(userEmail)
                      .get();
                    if (!doc) {
                      db.collection('WebsiteBasicInformation').doc(userEmail)
                        .add({
                          user_id: userEmail,
                          header_title: values.header_title,
                          header_subtitle: values.header_subtitle,
                          header_cover_image: headerImageAsFile ? fileDownloadUrls.shift() : "",
                          about_title: values.about_title,
                          about_description: values.about_description,
                          about_image: aboutImageAsFile ? fileDownloadUrls.shift() : "",
                          program_title: values.program_title,
                          program_subtitle: values.program_subtitle,
                          gallery_images: galleryImageAsFiles.length ? fileDownloadUrls.splice(0, galleryImageAsFiles.length) : "",
                          blog_title: values.blog_title,
                          blog_subtitle: values.blog_subtitle,
                          download_title: values.download_title,
                          download_subtitle: values.download_subtitle,
                          download_files: downloadFilesAsFiles.length ? fileDownloadUrls : "",
                          download_files_names: downloadFileNames,
                          show_programs_form: showProgramForm,
                          show_blogs_form: showBlogsForm,
                          show_download_form: showDownloadForm,
                          show_gallery_form: showGalleryForm,
                          show_testimonials_form: showTestimonialsForm
                        })
                        .then(() => {
                          if (button_clicked === 'manage_programs') {
                            history.push("/manage-programs")
                          }
                          else if (button_clicked === 'manage_blogs') {
                            history.push("/manage-blogs")
                          }
                          else if (button_clicked === 'manage_testimonials') {
                            history.push("/manage-testimonials")
                          }
                          else if (button_clicked === 'manage_contact') {
                            history.push("/contact-details")
                          }
                          else {
                            window.location.reload();
                          }

                        })
                        .catch((error) => {
                          alert(error.message);
                        });
                    }
                    else {
                      var header_image = ""
                      var about_image = ""
                      var gallery_image_list = []
                      var download_file_list = []
                      if (headerImageAsFile) {
                        header_image = fileDownloadUrls.shift();
                      }
                      if (aboutImageAsFile) {
                        about_image = fileDownloadUrls.shift();
                      }
                      if (existingGalleryImages.length) {
                        gallery_image_list.push.apply(gallery_image_list, existingGalleryImages)
                      }
                      if (galleryImageAsFiles.length) {
                        gallery_image_list.push.apply(gallery_image_list, fileDownloadUrls.splice(0, galleryImageAsFiles.length))
                      }
                      if (existingDownloadFiles.length) {
                        download_file_list.push.apply(download_file_list, existingDownloadFiles)
                      }
                      if (downloadFilesAsFiles.length) {
                        download_file_list.push.apply(download_file_list, fileDownloadUrls.splice(0, downloadFilesAsFiles.length))
                      }
                      db.collection('WebsiteBasicInformation')
                        .doc(userEmail)
                        .set({
                          user_id: userEmail,
                          header_title: values.header_title,
                          header_subtitle: values.header_subtitle,
                          header_cover_image: headerImageAsFile ? header_image : websiteBasicInfo.data.header_cover_image,
                          about_title: values.about_title,
                          about_image: aboutImageAsFile ? about_image : websiteBasicInfo.data.about_image,
                          about_description: values.about_description,
                          program_title: values.program_title,
                          program_subtitle: values.program_subtitle,
                          gallery_images: gallery_image_list,
                          blog_title: values.blog_title,
                          blog_subtitle: values.blog_subtitle,
                          download_title: values.download_title,
                          download_subtitle: values.download_subtitle,
                          download_files: download_file_list,
                          download_files_names: downloadFileNames,
                          show_programs_form: showProgramForm,
                          show_blogs_form: showBlogsForm,
                          show_download_form: showDownloadForm,
                          show_gallery_form: showGalleryForm,
                          show_testimonials_form: showTestimonialsForm
                        })
                        .then(() => {
                          if (button_clicked === 'manage_programs') {
                            history.push("/manage-programs")
                          }
                          else if (button_clicked === 'manage_blogs') {
                            history.push("/manage-blogs")
                          }
                          else if (button_clicked === 'manage_testimonials') {
                            history.push("/manage-testimonials")
                          }
                          else if (button_clicked === 'manage_contact') {
                            history.push("/contact-details")
                          }
                          else {
                            window.location.reload();
                          }
                        })
                        .catch((error) => {
                          alert(error.message);
                        });
                    }

                  })
                  .catch((err) => {
                    console.log('Error messages:', err)
                  });
                setHearderImageError('')
                setAboutImageError('')
              }
              else {
                if (!headerImageAsUrl) {
                  setHearderImageError("* Header Cover image is required.")
                }
                if (!aboutImageAsUrl) {
                  setAboutImageError("* About image is required.")
                }
                setShowDialog(false);
              }
            }
            }
          >
            <Form>
              <h4 className={styles.section_header}>
                Header
              </h4>
              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Title
              </p>
              <TextField className={styles.textfield}
                id='header_title'
                name='header_title'
                label='Enter a title'
              />
              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Subtitle
              </p>
              <TextField className={styles.textfield}
                id='header_subtitle'
                name='header_subtitle'
                label="Enter a sub title"
              />
              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Image
              </p>
              <div>
                <Fragment>
                  <div>
                    {
                      headerImageAsUrl ? headerImageAsUrl && (
                        <figure>
                          <img src={headerImageAsUrl} alt='' width='128' height='128' />
                        </figure>
                      ) : websiteBasicInfo.data ? websiteBasicInfo.data.header_cover_image && (
                        <figure>
                          <img src={websiteBasicInfo.data.header_cover_image} alt='' width='128' height='128' />
                        </figure>
                      ) : ""
                    }
                  </div>
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    onChange={handleHeaderImage}
                    id="header_cover_image"
                    className={styles.hide_component}
                  />
                  <p className={styles.form_header}>
                    <label htmlFor="header_cover_image">
                      <IconButton className={styles.icon_button} style={{ backgroundColor: 'white' }} aria-label="upload picture" component="span">
                        <PhotoCamera />
                      </IconButton>
                    </label>
                    Upload Image
                  </p>
                  <p style={{ color: 'red' }}>
                    {hearderImageError}
                  </p>
                </Fragment>
              </div>
              <br />
              <hr />
              <br />
              <h4 className={styles.section_header}>
                About
              </h4>
              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Title
              </p>
              <TextField className={styles.textfield}
                id="about_title"
                name="about_title"
                label="Enter a title"
              />
              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Descrition
              </p>
              <MultilineTextField className={styles.textfield}
                id="about_description"
                name="about_description"
                label="Description"
              />
              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Image
              </p>
              <div>
                <Fragment>
                  <div>
                    {
                      aboutImageAsUrl ? aboutImageAsUrl && (
                        <figure>
                          <img src={aboutImageAsUrl} alt='' width='128' height='128' />
                        </figure>
                      ) : websiteBasicInfo.data ? websiteBasicInfo.data.about_image && (
                        <figure>
                          <img src={websiteBasicInfo.data.about_image} alt='' width='128' height='128' />
                        </figure>
                      ) : null
                    }
                  </div>
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    onChange={handleAboutImage}
                    id="about_image"
                    className={styles.hide_component}
                  />
                  <p className={styles.form_header}>
                    <label htmlFor="about_image">
                      <IconButton className={styles.icon_button} style={{ backgroundColor: 'white' }} aria-label="upload picture" component="span">
                        <PhotoCamera />
                      </IconButton>
                    </label>
                    Upload Image
                  </p>
                  <p style={{ color: 'red' }}>
                    {aboutImageError}
                  </p>
                </Fragment>
              </div>
              <br />
              <hr />
              <br />
              <div>
                <h4 className={styles.section_header}>
                  <div>
                    Programs
                    {
                      showProgramForm ?
                        <Button className={styles.hide_button} onClick={() => onProgramsClick()}>Hide</Button>
                        :
                        <Button className={styles.show_button} onClick={() => onProgramsClick()} >Show</Button>
                    }
                  </div>
                </h4>
                {
                  showProgramForm ?
                    <ProgramForm />
                    : null
                }
              </div>
              <br />
              <hr />
              <br />
              <div>
                <h4 className={styles.section_header}>
                  <div>
                    Gallery
                    {
                      showGalleryForm ?
                        <Button className={styles.hide_button} onClick={() => onGalleryClick()} >Hide</Button>
                        :
                        <Button className={styles.show_button} onClick={() => onGalleryClick()} >Show</Button>
                    }
                  </div>
                </h4>
                {
                  showGalleryForm ?
                    <div>
                      <p className={styles.form_header}>
                        Image
                      </p>
                      <div>
                        <Fragment>
                          <Grid container>
                            {
                              galleryImageAsUrls.length > 0 && galleryImageAsUrls.map(each_image => {
                                return (
                                  <Grid item key={each_image}>
                                    <img key={each_image} style={{ marginLeft: '.5rem' }} src={each_image} alt='' width='128' height='128' />
                                    <IconButton aria-label="delete" onClick={() => deleteGalleryImages(each_image, "New")} >
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                )
                              }
                              )
                            }
                            {
                              existingGalleryImages.length > 0 && existingGalleryImages.map(each_image => {
                                return (
                                  <Grid item key={each_image}>
                                    <img key={each_image} style={{ marginLeft: '.5rem' }} src={each_image} alt='' width='128' height='128' />
                                    <IconButton aria-label="delete" onClick={() => deleteGalleryImages(each_image, "Old")}>
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                          <input
                            color="primary"
                            accept="image/*"
                            type="file"
                            multiple
                            onChange={handleGalleryImages}
                            id="gallery_images"
                            className={styles.hide_component}
                          />
                          <p className={styles.form_header}>
                            <label htmlFor="gallery_images">
                              <IconButton className={styles.icon_button} style={{ backgroundColor: 'white' }} aria-label="upload picture" component="span">
                                <PhotoCamera />
                              </IconButton>
                            </label>
                            Upload Images
                          </p>
                        </Fragment>
                      </div>
                    </div >
                    :
                    null
                }
              </div>
              <br />
              <hr />
              <br />
              <div>
                <h4 className={styles.section_header}>
                  <div>
                    Testimonials and Transformations
                    {
                      showTestimonialsForm ?
                        <Button className={styles.hide_button} onClick={() => onTestimonialsClick()} >Hide</Button>
                        :
                        <Button className={styles.show_button} onClick={() => onTestimonialsClick()} >Show</Button>
                    }
                  </div>
                </h4>
                {
                  showTestimonialsForm ? <TestimonialsForm /> : null
                }
              </div>
              <br />
              <hr />
              <br />
              <div>
                <h4 className={styles.section_header}>
                  <div>
                    Blog
                    {
                      showBlogsForm ?
                        <Button className={styles.hide_button} onClick={() => onBlogsClick()} >Hide</Button>
                        :
                        <Button className={styles.show_button} onClick={() => onBlogsClick()} >Show</Button>
                    }
                  </div>
                </h4>
                {
                  showBlogsForm ? <BlogsForm /> : null
                }
              </div>
              <br />
              <hr />
              <br />
              <div>
                <h4 className={styles.section_header}>
                  <div>
                    Downloads
                    {
                      showDownloadForm ?
                        <Button className={styles.hide_button} onClick={() => onDownloadClick()} >Hide</Button>
                        :
                        <Button className={styles.show_button} onClick={() => onDownloadClick()} >Show</Button>
                    }
                  </div>
                </h4>
                {
                  showDownloadForm ?
                    <div>
                      <p className={styles.form_header}>
                        Title
                      </p>
                      <TextField className={styles.textfield}
                        id="download_title"
                        name="download_title"
                        label="Enter a title"
                      />
                      <p className={styles.form_header}>
                        Subtitle
                      </p>
                      <TextField className={styles.textfield}
                        id="download_subtitle"
                        name="download_subtitle"
                        label="Enter a sub title"
                      />
                      <p className={styles.form_header}>
                        File
                      </p>
                      <div>
                        <Fragment>
                          <Grid container>
                            {
                              downloadFileAsUrls.length > 0 && downloadFileAsUrls.map(each_file => {
                                return (
                                  <Grid item key={each_file} style={{ marginLeft: '.5rem' }}>
                                    <Document
                                      file={each_file}
                                    >
                                      <Page size="A4" pageNumber={1} width={100} />
                                    </Document>
                                    <IconButton aria-label="delete" onClick={() => deleteDownloadFiles(each_file, "New")} >
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                )
                              }
                              )
                            }
                            {
                              existingDownloadFiles.length > 0 && existingDownloadFiles.map(each_file => {
                                return (
                                  <Grid item key={each_file} style={{ marginLeft: '.5rem' }}>
                                    <Document
                                      file={each_file}
                                    >
                                      <Page size="A4" pageNumber={1} width={100} />
                                    </Document>
                                    <IconButton aria-label="delete" onClick={() => deleteDownloadFiles(each_file, "Old")} >
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                          <input
                            color="primary"
                            accept="application/pdf"
                            type="file"
                            multiple
                            onChange={handleDownloadFiles}
                            id="download_files"
                            className={styles.hide_component}
                          />
                          <p className={styles.form_header}>
                            <label htmlFor="download_files">
                              <IconButton className={styles.icon_button} style={{ backgroundColor: 'white' }} aria-label="upload file" component="span">
                                <DescriptionOutlinedIcon />
                              </IconButton>
                              Upload Files
                            </label>
                          </p>
                        </Fragment>
                      </div>
                    </div >
                    :
                    null
                }
              </div>
              <br />
              <hr />
              <WebsiteContactForm />
              <hr />
              <p></p>
              {
                dataPresent ?
                  <Button className={styles.primary_button} type='submit' variant="outlined"> Continue </Button>
                  :
                  <Button className={styles.primary_button} type='submit' variant="outlined"> Confirm </Button>
              }
            </Form>
          </Formik>
        )
      }
      {
        showDialog && (
          <Dialog open={showDialog} className={styles.dialog}>
            <DialogTitle id="create_update_form"><p>Saving details...</p></DialogTitle>
            <div className={styles.dialog} >
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
              />
            </div>
          </Dialog>
        )
      }
    </div>
  )
}