import { Button, Dialog, DialogTitle, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Form, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import Loader from "react-loader-spinner";
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectUser } from '../../../../features/userSlice';
import db, { fb_storage } from '../../services/Firebase';
import ProgramValidatorDef from '../models/ProgramValidator';
import { customStyles } from '../Styles/UIStyles';
import TextField from '../UIElements/TextField';

export const UpdateProgram = () => {
  const history = useHistory();
  const styles = customStyles();
  const params = useParams();
  const userEmail = useSelector(selectUser)
  const [program_info, setProgramInfo] = useState({})
  const [imageAsFile, setImageAsFile] = useState('')
  const [imageAsUrl, setImageAsUrl] = useState('')
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(true)

  function handleChange(e) {
    const image = e.target.files[0]
    setImageAsUrl(URL.createObjectURL(image))
    setImageAsFile(imageAsFile => (image))
  };

  const fetchProgramInfo = async () => {
    const programRef = await db.collection('ProgramsInformation').doc(params.id).get();
    setProgramInfo({ data: programRef.data(), id: programRef.id })
    setLoading(false);
  };


  useEffect(() => {
    fetchProgramInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <div>
      {
        loading && (
          <div className={styles.dialog} >
            <Loader
              type="TailSpin"
              color="#00BFFF"
              height={100}
              width={100}
            />
          </div>
        )
      }
      {
        !loading &&
        <Formik
          initialValues={{
            program_name: program_info.data.program_name,
            program_details: program_info.data.program_details,
            program_fees: program_info.data.program_fees
          }}
          validationSchema={ProgramValidatorDef}
          onSubmit={values => {
            setShowDialog(!showDialog);
            if (imageAsFile) {
              var timeStamp = Date.now()
              const ref = fb_storage.ref().child(`/program_images/${userEmail}/${timeStamp}_${imageAsFile.name}`)
              ref.put(imageAsFile)
                .then(() => ref.getDownloadURL())
                .then(fireBaseUrl => {
                  db.collection('ProgramsInformation')
                    .doc(program_info.id)
                    .set({
                      user_id: userEmail,
                      program_name: values.program_name,
                      program_details: values.program_details,
                      program_fees: values.program_fees,
                      image: fireBaseUrl,
                      active: program_info.data.active
                    })
                    .then(() => {
                      // alert("Program has been added...");
                      setShowDialog(!showDialog);
                      history.push("/manage-programs");
                    })
                    .catch((error) => {
                      setShowDialog(!showDialog);
                      alert(error.message);
                    });
                })
            } else {
              db.collection('ProgramsInformation')
                .doc(program_info.id)
                .set({
                  user_id: userEmail,
                  program_name: values.program_name,
                  program_details: values.program_details,
                  program_fees: values.program_fees,
                  image: program_info.data.image,
                  active: program_info.data.active
                })
                .then(() => {
                  // alert("Program has been added...");
                  setShowDialog(!showDialog);
                  history.push("/manage-programs");
                })
                .catch((error) => {
                  setShowDialog(!showDialog);
                  alert(error.message);
                });
            }
          }}
        >
          <Form>
            <div>
              <h2 className={styles.section_header}>
                <p>
                  <label htmlFor="icon-button-file">
                    <IconButton className={styles.icon_button} aria-label="back-button" onClick={() => history.push("/manage-programs")} >
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                  </label>
                  Update Program
                </p>
              </h2>
            </div>
            <div id="theformone">

              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Title
              </p>
              <TextField className={styles.textfield}
                id="program_name"
                name="program_name"
                label="Enter program name"
              />
              <p className={styles.form_header}>
                <label style={{ color: 'red' }}>* </label> Details
              </p>
              <TextField className={styles.textfield}
                id="program_details"
                name="program_details"
                label="Enter program details"
              />
              <p className={styles.form_header}>
                Fees
              </p>
              <TextField className={styles.textfield}
                id="program_fees"
                name="program_fees"
                label="Enter fees"
              />
              <p className={styles.form_header}>
                Image
              </p>
              <div>
                <Fragment>
                  <div>
                    {
                      imageAsUrl ? imageAsUrl && (
                        <figure>
                          <img src={imageAsUrl} alt='' width='128' height='128' />
                        </figure>
                      ) : program_info.data ? program_info.data.image && (
                        <figure>
                          <img src={program_info.data.image} alt='' width='128' height='128' />
                        </figure>
                      ) : null
                    }
                  </div>
                  <input
                    color="primary"
                    accept="image/*"
                    type="file"
                    onChange={handleChange}
                    id="icon-button-file"
                    className={styles.hide_component}
                  />
                  <p className={styles.form_header}>
                    <label htmlFor="icon-button-file">
                      <IconButton className={styles.icon_button} style={{ background: 'white' }} aria-label="upload picture" component="span">
                        <PhotoCamera />
                      </IconButton>
                    </label>
                    <label >Upload Image</label>
                  </p>
                </Fragment>
              </div>
            </div>
            <br />
            <br />
            <Button className={styles.primary_button} type='submit' variant="outlined" style={{ width: '120px' }} >
              Update
            </Button>
          </Form>
        </Formik>
      }
      {
        showDialog && (
          <Dialog open={showDialog} className={styles.dialog}>
            <DialogTitle id="program_progress_title"><p>Saving Program details...</p><p>Soon will be redirected to manage programs...</p></DialogTitle>
            <div className={styles.dialog} >
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
              />
            </div>
          </Dialog>
        )
      }
    </div>

  )
}