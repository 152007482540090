import Button from '@material-ui/core/Button';
import React from 'react';
import { customStyles } from '../Styles/UIStyles';
import TextField from '../UIElements/TextField';

export const ProgramForm = () => {

    const styles = customStyles();

    return (
        <div>
            <p className={styles.form_header}>
                Title
            </p>
            <TextField className={styles.textfield}
                id="program_title"
                name="program_title"
                label="Enter a title"
            />
            <p className={styles.form_header}>
                Subtitle
            </p>
            <TextField className={styles.textfield}
                id="program_subtitle"
                name="program_subtitle"
                label="Enter a sub title"
            />
            <br />
            <br />
            <Button className={styles.primary_button} type="submit" id="manage_programs" name="manage_programs" variant="outlined">
                Manage Programs
            </Button>
            <br />
        </div >
    )
}
