import React, { useState, useEffect } from "react";

import { auth, db } from "../../utils/firebase";
const ResetPassword = () => {
  const [email, setEmail] = useState("");

  const forgotPassword = (Email) => {
    auth
      .sendPasswordResetEmail(Email)
      .then(function () {
        alert("Please check your email...");
      })
      .catch(function (e) {
        alert("Enter correct email...");
      });
  };
  return (
    <div
      style={{
        width: "300px",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        marginTop: 20,
        flexDirection: "column",
      }}
    >
      <h1>Reset Password</h1>

      <div style={{ marginTop: 20 }}>
        <label>Enter you email</label>
        <input
          style={{
            borderRadius: "0.3rem",
            border: "0.12rem solid grey",
            height: "1.5rem",
            width: "100%",
          }}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <button
          type="submit"
          className="signup__button"
          onClick={() => forgotPassword(email)}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
