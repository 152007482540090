import React from "react";
import { selectUserData, selectUserType } from "../../features/userSlice";
import { db } from "../../utils/firebase";
import { useSelector } from "react-redux";
import "./Header.css";
import { useHistory, useLocation } from "react-router";
import { useState, useEffect } from "react";

function Header(props) {
  const history = useHistory();
  const userData = useSelector(selectUserData);
  const userType = useSelector(selectUserType);
  const [pin, setPin] = useState(null);
  const Id = props.Id;
  const [name, setname] = useState(null);
  const [img, setimg] = useState(null);
  const [coachName, setCoachName] = useState("");
  const [CoachData, setCoachData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (userType === "coach") {
      if (props.Id) {
        db.collection("athletes")
          .doc(Id ? Id : "1")
          .get()
          .then(function (snap) {
            console.log("snap", snap.data());
            setimg(snap.data().imageUrl);
            setname(snap.data().name);
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
      } else {
        setimg(userData?.data.imageUrl);
        setname(userData?.data.name);
      }
    } else {
      if (props.Id) {
        db.collection("athletes")
          .doc(Id ? Id : "1")
          .get()
          .then(function (snap) {
            console.log("snap", snap.data());
            setimg(snap.data().imageUrl);
            setname(snap.data().name);
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
          });
      } else {
        setimg(userData?.data.imageUrl);
        setname(userData?.data.name);
      }
    }
  }, [userData, userType]);

  useEffect(() => {
    if (userType !== "coach") {
      // db.collection("coaches")
      //   .doc(userData?.data?.listOfCoaches[0])
      //   .get()
      //   .then(function(snap) {
      //     setCoachName(snap.data()?.name)
      //   })
      if (props.coachProfile) {
        db.collection("coaches")
          .doc(userData?.data?.listOfCoaches[0])
          .get()
          .then(function (snap) {
            setCoachData(snap.data());
            setimg(snap.data().imageUrl);
            setname(snap.data().name);
            setPin(snap.data()?.pin);
          });
      } else {
        db.collection("athletes")
          .doc(userData?.id)
          .get()
          .then(function (snap) {
            setCoachName(snap.data()?.name);
          });
      }
    }
  }, []);

  if (props.coachProfile) {
    return (
      <div className="header">
        <div className="coachProfile__header">
          <div className="coachProfile__img">
            <img
              className="leftarrow"
              src="/assets/left_arrow.png"
              alt=""
              onClick={() => history.push(props.back)}
            />
            <img
              className="image"
              src={
                userType == "coach"
                  ? props.tempProfPic
                    ? URL.createObjectURL(props.tempProfPic)
                    : img
                    ? img
                    : "https://firebasestorage.googleapis.com/v0/b/stairs-web-app.appspot.com/o/userImage.jpeg?alt=media&token=b50bf709-1e26-4d1a-8fd6-3689f66322c3"
                  : props.tempProfPic
                  ? props.tempProfPic
                  : "https://firebasestorage.googleapis.com/v0/b/stairs-web-app.appspot.com/o/userImage.jpeg?alt=media&token=b50bf709-1e26-4d1a-8fd6-3689f66322c3"
              }
              alt={name && name}
              width="100px"
              height="100px"
            />
          </div>
          <div className="coachProfile__content">
            {
              userType === "coach" ? (
                <div style={{ color: "var(--secondarycolor)" }}>
                  <h1
                    style={{
                      color: "var(--secondarycolor)",
                      cursor: "default",
                    }}
                  >
                    {name && name}
                  </h1>
                  <h2
                    style={{
                      color: "var(--secondarycolor)",
                      fontSize: 15,
                      cursor: "default",
                    }}
                  >
                    Coach ID :{userData?.data?.pin && userData?.data?.pin}
                  </h2>
                </div>
              ) : (
                // : props.athlete ?
                <div>
                  <h1 style={{ color: "var(--secondarycolor)" }}>
                    {props.coachData.name}
                  </h1>
                  <h2
                    style={{
                      color: "var(--secondarycolor)",
                      fontSize: 15,
                      cursor: "default",
                    }}
                  >
                    Coach ID :{props.coachData.pin}
                  </h2>
                </div>
              )
              // : (
              //   <h1>{coachName}</h1>
              // )
            }
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="coachProfile__header">
      <div className="coachProfile__img">
        <img
          className="leftarrow"
          src="/assets/left_arrow.png"
          alt=""
          onClick={() => {
            if (props.back) {
              history.push("/");
            } else {
              history.goBack();
            }
          }}
        />
        <img
          className="image"
          src={
            img
              ? img
              : "https://firebasestorage.googleapis.com/v0/b/stairs-web-app.appspot.com/o/userImage.jpeg?alt=media&token=b50bf709-1e26-4d1a-8fd6-3689f66322c3"
          }
          alt={name ? name : userData?.data.name}
          width="100px"
          height="100px"
        />
      </div>
      <div className="coachProfile__content">
        {
          userType === "coach" ? (
            <h1 style={{ color: "var(--secondarycolor)" }}>
              {name ? name : userData?.data.name}
            </h1>
          ) : (
            // : props.athlete ?
            <div>
              <h1 style={{ color: "var(--secondarycolor)" }}>
                {userData?.data.name}
              </h1>
            </div>
          )
          // : (
          //   <h1>{coachName}</h1>
          // )
        }
      </div>
    </div>
  );
}

export default Header;
