import * as React from "react";
import { useSelector } from "react-redux";
import WorkoutCard from "../../Components/WorkoutCard/WorkoutCard";
import {
  selectUserData,
  selectUserType,
  selectTemperoryId,
} from "../../features/userSlice";
import { db, firestore } from "../../utils/firebase";
import WorkoutScreenHeader from "./WorkoutScreenHeader";
import SearchIcon from "@material-ui/icons/Search";

import ClearIcon from "@material-ui/icons/Clear";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useLocation } from "react-router-dom";
const ViewAllWorkouts = ({ Id, backs }) => {
  const [openSearch, setopenSearch] = React.useState(false);
  const userData = useSelector(selectUserData);
  const userType = useSelector(selectUserType);
  const [workouts, setWorkouts] = React.useState([]);
  const [type, setType] = React.useState("");
  const temperoryId = useSelector(selectTemperoryId);

  const [athleteId, setAthleteId] = React.useState();
  const [completed, setCompleted] = React.useState(false);
  const [search, setsearch] = React.useState("");
  const [SearchList, setSearchList] = React.useState(null);
  const [SearchLoading, SetSearhLoading] = React.useState(false);

  const [showFilter, setShowFilter] = React.useState(false);
  const location = useLocation();
  const [headCoachData, setHeadCoachData] = React.useState([]);
  const [coaches, setCoaches] = React.useState([]);
  const [final, setFinal] = React.useState([]);
  document.addEventListener("mouseup", function (e) {
    if (showFilter) {
      setShowFilter(false);
    }
  });

  const [sorting, setsorting] = React.useState("desc");
  // React.useEffect(() => {
  //   workouts?.filter((coach) => {
  //     return coach.data.name.toLowerCase().includes(search);
  //   });
  // }, [search]);
  React.useEffect(() => {
    setAthleteId(location?.state?.AthleteId);
  }, [location?.state]);

  React.useEffect(() => {
    setSearchList(workouts);
    console.log(workouts);
    workouts.forEach((workout) => {
      if (workout?.data?.isLongTerm) {
        console.log("d", workout);
      }
    });
  }, [workouts]);

  React.useEffect(async () => {
    SetSearhLoading(true);

    if (search?.length > 0) {
      const names = await workouts?.filter((workout) => {
        return workout.data.preWorkout.workoutName
          .toLowerCase()
          .includes(search.toLowerCase());
      });

      setSearchList(names);
      console.log("toLower", names);
      SetSearhLoading(false);
    } else {
      setSearchList(workouts);
      SetSearhLoading(false);
    }
  }, [search]);
  console.log(athleteId, "this");

  React.useEffect(async () => {
    if (userData?.data?.headCoach) {
      const dataRef = db.collection("HeadCoaches").doc(userData?.id);
      const doc = await dataRef.get();

      // final.push(doc.data().listOfCoaches);
      // final.push(userData?.id);
      // doc.data().listOfCoaches.map((m) => final.push(m));
      setHeadCoachData(doc.data());

      // console.log("HAHA!", doc.data().listOfCoaches);
    }
  }, [userData?.id, userData?.data?.headCoach]);

  React.useEffect(async () => {
    if (userData) {
      if (userType !== "coach" && athleteId == undefined) {
        db.collection("workouts")
          .where("assignedToId", "==", userData?.id)
          .where("completed", "==", false)

          .orderBy("selectedDay", sorting)
          .onSnapshot((snapshot) => {
            setWorkouts(
              snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
              }))
            );
          });
      } else {
        if (athleteId) {
          setWorkouts([]);
          console.log(type, "firstt");
          db.collection("workouts")
            .where("assignedToId", "==", athleteId)
            .where("completed", "==", false)
            .orderBy("selectedDay", sorting)
            .onSnapshot((snapshot) => {
              if (snapshot) {
                console.log("Inside snapshot");
                setWorkouts(
                  snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                  }))
                );
              } else {
                console.log("outside snapshot");
                setWorkouts([]);
              }
            });
        } else {
          let datas = [];
          const subcoaches = userData.data?.listOfCoaches || [];

          const coachIds = [...subcoaches, userData.id];

          try {
            db.collection("CoachWorkouts")
              // .where("assignedById", "in", coachIds)
              .where("saved", "==", false)
              .orderBy("timestamp", sorting)
              .onSnapshot((snapshot) => {
                snapshot.docs.map((doc) =>
                  datas.push({
                    id: doc.id,
                    data: doc.data(),
                  })
                );
                console.log(coachIds, "subco");
                console.log(datas, "datas2");
                let filteredWorkouts = datas.filter((a) =>
                  coachIds.includes(a.data.assignedById)
                );

                setWorkouts(filteredWorkouts);
              });
          } catch (error) {
            console.log(error, "err");
          }
        }
      }
    }
  }, [
    userData?.id,
    athleteId,
    sorting,
    location?.state?.AthleteId,
    headCoachData?.listOfCoaches,
  ]);

  React.useEffect(() => {
    workouts.reverse();
  }, [sorting]);

  React.useEffect(() => {
    if (userData?.data?.listOfCoaches?.length) {
      let coaches = [];
      db.collection("coaches")
        // .where(
        //   firestore.FieldPath.documentId(),
        //   "in",
        //   userData.data.listOfCoaches
        // )
        .get()
        .then((snapshot) => {
          coaches = snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
          }));
          let filtCoaches = coaches.filter((a) =>
            userData.data.listOfCoaches.includes(a.id)
          );
          filtCoaches.push({
            id: userData.id,
            name: userData.data.name,
          });
          // console.log(filtCoaches, 'filtco')
          setCoaches(filtCoaches);
        });
    }
  }, [userData, userData?.data?.listOfCoaches]);

  const options = [
    { value: "asc", label: "Recent" },
    { value: "desc", label: "old" },
  ];

  return (
    <div style={{ minHeight: "99.7vh" }} id="savedworkouts">
      <WorkoutScreenHeader back={backs} name="Assigned workouts" />
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            setopenSearch(true);
            setsearch("");
          }}
          id="inputtagg"
          style={{
            marginTop: 20,
            marginLeft: 20,
            backgroundColor: "white",
            padding: 5,
            display: "flex",
            alignItems: "center",
            border: "1px solid black",
            width: "500px",
            transition: "all 0.5s",
            borderRadius: 10,
          }}
        >
          <input
            value={search}
            style={{
              width: "100%",
              display: openSearch ? "block" : "none",

              fontSize: 20,
              outline: "none",
              border: "none",
            }}
            onChange={(e) => {
              setsearch(e.target.value);
            }}
          />
          {search?.length == 0 ? (
            <div
              onClick={() => {
                setopenSearch(true);
                setsearch("");
              }}
            >
              <SearchIcon
                style={{
                  cursor: "pointer",
                  width: 30,
                  height: 30,
                }}
              />
            </div>
          ) : (
            <div
              onClick={() => {
                setsearch("");
              }}
            >
              <ClearIcon
                style={{
                  width: 30,
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            position: "relative",
            width: "100px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div className="filter_icon">
            <img
              onClick={() => {
                setShowFilter(!showFilter);
              }}
              src="/assets/filter.png"
              width="35px"
              height="35px"
            />
          </div>
          <div
            className="filter"
            style={{
              position: "absolute",
              marginTop: 40,
              display: showFilter ? "block" : "none",
              border: "1px solid black",
              fontSize: 12,
              borderRadius: 10,
            }}
          >
            <div
              onClick={() => {
                setsorting("desc");
                setShowFilter(false);
              }}
              style={{
                padding: 10,
                borderBottom: "1px solid black",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                backgroundColor:
                  sorting == "desc" ? "var(--primarycolor)" : "white",
              }}
            >
              New to Old
            </div>
            <div
              onClick={() => {
                setsorting("asc");
                setShowFilter(false);
              }}
              style={{
                padding: 10,
                backgroundColor:
                  sorting == "asc" ? "var(--primarycolor)" : "white",

                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              Old to New
            </div>
          </div>
        </div>
        {/* <div
          style={{
            width: 150,
            marginLeft: "auto",
          }}
        >
          <Dropdown
            options={options}
            placeholder="Order By"
            onChange={(s) => {
              setsorting(s.value);
            }}
          />
        </div> */}
      </div>
      {search.length > 0 && (
        <div
          style={{
            fontSize: 13,
            marginLeft: 20,
          }}
        >
          {SearchList?.length} search results loaded
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "100%",
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            width: "50%",
            marginTop: "20px",
            paddingLeft: "15px",
            paddingRight: "15px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {SearchList?.length > 0 ? (
            SearchList?.map((item, idx) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <WorkoutCard
                  extraclass="extraclass"
                  key={idx}
                  workouts={SearchList}
                  item={item}
                  idx={idx}
                  navigation={"ViewAllWorkouts"}
                  showDate={true}
                  type="non-editable"
                  completed={completed === true ? true : false}
                  coach={coaches.find((c) => c.id === item.data.assignedById)}
                />
              </div>
            ))
          ) : (
            <div
              style={{
                backgroundColor: "#fff",
                width: "100%",
                height: 90,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
                cursor: "default",
              }}
            >
              <h5>There are no workouts for now</h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewAllWorkouts;
