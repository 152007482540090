import * as Yup from 'yup';


const BlogValidatorDef = Yup.object().shape({
    
    blog_title: Yup.string().required('Blog title cannot be left empty!')
        .max(60, 'Should be within 60 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed'),
    blog_subtitle: Yup.string().required('Blog subtitle cannot be left empty!')
        .max(120, 'Should be within 120 characters of length')
        .matches(/^[ A-Za-z0-9_@.,/:;"'*()#&+-]*$/, 'Alphabets, numbers and special characters (_@.,/:;*"\'()#&+-) are only allowed')
});


export default BlogValidatorDef;