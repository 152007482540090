import React, { useState, useEffect, useCallback } from "react";

import { auth, db } from "../../utils/firebase";
import firebase from "firebase";

import { useDispatch, useSelector } from "react-redux";
import {
  setDbID,
  selectDbId,
  selectUser,
  setUserDetails,
  selectShowData,
  logout,
  setUserData,
  selectUserData,
} from "../../features/userSlice";
import ReactPlayer from "react-player";
import "./vod.css";
//import Notification from "../components/Notification";
import VideocamIcon from "@material-ui/icons/Videocam";
import { Formik } from "formik";
import formatSpecificDate1 from "../../functions/formatSpecificDate1";
import SearchableDropdown from "../../Components/SearchableDropdown";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import { formatDate } from "../../functions/formatDate";

import { Grid } from "@material-ui/core";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import { useHistory, useLocation } from "react-router-dom";
import VODScreenHeader from "./VODScreenHeader";
import formatSpecificDate from "../../functions/formatSpecificDate";
import incr_date from "../../functions/incr_date";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  DialogContentText,
} from "@material-ui/core";

import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InputWrapper = styled("div")`
  width: 350px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 4%;

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Listbox = styled("ul")`
  width: 350px;
  margin: 2px 0 0;
  margin-left: 2.1%;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 150px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected="true"] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus="true"] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;

  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  margin-left: 4%;
  margin-top: 20px;
`;
function AssignVideo({ navigation }) {
  const [userDetails, setUserDetails] = useState(null);
  const [athleteDetails, setAthleteDetails] = useState([]);
  const user = useSelector(selectUser);
  const userData = useSelector(selectUserData);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [athletes, setAthletes] = useState([]);
  const [listOfAthletes, setListOfAthletes] = useState([]);
  const [videoId, setVideoId] = useState(["576142998", "576142998"]);
  const [title, settitle] = useState("");
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const [selectedAthletes1, setSelectedAthletes1] = useState([]);
  const [currentStartWeek, setCurrentStartWeek] = useState(null);
  const [currentEndWeek, setCurrentEndWeek] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);

  const [verifiedModal, setVerifiedModal] = useState(false);
  const [onboardModal, setOnboardModal] = useState(false);

  const [SearchList, setSearchList] = React.useState(null);
  const [SearchLoading, SetSearhLoading] = React.useState(false);
  const [selectmultiple, setselectmultiple] = useState(false);

  const [filterAsc, setFilterAsc] = useState(false);
  const [sorting, setsorting] = React.useState("desc");
  const [athlete_selecteddays, setathlete_selecteddays] = useState([]);
  const location = useLocation();

  const [daysList, setDaysList] = useState([
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
  ]);
  const [specificDates, setSpecificDates] = useState([]);
  const [screen, setscreen] = useState(1);
  const history = useHistory();
  const [videoData, setVideoData] = useState();

  useEffect(() => {
    var video_data = [];
    var data = [];

    if (userData?.id) {
      db.collection("coaches")
        .doc(userData?.id)
        .collection("videos")
        .orderBy("timestamp")
        .get()

        .then((snapshot) => {
          snapshot.forEach((dat) => {
            let data = dat.data();
            if (location?.state?.videoData) {
              let temp = location?.state?.videoData;
              if (dat.data().videoId == temp[0]?.videoId) {
                data["checked"] = true;
              }
            }
            video_data.push(data);
          });
          setVideoData(video_data);
        });

      //   db.collection("WorkoutVideo")
      //     .where("AssignedById", "==", userData?.id)
      //     .orderBy("timestamp")
      //     .limit(4)
      //     .get()

      //     .then((snap) => {
      //       let data = [];
      //       snap.forEach((s) => {
      //
      //         data.push(s.data());
      //       });

      //       setAssignedVideos(data);
      //     });
    }
  }, [user, userData?.id, location?.state?.videoData]);
  useEffect(() => {
    let temp = [...selectedAthletes];
    selectedAthletes.map((athlete, idx) => {
      if (athlete_selecteddays[athlete.id]) {
        temp[idx].selectedDays = athlete_selecteddays[athlete.id];
      }
    });
    setSelectedAthletes1(temp);
  }, [selectedAthletes]);

  useEffect(() => {
    if (location?.state?.videoData) {
      let temp = location.state.videoData;
      let temp1 = [...videoData];
      temp1?.forEach((vd) => {
        if (vd.videoId == temp[0]?.videoId) {
          vd["checked"] = true;
        }
      });
      setVideoData(temp1);
    }
  }, [location?.state]);

  useEffect(() => {
    if (false) {
      var minDate = location.state?.workout?.data?.selectedDates?.reduce(
        function (a, b) {
          return a < b ? a : b;
        }
      );
      var curr = new Date(minDate); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the  day of the month - the day of the week \

      var firstday = new Date(curr.setDate(first)).toUTCString();
      var lastday = new Date(curr.setDate(curr.getDate() + 6)).toUTCString();

      setCurrentStartWeek(formatSpecificDate(firstday));
      setCurrentEndWeek(formatSpecificDate(lastday));
    } else {
      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the  day of the month - the day of the week \

      var firstday = new Date(curr.setDate(first)).toUTCString();
      var lastday = new Date(curr.setDate(curr.getDate() + 6)).toUTCString();

      setCurrentStartWeek(formatSpecificDate(firstday));
      setCurrentEndWeek(formatSpecificDate(lastday));
    }
  }, []);

  useEffect(() => {
    if (currentStartWeek) {
      let temp = currentStartWeek;

      let datesCollection = [];

      for (var i = 0; i < 7; i++) {
        datesCollection.push(temp);
        temp = incr_date(temp);
      }

      setSpecificDates(datesCollection);
    }
  }, [currentStartWeek]);

  useEffect(() => {
    if (userData?.id) {
      const data = [];
      db.collection("athletes")
        .orderBy("name", "asc")
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((athlete) => {
            if (userData?.data?.listOfAthletes?.includes(athlete.id)) {
              let currentID = athlete.id;
              let appObj = { ...athlete.data(), id: currentID };
              data.push(appObj);
            }
          });
          setListOfAthletes(data);
        });
    }
  }, [userData?.id]);
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    multiple: true,
    options: listOfAthletes,
    getOptionLabel: (option) => option.name,
  });

  React.useEffect(() => {
    setSearchList(videoData);
  }, [videoData]);

  useEffect(() => {
    value.map((v) => {
      v.selectedDays = [];
    });
    if (!location.state?.workout?.data?.selectedAthletes) {
      setSelectedAthletes(value);
    }
  }, [value]);

  React.useEffect(() => {
    (async () => {
      if (search?.length > 0 && videoData) {
        const names = await videoData?.filter((data) => {
          return data.title.toLowerCase().includes(search.toLowerCase());
        });

        setSearchList(names);
        SetSearhLoading(false);
      } else {
        setSearchList(videoData);
        SetSearhLoading(false);
      }
    })();
  }, [search, videoData]);

  console.log("seleted das", athlete_selecteddays);

  return (
    <div
      id="vodresp"
      style={{
        margin: 20,
        boxSizing: "border-box",
        minHeight: "100vh",
      }}
    >
      <p style={{ textAlign: "start" }} className="assignvideopage">
        {" "}
        <VODScreenHeader back="/vod" name="Assign Videos" />
      </p>
      {/* <Grid container>
        <Grid item xs={6} lg={3} xl={2}>
          ...
        </Grid>
        <Grid item xs={6} lg={3} xl={2}>
          ...
        </Grid>
        <Grid item xs={6} lg={3} xl={2}>
          ...
        </Grid>
        <Grid item xs={6} lg={3} xl={2}>
          ...
        </Grid>
        <Grid item xs={6} lg={3} xl={2}>
          ...
        </Grid>
        <Grid item xs={6} lg={3} xl={2}>
          ...
        </Grid>
      </Grid> */}
      {screen == 1 && (
        <Grid container spacing={1} className="workouts__homeContainer">
          <div
            style={{
              width: "90%",
              paddingLeft: 10,
              display: "flex",
              alignItems: "center",
            }}
            className="workoutHeading__row"
          >
            <h1 id="cursordefault">Uploaded Videos</h1>
          </div>

          {videoData?.length > 0 ? (
            videoData?.map((video, idx) => (
              <Grid
                item
                xs={4}
                lg={4}
                xl={2}
                className="workouts__homeLeftContainer"
              >
                <div
                  onClick={() => {
                    let temp = [...videoData];
                    temp[idx].checked = !temp[idx].checked;
                    setVideoData(temp);
                  }}
                  style={{
                    backgroundColor:
                      videoData[idx]?.checked && "var(--primarycolor)",
                    marginBottom: 20,
                  }}
                >
                  <div class="iframe_container">
                    <ReactPlayer
                      style={{ borderRadius: 10 }}
                      url={
                        "https://player.vimeo.com/video/" + `${video?.videoId}`
                      }
                      width="100%"
                      height="100%"
                      controls
                      frameborder="0"
                    />
                  </div>
                  <div>
                    title : {video?.title}
                    <br />
                    Uploaded On{" "}
                    {formatSpecificDate(video?.timestamp?.seconds * 1000)}
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <div
              style={{
                fontSize: "13px",
                backgroundColor: "#fff",
                width: "90%",
                padding: "10px 20px",
                textAlign: "center",
                borderRadius: "5px",
                fontWeight: "normal",
                marginLeft: 10,
              }}
            >
              <h5> There are no uploaded videos for now </h5>
            </div>
          )}
        </Grid>
      )}
      {screen == 2 && (
        // <div>
        //   <div {...getRootProps()}>
        //     <Label {...getInputLabelProps()}>Search for Athletes</Label>
        //     <InputWrapper
        //       ref={setAnchorEl}
        //       className={focused ? "focused" : ""}
        //     >
        //       {value.map((option, index) => (
        //         <Tag label={option.name} {...getTagProps({ index })} />
        //       ))}

        //       <input {...getInputProps()} />
        //     </InputWrapper>
        //   </div>
        //   {groupedOptions.length > 0 ? (
        //     <Listbox {...getListboxProps()}>
        //       {groupedOptions.map((option, index) => (
        //         <li {...getOptionProps({ option, index })}>
        //           <span>{option.name}</span>
        //           <CheckIcon fontSize="small" />
        //         </li>
        //       ))}
        //     </Listbox>
        //   ) : null}

        //   {selectmultiple ? (
        //     <div>
        //       {selectedAthletes1?.map((athlete, index) => (
        //         <div
        //           key={index}
        //           style={{
        //             marginLeft: "4%",
        //             marginTop: "25px",
        //             display: "flex",
        //             justifyContent: "center",
        //             flexDirection: "column",
        //             alignItems: "flex-start",
        //           }}
        //         >
        //           <div
        //             style={{
        //               display: "flex",
        //               alignItems: "center",
        //               backgroundColor: "var(--primarycolor)",
        //               borderRadius: "10px",
        //               height: "45px",
        //               width: "350px",
        //             }}
        //           >
        //             <img
        //               style={{
        //                 width: "36px",
        //                 height: "36px",
        //                 borderRadius: "18px",
        //                 marginLeft: "20px",
        //                 marginRight: "20px",
        //               }}
        //               src={athlete.imageUrl ? athlete.imageUrl : null}
        //             />
        //             <h2
        //               style={{
        //                 fontSize: "15px",
        //                 fontWeight: "600",
        //                 lineHeight: "28px",
        //                 color: "black",
        //                 marginLeft: "15%",
        //               }}
        //             >
        //               {athlete.name}
        //             </h2>
        //           </div>
        //           <h2
        //             style={{
        //               fontSize: "15px",
        //               fontWeight: "600",
        //               marginTop: "10px",
        //               lineHeight: "28px",
        //               marginLeft: "1%",
        //             }}
        //           >
        //             Select days
        //           </h2>
        //           <div
        //             style={{
        //               display: "flex",
        //               alignItems: "center",
        //               justifyContent: "flex-start",
        //               flexWrap: "wrap",
        //               marginBottom: "10px",
        //               width: "300px",
        //             }}
        //           >
        //             <div
        //               style={{
        //                 marginLeft: "3%",
        //                 display: "flex",
        //                 alignItems: "center",
        //                 justifyContent: "center",
        //                 width: "300px",
        //               }}
        //             >
        //               <IconButton
        //                 style={{
        //                   marginRight: "10px",
        //                   marginLeft: "25%",
        //                   display: "flex",
        //                   alignItems: "center",
        //                 }}
        //                 onClick={() => {
        //                   var curr = new Date(currentStartWeek); // get current date
        //                   var first = curr.getDate() - curr.getDay() - 7; // First day is the  day of the month - the day of the week \

        //                   var firstday = new Date(
        //                     curr.setDate(first)
        //                   ).toUTCString();
        //                   var lastday = new Date(
        //                     curr.setDate(curr.getDate() + 6)
        //                   ).toUTCString();
        //                   if (new Date(currentStartWeek) > new Date()) {
        //                     setCurrentStartWeek(formatSpecificDate(firstday));
        //                     setCurrentEndWeek(formatSpecificDate(lastday));
        //                   }
        //                 }}
        //               >
        //                 <ChevronLeftIcon />
        //               </IconButton>
        //               {daysList.map((day, idx) => (
        //                 <div
        //                   key={idx}
        //                   onClick={() => {
        //                     if (true) {
        //                       if (
        //                         athlete?.selectedDays?.includes(
        //                           specificDates[idx]
        //                         )
        //                       ) {
        //                         let selected =
        //                           selectedAthletes[index].selectedDays;
        //                         var index1 = selected.indexOf(
        //                           specificDates[idx]
        //                         );
        //                         if (index1 !== -1) {
        //                           selected.splice(index1, 1);
        //                           selectedAthletes[index] = {
        //                             ...selectedAthletes[index],
        //                             selected,
        //                           };
        //                           setSelectedAthletes([...selectedAthletes]);
        //                         }
        //                       } else {
        //                         if (
        //                           new Date(specificDates[idx]) > new Date() ||
        //                           specificDates[idx] === formatDate()
        //                         ) {
        //                           let selectedDays =
        //                             selectedAthletes[index].selectedDays;
        //                           selectedAthletes[index] = {
        //                             ...selectedAthletes[index],
        //                             selectedDays: [
        //                               ...selectedDays,
        //                               specificDates[idx],
        //                             ],
        //                           };
        //                           let temp = athlete_selecteddays;
        //                           temp[selectedAthletes[index].id] =
        //                             selectedAthletes[index].selectedDays;

        //                           setathlete_selecteddays(temp);
        //                           setSelectedAthletes([...selectedAthletes]);
        //                         }
        //                       }
        //                     }
        //                   }}
        //                   style={
        //                     athlete?.selectedDays?.includes(specificDates[idx])
        //                       ? {
        //                           backgroundColor: "var(--primarycolor)",
        //                           color: "#fff",
        //                           width: "85px",
        //                           height: "25px",
        //                           justifyContent: "center",
        //                           alignItems: "center",
        //                           position: "relative",
        //                           borderRadius: "8px",
        //                           marginRight: "2px",
        //                           marginBottom: "5px",
        //                           padding: "5px",
        //                           cursor: "pointer",
        //                         }
        //                       : {
        //                           width: "85px",
        //                           height: "25px",
        //                           justifyContent: "center",
        //                           alignItems: "center",
        //                           position: "relative",
        //                           borderRadius: "8px",
        //                           marginRight: "2px",
        //                           marginBottom: "5px",
        //                           padding: "5px",
        //                           cursor: "pointer",
        //                         }
        //                   }
        //                 >
        //                   <div>
        //                     <div
        //                       style={{
        //                         fontSize: "12px",
        //                         fontWeight: "600",
        //                         lineHeight: "20px",
        //                         width: "80%",
        //                         textAlign: "center",
        //                         padding: "5px",
        //                         color: athlete?.selectedDays?.includes(
        //                           specificDates[idx]
        //                         )
        //                           ? "black"
        //                           : "black",
        //                       }}
        //                     >
        //                       {day}
        //                     </div>
        //                   </div>
        //                 </div>
        //               ))}

        //               <IconButton
        //                 style={{
        //                   marginLeft: "10%",
        //                   display: "flex",
        //                   alignItems: "center",
        //                 }}
        //                 onClick={() => {
        //                   var curr = new Date(currentStartWeek); // get current date
        //                   var first = curr.getDate() - curr.getDay() + 7; // First day is the  day of the month - the day of the week \

        //                   var firstday = new Date(
        //                     curr.setDate(first)
        //                   ).toUTCString();
        //                   var lastday = new Date(
        //                     curr.setDate(curr.getDate() + 6)
        //                   ).toUTCString();

        //                   setCurrentStartWeek(formatSpecificDate(firstday));
        //                   setCurrentEndWeek(formatSpecificDate(lastday));
        //                 }}
        //               >
        //                 <ChevronRightIcon />
        //               </IconButton>
        //             </div>

        //             <div
        //               style={{
        //                 fontSize: "10px",
        //                 fontWeight: "500",
        //                 lineHeight: "18px",
        //                 display: "flex",
        //                 justifyContent: "space-evenly",
        //                 alignItems: "center",
        //                 width: "100%",
        //                 height: "25px",
        //                 marginLeft: "35px",
        //                 cursor: "pointer",
        //               }}
        //             >
        //               {specificDates?.map((tempDate, idx) => (
        //                 <div
        //                   style={{
        //                     width: "45px",
        //                     height: "30px",
        //                   }}
        //                   key={idx}
        //                 >
        //                   <div
        //                     style={{
        //                       fontSize: "10px",
        //                       fontWeight: "500",
        //                       lineHeight: "18px",
        //                       width: "100%",
        //                       paddingLeft: "5px",
        //                       paddingRight: "5px",
        //                       paddingBottom: "5px",
        //                       textAlign: "center",
        //                     }}
        //                   >
        //                     {formatSpecificDate1(tempDate)}
        //                   </div>
        //                 </div>
        //               ))}
        //             </div>
        //           </div>
        //         </div>
        //       ))}
        //     </div>
        //   ) : (
        //     <div
        //       style={{
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "flex-start",
        //         flexWrap: "wrap",
        //         marginBottom: "10px",
        //         width: "300px",
        //       }}
        //     >
        //       <div
        //         style={{
        //           marginLeft: "3%",
        //           display: "flex",
        //           alignItems: "center",
        //           justifyContent: "center",
        //           width: "300px",
        //         }}
        //       >
        //         <IconButton
        //           style={{
        //             marginRight: "10px",
        //             marginLeft: "25%",
        //             display: "flex",
        //             alignItems: "center",
        //           }}
        //           onClick={() => {
        //             var curr = new Date(currentStartWeek); // get current date
        //             var first = curr.getDate() - curr.getDay() - 7; // First day is the  day of the month - the day of the week \

        //             var firstday = new Date(curr.setDate(first)).toUTCString();
        //             var lastday = new Date(
        //               curr.setDate(curr.getDate() + 6)
        //             ).toUTCString();
        //             if (new Date(currentStartWeek) > new Date()) {
        //               setCurrentStartWeek(formatSpecificDate(firstday));
        //               setCurrentEndWeek(formatSpecificDate(lastday));
        //             }
        //           }}
        //         >
        //           <ChevronLeftIcon />
        //         </IconButton>
        //         {daysList.map((day, idx) => (
        //           <div
        //             key={idx}
        //             onClick={() => {
        //               if (true) {
        //                 if (selectedDays?.includes(specificDates[idx])) {
        //                   let selected = selectedDays;
        //                   var index1 = selected.indexOf(specificDates[idx]);
        //                   if (index1 !== -1) {
        //                     selected.splice(index1, 1);
        //                     // selectedAthletes[index] = {
        //                     //   ...selectedAthletes[index],
        //                     //   selected,
        //                     // };
        //                     setSelectedDays(selected);
        //                   }
        //                 } else {
        //                   if (
        //                     new Date(specificDates[idx]) > new Date() ||
        //                     specificDates[idx] === formatDate()
        //                   ) {
        //                     //   let selectedDays =
        //                     //     selectedAthletes[index].selectedDays;
        //                     //   selectedAthletes[index] = {
        //                     //     ...selectedAthletes[index],
        //                     //     selectedDays: [
        //                     //       ...selectedDays,
        //                     //       specificDates[idx],
        //                     //     ],
        //                     //   };
        //                     //   let temp = athlete_selecteddays;
        //                     //   temp[selectedAthletes[index].id] =
        //                     //     selectedAthletes[index].selectedDays;
        //                     //   setathlete_selecteddays(temp);
        //                     //   setSelectedAthletes([...selectedAthletes]);

        //                     let temp = [...selectedDays];
        //                     // selectedAthletes[index] = {
        //                     //   ...selectedAthletes[index],
        //                     //   selectedDays: [...selectedDays, specificDates[idx]],
        //                     // };
        //                     temp.push(specificDates[idx]);
        //                     setSelectedDays(temp);
        //                   }
        //                 }
        //               }
        //             }}
        //             style={
        //               selectedDays?.includes(specificDates[idx])
        //                 ? {
        //                     backgroundColor: "var(--primarycolor)",
        //                     color: "#fff",
        //                     width: "85px",
        //                     height: "25px",
        //                     justifyContent: "center",
        //                     alignItems: "center",
        //                     position: "relative",
        //                     borderRadius: "8px",
        //                     marginRight: "2px",
        //                     marginBottom: "5px",
        //                     padding: "5px",
        //                     cursor: "pointer",
        //                   }
        //                 : {
        //                     width: "85px",
        //                     height: "25px",
        //                     justifyContent: "center",
        //                     alignItems: "center",
        //                     position: "relative",
        //                     borderRadius: "8px",
        //                     marginRight: "2px",
        //                     marginBottom: "5px",
        //                     padding: "5px",
        //                     cursor: "pointer",
        //                   }
        //             }
        //           >
        //             <div>
        //               <div
        //                 style={{
        //                   fontSize: "12px",
        //                   fontWeight: "600",
        //                   lineHeight: "20px",
        //                   width: "80%",
        //                   textAlign: "center",
        //                   padding: "5px",
        //                   color: selectedDays?.includes(specificDates[idx])
        //                     ? "black"
        //                     : "black",
        //                 }}
        //               >
        //                 {day}
        //               </div>
        //             </div>
        //           </div>
        //         ))}

        //         <IconButton
        //           style={{
        //             marginLeft: "10%",
        //             display: "flex",
        //             alignItems: "center",
        //           }}
        //           onClick={() => {
        //             var curr = new Date(currentStartWeek); // get current date
        //             var first = curr.getDate() - curr.getDay() + 7; // First day is the  day of the month - the day of the week \

        //             var firstday = new Date(curr.setDate(first)).toUTCString();
        //             var lastday = new Date(
        //               curr.setDate(curr.getDate() + 6)
        //             ).toUTCString();

        //             setCurrentStartWeek(formatSpecificDate(firstday));
        //             setCurrentEndWeek(formatSpecificDate(lastday));
        //           }}
        //         >
        //           <ChevronRightIcon />
        //         </IconButton>
        //       </div>

        //       <div
        //         style={{
        //           fontSize: "10px",
        //           fontWeight: "500",
        //           lineHeight: "18px",
        //           display: "flex",
        //           justifyContent: "space-evenly",
        //           alignItems: "center",
        //           width: "100%",
        //           height: "25px",
        //           marginLeft: "35px",
        //           cursor: "pointer",
        //         }}
        //       >
        //         {specificDates?.map((tempDate, idx) => (
        //           <div
        //             style={{
        //               width: "45px",
        //               height: "30px",
        //             }}
        //             key={idx}
        //           >
        //             <div
        //               style={{
        //                 fontSize: "10px",
        //                 fontWeight: "500",
        //                 lineHeight: "18px",
        //                 width: "100%",
        //                 paddingLeft: "5px",
        //                 paddingRight: "5px",
        //                 paddingBottom: "5px",
        //                 textAlign: "center",
        //               }}
        //             >
        //               {formatSpecificDate1(tempDate)}
        //             </div>
        //           </div>
        //         ))}
        //       </div>
        //     </div>
        //   )}
        // </div>
        <div>
          <div {...getRootProps()}>
            <Label
              style={{
                fontSize: 18,
                marginLeft: 20,
                fontWeight: 400,
              }}
            >
              Search for Athletes
            </Label>
            <InputWrapper
              style={{ marginLeft: 20 }}
              ref={setAnchorEl}
              className={focused ? "focused" : ""}
            >
              {value.map((option, index) => (
                <Tag label={option.name} {...getTagProps({ index })} />
              ))}

              <input {...getInputProps()} />
            </InputWrapper>
          </div>
          {groupedOptions.length > 0 ? (
            <Listbox {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li {...getOptionProps({ option, index })}>
                  <span>{option.name}</span>
                  <CheckIcon fontSize="small" />
                </li>
              ))}
            </Listbox>
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              spacing={3}
              className="coachNutrition__homeContainer"
            >
              {selectedAthletes1?.map((athlete, index) => (
                <Grid
                  item
                  xs={6}
                  className="coachNutrition__homeRightContainer"
                >
                  <div
                   className="backdivvod" 
                    key={index}
                    style={{
                      marginTop: "25px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "95%",
                      backgroundColor: "white",
                      borderRadius: 5,
                      padding: 15,
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                    className="vodathletename"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "var(--primarycolor)",
                        borderRadius: "10px",
                        height: "45px",
                        width: "350px",
                      }}
                    >
                      <img
                        style={{
                          width: "36px",
                          height: "36px",
                          borderRadius: "18px",
                          marginLeft: "20px",
                          marginRight: "20px",
                        }}
                        src={
                          athlete.imageUrl
                            ? athlete.imageUrl
                            : "https://firebasestorage.googleapis.com/v0/b/stairs-web-app.appspot.com/o/userImage.jpeg?alt=media&token=b50bf709-1e26-4d1a-8fd6-3689f66322c3"
                        }
                      />
                      <h2
                        style={{
                          fontSize: "15px",
                          fontWeight: "600",
                          lineHeight: "28px",
                          color: "black",
                          marginLeft: "15%",
                        }}
                      >
                        {athlete.name}
                      </h2>
                    </div>
                    <h2
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        marginTop: "10px",
                        lineHeight: "28px",
                        marginLeft: "1%",
                      }}
                    >
                      Select days
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "300px",
                        }}
                      >
                          <ChevronLeftIcon 
                           className="leftbtnwkt"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              var curr = new Date(currentStartWeek); // get current date
                              var first = curr.getDate() - curr.getDay() - 7; // First day is the  day of the month - the day of the week \
  
                              var firstday = new Date(
                                curr.setDate(first)
                              ).toUTCString();
                              var lastday = new Date(
                                curr.setDate(curr.getDate() + 6)
                              ).toUTCString();
                              if (new Date(currentStartWeek) > new Date()) {
                                // let temp = { ...athlete_dates };
                                // let tm = [...selectedAthletes1];
                                // tm[index].currentStartWeek =
                                //   formatSpecificDate(firstday);
  
                                // tm[index].currentEndWeek =
                                //   formatSpecificDate(lastday);
                                // setSelectedAthletes1(tm);
  
                                // let tmp = formatSpecificDate(firstday);
  
                                // let datesCollection = [];
  
                                // for (var i = 0; i < 7; i++) {
                                //   datesCollection.push(tmp);
                                //   tmp = incr_date(tmp);
                                // }
  
                                // temp[athlete.id] = datesCollection;
  
                                // setathlete_dates(temp);
                                setCurrentStartWeek(formatSpecificDate(firstday));
                                setCurrentEndWeek(formatSpecificDate(lastday));
                              }
                            }}
                          />
                        {daysList.map((day, idx) => (
                          <div
                          className="insidescrollwkt"

                            key={idx}
                            onClick={() => {
                              if (
                                athlete?.selectedDays?.includes(
                                  specificDates[idx]
                                )
                              ) {
                                let selected =
                                  selectedAthletes[index].selectedDays;
                                var index1 = selected.indexOf(
                                  specificDates[idx]
                                );
                                if (index1 !== -1) {
                                  selected.splice(index1, 1);
                                  selectedAthletes[index] = {
                                    ...selectedAthletes[index],
                                    selected,
                                  };
                                  setSelectedAthletes([...selectedAthletes]);
                                }
                              } else {
                                if (
                                  new Date(specificDates[idx]) > new Date() ||
                                  specificDates[idx] === formatDate()
                                ) {
                                  let selectedDays =
                                    selectedAthletes[index].selectedDays;
                                  selectedAthletes[index] = {
                                    ...selectedAthletes[index],
                                    selectedDays: [
                                      ...selectedDays,
                                      specificDates[idx],
                                    ],
                                  };
                                  let temp = athlete_selecteddays;
                                  temp[selectedAthletes[index].id] =
                                    selectedAthletes[index].selectedDays;

                                  setathlete_selecteddays(temp);
                                  setSelectedAthletes([...selectedAthletes]);
                                }
                              }
                            }}
                            style={
                              athlete?.selectedDays?.includes(
                                // athlete_dates[athlete.id][idx]
                                specificDates[idx]
                              )
                                ? {
                                    backgroundColor: "var(--primarycolor)",
                                    color: "#fff",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "relative",
                                    borderRadius: "8px",
                                    marginRight: "2px",

                                    cursor: "pointer",
                                  }
                                : {
                                    width: "40px",

                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "relative",
                                    borderRadius: "8px",
                                    marginRight: "2px",
                                    cursor: "pointer",
                                  }
                            }
                          >
                            <div>
                              <div
                              className="daywkt"
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                  width: "80%",
                                  textAlign: "center",
                                  padding: "5px",
                                  color:
                                    new Date(specificDates[idx]) <
                                    new Date(formatDate())
                                      ? "grey"
                                      : "black",
                                }}
                              >
                                {day}
                              </div>
                              <div
                                style={{
                                  width: "45px",
                                  height: "30px",
                                }}
                                key={idx}
                              >
                                <div
                                className="datewkt"
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "500",
                                    lineHeight: "18px",
                                    width: "100%",

                                    paddingBottom: "5px",
                                    textAlign: "center",
                                    color:
                                      new Date(specificDates[idx]) <
                                      new Date(formatDate())
                                        ? "grey"
                                        : "black",
                                  }}
                                >
                                  {formatSpecificDate1(specificDates[idx])}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        
                          <ChevronRightIcon
                          className="rightbtnwkt"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            var curr = new Date(currentStartWeek); // get current date
                            var first = curr.getDate() - curr.getDay() + 7; // First day is the  day of the month - the day of the week \

                            var firstday = new Date(
                              curr.setDate(first)
                            ).toUTCString();
                            var lastday = new Date(
                              curr.setDate(curr.getDate() + 6)
                            ).toUTCString();

                            // let temp = { ...athlete_dates };
                            // let tm = [...selectedAthletes1];
                            // tm[index].currentStartWeek =
                            //   formatSpecificDate(firstday);

                            // tm[index].currentEndWeek =
                            //   formatSpecificDate(lastday);
                            // setSelectedAthletes1(tm);

                            // let tmp = formatSpecificDate(firstday);

                            // let datesCollection = [];

                            // for (var i = 0; i < 7; i++) {
                            //   datesCollection.push(tmp);
                            //   tmp = incr_date(tmp);
                            // }

                            // temp[athlete.id] = datesCollection;

                            // setathlete_dates(temp);

                            // setCurrentStartWeek(
                            //   formatSpecificDate(firstday)
                            // );
                            // setCurrentEndWeek(
                            //   formatSpecificDate(lastday)
                            // );

                            setCurrentStartWeek(formatSpecificDate(firstday));
                            setCurrentEndWeek(formatSpecificDate(lastday));
                          }}  
                          />
                        
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      )}

      <div>
        <div
          style={{
            marginTop: 40,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            class="button__assignVideo"
            style={{
              border: "none",
              padding: 10,
              width: 150,
              borderRadius: 10,
              backgroundColor: "var(--primarycolor)",
              fontSize: 15,
              color: "var(--secondarycolor)",
            }}
            onClick={() => {
              if (screen == 1) {
                let data = [...videoData];

                data.forEach((temp) => {
                  temp["checked"] = false;
                });
                setVideoData(data);
              }
              if (screen == 2) {
                setscreen(1);
              }
            }}
          >
            {screen == 1 ? "RESET" : "BACK"}
          </button>
          <button
            class="button__assignVideo"
            style={{
              border: "none",
              padding: 10,
              width: 150,
              borderRadius: 10,
              backgroundColor: "var(--primarycolor)",
              color: "var(--secondarycolor)",
              marginLeft: 20,
              fontSize: 15,
            }}
            onClick={() => {
              if (screen == 1) {
                setscreen(2);
              }

              if (screen == 2) {
                if (selectedAthletes?.length > 0 && videoData?.length > 0) {
                  selectedAthletes.map((sa) =>
                    db
                      .collection("CoachWorkoutVideo") //WorkoutVideo
                      .add({
                        Video: videoData.filter((vd) => vd.checked),
                        AssignedById: userData?.id,
                        AssignedToId: sa.id,
                        Name: sa.name,
                        imageUrl: sa.imageUrl,
                        selectedDays: selectedAthletes1[0].selectedDays,
                        timestamp:
                          firebase.firestore.FieldValue.serverTimestamp(),
                      })
                      .then((e) => {
                        alert("Video Assigned Successfully");
                        history.push("/vod");
                      })
                      .catch((error) => {
                        console.log("Error in adding workout video", error);
                      })
                  );
                  db.collection("WorkoutVideo")
                    .add({
                      Video: videoData.filter((vd) => vd.checked),
                      AssignedById: userData?.id,
                      AssignedToId: selectedAthletes.map((sa) => sa.id),
                      selectedDays: selectedAthletes1[0].selectedDays,
                      timestamp:
                        firebase.firestore.FieldValue.serverTimestamp(),
                    })
                    .then((e) => {
                      alert("Video Assigned Successfully");
                      history.push("/vod");
                    })
                    .catch((error) => {
                      console.log("Error in adding workout video", error);
                    });
                } else {
                  alert("select atleast one video and one athlete");
                }
              }
            }}
          >
            {screen == 1 ? "Select Athletes" : "Assign Video"}
          </button>

          {/* <button
            class="button__assignVideo"
            style={{
              border: "none",
              padding: 10,
              width: 150,
              borderRadius: 10,
              backgroundColor: "var(--primarycolor)",
              marginLeft: 20,
              fontSize: 15,
            }}
            onClick={() => {
              setselectmultiple(!selectmultiple);
            }}
          >
            Toggle Selection
          </button> */}
        </div>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
}

export default AssignVideo;
