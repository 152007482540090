import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import WorkoutCard from "../../Components/WorkoutCard/WorkoutCard";
import { selectUserData, selectUserType } from "../../features/userSlice";
import { db, firestore } from "../../utils/firebase";
import WorkoutScreenHeader from "./WorkoutScreenHeader";

import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./workouts.css";
import { useLocation } from "react-router-dom";
function ViewAllAssignedLongTermWorkouts(props) {
  const location = useLocation();
  const userData = useSelector(selectUserData);
  const userType = useSelector(selectUserType);
  const [workouts, setWorkouts] = React.useState([]);
  const [savedWorkouts, setsavedWorkouts] = useState([]);
  const [type, setType] = React.useState("");
  const [athleteId, setAthleteId] = React.useState("");
  const [completed, setCompleted] = React.useState(false);

  const [search, setsearch] = React.useState("");
  const [SearchList, setSearchList] = React.useState(null);
  const [SearchLoading, SetSearhLoading] = React.useState(false);
  const [LongTermWorkouts, setLongTermWorkouts] = React.useState([]);

  const [sorting, setsorting] = React.useState("desc");
  const [openSearch, setopenSearch] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);
  const [coaches, setCoaches] = React.useState([]);

  document.addEventListener("mouseup", function (e) {
    if (showFilter) {
      setShowFilter(false);
    }
  });

  React.useEffect(() => {
    setAthleteId(location?.state?.AthleteId);
  }, [location?.state]);

  React.useEffect(() => {
    if (userData) {
      if (userType === "athlete") {
        db.collection("workouts")
          .where("assignedToId", "==", userData?.id)
          .where("completed", "==", true)
          //.orderBy("date","desc")
          .onSnapshot((snapshot) => {
            setWorkouts(
              snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
              }))
            );
          });
      } else {
        if (type && athleteId) {
          db.collection("workouts")
            .where("assignedToId", "==", athleteId)
            .where("saved", "==", false)
            .where("completed", "==", completed)
            .onSnapshot((snapshot) => {
              if (snapshot) {
                setWorkouts(
                  snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                  }))
                );
              } else {
                setWorkouts([]);
              }
            });
        } else {
          // db.collection("CoachWorkouts")
          //   .where("assignedById", "==", userData?.id)
          //   .where("saved", "==", false)
          //   .onSnapshot((snapshot) => {
          //     setWorkouts(
          //       snapshot.docs.map((doc) => ({
          //         id: doc.id,
          //         data: doc.data(),
          //       }))
          //     );
          //   });
        }
      }
    }
  }, [userData?.id, athleteId]);

  useEffect(() => {
    if (userData && userType === "coach") {
      const subcoaches = userData.data?.listOfCoaches || [];
      const coachIds = [...subcoaches, userData.id];
      db.collection("longTermWorkout")
        .where("assignedById", "in", coachIds)
        .where("saved", "==", false)
        .where("isLongTerm", "==", true)
        // .where("date", "==", formatDate()) // replace with formatDate() for realtime data
        .orderBy("timestamp", sorting)
        .onSnapshot((snapshot) => {
          setLongTermWorkouts(
            snapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          );
        });
    }
  }, [userData?.id, sorting, userType]);

  React.useEffect(() => {
    setSearchList(LongTermWorkouts);
    console.log(LongTermWorkouts);
  }, [LongTermWorkouts]);

  React.useEffect(async () => {
    SetSearhLoading(true);

    if (search?.length > 0) {
      const names = await LongTermWorkouts?.filter((workout) => {
        return workout.data.workoutName
          .toLowerCase()
          .includes(search.toLowerCase());
      });

      setSearchList(names);
      SetSearhLoading(false);
    } else {
      setSearchList(LongTermWorkouts);
      SetSearhLoading(false);
    }
  }, [search]);

  React.useEffect(() => {
    if (userData) {
      if (userType !== "coach" && athleteId == undefined) {
        db.collection("longTermWorkout")
          .where("assignedToId", "==", userData?.id)
          .where("completed", "==", false)
          .where("isLongTerm", "==", true)

          .orderBy("timestamp", "asc")
          .onSnapshot((snapshot) => {
            setLongTermWorkouts(
              snapshot.docs.map((doc) => ({
                id: doc.id,
                data: doc.data(),
              }))
            );
          });
      } else {
        if (athleteId) {
          setLongTermWorkouts([]);
          db.collection("longTermWorkout")
            .where("assignedToId", "==", athleteId)
            .where("completed", "==", false)
            .orderBy("timestamp", "asc")
            .onSnapshot((snapshot) => {
              if (snapshot) {
                setLongTermWorkouts(
                  snapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                  }))
                );
              } else {
                setLongTermWorkouts([]);
              }
            });
        } else {
          const subcoaches = userData.data?.listOfCoaches || [];
          const coachIds = [...subcoaches, userData.id];
          db.collection("CoachWorkouts")
            .where("assignedById", "in", coachIds)
            .where("saved", "==", false)
            .orderBy("timestamp", sorting)
            .onSnapshot((snapshot) => {
              setWorkouts(
                snapshot.docs.map((doc) => ({
                  id: doc.id,
                  data: doc.data(),
                }))
              );
            });
        }
      }
    }
  }, [userData?.id, athleteId, sorting, location?.state?.AthleteId]);

  useEffect(() => {
    if (userData?.data?.listOfCoaches?.length) {
      let coaches = [];
      db.collection("coaches")
        .where(
          firestore.FieldPath.documentId(),
          "in",
          userData.data.listOfCoaches
        )
        .get()
        .then((snapshot) => {
          const subcoaches = snapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
          }));
          coaches = coaches.concat(subcoaches);
          coaches.push({
            id: userData.id,
            name: userData.data.name,
          });
          setCoaches(coaches);
        });
    }
  }, [userData?.data?.listOfCoaches]);

  const options = [
    { value: "asc", label: "Recent" },
    { value: "desc", label: "old" },
  ];
  return (
    <div id="savedworkouts" style={{ minHeight: "99.6vh" }}>
      <WorkoutScreenHeader
        back="/workouts"
        name="Long Term Assigned Workouts"
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            setopenSearch(true);
            setsearch("");
          }}
          id="inputtagg"
          style={{
            marginTop: 20,
            marginLeft: 20,
            backgroundColor: "white",
            padding: 5,
            display: "flex",
            alignItems: "center",
            border: "1px solid black",
            width: "500px",
            transition: "all 0.5s",
            borderRadius: 10,
          }}
        >
          <input
            value={search}
            style={{
              width: "100%",
              display: openSearch ? "block" : "none",

              fontSize: 20,
              outline: "none",
              border: "none",
            }}
            onChange={(e) => {
              setsearch(e.target.value);
            }}
          />
          {search?.length == 0 ? (
            <div
              onClick={() => {
                setopenSearch(true);
                setsearch("");
              }}
            >
              <SearchIcon
                style={{
                  cursor: "pointer",
                  width: 30,
                  height: 30,
                }}
              />
            </div>
          ) : (
            <div
              onClick={() => {
                setsearch("");
              }}
            >
              <ClearIcon
                style={{
                  width: 30,
                  height: 30,
                  cursor: "pointer",
                }}
              />
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            position: "relative",
            width: "100px",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div className="filter_icon">
            <img
              onClick={() => {
                setShowFilter(!showFilter);
              }}
              src="/assets/filter.png"
              width="35px"
              height="35px"
            />
          </div>
          <div
            className="filter"
            style={{
              position: "absolute",
              marginTop: 40,
              display: showFilter ? "block" : "none",
              border: "1px solid black",
              fontSize: 12,
              borderRadius: 10,
            }}
          >
            <div
              onClick={() => {
                setsorting("desc");
                setShowFilter(false);
              }}
              style={{
                padding: 10,
                borderBottom: "1px solid black",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                backgroundColor:
                  sorting == "desc" ? "var(--primarycolor)" : "white",
              }}
            >
              New to Old
            </div>
            <div
              onClick={() => {
                setsorting("asc");
                setShowFilter(false);
              }}
              style={{
                padding: 10,
                backgroundColor:
                  sorting == "asc" ? "var(--primarycolor)" : "white",

                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              old to new
            </div>
          </div>
        </div>
        {/* 
        <div
          style={{
            width: 150,
            marginLeft: "auto",
          }}
        >
          <Dropdown
            options={options}
            placeholder="Order By"
            onChange={(s) => {
              setsorting(s.value);
            }}
          />
        </div> */}
      </div>
      {search?.length > 0 && (
        <div
          style={{
            fontSize: 13,
            marginLeft: 20,
          }}
        >
          {SearchList?.length} search results loaded
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "100%",
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            width: "50%",
            marginTop: "20px",
            paddingLeft: "15px",
            paddingRight: "15px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {SearchList?.length > 0 ? (
            SearchList?.map((workout, idx) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <WorkoutCard
                  extraclass="extraclass"
                  key={workout.id}
                  weeks={workout.data.weeks}
                  item={workout}
                  idx={idx}
                  isLongTerm={true}
                  type="view"
                  navigate={true}
                  workoutName={workout?.data?.workoutName}
                  coach={coaches.find(
                    (c) => c.id === workout.data.assignedById
                  )}
                />
              </div>
            ))
          ) : (
            <div
              style={{
                backgroundColor: "#fff",
                width: "100%",
                height: 90,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
                cursor: "default",
              }}
            >
              <h5> There are no saved Long Term workouts for now </h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewAllAssignedLongTermWorkouts;
