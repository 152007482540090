import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import VODScreenHeader from "../VOD/VODScreenHeader";
import { auth, db } from "../../utils/firebase";
import firebase from "firebase";
import { storage } from "../../utils/firebase";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

import {
  setDbID,
  selectDbId,
  selectUser,
  setUserDetails,
  selectShowData,
  logout,
  setUserData,
  selectUserData,
} from "../../features/userSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  DialogContentText,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OwnWorkouts = () => {
  const [workouts, setWorkouts] = useState([]);
  const userData = useSelector(selectUserData);
  const [name, setName] = React.useState("");
  const history = useHistory();
  const [Id, setId] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  useEffect(() => {
    db.collection("coaches")
      .doc(userData?.id)
      .collection("ownWorkout")
      .onSnapshot((snapshot) =>
        setWorkouts(
          snapshot.docs.map((doc) => ({ id: doc.id, workout: doc.data() }))
        )
      );
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setId("");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          margin: 20,
          alignItems: "center",
        }}
      >
        <div
          style={{
            cursor: "pointer",
            marginTop: 5,
          }}
          onClick={() => history.push("/workouts")}
        >
          <ArrowBackIosRoundedIcon
            style={{ height: 18, width: 18, cursor: "pointer" }}
          />
        </div>
        <h2
          style={{
            marginLeft: 10,
            fontWeight: 200,
          }}
        >
          Own Workouts
        </h2>

        <div
          style={{ marginLeft: "40px", marginTop: "-20px" }}
          onClick={() => history.push("/add-own-workout")}
        >
          <div
            className="addWorkout__button"
            style={{
              marginRight: 0,
            }}
            onClick={() => history.push("/add-own-workout")}
            style={{ width: 150 }}
          >
            <img
              src="/assets/plus_thin_black.png"
              alt=""
              width="15px"
              height="15px"
            />
            <h5>ADD OWN EXERCISE</h5>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: 40,
        }}
      >
        {workouts.map(({ id, workout }) => (
          <div
            style={{
              height: 150,
              width: 700,
              borderRadius: 5,
              border: "1px solid black",
              marginBottom: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: 25,
              }}
            >
              <img
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setId(workout.videoUrl);
                  setOpenDialog(true);
                }}
                src={workout.thumbnail_url}
                width="100"
                height="100"
              />

              <div
                style={{
                  marginLeft: 30,
                  width: 500,
                }}
              >
                <h3>{workout.name}</h3>
                <a href={workout.videoUrl} target="_blank">
                  {workout.videoUrl}
                </a>
              </div>
              <div style={{}}>
                <div
                  style={{
                    fontWeight: "bold",
                    width: 80,
                    height: 25,
                    textAlign: "center",
                    borderRadius: 10,
                    backgroundColor: "#ef654d",
                    color: "white",
                    cursor: "pointer",
                    fontSize: 16,
                  }}
                  onClick={() =>
                    history.push({
                      pathname: "/edit-own-workout",
                      state: {
                        workout: workout,
                        workoutId: id,
                      },
                    })
                  }
                >
                  Edit
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    width: 80,
                    height: 25,
                    textAlign: "center",
                    borderRadius: 10,
                    backgroundColor: "red",
                    color: "white",
                    marginTop: 30,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    db.collection("coaches")
                      .doc(userData?.id)
                      .collection("ownWorkout")
                      .doc(id)
                      .delete()
                      .then(() => {
                        alert("Deleted Successfully");
                      });
                  }}
                >
                  Delete
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        // fullWidth
        onClose={handleCloseDialog}
      >
        <DialogContent>
          {/* <video width="500" height="500" controls>
            <source src={workoutVideoUrl} type="video/mp4" />
          </video> */}
          {/* <div
              dangerouslySetInnerHTML={{
                __html: `<iframe title="video" height="470" width="730" frameborder="0" src="https://player.vimeo.com/video/${workoutVideoUrl.substring(
                  workoutVideoUrl.lastIndexOf("/") + 1
                )}"></iframe>`,
              }}
            /> */}

          <ReactPlayer
            // title={video.title}
            // id="iframe"
            style={{ borderRadius: 10 }}
            url={Id}
            width="600px"
            height="400px"
            frameborder="0"
            controls
          />

          <div
            onClick={handleCloseDialog}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: 0,
              top: 0,
              padding: 12,
            }}
          >
            {" "}
            <CloseIcon />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OwnWorkouts;
