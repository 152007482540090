import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TableContainer } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React, { useEffect, useState } from 'react';
import Loader from "react-loader-spinner";
import { useHistory } from 'react-router-dom';
import db from '../../services/Firebase';
import { customStyles } from '../Styles/UIStyles';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../features/userSlice';
import "./Website.css"
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);


export const WebsiteLeadsInfo = () => {

    const styles = customStyles();
    const history = useHistory();
    const userEmail = useSelector(selectUser)
    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState(true)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchBlogsInfo = async () => {
        const leads_info = [];
        var snapshot = await db.collection('WebsiteLeadsInformation')
            .where("user_id", "==", userEmail)
            .get();
        snapshot.forEach((doc) => {
            leads_info.push({ data: doc.data(), id: doc.id });
        });
        setLeads(leads_info);
        setLoading(false);
    };

    useEffect(() => {
        fetchBlogsInfo();
    }, [])

    return (
        <div id="websiteid">
            <h1 className={styles.section_header}>
                <label htmlFor="icon-button-file">
                    <IconButton className={styles.icon_button} aria-label="back-button" onClick={() => history.push("/")} >
                        <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                </label>
                Website Leads
            </h1>
            <br />
            <br />
            {
                loading && (
                    <div className={styles.dialog} >
                        <Loader
                            type="TailSpin"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        />
                    </div>
                )
            }
            {
                !loading &&
                <Paper>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Contact Name</StyledTableCell>
                                    <StyledTableCell>Contact Number</StyledTableCell>
                                    <StyledTableCell>Contact Email</StyledTableCell>
                                    <StyledTableCell>Message</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((each_lead) => {
                                    return (
                                        <TableRow key={each_lead.id}>
                                            <TableCell>{each_lead.data.sender_name}</TableCell>
                                            <TableCell>{each_lead.data.sender_number}</TableCell>
                                            <TableCell>{each_lead.data.sender_email}</TableCell>
                                            <TableCell>{each_lead.data.sender_message}</TableCell>
                                        </TableRow>
                                    )
                                }
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={leads.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            component="div"
                        />
                    </TableContainer>
                </Paper>
            }
        </div >
    )
}
